<template>
  <v-select :items="items" :label="label" :value="value" @input="value_change">
    <template slot="item" slot-scope="item">
      <div style="margin-top: 5px">
        <div v-if="item.item.value">
          <img
            :src="'https://storage.googleapis.com/opticalgym-large-assets/patterns/thumbnails/' + item.item.value + '.png'"
            style="background-color: black; height:80px; width: 80px;"
          />
          <div style="padding-top: 30px;  padding-left: 10px; float: right;">{{ item.item.text }}</div>
        </div>
        <div v-else>{{ $t('common.none') }}</div>
      </div>
    </template>
  </v-select>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: "Pattern",
    },
  },
  data() {
    return {
      items: [
        { text: this.$t("common.none"), value: "" },
        { text: this.$t("select_pattern.blanket"), value: "Blanket" },
        { text: this.$t("select_pattern.blanket") + " 2", value: "Blanket2" },
        { text: this.$t("select_pattern.circles") + " 1", value: "Circles1" },
        { text: this.$t("select_pattern.circles") + " 2", value: "Circles2" },
        { text: this.$t("select_pattern.circles") + " 3", value: "Circles3" },
        { text: this.$t("select_pattern.diagonal"), value: "Diagonal" },
        {
          text: this.$t("select_pattern.horizontal_stripes"),
          value: "Horizontal Stripes",
        },
        {
          text: this.$t("select_pattern.horizontal_stripes") + " 2",
          value: "Horizontal Stripes2",
        },
        { text: this.$t("select_pattern.mix"), value: "Mix" },
        { text: this.$t("select_pattern.mix") + " 2", value: "Mix2" },
        { text: this.$t("select_pattern.mix") + " 3", value: "Mix3" },
        { text: this.$t("select_pattern.mix") + " 4", value: "Mix4" },
        { text: this.$t("select_pattern.net"), value: "Net" },
        { text: this.$t("select_pattern.net") + " 2", value: "Net2" },
        { text: this.$t("select_pattern.net") + " 3", value: "Net3" },
        { text: this.$t("select_pattern.ornament"), value: "Ornament" },
        { text: this.$t("select_pattern.plant"), value: "Plant" },
        { text: this.$t("select_pattern.ribby"), value: "Ribby" },
        { text: this.$t("select_pattern.ribby") + " 2", value: "Ribby2" },
        { text: this.$t("select_pattern.ribby") + " 3", value: "Ribby3" },
        { text: this.$t("select_pattern.squares"), value: "Squares" },
        { text: this.$t("select_pattern.squares") + " 2", value: "Squares2" },
        { text: this.$t("select_pattern.stars"), value: "Stars" },
        { text: this.$t("select_pattern.stars") + " 2", value: "Stars Black" },
        {
          text: this.$t("select_pattern.stars") + " 3",
          value: "Stars on Black",
        },
        {
          text: this.$t("select_pattern.stripes_with_dots"),
          value: "Stripes with Dots",
        },
        {
          text: this.$t("select_pattern.stripes_and_dots_horizontal"),
          value: "Stripes with Dots Horizontal",
        },
        { text: this.$t("select_pattern.swirls"), value: "Swirls1" },
        { text: this.$t("select_pattern.swirls") + " 2", value: "Swirls2" },
        { text: this.$t("select_pattern.swirls") + " 3", value: "Swirls3" },
        { text: this.$t("select_pattern.triangles"), value: "Triangles1" },
        {
          text: this.$t("select_pattern.triangles") + " 2",
          value: "Triangles2",
        },
        {
          text: this.$t("select_pattern.triangles") + " 3",
          value: "Triangles3",
        },
        {
          text: this.$t("select_pattern.vertical_lines"),
          value: "Vertical Lines",
        },
        {
          text: this.$t("select_pattern.vertical_lines") + " 2",
          value: "Vertical Lines2",
        },
        {
          text: this.$t("select_pattern.vertical_stripes"),
          value: "Vertical Stripes",
        },
        { text: this.$t("select_pattern.waves"), value: "Waves1" },
        { text: this.$t("select_pattern.waves") + " 2", value: "Waves2" },
        { text: this.$t("select_pattern.waves") + " 3", value: "Waves3" },
        { text: this.$t("select_pattern.web"), value: "Web" },
        { text: this.$t("select_pattern.web") + " 2", value: "Web2" },
        { text: this.$t("select_pattern.zigzag"), value: "Zigzag1" },
        { text: this.$t("select_pattern.zigzag") + " 2", value: "Zigzag2" },
        { text: this.$t("select_pattern.zigzag") + " 3", value: "Zigzag3" },
        { text: this.$t("select_pattern.zigzag") + " 4", value: "Zigzag4" },
        { text: this.$t("select_pattern.zigzag") + " 5", value: "Zigzag5" },
        { text: this.$t("select_pattern.zigzag") + " 6", value: "Zigzag6" },
        { text: this.$t("select_pattern.zigzag") + " 7", value: "Zigzag7" },
        { text: this.$t("select_pattern.zigzag") + " 8", value: "Zigzag8" },
      ],
    };
  },
  methods: {
    value_change(event) {
      this.$emit("input", event);
    },
  },
};
</script>