<template>
  <v-sheet elavation="5" style="max-width: 800px; margin: auto;">
    <v-card class="d-flex flex-column justify-center align-center" style="height: 100%; text-align: center;">
      <v-card-title class="headline" style="text-align: center;">{{
        $t("common.no_results")
      }}</v-card-title>
      <v-card-text>
        <v-icon size="50">mdi-emoticon-sad-outline</v-icon>
        <p style="font-size: larger" class="mt-4">{{ $t("common.no_results_message", { query: query }) }}</p>
      </v-card-text>
    </v-card>
  </v-sheet>
</template>

<script>
export default {
  props: {
    query: {
      type: String,
      required: true,
    },
  },
};
</script>
