<template>
  <v-app>
    <div v-if="current_user.is_admin">
      <v-toolbar dark flat style="max-height: 65px" class="page_toolbar">
        <v-toolbar-title>
          <v-icon left>fa-cogs</v-icon>Administration
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn text to="/app/admin/clinics">
            <v-icon left>business</v-icon>Clinics
          </v-btn>
          <v-btn text to="/app/admin/revisions">
            <v-icon left>fa-database</v-icon>Revisions
          </v-btn>
          <v-btn text to="/app/admin/users">
            <v-icon left>account_circle</v-icon>Users
          </v-btn>
          <v-btn text to="/app/admin/charges">
            <v-icon left>attach_money</v-icon>Charges
          </v-btn>
          <v-btn text to="/app/admin/monthly">
            <v-icon left>assessment</v-icon>KPI
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <AllCharges />
    </div>
    <PermissionDenied v-else />
  </v-app>
</template>

<script>
import AllCharges from"../../shared/components/admin/AllCharges";
import PermissionDenied from"../../shared/components/PermissionDenied";

export default {
  name: "App",
  components: {
    AllCharges,
    PermissionDenied,
  },
  data() {
    return {
      current_user: this.$root.$children[0].current_user,
      user_invites: this.$root.$children[0].user_invites,
    };
  },
};
</script>
