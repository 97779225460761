<template>
  <div>
    <v-card id="questionnaire_exercise_modal">
      <v-card-title style="padding: 0px;">
        <v-toolbar grey flat>
          <v-btn icon @click="close">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ exercise.title }}</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <slot name="toolbar_items"></slot>
          </v-toolbar-items>
        </v-toolbar>
      </v-card-title>
      <v-divider />

      <v-card-text>
        <div
          v-if="exercise_instructions"
          style="font-size:larger; margin-top: 20px;"
          class="exercise-instructions"
          v-html="exercise_instructions"
        ></div>

        <VuetifySurvey ref="VuetifySurvey" v-model="survey_data" :survey="questionnaire" />
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn @click="close">Cancel</v-btn>
        <v-btn color="primary" @click="finished">Submit</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import xss from "xss";
import VuetifySurvey from "./vuetify-survey/components/VuetifySurvey.vue";

export default {
  components: { VuetifySurvey },
  props: {
    exercise: {
      type: Object,
      required: true,
    },
    trusted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      survey_data: {},
      start_time: Date.now(),
      end_time: 0,
    };
  },
  computed: {
    current_language() {
      return this.$i18n.lang().slice(0, 2);
    },
    exercise_instructions() {
      if (this.trusted) {
        return this.exercise.instructions;
      } else {
        return xss(this.exercise.instructions);
      }
    },
    questionnaire() {
      return {
        items: [this.exercise.question]
      }
    },
  },
  watch: {},
  methods: {
    finished: function () {
      if (!this.$refs.VuetifySurvey.validate()) {
        return;
      }
      this.stop();

      this.end_time = Date.now();
      let time_taken_ms = this.end_time - this.start_time;
      
      this.$emit("exercise-finished", {
        level_completed: false,
        type: "questionnaire",
        score: Object.assign({time_taken_ms}, this.survey_data),
        report: {
          timeStart: this.start_time,
          exercise_id: this.exercise.id || "questionnaire",
          level: 0,
          timeToComplete: time_taken_ms, //ms
          pausedTime: 0,
          events: [],
          params: {},
          calibration: {},
        },
      });
    },
    close: function () {
      this.stop();
      this.$emit("close", {});
    },
    stop: function () {},
  },
};
</script>

<style>
</style>