import OpticalGymBaseClass from "./opticalgym_base_class";

export default class UserMetadata extends OpticalGymBaseClass {
    id: string | null;
    data: any;
    user_id: string | null;
    clinic_id: string | null;
    category: string;

    static fromJson(data: Object): UserMetadata {
        let calibration = new UserMetadata();
        Object.assign(calibration, data);
        return calibration;
    }
}
