<template>
  <div class="text-center mt-6">
    <v-btn v-if="exercise_loaded" large color="green" @click="start_exercise">
      <v-icon>mdi-play</v-icon>
      {{ $t("common.start") }}
    </v-btn>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  components: {},
  props: {
    fullscreen_enabled: {
      type: Boolean,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    exercise: {
      type: Object,
      required: true,
    },
    compat: {
      type: Boolean,
      default: () => false,
    },
    unique_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      exercise_loaded: false,
      instance: null,
    };
  },
  mounted() {
    this.create_exercise_container();
    this.load_exercise();
  },
  methods: {
    reset: function () {
      this.exercise_loaded = false;
      this.remove_exercise_parent();
    },
    create_exercise_container() {
      const container = document.createElement("div");
      container.id = "opticalgym-exercise-container";
      container.style.display = "none";
      document.body.appendChild(container);
    },
    async load_exercise() {
      try {
        // Use new Function to dynamically create the import statement
        const importPath = `/api/exercise/${this.exercise.id}/main.js`;
        const module = await new Function(`return import('${importPath}')`)();
        const ExerciseClass = module.default;
        this.initialize_exercise(ExerciseClass);
      } catch (error) {
        console.error("Failed to load exercise module:", error);
      }
    },
    initialize_exercise(ExerciseClass) {
      this.instance = new ExerciseClass({
        params: this.config,
        lang: this.$i18n.lang(),
        parent: "opticalgym-exercise-container",
        asset_path: `/api/exercise/${this.exercise.id}/assets/`,
        ready: () => {
          this.exercise_loaded = true;
        },
        finished: (report) => {
          // TODO: modify some vue state to show the report and log it to the backend etc.
          console.log(report);
        },
      });
    },
    start_exercise() {
      if (this.instance) {
        this.instance.start();
      }
    },
    clear_exercise: function () {
      this.exercise_loaded = false;
      this.instance = null;
      this.remove_exercise_parent();
    },
    remove_exercise_parent() {
      const container = document.getElementById("game-container");
      if (container) {
        container.parentNode.removeChild(container);
      }
    },
  },
};
</script>
