<template>
  <v-card class="opticalgym_calibration_card">
    <v-card-title style="padding: 0px;" class="calibration-toolbar-wrapper">
      <v-toolbar>
        <v-btn icon @click="close_calibration">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("calibration.calibrate_device") }}</v-toolbar-title>
      </v-toolbar>
    </v-card-title>
    <v-card-text style="padding: 0px; margin-left: -8px; margin-top: -10px">
      <v-progress-circular v-if="user_metadata_loading" />
      <v-stepper v-else v-model="current_step" non-linear>
        <v-stepper-header>
          <v-stepper-step
            style="cursor: pointer;"
            complete-icon="check"
            :complete="current_step > 1"
            step="1"
            @click="save_calibration(); current_step = 1"
          >{{ $t("calibration.screen_height") }}</v-stepper-step>
          <v-divider />

          <v-stepper-step
            style="cursor: pointer;"
            complete-icon="check"
            :complete="current_step > 2"
            step="2"
            @click="save_calibration(); current_step = 2"
          >{{ $t("calibration.working_distance") }}</v-stepper-step>
          <v-divider />

          <v-stepper-step
            style="cursor: pointer;"
            complete-icon="check"
            :complete="current_step > 3"
            step="3"
            @click="save_calibration(); current_step = 3"
          >{{ $t("calibration.anaglyph_glasses") }}</v-stepper-step>
          <v-divider />

          <v-stepper-step
            style="cursor: pointer;"
            complete-icon="check"
            step="4"
            @click="save_calibration(); current_step = 4"
          >{{ $t("calibration.colour_calibration") }}</v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <div v-if="viewport_likely_scaled">
              <v-alert border="right" colored-border type="warning" elevation="5" class="pa-10">
                <h2>{{ $t('calibration.browser_zoomed') }}</h2>

                <p class="pt-5 pb-5"> {{ $t('calibration.browser_zoomed_desc') }}</p>

                <v-layout row wrap justify-space-around>
                  <v-flex xs5>
                    <v-sheet elevation="2" class="pa-10" style="height: 200px">
                      <div class="zoom-text">
                        <div v-if="is_mobile">
                          <img src="/assets/pinch_zoom.png" style="height: 100px" />
                          <h3>{{ $t('calibration.pinch_zoom') }}</h3>
                        </div>
                        <div v-else-if="is_mac">
                          <img src="/assets/command_zero.png" style="height: 100px" />
                          <h3>{{ $t('calibration.command_zero_zoom') }} </h3>
                        </div>

                        <div v-else>
                          <img class="mb-6" src="/assets/control_zero.png" style="height: 100px" />
                          <h3>{{ $t('calibration.control_zero_zoom') }}</h3>
                        </div>
                      </div>
                    </v-sheet>
                  </v-flex>

                  <v-flex xs5>
                    <v-sheet elevation="2" class="pa-10" style="height: 200px">
                      <p>{{ $t('calibration.confirm_zoom_desc') }}</p>
                      <v-btn @click="viewport_likely_scaled = false">{{ $t('calibration.confirm_zoom') }}</v-btn>
                    </v-sheet>
                  </v-flex>
                </v-layout>
              </v-alert>
            </div>
            <v-container v-else style="min-height: 480px">
              <v-layout row wrap>
                <v-flex xs12>
                  <h3>{{ $t("calibration.screen_height") }}</h3>
                  <p class="calibration-instruction mt-4">{{ $t("calibration.screen_height_text") }}</p>
                  <v-container fluid grid-list-lg>
                    <v-layout row wrap>
                      <v-flex xs12 md4>
                        <DistanceSelector
                          v-model="height_cm"
                          class="calibration_distance_selector"
                          :min="10"
                          :max="150"
                        />

                        <v-switch
                          v-if="isTouchDevice"
                          v-model="is_touch_screen"
                          :label="$t('calibration.touch_screen')"
                        ></v-switch>
                      </v-flex>
                      <v-flex xs12 md8 class="calibration_card_image_wraper" :styl="image_style">
                        <img
                          id="calibration_card_image"
                          :src="'/assets/standard-card.' + $i18n.lang() + '.png'"
                        />
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-flex>
              </v-layout>
            </v-container>

            <v-btn
              v-if="!viewport_likely_scaled"
              color="primary"
              class="calibration_continue"
              @click="save_calibration(); current_step = 2"
            >{{ $t("common.continue") }}</v-btn>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-container style="min-height: 480px">
              <v-layout row wrap justify-space-around>
                <v-flex md6 xs12>
                  <h3>{{ $t("calibration.working_distance") }}</h3>
                  <p class="calibration-instruction mt-4">{{ $t("calibration.working_distance_text") }}</p>
                  <v-container fluid grid-list-lg>
                    <DistanceSelector
                      v-model="distance_cm"
                      class="calibration_working_distance_selector"
                      :min="10"
                      :max="500"
                      @change="distance_manually_changed"
                    />
                  </v-container>
                </v-flex>
              </v-layout>
            </v-container>

            <v-btn
              color="primary"
              class="calibration_continue"
              @click="save_calibration(); current_step = 3"
            >{{ $t("common.continue") }}</v-btn>
          </v-stepper-content>

          <v-stepper-content step="3">
            <v-container style="min-height: 480px">
              <v-layout row wrap justify-space-around>
                <v-flex xs12 md12>
                  <h3>{{ $t("calibration.anaglyph_glasses") }}</h3>
                  <p class="calibration-instruction mt-4">{{ $t("calibration.anaglyph_glasses_text") }}</p>
                  <v-container fluid class="calibration_select_anaglyph_glasses">
                    <v-layout justify-center>
                      <v-flex
                        xs2
                        class="selector-red-cyan"
                        :class="anaglyph_orientation"
                        @click="change_anaglyph_orientation('red-cyan')"
                      >
                        <div class="align-center">{{ $t("calibration.red_left_blue_right") }}</div>
                        <v-img src="/assets/red-cyan.png" contain />
                      </v-flex>
                      <v-flex
                        xs2
                        offset-xs1
                        class="selector-cyan-red"
                        :class="anaglyph_orientation"
                        @click="change_anaglyph_orientation('cyan-red')"
                      >
                        <div class="align-center">{{ $t("calibration.blue_left_red_right") }}</div>
                        <v-img src="/assets/cyan-red.png" contain />
                      </v-flex>
                      <v-flex
                        xs2
                        offset-xs1
                        class="selector-green-red"
                        :class="anaglyph_orientation"
                        @click="change_anaglyph_orientation('green-red')"
                      >
                        <div class="align-center">{{ $t("calibration.green_left_red_right") }}</div>
                        <v-img src="/assets/green-red.png" contain />
                      </v-flex>
                      <v-flex
                        xs2
                        offset-xs1
                        class="selector-red-green"
                        :class="anaglyph_orientation"
                        @click="change_anaglyph_orientation('red-green')"
                      >
                        <div class="align-center">{{ $t("calibration.red_left_green_right") }}</div>
                        <v-img src="/assets/red-green.png" contain />
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-flex>
              </v-layout>
            </v-container>

            <v-btn
              color="primary"
              class="calibration_continue"
              @click="save_calibration(); current_step = 4"
            >{{ $t("common.continue") }}</v-btn>
            <v-btn
              style="margin-left: 30px"
              @click="save_calibration(); close_calibration();"
            >{{ $t("calibration.dont_have_glasses") }}</v-btn>
          </v-stepper-content>

          <v-stepper-content step="4">
            <v-container style="min-height: 470px">
              <v-layout row wrap justify-space-around>
                <v-flex xs12 md12>
                  <h3>{{ $t("calibration.colour_calibration") }}</h3>
                  <ol class="calibration-instruction mt-4">
                    <li>
                      {{ $t("calibration.put_anaglyph_glasses") }}
                      <img
                        src="/assets/red-blue-glasses-icon.png"
                        style="height: 14px"
                      />
                    </li>
                    <li>{{ $t("calibration.covering_one_eye_text") }}</li>
                  </ol>
                  <v-container fluid class="calibration_colour_calibration">
                    <v-layout v-if="anaglyph_orientation == 'cyan-red'" justify-center>
                      <v-flex xs6 md6>
                        <CalibrationColour
                          v-model="red_brightness"
                          side="left"
                          colour="red"
                          opposite_colour="blue"
                        />
                      </v-flex>
                      <v-flex xs6 md6>
                        <CalibrationColour
                          v-model="blue_brightness"
                          side="right"
                          colour="blue"
                          opposite_colour="red"
                        />
                      </v-flex>
                    </v-layout>
                    <v-layout v-if="anaglyph_orientation == 'red-cyan'" justify-center>
                      <v-flex xs6 md6>
                        <CalibrationColour
                          v-model="blue_brightness"
                          side="left"
                          colour="blue"
                          opposite_colour="red"
                        />
                      </v-flex>
                      <v-flex xs6 md6>
                        <CalibrationColour
                          v-model="red_brightness"
                          side="right"
                          colour="red"
                          opposite_colour="blue"
                        />
                      </v-flex>
                    </v-layout>

                    <v-layout v-if="anaglyph_orientation == 'green-red'" justify-center>
                      <v-flex xs6 md6>
                        <CalibrationColour
                          v-model="red_brightness"
                          side="left"
                          colour="red"
                          opposite_colour="green"
                        />
                      </v-flex>
                      <v-flex xs6 md6>
                        <CalibrationColour
                          v-model="green_brightness"
                          side="right"
                          colour="green"
                          opposite_colour="red"
                        />
                      </v-flex>
                    </v-layout>
                    <v-layout v-if="anaglyph_orientation == 'red-green'" justify-center>
                      <v-flex xs6 md6>
                        <CalibrationColour
                          v-model="green_brightness"
                          side="left"
                          colour="green"
                          opposite_colour="red"
                        />
                      </v-flex>
                      <v-flex xs6 md6>
                        <CalibrationColour
                          v-model="red_brightness"
                          side="right"
                          colour="red"
                          opposite_colour="green"
                        />
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-flex>
              </v-layout>
            </v-container>
            <v-btn
              class="calibration_continue calibration_done"
              color="primary"
              @click="save_calibration(); close_calibration();"
            >{{ $t("common.done") }}</v-btn>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { Calibration } from "../classes/calibration";
import DistanceSelector from "./DistanceSelector.vue";
import CalibrationColour from "./CalibrationColour.vue";
import Vue from "vue";

import jQuery from "jquery";

export default {
  components: { DistanceSelector, CalibrationColour },
  data() {
    var screen_height_guess = Math.round(screen_height() / (28 * window.devicePixelRatio));

    let default_anaglyph_orientation = "cyan-red";
    if (window.app_config && window.app_config.default_anaglyph_orientation) {
      default_anaglyph_orientation = window.app_config.default_anaglyph_orientation;
    }

    var data = {
      current_step: 1,
      height_cm: screen_height_guess,
      distance_cm: distance_cm_guess(screen_height_guess),
      anaglyph_orientation: default_anaglyph_orientation, // Are we red-cyan or cyan-red?
      calibrate_distance_set: false, // Has the user yet to manually calibrate the distance?
      red_brightness: 75,
      blue_brightness: 75,
      green_brightness: 75,
      is_touch_screen: navigator.maxTouchPoints && navigator.maxTouchPoints > 2,
      viewport_likely_scaled: viewport_likely_scaled(),
      is_mac: navigator.platform.toLowerCase().indexOf("mac") > -1,
    };

    var calibration = this.$store.state.current_calibration;

    if (calibration) {
      if (!calibration.colour_correction) {
        calibration.colour_correction = {
          red_brightness: 0.75,
          blue_brightness: 0.75,
          green_brightness: 0.75,
        };
      }
      if (!calibration.colour_correction.red_brightness) {
        calibration.colour_correction.red_brightness = 0.75;
      }
      if (!calibration.colour_correction.blue_brightness) {
        calibration.colour_correction.blue_brightness = 0.75;
      }
      if (!calibration.colour_correction.green_brightness) {
        calibration.colour_correction.green_brightness = 0.75;
      }

      data.height_cm = calibration.height_cm;
      data.distance_cm = calibration.distance_cm;
      data.anaglyph_orientation = calibration.anaglyph_orientation;
      data.red_brightness = parseInt(calibration.colour_correction.red_brightness * 100);
      data.blue_brightness = parseInt(calibration.colour_correction.blue_brightness * 100);
      data.green_brightness = parseInt(calibration.colour_correction.green_brightness * 100);

      if (calibration.no_touch_screen === true || calibration.no_touch_screen === false) {
        data.is_touch_screen = !calibration.no_touch_screen;
      }
    }

    return data;
  },
  computed: {
    card_height_px: function () {
      return card_image_height(this.height_cm);
    },
    image_style() {
      return "zoom: " + this.unzoom_amount;
    },
    isTouchDevice() {
      return navigator.maxTouchPoints && navigator.maxTouchPoints > 2;
    },
    unzoom_amount() {
      let body_zoom = jQuery("body").css("zoom");
      return 1 / body_zoom;
    },
    ...mapState(["user_calibrations", "user_metadata_loading", "current_user", "current_calibration", "is_mobile", "app_config"]),
  },
  watch: {
    height_cm: function () {
      if (!this.calibrate_distance_set) {
        this.distance_cm = distance_cm_guess(this.height_cm);
      }

      // Set the image size manually so we have total control
      this.set_image_size();
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.viewport_likely_scaled = viewport_likely_scaled();
      Vue.nextTick(() => {
        this.set_image_size();
      });
    });

    // Set the image size manually so we have total control
    Vue.nextTick(() => {
      this.set_image_size();
    });
  },
  methods: {
    set_image_size() {
      if (!this.viewport_likely_scaled) {
        var image = document.getElementById("calibration_card_image");
        image.style.height = this.card_height_px + "px";
      }
    },
    change_anaglyph_orientation(new_orientation) {
      this.anaglyph_orientation = new_orientation;
    },
    distance_manually_changed: function () {
      this.calibrate_distance_set = true;
    },

    close_calibration: function () {
      this.$store.commit("setCalibrationDialog", false);
      this.current_step = 1;
    },
    save_calibration: function () {
      var current_calibration;

      if (this.current_calibration) {
        current_calibration = this.current_calibration;
      } else {
        current_calibration = new Calibration();
        current_calibration.setDefaultValues();
      }
      current_calibration = Object.assign(current_calibration, {
        height_cm: this.height_cm,
        distance_cm: this.distance_cm,
        anaglyph_orientation: this.anaglyph_orientation,
        colour_correction: {
          red_brightness: this.red_brightness / 100,
          blue_brightness: this.blue_brightness / 100,
          green_brightness: this.green_brightness / 100,
        },
      });

      if (this.isTouchDevice && !this.is_touch_screen) {
        current_calibration.no_touch_screen = true;
      } else {
        current_calibration.no_touch_screen = false;
      }

      current_calibration.storeToLocal();

      this.$store.dispatch("updateCurrentCalibration", current_calibration).then(() => {
        // TODO: MessageBus Calibration updated
      });
    },
  },
};

// Utility Functions
// -----------------

function card_image_height(ui_value) {
  // Standard card is 53.98 mm (5.398cm) in height.
  // Equation: scaler = 5.398cm * screen_height_px / screen_cm
  var scaler = (5.398 * screen_height()) / ui_value;

  return scaler;
}

// Some stupid devices (iOS cough cough) don't have width and height
// correctly defined when they are in landscape mode.
// So always use the largest as width and the smallest as height.
// We enforce portait mode, so this should be fine.
function _screen_width() {
  return Math.max(screen.width, screen.height);
}

// TODO: Take into account devices that cannot go fullscreen.
function screen_height() {
  return Math.min(screen.width, screen.height);
}

function distance_cm_guess(screen_height_cm) {
  var distance_cm_guess = screen_height_cm * 3;
  if (distance_cm_guess < 30) {
    distance_cm_guess = 30;
  }

  return distance_cm_guess;
}

function viewport_likely_scaled() {
  if (window.visualViewport) {
    if (!Number.isInteger(window.visualViewport.height) && !Number.isInteger(window.visualViewport.width)) {
      return true;
    }
  }
  if (window.devicePixelRatio && !Number.isInteger(window.devicePixelRatio)) {
    return true;
  }
  if (window.innerWidth > window.screen.width && window.innerHeight > window.screen.width) {
    return true;
  }

  if (window.devicePixelRatio && window.devicePixelRatio >= 3 && !(navigator.maxTouchPoints && navigator.maxTouchPoints > 2)) {
    return true;
  }

  return false;
}
</script>


<style scoped>
.selector-red-cyan.red-cyan,
.selector-cyan-red.cyan-red,
.selector-green-red.green-red,
.selector-red-green.red-green {
  font-weight: bold;
  box-shadow: 1px 1px 2px black, 0 0 25px blue, 0 0 5px darkblue;
}

.selector-red-cyan,
.selector-cyan-red,
.selector-green-red,
.selector-red-green {
  cursor: pointer;
}

.calibration-toolbar-wrapper {
  padding: 0px;
}

.calibration_card_image_wraper {
  text-align: center;
}

.align-center {
  text-align: center;
}

.opticalgym_calibration_card .v-stepper__header {
  height: 55px;
}

.opticalgym_calibration_card .v-stepper__step {
  margin-top: -5px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.zoom-text h3 {
  font-weight: 500;
}

.calibration-instruction {
  font-size: larger;
}
</style>