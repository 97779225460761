<template>
  <v-card>
    <v-card-title style="padding: 0px">
      <v-toolbar grey flat>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title
          >{{ $t("common.activity_calendar") }} - {{ user.name_given }}
          {{ user.name_family[0] }}</v-toolbar-title
        >
      </v-toolbar>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-container>
        <v-layout v-if="loading" justify-center>
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-layout>
        <div v-else>
          <v-layout
            justify-center
            :class="small_screen ? 'pt-2 pb-5' : 'pt-5 pb-8'"
          >
            <v-flex xs1 offset-xs3>
              <v-btn fab small color="primary" @click="previous">
                <v-icon dark>mdi-chevron-left</v-icon>
              </v-btn>
            </v-flex>
            <v-flex xs4>
              <h2
                style="
                  font-size: 30px;
                  margin-top: 10px;
                  width: 300px;
                  text-align: center;
                "
              >
                {{ current_month }}
              </h2>
            </v-flex>
            <v-flex xs4>
              <v-btn
                fab
                small
                color="primary"
                :disabled="next_disabled"
                @click="next"
              >
                <v-icon dark>mdi-chevron-right</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>

          <v-sheet height="600" class="user_activity_calendar">
            <v-calendar
              ref="calendar"
              v-model="caldendar_start"
              type="month"
              :events="events"
              :event-height="event_height"
              :event-color="event_color"
              :event-more="false"
              @click:event="open_day_report"
              @change="calendar_changed"
            >
              <template v-slot:event="{ event }">
                <div class="activity_calendar_event">
                  <v-btn
                    v-if="event.report_feedback"
                    class="mx-2"
                    dark
                    fab
                    x-small
                    color="green darken-2"
                    style="position: absolute; left: 110px; top: 5px"
                  >
                    <v-icon dark>mdi-comment</v-icon>
                  </v-btn>
                  <div style="margin-left: 63px; margin-bottom: 10px">
                    {{ event.day }}
                  </div>
                  <div
                    v-for="(summary, index) in event.activity_summary"
                    :key="index"
                  >
                    {{ summary }}
                  </div>
                  <div v-if="event.report_feedback">
                    {{ $t("activity_calendar.feedback_given") }}
                  </div>
                </div>
              </template>
            </v-calendar>
          </v-sheet>
        </div>
      </v-container>
    </v-card-text>
    <v-dialog v-model="day_report_dialog" max-width="1200" :transition="false">
      <ActivityCalendarDayReport
        v-if="day_report_dialog"
        ref="day_report"
        :clinic_id="clinic_id"
        :user="user"
        :date="day_report_dialog_date"
        :exercises="exercises"
        :video_exercises="video_exercises"
        @close="close_day_report"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "axios";
import ActivityCalendarDayReport from "./ActivityCalendarDayReport";
import Vue from "vue";
import { mapState } from "vuex";

export default {
  components: { ActivityCalendarDayReport },
  props: {
    clinic_id: {
      type: String,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    exercises: {
      type: Object,
      required: true,
      validator: function (value) {
        return typeof value == "object" || typeof value == "undefined";
      },
    },
    video_exercises: {
      type: Object,
      required: true,
      validator: function (value) {
        return typeof value == "object" || typeof value == "undefined";
      },
    },
  },
  data() {
    return {
      activity_summary: null,
      loading: true,
      caldendar_start: new Date().toString(),
      current_month: "",
      event_height: 110,
      current_calendar_start: "",
      current_calendar_end: "",
      day_report_dialog: false,
      day_report_dialog_date: "1900-01-01",
      events: [{ start: "1900-01-01", name: "" }], // Hack: https://github.com/vuetifyjs/vuetify/issues/7790
    };
  },
  computed: {
    ...mapState(["small_screen"]),
    next_disabled() {
      var now = new Date();
      let end = new Date(this.current_calendar_end);
      return now < end;
    },
  },
  mounted() {
    this.load_activity_summary();
  },
  methods: {
    previous() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    calendar_changed(calendar_date) {
      this.current_calendar_start = calendar_date.start.date;
      this.current_calendar_end = calendar_date.end.date;
      this.compute_current_month();
    },
    compute_current_month() {
      let tz_date = new Date(this.current_calendar_start);
      let date = new Date(
        tz_date.getUTCFullYear(),
        tz_date.getUTCMonth(),
        tz_date.getUTCDate()
      );
      let month = date.toLocaleString(this.$i18n.lang(), {
        month: "long",
        year: "numeric",
      });
      this.current_month = month;
    },
    close() {
      this.$emit("close", {});
    },
    load_activity_summary: function () {
      let self = this;

      let d = new Date();
      let offset = d.getTimezoneOffset();

      axios
        .get(
          "/api/user/" +
            this.user.id +
            "/activity_summary/" +
            this.clinic_id +
            "?offset=" +
            offset
        )
        .then(function (response) {
          self.loading = false;
          let resp = response.data;
          if (resp.status == "success") {
            self.activity_summary = resp.data;
            self.compute_events();
          } else if (resp.status == "error") {
            messageBus.$emit("api-error", resp);
          }
        });
    },
    compute_events() {
      let events = [];
      if (this.activity_summary) {
        for (var date in this.activity_summary.days) {
          let parsed_date = new Date(date);
          let day = this.activity_summary.days[date];

          let report_feedback = day.activities.report_feedback ? true : false;

          let event = {
            start: date,
            day: parsed_date.getUTCDate(),
            name: "activity",
            report_feedback,
          };

          // Determine the colour:
          if (day.activities["daily_regimen_complete"]) {
            event.color = "green darken-1";
          } else if (day.activities["create_report"]) {
            event.color = "green lighten-1";
          } else if (day.activities["get_clinic"]) {
            event.color = "blue lighten-2";
          } else {
            if (this.$vuetify.theme.dark) {
              event.color = "grey darken-2";
            } else {
              event.color = "grey lighten-2";
            }
          }

          // Set the event text and the color
          var activity_summary = [];
          if (Object.keys(day.activities).length == 0) {
            activity_summary.push(this.$t("common.no_activity"));
          } else {
            // Did they complete a daily regimen?
            if (day.activities["daily_regimen_complete"] > 0) {
              activity_summary.push(this.$t("common.daily_regimen_completed"));
            }

            // Did they complete an activity?
            if (day.activities["create_report"] > 0) {
              var exercises_completed = day.activities["create_report"];
              activity_summary.push(
                exercises_completed + this.$t("common.exercises_completed")
              );
            }

            // Did they at least log in?
            else if (day.activities["get_clinic"] > 0) {
              activity_summary.push(this.$t("activity_calendar.logged_in"));
              activity_summary.push(
                this.$t("activity_calendar.no_activities_completed")
              );
            }
          }
          event.activity_summary = activity_summary;
          events.push(event);
        }
      }
      this.events = events;
    },
    event_color(event) {
      return event.color;
    },
    open_day_report(event) {
      let self = this;

      this.day_report_dialog = true;
      this.day_report_dialog_date = event.day.date;
      Vue.nextTick(() => {
        self.$refs.day_report.load();
      });
    },
    close_day_report() {
      this.day_report_dialog = false;
    },
  },
};
</script>

<style>
.user_activity_calendar .v-calendar .v-event {
  position: relative;
  top: -44px;
  padding-top: 14px;
  padding-left: 10px;
  border-radius: unset;
  margin-left: 4px;
  margin-top: 2px;
}

.user_activity_calendar .v-calendar-weekly__day {
  overflow: hidden !important;
}
</style>
