<template>
  <div class="component-duration">
    <v-label v-if="!noLabel">{{ duration_label }}</v-label>
    <div style="clear:both; margin-left: 4px; width: 5em">
      <v-text-field
        v-model="minutes"
        flat
        :label="$t('common.mins')"
        type="number"
        maxlength="2"
        min="-1"
        max="60"
        pattern="\d*"
        class="component-duration-minutes"
        style="float: left; margin-top: 0px"
        @input="inputted"
        @focus="focused"
        @change="changed"
      />
      <v-text-field
        v-model="seconds"
        flat
        :label="$t('common.secs')"
        type="number"
        maxlength="2"
        min="-1"
        max="60"
        pattern="\d*"
        class="component-duration-seconds"
        prefix=": "
        @input="inputted"
        @focus="focused"
        @change="changed"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "DEFAULT",
    },
    noLabel: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      required: true,
    },
  },
  data() {
    let duration_label = this.label;
    if (duration_label == "DEFAULT") {
      duration_label = this.$t("common.duration");
    }

    let data = {
      minutes: this.prepend_zeroes(Math.floor(this.value / 60)),
      seconds: this.prepend_zeroes(this.value % 60),
      focus_minutes: false,
      focus_seconds: false,
      duration_label,
    };

    return data;
  },
  methods: {
    get_value() {
      return parseInt(this.minutes) * 60 + parseInt(this.seconds);
    },
    prepend_zeroes(numeric_input) {
      if (numeric_input < 10 && String(numeric_input).length == 1) {
        return "0" + numeric_input;
      } else {
        return numeric_input;
      }
    },
    changed() {
      if (!this.seconds) {
        this.seconds = "00";
      }
      if (!this.minutes) {
        this.minutes = "00";
      }
      this.minutes = this.prepend_zeroes(this.minutes);
      this.seconds = this.prepend_zeroes(this.seconds);
      this.$emit("input", this.get_value());
    },
    inputted() {
      if (this.minutes.length > 2) {
        this.minutes = this.minutes[this.minutes.length - 2];
      }
      if (this.seconds.length > 2) {
        this.seconds = this.seconds[this.seconds.length - 2];
      }
      if (this.minutes >= 60) {
        this.minutes = "00";
      }
      if (this.seconds >= 60) {
        this.seconds = "00";
      }
      if (this.minutes < 0) {
        this.minutes = "59";
      }
      if (this.seconds < 0) {
        this.seconds = "59";
      }
    },
    focused(event) {
      event.target.select();
    },
    clear_minutes() {
      this.minutes = "";
    },
    clear_seconds() {
      this.seconds = "";
    },
  },
};
</script>

<style>
.component-duration-minutes input {
  margin-right: -8px;
}
.component-duration-minutes label {
  margin-left: -4px;
}

.component-duration-seconds input {
  margin-left: 4px;
  margin-right: -20px;
}
.component-duration-seconds label {
  margin-left: 8px;
}

.component-duration-seconds.v-text-field input {
  max-width: 3em;
}
.component-duration-minutes.v-text-field input {
  max-width: 3em;
}

/* iPad in landscape */
@media only screen and (min-device-width: 740px) and (max-device-width: 1080px) and (orientation: landscape) {
  .component-duration-minutes input {
    margin-right: 2px;
    width: 2em;
  }
  .component-duration-minutes label {
    margin-left: 0px;
    overflow: visible;
  }

  .component-duration-seconds.v-text-field input {
    max-width: 3em;
  }
  .component-duration-minutes.v-text-field input {
    max-width: 3em;
  }
}
</style>