export var category_icons = {
    Uncategorized: "fa-question",
    Accommodation: "fa-images",
    "Anti-Suppression": "fa-circle-notch",
    "Gross & Fine Motor": "fa-table-tennis",
    Oculomotor: "fa-eye",
    Syntonics: "fa-glasses",
    Vergence: "fa-exchange-alt",
    "Visual Perception": "fa-cubes",
    "Visual Processing": "fa-brain",
    "Visual-Vestibular": "fa-child",
    "Primitive Reflexes": "fa-baby",
    "Laterality / Directionality": "fa-arrows-alt",
    "Disabled": "mdi-circle-off-outline",
    Testing: "fa-tasks",

    // VisionX Icons
    "Performance Series": "fa-solid fa-fire",
    "Wall Series": "fa-solid fa-block-brick",
    "Juggling Series": "fa-solid fa-ball-pile",
    "Computer Series": "fa-solid fa-laptop",
    "Balance Series": "fas fa-balance-scale-right",
    "Eye Patch Series": "fas fa-skull-crossbones",
    "Saccadic Strip Series": "fas fa-grip-lines-vertical"
};

export function category_items(context, array_value = false, app = "nvt") {
    let items = [
        { text: context.$t("common.accommodation"), value: "Accommodation" },
        { text: context.$t("common.anti_suppression"), value: "Anti-Suppression" },
        {
            text: context.$t("common.gross_fine_motor"),
            value: "Gross & Fine Motor",
        },
        { text: context.$t("common.oculomotor"), value: "Oculomotor" },
        {
            text: context.$t("common.primitive_reflexes"),
            value: "Primitive Reflexes",
        },
        { text: context.$t("common.syntonics"), value: "Syntonics" },
        { text: context.$t("common.testing"), value: "Testing" },
        { text: context.$t("common.vergence"), value: "Vergence" },
        {
            text: context.$t("common.visual_perception"),
            value: "Visual Perception",
        },
        {
            text: context.$t("common.visual_processing"),
            value: "Visual Processing",
        },
        {
            text: context.$t("common.visual_vestibular"),
            value: "Visual-Vestibular",
        },
        {
            text: context.$t("common.laterality_directionality"),
            value: "Laterality / Directionality",
        },
    ];

    if (app == "visionx") {
        let additional_items = [
            { text: context.$t("categories.performance_series"), value: "Performance Series" },
            { text: context.$t("categories.wall_series"), value: "Wall Series" },
            { text: context.$t("categories.juggling_series"), value: "Juggling Series" },
            { text: context.$t("categories.computer_series"), value: "Computer Series" },
            { text: context.$t("categories.balance_series"), value: "Balance Series" },
            { text: context.$t("categories.eye_patch_series"), value: "Eye Patch Series" },
            { text: context.$t("categories.saccadic_strip_series"), value: "Saccadic Strip Series" },
        ];
        items = items.concat(additional_items);
    }

    items.sort(function (a, b) {
        return a.text < b.text;
    });

    // Value should be an array, not a single-value
    if (array_value) {
        items = items.map((item) => {
            item.value = [item.value];
            return item;
        });
    }

    return items;
}
