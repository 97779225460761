<template>
  <div>
    <ClinicPage
      v-if="clinics.length == 1"
      :clinic_id="clinics[0].id"
      :current_user="current_user"
      :user_invites="user_invites"
      :show_billing="true"
    />
    <v-container v-else-if="clinics.length > 1" fluid fill-height style="margin-top: 200px">
      <v-layout align-center justify-center>
        <v-flex xs8 m6>
          <v-card>
            <v-toolbar dark color="primary">
              <v-toolbar-title>{{ $t("common.select_clinic") }}</v-toolbar-title>
              <v-spacer />
            </v-toolbar>
            <v-card-text>
              <v-list two-line subheader>
                <div v-for="(item, index) in clinics" :key="index">
                  <v-list-item @click="navigate_to_clinic(item.id)">
                    <v-list-item-content>{{ item.title }}</v-list-item-content>
                    <v-list-item-content>{{ item.website }}</v-list-item-content>
                    <v-list-item-content>{{ item.phone }}</v-list-item-content>
                  </v-list-item>
                  <v-divider />
                </div>
              </v-list>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import ClinicPage from "../../shared/components/ClinicPage";

export default {
  components: { ClinicPage },
  data() {
    return {
      current_user: this.$root.$children[0].current_user,
      user_invites: this.$root.$children[0].user_invites,
    };
  },
  computed: {
    clinics() {
      // Create list of valid clinics
      let all_clinics = this.$root.$children[0].user_clinics;
      let clinics = [];
      for (var c in all_clinics) {
        let clinic = all_clinics[c];
        for (var i in this.user_invites) {
          let invite = this.user_invites[i];
          if (
            invite.user_role == "admin" &&
            !invite.archived &&
            invite.clinic_id == clinic.id
          ) {
            clinics.push(clinic);
          }
        }
      }

      return clinics;
    },
  },
  methods: {
    navigate_to_clinic: function (clinic_id) {
      this.$router.push("/app/clinic/" + clinic_id + "/billing");
    },
  },
};
</script>

<style>
</style>