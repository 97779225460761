import md5 from 'md5';
import cookie from "js-cookie";
import OpticalGymBaseClass from "./opticalgym_base_class";

export class Calibration {
    id: string | null;
    user_id: string | null;
    width_px: Number;
    height_px: Number;
    pixel_ratio: Number;
    height_cm: Number;
    distance_cm: Number;
    anaglyph_orientation: string;
    colour_correction: {
        red_brightness: Number
        blue_brightness: Number,
        green_brightness: Number,
    };
    fingerprint: string;
    no_touch_screen: boolean | null;

    setDefaultValues() {
        // We always assume landscape mode when doing exercises or calibrating screen
        let width_px = screen.width;
        let height_px = screen.height;
        if (height_px > width_px) {
            width_px = height_px;
            height_px = screen.width;
        }

        // When constructing an empty calibration, we take our best guess
        const height_cm_guess = Math.round(
            height_px / (28 * window.devicePixelRatio)
        );
        this.id = '';
        this.width_px = width_px;
        this.height_px = height_px;
        this.pixel_ratio = window.devicePixelRatio;
        this.height_cm = height_cm_guess;
        this.distance_cm = height_cm_guess * 2.5;
        this.anaglyph_orientation = 'cyan-red';
        this.colour_correction = {
            red_brightness: 0.75,
            blue_brightness: 0.75,
            green_brightness: 0.75,
        };
        this.fingerprint = Calibration.calcFingerprint();
        this.no_touch_screen = null;
    }

    // Store to local storage
    storeToLocal() {
        window.localStorage['opticalgym_calibration'] = JSON.stringify(this);
    }

    // Calculate the screen fingerprint
    static calcFingerprint(): string {
        let string =
            screen.colorDepth + '|' +
            screen.width + '|' +
            screen.height + '|' +
            window.devicePixelRatio;

        return md5(string);
    }

    static fromJson(data: Object): Calibration {
        let calibration = new Calibration();
        Object.assign(calibration, data);
        return calibration;
    }
}

export class UserCalibrationMetadata extends OpticalGymBaseClass {
    id: string | null;
    data: Calibration;
    user_id: string | null;
    clinic_id: string | null;
    category: string;

    static fromJson(data: Object): UserCalibrationMetadata {
        let calibration = new UserCalibrationMetadata();
        Object.assign(calibration, data);
        return calibration;
    }
}

export class CalibrationList {
    data: Calibration[];

    constructor() {
        this.data = [];
    }

    public getCurrentCalibration(): null | Calibration {
        var calibration: Calibration | null = null;

        let fingerprint = Calibration.calcFingerprint();
        for (let calib of this.data) {
            if (calib.fingerprint == fingerprint) {
                calibration = calib;
                break;
            }
        }

        // Check local storage
        if (!calibration) {
            if (window.localStorage['opticalgym_calibration']) {
                let json = JSON.parse(window.localStorage['opticalgym_calibration']);
                calibration = Calibration.fromJson(json);
            }
        }

        // Check old cookie
        // TODO: Eventually this can be deprecated
        if (!calibration) {
            let calibration_cookie = cookie.getJSON(
                "opticalgym_calibration_v2_" + screen.width + "-" + screen.height
            );

            if (calibration_cookie) {
                calibration = Calibration.fromJson(calibration_cookie);
                calibration.fingerprint = Calibration.calcFingerprint();
                calibration.id = '';
            }
        }

        // Set local-storage cache
        if (calibration) {
            calibration.storeToLocal();
        }

        return calibration;
    }

    static fromResponse(response_data: Array<UserCalibrationMetadata>) {
        let calibration_list = new CalibrationList();

        for (var i in response_data) {
            let metadata = response_data[i];
            let calibration_data = metadata.data;
            calibration_data.id = metadata.id;
            calibration_data.user_id = metadata.user_id;

            let calibration = Calibration.fromJson(calibration_data);
            calibration_list.data.push(calibration);
        }

        return calibration_list;
    }
}
