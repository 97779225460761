import Vue from 'vue';
import Vuetify from "vuetify/lib"
import i18n, { OpticalgymVueI18n } from '../i18n';

import en from 'vuetify/src/locale/en';
import es from 'vuetify/src/locale/es';
import fr from 'vuetify/src/locale/fr';
import he from 'vuetify/src/locale/he';
import pt from 'vuetify/src/locale/pt';

// TODO: Why do we need both of these??
// TOOD: See https://vuetifyjs.com/en/customization/icons
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import "material-design-icons-iconfont/dist/material-design-icons.css"; // Why do we need both??

// See https://pictogrammers.github.io/@mdi/font/4.6.95/ for a list of icons

import "@fortawesome/fontawesome-free/css/all.css";

// TODO: https://stackoverflow.com/questions/48511436/what-is-the-correct-way-to-import-vuetifys-directives
// import * as directives from 'vuetify/lib/directives'; 
Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'md',
  },
  lang: {
    locales: { en, es, fr, he, pt },
    current: i18n.lang(),
  },
  rtl: i18n.lang() == 'he' || i18n.lang() == 'ar'
});
