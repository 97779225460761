// V1
import anablast from "./v1/src/anablast/anablast.config.vue";
import binocular_reading from "./v1/src/binocular_reading/binocular_reading.config.vue";
import comprehension from "./v1/src/comprehension/comprehension.config.vue";
import directional_response from "./v1/src/directional_response/directional_response.config.vue";
import flat_jump_vergence from "./v1/src/flat_jump_vergence/flat_jump_vergence.config.vue";
import flat_vergence from "./v1/src/flat_vergence/flat_vergence.config.vue";
import line_maze from "./v1/src/line_maze/line_maze.config.vue";
import motion_detection from "./v1/src/motion_detection/motion_detection.config.vue";
import movingwindow from "./v1/src/movingwindow/movingwindow.config.vue";
import perceptual_speed from "./v1/src/perceptual_speed/perceptual_speed.config.vue";
import peripheral from "./v1/src/peripheral/peripheral.config.vue";
import peripheral_chart from "./v1/src/peripheral_chart/peripheral_chart.config.vue";
import pong from "./v1/src/pong/pong.config.vue";
import phoria from "./v1/src/phoria/phoria.config.vue";
import randot from "./v1/src/randot/randot.config.vue";
import randot_vertical from "./v1/src/randot_vertical/randot_vertical.config.vue";
import randotin from "./v1/src/randotin/randotin.config.vue";
import react from "./v1/src/react/react.config.vue";
import recognition from "./v1/src/recognition/recognition.config.vue";
import rotator from "./v1/src/rotator/rotator.config.vue";
import saccades from "./v1/src/saccades/saccades.config.vue";
import stereoacuity from "./v1/src/stereoacuity/stereoacuity.config.vue";
import tachistoscope from "./v1/src/tachistoscope/tachistoscope.config.vue";
import vectograph from "./v1/src/vectograph/vectograph.config.vue";
import visual_auditory from "./v1/src/visual_auditory/visual_auditory.config.vue";
import visual_memory from "./v1/src/visual_memory/visual_memory.config.vue";
import wordreading from "./v1/src/wordreading/wordreading.config.vue";
import jumble_scan from "./v1/src/jumble_scan/jumble_scan.config.vue";

// V2
import example_click_the_target from "./v2/src/example_click_the_target/example_click_the_target.config.vue";

export default {
  anablast,
  associated_phoria: phoria, // use phoria
  binocular_reading,
  comprehension,
  directional_response,
  flat_jump_vergence,
  flat_vergence,
  line_maze,
  motion_detection,
  movingwindow,
  perceptual_speed,
  peripheral,
  peripheral_chart,
  pong,
  phoria,
  randot,
  randot_jump: randotin, // use randotin
  randot_vertical,
  randot_vertical_jump: randot_vertical, // use randot_vertical
  randotin,
  randotin_jump: randotin, // use randotin
  randotout: randotin, // use randotin
  randotout_jump: randotin, // use randotin 
  react,
  recognition,
  rotator,
  saccades,
  line_reading: comprehension, // use comprehension
  stereoacuity,
  tachistoscope,
  visual_auditory,
  visual_memory,
  vectograph,
  wordreading,
  jumble_scan,
  example_click_the_target
};
