<template>
  <div>
    <!-- Toolbar -->
    <v-toolbar grey dense>
      <v-toolbar-title>{{ $t("common.users") }}</v-toolbar-title>
      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        @change="load_search"
      ></v-text-field>
    </v-toolbar>

    <!-- All Clinics List -->

    <v-sheet
      v-if="!search"
      elevation="4"
      style="width: 500px; padding: 50px; margin: 0 auto; margin-top: 100px"
    >
      <h2>Please enter a search term</h2>
    </v-sheet>

    <div v-if="users.length != 0">
      <h2>Users</h2>
      <v-list two-line subheader style="font-size: smaller">
        <div v-for="(item, index) in users" :key="index">
          <v-list-item>
            <v-list-item-content>
              {{ item.name_prefix }} {{ item.name_given }}
              {{ item.name_family }} {{ item.name_credentials }}
              <br />
              <span
                style="font-size: xx-small; font-family: monospace; color: grey"
                >{{ item.id }}</span
              >
            </v-list-item-content>
            <v-list-item-content>{{ item.email }}</v-list-item-content>
            <v-list-item-action>
              <v-btn
                small
                outlined
                fab
                target="_blank"
                :href="'/app/admin/user/' + item.id"
              >
                <v-icon x-small>fas fa-tools</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action>
              <v-btn
                small
                outlined
                fab
                color="green"
                @click.stop="dialogs[item.id] = true"
              >
                <v-icon>edit</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider />
          <v-dialog v-model="dialogs[item.id]" max-width="1000">
            <UserEdit
              :user="item"
              show-delete
              show_is_admin
              @close-user-account="dialogs[item.id] = false"
              @user-updated="load_search"
              @user-deleted="load_search"
            />
          </v-dialog>
        </div>
      </v-list>
    </div>

    <div v-if="invites.length != 0">
      <h2>Invites</h2>
      <v-list two-line subheader style="font-size: smaller">
        <div v-for="(item, index) in invites" :key="index">
          <v-list-item>
            <v-list-item-content>
              {{ item.name }} {{ item.name_family }}
              <br />
              <span
                style="font-size: xx-small; font-family: monospace; color: grey"
                >{{ item.id }}</span
              >
            </v-list-item-content>
            <v-list-item-content>{{ item.user_role }}</v-list-item-content>
            <v-list-item-content>
              <span v-if="item.archived">Archived</span>
              <span v-else-if="item.user_id">Accepted</span>
              <span v-else>Not Accepted</span>
            </v-list-item-content>
            <v-list-item-content>
              <a :href="'/app/clinic/' + item.clinic_id">{{
                clinics[item.clinic_id] ? clinics[item.clinic_id].title : ""
              }}</a>
            </v-list-item-content>
            <v-list-item-content>{{ item.email }}</v-list-item-content>
            <v-list-item-action>
              <v-btn
                small
                outlined
                fab
                color="green"
                @click.stop="invite_dialogs[item.id] = true"
              >
                <v-icon>edit</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider />
          <v-dialog v-model="invite_dialogs[item.id]" max-width="1000">
            <InviteEdit
              :invite="item"
              :current_user="current_user"
              show_delete
              allow_user_role_edit
              show_user_role_option
              resend_on_update
              @close-user-account="invite_dialogs[item.id] = false"
              @user-updated="load_search"
              @user-deleted="load_search"
            />
          </v-dialog>
        </div>
      </v-list>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";
import UserEdit from "./../UserEdit.vue";
import InviteEdit from "./../InviteEdit.vue";
import Clinic from "../../classes/clinic";
import { mapState } from "vuex";

export default {
  components: { UserEdit, InviteEdit },
  data() {
    return {
      users: [],
      rows_per_page: [-1],
      dialogs: {},
      invites: [],
      invite_dialogs: {},
      search: "",
      clinics: {},
    };
  },
  computed: {
    ...mapState(["current_user"]),
  },
  mounted() {
    this.load_clinics();
  },
  methods: {
    load_search: function () {
      if (this.search == "") {
        return;
      }

      let self = this;
      axios
        .get("/api/user?query=" + this.search.trim())
        .then(function (response) {
          if (response.data.status == "success") {
            let results = response.data.data;

            // Sort results according to if there's an exact match
            results.sort(function (a, b) {
              let a_name =
                a.name_given.toLowerCase() + " " + a.name_family.toLowerCase();
              let b_name =
                b.name_given.toLowerCase() + " " + b.name_family.toLowerCase();
              let a_email = a.email.toLowerCase();
              let b_email = b.email.toLowerCase();

              let search = self.search.trim().toLowerCase();

              if (a_name == search) {
                return -1;
              } else if (b_name == search) {
                return 1;
              } else if (a_email == search) {
                return -1;
              } else if (b_email == search) {
                return 1;
              } else {
                return 0;
              }
            });

            self.users = results;
            self.$forceUpdate();

            // Set up dialogs
            for (var i in self.users) {
              Vue.set(self.dialogs, self.users[i].id, false);
            }
          }
        });

      axios
        .get("/api/invite?query=" + this.search.trim())
        .then(function (response) {
          if (response.data.status == "success") {
            let results = response.data.data;

            // Sort results according to if there's an exact match
            results.sort(function (a, b) {
              let a_name =
                a.name.toLowerCase() + " " + a.name_family.toLowerCase();
              let b_name =
                b.name.toLowerCase() + " " + b.name_family.toLowerCase();
              let a_email = a.email.toLowerCase();
              let b_email = b.email.toLowerCase();

              let search = self.search.trim().toLowerCase();

              if (a_name == search) {
                return -1;
              } else if (b_name == search) {
                return 1;
              } else if (a_email == search) {
                return -1;
              } else if (b_email == search) {
                return 1;
              } else {
                return 0;
              }
            });

            self.invites = results;
            self.$forceUpdate();

            // Set up dialogs
            for (var i in self.invites) {
              Vue.set(self.invite_dialogs, self.invites[i].id, false);
            }
          }
        });
    },
    load_clinics: function () {
      let self = this;
      axios.get("/api/clinic").then(function (response) {
        if (response.data.status == "success") {
          for (var i in response.data.data) {
            let clinic = Clinic.fromJson(response.data.data[i]);
            self.clinics[clinic.id] = clinic;
          }
        }
      });
    },
  },
};
</script>
