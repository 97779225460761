<template>
  <v-card>
    <v-card-title style="padding: 0px;">
      <v-toolbar grey flat>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("common.fusion_card") }}</v-toolbar-title>
      </v-toolbar>
    </v-card-title>
    <v-card-text>
      <v-select v-model="image" :items="image_options" :label="$t('common.vectograph')">
        <template slot="item" slot-scope="item">
          <div style="margin-top: 5px">
            <img
              v-if="item.item.value == 'ring'"
              :src="'https://storage.googleapis.com/opticalgym-large-assets/fusion_card/ring_red_left.png'"
              style="background-color: white; height:80px; width: 80px;"
            />
            <img
              v-else
              :src="'https://storage.googleapis.com/opticalgym-large-assets/fusion_card/' + item.item.value + '_left.png'"
              style="background-color: white; height:80px; width: 80px;"
            />
            <div style="padding-top: 30px;  padding-left: 10px; float: right;">{{ item.item.text }}</div>
          </div>
        </template>
      </v-select>

      <v-select
        v-model="colour_scheme"
        :label="$t('common.colour')"
        :items="colour_scheme_options"
      />

      <v-checkbox
        v-model="suppresion_check"
        :label="$t('tool_fusion_chart.include_supression_check')"
      />

      <v-sheet>
        <p>{{ $t("tool_fusion_chart.for_best_results_message") }}</p>
        <table>
          <tr>
            <td>{{ $t("common.red") }}</td>
            <td>C=0 / M=100 / Y=100 / K=0</td>
          </tr>
          <tr>
            <td>{{ $t("common.green") }}</td>
            <td>C=100 / M=0 / Y=100 / K=0</td>
          </tr>
          <tr>
            <td>{{ $t("common.blue") }}</td>
            <td>C=100 / M=0 / Y=0 / K=0</td>
          </tr>
        </table>
      </v-sheet>
    </v-card-text>

    <v-card-actions>
      <v-btn @click="view()">{{ $t("common.view") }}</v-btn>
      <v-spacer />
      <v-btn color="primary" @click="print()">{{ $t("common.print") }}</v-btn>
    </v-card-actions>
    <v-progress-linear v-if="in_flight" :indeterminate="true" />
  </v-card>
</template>

<script>
import printJS from "print-js";

export default {
  components: {},
  props: {},
  data() {
    let data = {
      image: "ring",
      colour_scheme: "red_blue",
      suppresion_check: false,
      image_options: [
        { text: this.$t("tool_fusion_chart.simple_ring"), value: "ring" },
        { text: this.$t("tool_fusion_chart.snowflake"), value: "snow_flake" },
        {
          text: this.$t("tool_fusion_chart.bike_girl"),
          value: "bridge_cartoon",
        },
      ],
      colour_scheme_options: [
        { text: this.$t("tool_fusion_chart.red_blue"), value: "red_blue" },
        { text: this.$t("tool_fusion_chart.red_green"), value: "red_green" },
        { text: this.$t("tool_fusion_chart.blue_red"), value: "blue_red" },
        { text: this.$t("tool_fusion_chart.green_red"), value: "green_red" },
      ],
      in_flight: false,
    };
    return data;
  },
  computed: {},
  methods: {
    view: function () {
      window.open(this.url(), "_blank");
    },
    url: function () {
      let colours = this.colour_scheme.split("_");

      let uri = "/api/tools/pdf/fusion_card.pdf";
      uri += "?image=" + this.image;
      uri += "&left_colour=" + colours[0];
      uri += "&right_colour=" + colours[1];
      uri += "&suppression_check=" + (this.suppresion_check ? "true" : "false");

      return uri;
    },
    print: function () {
      let self = this;

      if (navigator.vendor.includes("Apple")) {
        window.open(this.url(), "_blank");
        return;
      }

      printJS({
        printable: self.url(),
        onLoadingStart: function () {
          self.in_flight = true;
        },
        onLoadingEnd: function () {
          self.in_flight = false;
        },
      });
    },
    close: function () {
      this.$emit("close");
    },
  },
};
</script>


<style>
</style>