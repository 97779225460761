<template>
  <div>
    <div
      v-if="current_user_role != 'admin' && current_user_role != 'staff'"
      style="margin-top: 200px"
    >
      <v-layout justify-center>
        <v-flex md6>{{ $t("clinic_grids.permission_denied") }}</v-flex>
      </v-layout>
    </div>
    <div v-else-if="exercises && video_exercises">
      <v-row justify="center">
        <v-col cols="12" md="12" lg="6">
          <v-sheet elevation="3" style="margin: 30px">
            <v-toolbar grey>
              <v-toolbar-title>{{ $t("clinic_grids.sets") }}</v-toolbar-title>
              <v-spacer />
              <v-btn
                color="primary"
                style="margin-right: 10px"
                @click="new_set"
                >{{ $t("clinic_grids.new_set") }}</v-btn
              >
            </v-toolbar>

            <div style="padding: 20px">
              <v-banner two-line>
                <p>{{ $t("clinic_grids.sets_desc") }}</p>
                <p v-if="!grid_sets || !grid_sets.length">
                  {{ $t("clinic_grids.no_grids") }}
                </p>
              </v-banner>

              <v-list v-if="grid_sets && grid_sets.length != 0">
                <div v-for="(item, index) in grid_sets" :key="index">
                  <v-list-item :class="dense ? 'px-0' : ''">
                    <v-list-item-icon v-if="!dense">
                      <v-badge
                        v-if="item.shared && !item.shared.hidden"
                        overlap
                        bottom
                        bordered
                        color="grey"
                        icon="mdi-share-variant"
                      >
                        <v-icon>fas fa-align-justify</v-icon>
                      </v-badge>
                      <v-icon v-else>fas fa-align-justify</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title style="font-weight: 550">
                        {{ item.title }}
                        <v-chip
                          v-if="item.share_source"
                          label
                          small
                          outlined
                          class="mt-0 ml-5"
                          :href="
                            '/app/shared/clinic/' + item.share_source.clinic_id
                          "
                          rget="_blank"
                          >{{
                            $t("community_content.clinic_attribution", {
                              clinic_title: item.share_source.clinic_title,
                            })
                          }}</v-chip
                        >
                      </v-list-item-title>
                      <v-list-item-subtitle>{{
                        item.description
                      }}</v-list-item-subtitle>
                      <v-list-item-subtitle v-if="show_id">
                        <pre style="user-select: all">{{ item.id }}</pre>
                      </v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action v-if="item.clinic_id == clinic.id">
                      <v-btn
                        :small="!dense"
                        :x-small="dense"
                        :tile="dense"
                        outlined
                        fab
                        color="green"
                        @click="open_grid_set_dialog(index)"
                      >
                        <v-icon>edit</v-icon>
                      </v-btn>
                    </v-list-item-action>

                    <v-list-item-action>
                      <v-tooltip top open-delay="500">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            :class="dense ? ($vuetify.rtl ? 'mr-1' : 'ml-1') : ($vuetify.rtl ? 'mr-4' : 'ml-4')"
                            :small="!dense"
                            :x-small="dense"
                            :tile="dense"
                            outlined
                            fab
                            color="purple"
                            v-on="on"
                            @click="duplicate_grid_set(item)"
                          >
                            <v-icon>mdi-content-duplicate</v-icon>
                          </v-btn>
                        </template>
                        {{ $t("clinic_grids.duplicate_set") }}
                      </v-tooltip>
                    </v-list-item-action>

                    <v-list-item-action
                      v-if="item.clinic_id == clinic.id"
                      :class="dense ? ($vuetify.rtl ? 'mr-1' : 'ml-1') : ''"
                    >
                      <v-tooltip top open-delay="500">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            :small="!dense"
                            :x-small="dense"
                            :tile="dense"
                            outlined
                            fab
                            color="red"
                            v-on="on"
                            @click="delete_grid_set(item)"
                          >
                            <v-icon>delete</v-icon>
                          </v-btn>
                        </template>
                        {{ $t("clinic_grids.delete_set") }}
                      </v-tooltip>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider />

                  <v-dialog
                    v-model="edit_grid_set_dialog[index]"
                    max-width="1200"
                  >
                    <GridSetEdit
                      ref="grid_set_edit"
                      :grid_set_id="item.id"
                      :clinic_id="clinic.id"
                      @close="close_grid_set_dialog(index)"
                      @grid-set-created="$emit('grid-set-created')"
                      @grid-set-updated="$emit('grid-set-updated')"
                    ></GridSetEdit>
                  </v-dialog>
                </div>
              </v-list>
            </div>
          </v-sheet>
        </v-col>

        <v-col cols="12" md="12" lg="6">
          <v-sheet elevation="3" style="margin: 30px">
            <v-toolbar grey>
              <v-toolbar-title>{{
                $t("clinic_grids.schedules")
              }}</v-toolbar-title>
              <v-spacer />
              <v-btn
                color="primary"
                style="margin-right: 10px"
                @click="new_schedule"
                >{{ $t("clinic_grids.new_schedule") }}</v-btn
              >
            </v-toolbar>

            <div style="padding: 20px">
              <v-banner two-line>
                <p>{{ $t("clinic_grids.schedule_desc") }}</p>

                <p v-if="!grid_schedules || !grid_schedules.length">
                  {{ $t("clinic_grids.no_schedules") }}
                </p>
              </v-banner>

              <v-list v-if="grid_schedules && grid_schedules.length != 0">
                <div v-for="(item, index) in grid_schedules" :key="index">
                  <v-list-item>
                    <v-list-item-icon v-if="!dense">
                      <v-badge
                        v-if="item.shared && !item.shared.hidden"
                        overlap
                        bottom
                        bordered
                        color="grey"
                        icon="mdi-share-variant"
                      >
                        <v-icon>fas fa-border-all</v-icon>
                      </v-badge>
                      <v-icon v-else>fas fa-border-all</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title style="font-weight: 550">
                        {{ item.title }}
                        <v-chip
                          v-if="item.share_source"
                          label
                          small
                          outlined
                          class="mt-0 ml-5"
                          :href="
                            '/app/shared/clinic/' + item.share_source.clinic_id
                          "
                          rget="_blank"
                          >{{
                            $t("community_content.clinic_attribution", {
                              clinic_title: item.share_source.clinic_title,
                            })
                          }}</v-chip
                        >
                      </v-list-item-title>
                      <v-list-item-subtitle>{{
                        item.description
                      }}</v-list-item-subtitle>
                      <v-list-item-subtitle v-if="show_id">
                        <pre style="user-select: all">{{ item.id }}</pre>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action v-if="item.clinic_id == clinic.id">
                      <v-btn
                        :small="!dense"
                        :x-small="dense"
                        :tile="dense"
                        outlined
                        fab
                        color="green"
                        @click="open_grid_schedule_dialog(index)"
                      >
                        <v-icon>edit</v-icon>
                      </v-btn>
                    </v-list-item-action>

                    <v-list-item-action>
                      <v-tooltip top open-delay="500">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            :class="dense ? ($vuetify.rtl ? 'mr-1' : 'ml-1') : ($vuetify.rtl ? 'mr-4' : 'ml-4')"
                            :small="!dense"
                            :x-small="dense"
                            :tile="dense"
                            outlined
                            fab
                            color="purple"
                            v-on="on"
                            @click="duplicate_grid_schedule(item)"
                          >
                            <v-icon>mdi-content-duplicate</v-icon>
                          </v-btn>
                        </template>
                        {{ $t("clinic_grids.duplicate_schedule") }}
                      </v-tooltip>
                    </v-list-item-action>

                    <v-list-item-action
                      v-if="item.clinic_id == clinic.id"
                      :class="dense ? ($vuetify.rtl ? 'mr-1' : 'ml-1') : ''"
                    >
                      <v-tooltip top open-delay="500">
                        <template v-slot:activator="{ on }">
                          <v-btn
                            :small="!dense"
                            :x-small="dense"
                            :tile="dense"
                            outlined
                            fab
                            color="red"
                            v-on="on"
                            @click="delete_grid_schedule(item)"
                          >
                            <v-icon>delete</v-icon>
                          </v-btn>
                        </template>
                        {{ $t("clinic_grids.delete_schedule") }}
                      </v-tooltip>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider />

                  <v-dialog
                    v-model="edit_grid_schedule_dialog[index]"
                    :fullscreen="grid_schedule_edit_fullscreen"
                    max-width="1200"
                  >
                    <GridScheduleEdit
                      v-if="edit_grid_schedule_dialog[index]"
                      ref="grid_schedule_edit"
                      :grid_schedule_id="item.id"
                      :clinic_id="clinic.id"
                      :is_fullscreen="grid_schedule_edit_fullscreen"
                      :all_grid_schedules="grid_schedules"
                      @close="close_grid_schedule_dialog(index)"
                      @grid-schedule-created="$emit('grid-schedule-created')"
                      @grid-schedule-updated="$emit('grid-schedule-updated')"
                      @fullscreen="set_grid_schedule_fullscreen"
                    ></GridScheduleEdit>
                  </v-dialog>
                </div>
              </v-list>
            </div>
          </v-sheet>
        </v-col>
      </v-row>

      <v-layout
        v-if="
          show_intro_video &&
          grid_sets &&
          grid_schedules &&
          grid_sets.length + grid_schedules.length < 6
        "
        justify-center
      >
        <iframe
          style="margin-top: 20px; margin-bottom: 10px"
          width="560"
          height="315"
          src="https://www.youtube-nocookie.com/embed/j8ANXM7Aqek"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </v-layout>

      <!-- New grid set -->
      <v-dialog v-model="new_grid_set_dialog" scrollable max-width="1200">
        <GridSetEdit
          ref="new_grid_set"
          :clinic_id="clinic.id"
          @close="new_grid_set_dialog = false"
          @grid-set-created="$emit('grid-set-created')"
          @grid-set-updated="$emit('grid-set-updated')"
        />
      </v-dialog>

      <!-- New grid schedule -->
      <v-dialog
        v-model="new_grid_schedule_dialog"
        scrollable
        :fullscreen="grid_schedule_edit_fullscreen"
        max-width="1200"
      >
        <GridScheduleEdit
          ref="new_grid_schedule"
          :clinic_id="clinic.id"
          :is_fullscreen="grid_schedule_edit_fullscreen"
          :all_grid_schedules="grid_schedules"
          @close="new_grid_schedule_dialog = false"
          @grid-schedule-created="$emit('grid-schedule-created')"
          @grid-schedule-updated="$emit('grid-schedule-updated')"
          @fullscreen="set_grid_schedule_fullscreen"
        />
      </v-dialog>
    </div>

    <!-- Loading -->
    <div v-else style="margin-top: 200px">
      <v-layout justify-center>
        <v-flex md1>
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import uuid from "uuid";

import GridSetEdit from "./GridSetEdit";
import GridScheduleEdit from "./GridScheduleEdit";

export default {
  components: { GridSetEdit, GridScheduleEdit },
  props: {
    current_user: {
      type: Object,
      required: true,
    },
    current_user_role: {
      type: String,
      required: true,
    },
    clinic: {
      type: Object,
      required: true,
    },
    exercises: {
      type: Object,
      required: false,
      default: null,
    },
    video_exercises: {
      type: Object,
      required: false,
      default: null,
    },
    grid_sets: {
      type: Array,
      required: true,
    },
    grid_schedules: {
      type: Array,
      required: true,
    },
    show_intro_video: {
      type: Boolean,
      default: () => true,
    },
    show_id: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    let data = {
      new_grid_set_dialog: false,
      new_grid_schedule_dialog: false,
      edit_grid_set_dialog: [],
      edit_grid_schedule_dialog: [],
    };
    for (var i = 0; i < this.grid_sets.length; i++) {
      data.edit_grid_set_dialog.push(false);
    }
    for (var j = 0; j < this.grid_schedules.length; j++) {
      data.edit_grid_schedule_dialog.push(false);
    }

    data.grid_schedule_edit_fullscreen = false;
    return data;
  },
  computed: {
    dense() {
      let stacked = this.$vuetify.breakpoint.name == "md";
      return (
        !stacked &&
        ((!this.$root.$children[0].drawer_mini &&
          this.$vuetify.breakpoint.width < 1550) ||
          this.$vuetify.breakpoint.width < 1350)
      );
    },
  },
  watch: {
    grid_sets() {
      this.edit_grid_set_dialog = [];
      for (var i = 0; i < this.grid_sets.length; i++) {
        this.edit_grid_set_dialog.push(false);
      }
    },
    grid_schedules() {
      this.edit_grid_schedule_dialog = [];
      for (var j = 0; j < this.grid_schedules.length; j++) {
        this.edit_grid_schedule_dialog.push(false);
      }
    },
  },
  mounted() {},
  methods: {
    set_grid_schedule_fullscreen(val) {
      this.grid_schedule_edit_fullscreen = val;
    },
    delete_grid_set(grid_set) {
      if (
        !confirm(
          "Deleting " +
            grid_set.title +
            "\n\n 1. Patients assigned to this set will continue on with the most recent configuration. Updates will need to be done to the patient's assigned exercises directly. "
        )
      ) {
        return;
      }

      let self = this;
      axios.delete("/api/grid_set/" + grid_set.id).then(function (response) {
        if (response.data.status == "success") {
          messageBus.$emit("success", "Grid Set Deleted");
          self.$emit("grid-set-deleted", grid_set.id);
        } else {
          messageBus.$emit("error", response.data.error_message);
        }
      });
    },

    duplicate_grid_set(grid_set) {
      grid_set = JSON.parse(JSON.stringify(grid_set));
      delete grid_set.id;
      delete grid_set.created;
      delete grid_set.updated;
      grid_set.shard = null;
      grid_set.title += " (Copy)";
      grid_set.clinic_id = this.clinic.id;

      // Assign new IDs to exercises
      for (var e in grid_set.exercises) {
        grid_set.exercises[e].uuid = uuid.v4();
      }

      let self = this;
      axios.post("/api/grid_set", grid_set).then(function (response) {
        if (response.data.status == "success") {
          messageBus.$emit("success", "Grid Set Duplicated");
          self.$emit("grid-set-created", grid_set.id);
        } else {
          messageBus.$emit("error", response.data.error_message);
        }
      });
    },

    duplicate_grid_schedule(grid_schedule) {
      grid_schedule = JSON.parse(JSON.stringify(grid_schedule));
      delete grid_schedule.id;
      delete grid_schedule.created;
      delete grid_schedule.updated;
      grid_schedule.shared = null;
      grid_schedule.title += " (Copy)";
      grid_schedule.clinic_id = this.clinic.id;

      // Assign new IDs to stages and exercises
      for (var s in grid_schedule.grid) {
        grid_schedule.grid[s].id = uuid.v4();

        for (var e in grid_schedule.grid[s].exercises) {
          grid_schedule.grid[s].exercises[e].uuid = uuid.v4();
        }
      }

      let self = this;
      axios.post("/api/grid_schedule", grid_schedule).then(function (response) {
        if (response.data.status == "success") {
          messageBus.$emit("success", this.$t("clinic_grids.schedule_duplicated"));
          self.$emit("grid-schedule-created", grid_schedule.id);
        } else {
          messageBus.$emit("error", response.data.error_message);
        }
      });
    },

    delete_grid_schedule(grid_schedule) {
      if (!confirm("Delete " + grid_schedule.title)) {
        return;
      }

      let self = this;
      axios
        .delete("/api/grid_schedule/" + grid_schedule.id)
        .then(function (response) {
          if (response.data.status == "success") {
            messageBus.$emit("success", "Grid Schedule Deleted");
            self.$emit("grid-schedule-deleted", grid_schedule.id);
          } else {
            messageBus.$emit("error", response.data.error_message);
          }
        });
    },
    new_set() {
      this.new_grid_set_dialog = true;
      this.$nextTick(() => {
        this.$refs.new_grid_set.reset();
      });
    },
    new_schedule() {
      this.new_grid_schedule_dialog = true;
      this.$nextTick(() => {
        this.$refs.new_grid_schedule.reset();
      });
    },
    open_grid_set_dialog(index) {
      Vue.set(this.edit_grid_set_dialog, index, true);
    },
    close_grid_set_dialog(index) {
      Vue.set(this.edit_grid_set_dialog, index, false);
    },
    open_grid_schedule_dialog(index) {
      Vue.set(this.edit_grid_schedule_dialog, index, true);
    },
    close_grid_schedule_dialog(index) {
      Vue.set(this.edit_grid_schedule_dialog, index, false);
    },
  },
};
</script>

<style></style>
