<template>
  <v-card>
    <v-toolbar>
      <v-btn icon class="clinic_page_reading_texts_close" @click="$emit('close')">
        <v-icon>close</v-icon>
      </v-btn>
      <v-toolbar-title>{{ $t("common.reading_texts") }}</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-btn
          small
          color="primary"
          @click="create_text_dialog = true"
        >{{ $t("common.create_reading_text") }}</v-btn>
        <v-dialog v-model="create_text_dialog" max-width="1200">
          <ReadingTextEdit
            v-if="create_text_dialog"
            :text="new_reading_text"
            @close="close_new_reading_text_dialog"
            @text-created="load_texts"
            @text-updated="load_texts"
          ></ReadingTextEdit>
        </v-dialog>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text>
      <v-banner two-line style="margin-top:20px">
        <p>{{ $t("clinic_page_manage_texts.reading_text_first_msg") }}</p>

        <p>{{ $t("clinic_page_manage_texts.copy_right_text") }}</p>
      </v-banner>

      <v-list v-if="reading_texts && reading_texts.length != 0">
        <div v-for="(item, index) in reading_texts" :key="index">
          <v-list-item>
            <v-list-item-icon>
              <v-badge
                v-if="item.shared && !item.shared.hidden"
                overlap
                bottom
                bordered
                color="grey"
                icon="mdi-share-variant"
              >
                <v-icon>mdi-file-document-box-outline</v-icon>
              </v-badge>
              <v-icon v-else>mdi-file-document-box-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.title }}
                <v-chip
                  v-if="item.share_source"
                  label
                  small
                  outlined
                  class="mt-0 ml-5"
                  :href="'/app/shared/clinic/' + item.share_source.clinic_id"
                  target="_blank"
                >
                  {{ $t('community_content.clinic_attribution', {'clinic_title': item.share_source.clinic_title }) }}
                </v-chip>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title>{{ $t("clinic_page_manage_texts.grade") }} {{ item.grade }}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <ExercisePreviewButton
                :exercise="build_preview_exercise(item)"
                class="mr-5"
                :fully_configured="true"
              />
            </v-list-item-action>
            <v-list-item-action>
              <v-btn small outlined fab color="green" @click="open_text_dialog(index)">
                <v-icon>edit</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action>
              <v-tooltip top open-delay="500">
                <template v-slot:activator="{ on }">
                  <v-btn small outlined fab color="red" v-on="on" @click="delete_text(item)">
                    <v-icon>delete</v-icon>
                  </v-btn>
                </template>
                {{ $t("clinic_page_manage_texts.delete_reading_text") }}
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
          <v-divider inset />

          <v-dialog v-model="edit_text_dialog[index]" max-width="1200">
            <ReadingTextEdit
              v-if="edit_text_dialog[index]"
              :text="item"
              @close="close_text_dialog(index)"
              @text-updated="load_texts"
            ></ReadingTextEdit>
          </v-dialog>
        </div>
      </v-list>
      <v-banner v-else-if="!loading" two-line style="margin-top:20px; font-style:italic">
        <p>{{ $t("clinic_page_manage_texts.no_uploaded_text") }}</p>
      </v-banner>
      <v-layout v-else-if="loading" align-center justify-center>
        <v-progress-circular style="margin: 30px" indeterminate color="primary"></v-progress-circular>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import ReadingTextEdit from "./ReadingTextEdit";
import ExercisePreviewButton from "./ExercisePreviewButton";
import Vue from "vue";
import { mapState } from "vuex";

export default {
  components: { ReadingTextEdit, ExercisePreviewButton },
  props: {
    clinic_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      create_text_dialog: false,
      edit_text_dialog: [],
      new_reading_text: {
        title: "",
        grade: 1,
        fulltext: "",
        questions: [],
        lang: this.$i18n.lang(),
        clinic_id: this.clinic_id,
        disposition: "all",
        attribution: "",
      },
    };
  },
  computed: {
    ...mapState(["reading_texts"]),
  },
  mounted() {
    this.load_texts();
  },
  methods: {
    close_new_reading_text_dialog() {
      this.create_text_dialog = false;
      this.new_reading_text = {
        title: "",
        grade: 1,
        fulltext: "",
        questions: [],
        clinic_id: this.clinic_id,
        disposition: "all",
        attribution: "",
        lang: this.$i18n.lang(),
      };
    },
    close_existing_reading_text_dialog(index) {
      this.edit_text_dialog[index] = false;
    },
    load_texts() {
      this.close_new_reading_text_dialog();
      this.loading = true;
      this.$store.dispatch("loadReadingTexts", () => {
        this.loading = false;
      });
    },
    open_text_dialog(index) {
      Vue.set(this.edit_text_dialog, index, true);
    },
    close_text_dialog(index) {
      Vue.set(this.edit_text_dialog, index, false);
    },
    delete_text(text) {
      if (!confirm(this.$t("common.delete") + " " + text.title + "?")) {
        return;
      }

      let self = this;
      axios.delete("/api/reading_text/" + text.id).then(function (response) {
        if (response.data.status == "success") {
          messageBus.$emit("success", self.$t("common.reading_text_deleted"));
          self.load_texts();
        } else {
          messageBus.$emit("error", response.data.error_message);
        }
      });
    },
    build_preview_exercise(item) {
      let exercise = {
        exercise_type: "interactive",
        exercise_id: "comprehension",
        config: {
          texturl: item.id,
        },
      };
      return exercise;
    },
  },
};
</script>

<style>
</style>