<template>
  <v-card>
    <v-card-text>
      <v-container>
        <v-text-field v-model="localValue.title" outlined :label="$t('common.title')" type="text" />

        <v-textarea v-model="localValue.config.plain_text" outlined :label="$t('regimen_email_edit.plain_text')" rows="5" />

        <v-textarea
          v-model="localValue.config.html"
          outlined
          label="HTML"
          rows="5"
          style="font-size: smaller; font-family: 'Courier New', Courier, monospace"
        />
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" @click="save">{{ $t("common.save") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  components: {},
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    let data = {};
    return data;
  },
  computed: {
    localValue: {
      get() {
        let value = this.value;
        return value;
      },
      set(localValue) {
        this.$emit("input", localValue);
      },
    },
  },
  mounted() {},
  methods: {
    save() {
      this.$emit("save");
    },
  },
};
</script>

<style></style>
