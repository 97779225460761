<template>
  <div :style="width ? 'width: ' + width + 'px' : ''">
    <v-card>
      <v-toolbar v-if="show_toolbar" flat>
        <v-toolbar-title>
          <template v-if="instance_title">{{ instance_title }}</template>
          <template v-else>{{ exercise_title }}</template>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon
          v-if="hover_report.exercise_type == 'interactive'"
          left
          small
          grey
          >category</v-icon
        >
        <v-icon v-else small grey>ondemand_video</v-icon>
      </v-toolbar>
      <v-divider v-if="show_toolbar" />

      <v-card-text v-if="reports.length == 0">Nothing to show.</v-card-text>
      <v-card-text v-else style="padding: 0px">
        <v-row class="mb-6" no-gutters>
          <v-col v-if="display_graph" cols="sm">
            <div style="overflow: hidden">
              <v-layout v-if="loading" justify-center>
                <v-progress-circular
                  indeterminate
                  color="primary"
                  style="margin-top: 200px; margin-bottom: -200px"
                ></v-progress-circular>
              </v-layout>
              <div
                :id="plot_div_id"
                class="regimen_report_card_plotly"
                :style="plotly_style"
              />
            </div>
          </v-col>
          <v-col cols="sm">
            <div>
              <div
                v-if="hover_report"
                class="hover_report_table_wrapper"
                style="height: 450px; position: relative"
              >
                <div
                  style="
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 400px;
                  "
                >
                  <table class="hover_report_table">
                    <tr
                      v-if="
                        (hover_report.regimen_instance_title
                          ? hover_report.regimen_instance_title
                          : exercise_title) != exercise_title
                      "
                    >
                      <td
                        class="hover_report_label hover_report_label_overview"
                      >
                        {{ $t("common.title") }}
                      </td>
                      <td
                        class="hover_report_value hover_report_value_overview"
                      >
                        {{
                          hover_report.regimen_instance_title
                            ? hover_report.regimen_instance_title
                            : exercise_title
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="hover_report_label hover_report_label_overview"
                      >
                        {{ $t("common.exercise") }}
                      </td>
                      <td
                        class="hover_report_value hover_report_value_overview"
                      >
                        {{ exercise_title }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="hover_report_label hover_report_label_overview"
                      >
                        {{ $t("common.date") }}
                      </td>
                      <td
                        class="hover_report_value hover_report_value_overview"
                      >
                        {{ hover_report_date_date }}
                        {{ hover_report_date_time }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="hover_report_label hover_report_label_overview"
                      >
                        {{ $t("regimen_report_card.time_spent") }}
                      </td>
                      <td
                        class="hover_report_value hover_report_value_overview"
                      >
                        {{ hover_report_time }}
                      </td>
                    </tr>
                    <tr v-if="hover_report && hover_report.level">
                      <td
                        class="hover_report_label hover_report_label_overview"
                      >
                        {{ $t("common.level") }}
                      </td>
                      <td
                        class="hover_report_value hover_report_value_overview"
                      >
                        {{ hover_report.level }}
                      </td>
                    </tr>
                    <tr v-if="hover_report && hover_report.level">
                      <td
                        class="hover_report_label hover_report_label_overview"
                      >
                        {{ $t("regimen_report_card.level_completed") }}
                      </td>
                      <td
                        class="hover_report_value hover_report_value_overview"
                      >
                        <span v-if="hover_report.level_completed">{{
                          $t("regimen_report_card.yes")
                        }}</span>
                        <span v-else>{{ $t("regimen_report_card.no") }}</span>
                      </td>
                    </tr>
                  </table>

                  <table class="hover_report_table" style="margin-top: 20px">
                    <!-- Score is displayed via Vue Template -->
                    <tr v-if="OpticalGymScores[hover_report.exercise_id]">
                      <td colspan="2">
                        <component
                          :is="hover_report.exercise_id + '-score'"
                          context="regimen_report_card"
                          :score="hover_report.score"
                          :exercise="exercises[hover_report.exercise_id]"
                          :config="hover_report.params"
                        />
                      </td>
                    </tr>

                    <tr
                      v-for="(field_config, field) in exercise.reporting"
                      v-else
                      :key="field"
                    >
                      <td
                        v-if="
                          display_field_title(
                            hover_report.score[field],
                            field_config
                          )
                        "
                        :class="
                          'hover_report_label hover_report_label_score' +
                          (display_field_value(
                            hover_report.score[field],
                            field_config
                          )
                            ? ''
                            : ' hover_report_label_empty_value')
                        "
                      >
                        {{ field_config.title[$i18n.lang()] }}
                      </td>
                      <td
                        v-if="
                          display_field_value(
                            hover_report.score[field],
                            field_config
                          )
                        "
                        class="hover_report_value hover_report_value_score"
                      >
                        {{
                          format_value(hover_report.score[field], field_config)
                        }}
                      </td>
                    </tr>

                    <!-- Questionnaire -->
                    <template
                      v-if="hover_report.exercise_type == 'questionnaire'"
                    >
                      <tr
                        v-for="(
                          item, item_idx
                        ) in hover_report_questionnaire_answers"
                        :key="item_idx"
                      >
                        <td class="hover_report_label hover_report_label_score">
                          {{ item.item.title }}
                        </td>
                        <td class="hover_report_value hover_report_value_score">
                          {{ item.answer_display }}
                        </td>
                      </tr>
                    </template>

                    <!-- Single Question -->
                    <template v-if="hover_report.exercise_type == 'question'">
                      <tr v-if="hover_report_question_answer">
                        <td class="hover_report_label hover_report_label_score">
                          {{ $t("regimen_report_card.answer") }}
                        </td>
                        <td class="hover_report_value hover_report_value_score">
                          {{ hover_report_question_answer.answer_display }}
                        </td>
                      </tr>
                    </template>

                    <!-- Feedback -->
                    <tr v-if="hover_report.feedback">
                      <td
                        colspan="2"
                        style="
                          width: 400px;
                          font-weight: bold;
                          padding-top: 20px;
                          text-align: center;
                        "
                      >
                        {{ $t("regimen_report_card.feedback") }}
                      </td>
                    </tr>
                    <tr v-if="hover_report.feedback">
                      <td colspan="2">{{ hover_report.feedback }}</td>
                    </tr>
                  </table>
                  <div
                    v-if="
                      !hover_report.exercise_type ||
                      hover_report.exercise_type == 'interactive'
                    "
                    style="width: 440px; margin: 0 auto; margin-top: 20px"
                  >
                    <v-container>
                      <v-layout justify-space-around>
                        <v-flex>
                          <v-btn small @click="options_dialog = true">{{
                            $t("regimen_report_card.view_config")
                          }}</v-btn>
                        </v-flex>
                        <v-flex>
                          <v-btn small @click="events_dialog = true">{{
                            $t("regimen_report_card.view_events")
                          }}</v-btn>
                        </v-flex>
                        <v-flex
                          v-if="
                            allow_edit_assigned &&
                            regimen &&
                            hover_report.regimen_instance_id &&
                            exercise_instance_by_uuid[
                              hover_report.regimen_instance_id
                            ]
                          "
                        >
                          <v-btn
                            small
                            @click="
                              regimen_edit_dialogs[
                                hover_report.regimen_instance_id
                              ] = true
                            "
                            >{{
                              $t("regimen_report_card.edit_exercise")
                            }}</v-btn
                          >

                          <v-dialog
                            v-model="
                              regimen_edit_dialogs[
                                hover_report.regimen_instance_id
                              ]
                            "
                            scrollable
                            max-width="1200"
                          >
                            <v-card
                              v-if="
                                regimen_edit_dialogs[
                                  hover_report.regimen_instance_id
                                ]
                              "
                            >
                              <v-card-title style="padding: 0px">
                                <v-toolbar>
                                  <v-btn
                                    icon
                                    @click="
                                      regimen_edit_dialogs[
                                        hover_report.regimen_instance_id
                                      ] = false
                                    "
                                  >
                                    <v-icon>close</v-icon>
                                  </v-btn>
                                  <v-toolbar-title>
                                    <span>{{ exercise_title }}</span>
                                  </v-toolbar-title>
                                </v-toolbar>
                              </v-card-title>
                              <v-card-text class="pt-6">
                                <RegimenExerciseEdit
                                  v-model="
                                    exercise_instance_by_uuid[
                                      hover_report.regimen_instance_id
                                    ]
                                  "
                                  :allow_select_exercise="false"
                                  @save="
                                    save_exercise_instance_edit(
                                      exercise_instance_by_uuid[
                                        hover_report.regimen_instance_id
                                      ]
                                    )
                                  "
                                />
                              </v-card-text>
                            </v-card>
                          </v-dialog>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="options_dialog" max-width="600px">
      <v-card v-if="options_dialog">
        <v-card-title>
          <v-toolbar grey flat>
            <v-btn icon @click="options_dialog = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>{{
              $t("regimen_report_card.exercise_configuration")
            }}</v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <table class="hover_report_options">
            <tr>
              <td
                colspan="2"
                style="
                  width: 400px;
                  font-weight: bold;
                  padding-top: 20px;
                  text-align: center;
                "
              >
                {{ $t("regimen_report_card.configuration") }}
              </td>
            </tr>
            <tr v-for="(value, field) in hover_report.params" :key="field">
              <td
                v-if="field != 'level'"
                class="hover_report_label"
                style="min-width: 100px"
              >
                {{ field }}
              </td>
              <td v-if="field != 'level'" class="hover_report_value">
                {{ value }}
              </td>
            </tr>
            <tr>
              <td
                colspan="2"
                style="
                  width: 400px;
                  font-weight: bold;
                  padding-top: 20px;
                  text-align: center;
                "
              >
                {{ $t("regimen_report_card.calibration") }}
              </td>
            </tr>
            <tr v-for="(value, field) in hover_report.calibration" :key="field">
              <template
                v-if="
                  current_user.is_admin ||
                  (field != 'fingerprint' &&
                    field != 'id' &&
                    field != 'user_id')
                "
              >
                <td class="hover_report_label" style="min-width: 100px">
                  {{ field }}
                </td>
                <td class="hover_report_value">{{ value }}</td>
              </template>
            </tr>
          </table>
          <v-layout justify-space-around class="mt-6">
            <v-flex>
              <v-btn small @click="laumch_exercise_with_config(false)"><v-icon small left color="blue">mdi-play</v-icon>{{ $t('regimen_report_card.launch_exercise') }}</v-btn>
            </v-flex>
            <v-flex>
              <v-btn small @click="laumch_exercise_with_config(true)"><v-icon small left color="blue">mdi-play</v-icon>{{ $t('regimen_report_card.launch_exercise_match_calibration') }}</v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="events_dialog" max-width="1200px">
      <v-card v-if="events_dialog">
        <v-card-title style="padding: 0px">
          <v-toolbar>
            <v-btn icon @click="events_dialog = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>{{
              $t("regimen_report_card.events")
            }}</v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <table class="hover_report_events">
            <tr>
              <th>{{ $t("regimen_report_card.time") }}</th>
              <th>{{ $t("regimen_report_card.type") }}</th>
              <th>{{ $t("regimen_report_card.details") }}</th>
            </tr>
            <tr v-for="(event, index) in hover_report_events" :key="index">
              <td>{{ format_minute_seconds(event.event_time) }}</td>
              <td>{{ event.event_type }}</td>
              <td>
                <span
                  v-for="(event_value, event_field) in event"
                  :key="event_field"
                  class="event_values"
                >
                  <span v-if="display_event_field(event_field)">
                    <span>{{ event_field }}:&nbsp;</span>
                    <span>{{ event_value }}</span>
                    <span class="event_values_comma">,&nbsp;</span>
                  </span>
                </span>
              </td>
            </tr>
          </table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import $ from "jquery";
import { Chance } from "chance";
let chance = Chance();
import { mapState } from "vuex";
import OpticalGymScores from "../../../exercises/OpticalGymScores.js";
import RegimenExerciseEdit from "./RegimenExerciseEdit.vue";
import axios from "axios";

// Map ConfigForms and Score components
let exercise_components = {};
for (let exercise_id of Object.keys(OpticalGymScores)) {
  exercise_components[exercise_id + "-score"] = OpticalGymScores[exercise_id];
}

import plotly_dark from "../plotly_dark.json";

export default {
  components: Object.assign(exercise_components, { RegimenExerciseEdit }),
  props: {
    exercise: {
      type: Object,
      required: true,
    },
    reports: {
      type: Array,
      required: true,
    },
    display_graph: {
      type: Boolean,
      default: true,
    },
    plotly_style: {
      type: String,
      default: "height: 420px; width: 600px",
    },
    max_ticks: {
      type: Number,
      default: 30,
    },
    show_toolbar: {
      type: Boolean,
      default() {
        return true;
      },
    },
    regimen: {
      type: Object,
      default: () => null,
    },
    width: {
      type: Number,
      default: 0,
    },
    allow_edit_assigned: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    var hover_report;
    if (this.reports.length) {
      hover_report = this.reports[this.reports.length - 1];
    } else {
      hover_report = null;
    }

    let plot_div_id =
      "regimen_report_card_" + chance.string({ length: 10, alpha: true });

    let loading = this.display_graph;

    let regimen_edit_dialogs = {};
    if (this.regimen) {
      for (let exercise of this.regimen.exercises) {
        if (
          exercise.uuid &&
          !regimen_edit_dialogs.hasOwnProperty(exercise.uuid)
        ) {
          regimen_edit_dialogs[exercise.uuid] = false;
        }
      }
    }

    return {
      hover_report,
      options_dialog: false,
      events_dialog: false,
      lazy: false,
      loading,
      plot_div_id,
      regimen_edit_dialogs,
      OpticalGymScores,
    };
  },
  computed: {
    ...mapState(["exercises", "is_mobile"]),
    exercise_instance_by_uuid() {
      let instances = {};
      if (!this.regimen) {
        return instances;
      }
      for (let exercise of this.regimen.exercises) {
        if (exercise.uuid) {
          instances[exercise.uuid] = exercise;
        }
      }

      return instances;
    },
    exercise_title() {
      // TODO: This should be possible since exercise should always exist
      if (!this.exercise) {
        return this.$t("regimen_report_card.unknown_exercise");
      }
      if (!this.exercise.title) {
        if (this.exercise.exercise_type == "questionnaire") {
          return this.$t("common.questionnaire");
        } else if (this.exercise.exercise_type == "question") {
          return this.$t("common.single_question");
        } else {
          return this.$t("regimen_report_card.unknown_exercise");
        }
      }
      if (typeof this.exercise.title == "string") {
        return this.exercise.title;
      }
      let locale = this.$i18n.lang();
      if (this.exercise.title[locale]) {
        return this.exercise.title[locale];
      } else {
        return this.exercise.title.en;
      }
    },
    instance_title() {
      if (!this.reports.length) {
        return "";
      }

      let title = this.reports[0].regimen_instance_title;
      for (let report of this.reports) {
        if (title != report.regimen_instance_title) {
          return "";
        }
      }

      return title;
    },
    hover_report_date_date() {
      let date = new Date(this.hover_report.start_time);
      var options = {
        month: "long",
        day: "numeric",
        year: "numeric",
      };
      let formatter = new Intl.DateTimeFormat(this.$i18n.lang(), options);
      return formatter.format(date);
    },
    hover_report_date_time() {
      let date = new Date(this.hover_report.start_time);
      var options = {
        hour: "numeric",
        minute: "numeric",
      };
      let formatter = new Intl.DateTimeFormat(this.$i18n.lang(), options);
      return formatter.format(date);
    },
    hover_report_time() {
      let time_played = this.hover_report.time_played / 1000;
      let time_paused = this.hover_report.time_paused / 1000;
      let time_total = this.hover_report.time_total / 1000;

      let output = this.format_minute_seconds(time_played);

      if (time_paused) {
        output +=
          " (" +
          this.format_minute_seconds(time_paused) +
          this.$t("common.paused") +
          ", " +
          this.format_minute_seconds(time_total) +
          " " +
          this.$t("common.total") +
          ")";
      }
      return output;
    },
    hover_report_events() {
      let events = [];

      for (var event_type in this.hover_report.events) {
        for (var i in this.hover_report.events[event_type]) {
          let event = this.hover_report.events[event_type][i];
          event.event_time =
            (event.timestamp - this.hover_report.start_time) / 1000;
          events.push(event);
        }
      }

      events.sort(function (a, b) {
        return a.timestamp - b.timestamp;
      });
      return events;
    },
    hover_report_questionnaire_answers() {
      let answers = [];
      if (this.exercise.exercise_type != "questionnaire") {
        return [];
      }
      if (!this.hover_report.params.items) {
        return [];
      }

      for (let item of this.hover_report.params.items) {
        let answer = this.hover_report.score[item.id];

        if (answer === null || answer === undefined) continue;

        let answer_display = this.question_answer_display(item, answer);

        if (item) {
          answers.push({
            item,
            answer,
            answer_display,
          });
        }
      }

      return answers;
    },
    hover_report_question_answer() {
      if (this.exercise.exercise_type != "question") {
        return null;
      }

      let item = this.hover_report.params;

      let answer = this.hover_report.score[item.id];

      if (answer === null || answer === undefined) return;

      let answer_display = this.question_answer_display(item, answer);

      return {
        item,
        answer,
        answer_display,
      };
    },
    questionnaire_graphable_items() {
      let items = [];
      if (this.reports[0].exercise_type == "question") {
        for (let report of this.reports) {
          if (report.params) {
            let item = report.params;

            // Check if item already exists by looking at item.id and item.instance_id
            let exists = false;
            for (let existing_item of items) {
              if (
                existing_item.id == item.id &&
                existing_item.instance_id == report.instance_id
              ) {
                exists = true;
                break;
              }
            }
            if (exists) continue;

            if (
              item.type == "number-field" ||
              item.type == "mood" ||
              item.type == "rating"
            ) {
              item.instance_id = report.instance_id;
              items.push(item);
            }
          }
        }
      } else if (this.reports[0].exercise_type == "questionnaire") {
        for (let report of this.reports) {
          if (report.params && report.params.items) {
            for (let item of report.params.items) {
              // Check if item already exists by looking at item.id and item.instance_id
              let exists = false;
              for (let existing_item of items) {
                if (
                  existing_item.id == item.id &&
                  existing_item.instance_id == report.instance_id
                ) {
                  exists = true;
                  break;
                }
              }
              if (exists) continue;

              if (
                item.type == "number-field" ||
                item.type == "mood" ||
                item.type == "rating"
              ) {
                item.instance_id = report.instance_id;
                items.push(item);
              }
            }
          }
        }
      }

      return items;
    },
    ...mapState(["current_user"]),
  },
  watch: {
    reports() {
      this.plot();
      if (this.reports.length) {
        this.hover_report = this.reports[this.reports.length - 1];
      } else {
        this.hover_report = null;
      }
    },
    regimen() {
      if (this.regimen) {
        for (let exercise of this.regimen.exercises) {
          if (
            exercise.uuid &&
            !this.regimen_edit_dialogs.hasOwnProperty(exercise.uuid)
          ) {
            this.regimen_edit_dialogs[exercise.uuid] = false;
          }
        }
      }
    },
  },
  mounted() {
    if (this.display_graph) {
      this.load_plotly();
      this.wait_for_plotly();
    }
  },
  methods: {
    question_answer_display(item, answer) {
      let answer_display = answer;
      if (
        (item.type == "select" || item.type == "radio-group") &&
        item.items &&
        item.items.length
      ) {
        for (let option of item.items) {
          if (option.value == answer) {
            answer_display = option.text;
            break;
          }
        }
      }
      if (item.type == "checkboxes" && item.items && item.items.length) {
        answer_display = [];
        for (let option of item.items) {
          if (answer.includes(option.value)) {
            answer_display.push(option.text);
          }
        }
        answer_display = answer_display.join(", ");
      }
      if (item.type == "checkbox" || item.type == "switch") {
        answer_display = answer ? this.$t("common.yes") : this.$t("common.no");
      }
      if (item.type == "mood") {
        if (answer) {
          answer_display = this.mood_map(answer) + " (" + answer + ")";
        }
      }
      return answer_display;
    },
    load() {
      if (this.display_graph) {
        this.load_plotly();
        this.wait_for_plotly();
      }
    },
    mood_map(mood) {
      let mood_map = ["😟", "😕", "😐", "🙂", "😀"];
      if (mood) {
        if (mood_map[mood - 1]) {
          return mood_map[mood - 1];
        } else {
          return mood;
        }
      }
    },
    save_exercise_instance_edit(instance) {
      // Load the regimen fresh and replace the instance
      axios.get("/api/regimen/" + this.regimen.id).then((response) => {
        let resp = response.data;
        if (resp.status == "error") {
          messageBus.$emit("api-error", resp);
        } else {
          if (resp.data) {
            let regimen = resp.data;
            let instance_replace = false;
            for (let idx in regimen.exercises) {
              if (
                regimen.exercises[idx].uuid &&
                regimen.exercises[idx].uuid == instance.uuid
              ) {
                regimen.exercises[idx] = instance;
                instance_replace = true;
              }
            }
            if (!instance_replace) {
              messageBus.$emit("error", "Unable to update exercise intance.");
            } else {
              axios
                .put("/api/regimen/" + regimen.id, regimen)
                .then((response) => {
                  let resp = response.data;
                  if (resp.status == "error") {
                    messageBus.$emit("api-error", resp);
                  } else {
                    this.$emit("regimen-updated", regimen);
                    this.regimen_edit_dialogs[instance.uuid] = false;
                    messageBus.$emit(
                      "success",
                      this.$t("regimen_report_card.assigned_exercise_updated")
                    );
                  }
                });
            }
          }
        }
      });
    },
    laumch_exercise_with_config(include_calibration = false) {
      let params = this.hover_report.params;
      let calibration = this.hover_report.calibration;

      let url = "/api/exercise/" + this.hover_report.exercise_id + "/launch";
      if (this.hover_report.level) {
        url += "/" + this.hover_report.level;
      }

      const params_string = new URLSearchParams(params).toString();
      url += "?" + params_string;

      if (include_calibration) {
        for (var ckey in calibration) {
          if (typeof calibration[ckey] !== "object") {
            url +=
              "calibration_override_" + ckey + "=" + calibration[ckey] + "&";
          }
        }
      }

      window.open(url);
    },
    display_event_field(field_name) {
      return !(
        field_name == "timestamp" ||
        field_name == "event_type" ||
        field_name == "event_time"
      );
    },
    format_minute_seconds(time_in_seconds) {
      let str_pad_left = function (string, pad, length) {
        return (new Array(length + 1).join(pad) + string).slice(-length);
      };

      time_in_seconds = Math.floor(time_in_seconds);

      var minutes = Math.floor(time_in_seconds / 60);
      var seconds = time_in_seconds - minutes * 60;
      return minutes + ":" + str_pad_left(seconds, "0", 2);
    },
    display_field_value(value, field_config) {
      if (value === null || value === undefined) {
        return false;
      }
      if (field_config.display == "boolean_label_only") {
        return false;
      }
      return true;
    },
    display_field_title(value, field_config) {
      if (field_config.display == "boolean_label_only") {
        return value;
      }
      if (value === null || value === undefined) {
        return false;
      }
      return true;
    },
    format_value(value, field_config) {
      let formatted;
      if (field_config.display == "integer" || field_config.display == "fraction" || field_config.display == "fraction_with_percent") {
        if (value) {
          formatted = Math.round(value);
        } else {
          formatted = "0";
        }
      } else if (field_config.display == "decimal_2") {
        formatted = Number(value).toFixed(2);
      } else if (field_config.display == "percent") {
        formatted = Math.round(value * 100) + "%";
      } else if (field_config.display == "ms_to_seconds") {
        formatted = Math.round(value / 1000);
      } else if (field_config.display == "ms_to_decimal_1_seconds") {
        formatted = Math.round(value / 100) / 10;
      } else if (field_config.display == "ms_to_decimal_2_seconds") {
        formatted = Math.round(value / 10) / 100;
      } else if (field_config.display == "ms_to_minutes_seconds") {
        formatted = this.format_minute_seconds(value / 1000);
      } else if (field_config.display == "boolean") {
        formatted = value ? this.$t("common.yes") : this.$t("common.no");
      } else if (field_config.display == "boolean_label_only") {
        formatted = value ? this.$t("common.yes") : this.$t("common.no"); // This isn't actually used...
      } else if (field_config.display == "label") {
        const label = field_config.value_labels[value];
        if (label) {
          return label[this.$i18n.lang()] || label["en"];
        }
        return value;
      } else {
        formatted = value;
      }

      if ("suffix" in field_config) {
        let suffix =
          field_config.suffix[this.$i18n.lang()] || field_config.suffix["en"];
        formatted += " " + suffix;
      }

      return formatted;
    },
    plot() {
      if (this.reports.length == 0) {
        return;
      }
      if (!window.Plotly) {
        return;
      }

      let self = this;

      let data, layout;
      if (
        this.reports[0].exercise_type == "interactive" ||
        !this.reports[0].exercise_type
      ) {
        ({ data, layout } = this.data_layout_interactive());
      } else if (
        this.reports[0].exercise_type == "questionnaire" ||
        this.reports[0].exercise_type == "question"
      ) {
        ({ data, layout } = this.data_layout_questionnaire());
      } else {
        ({ data, layout } = this.data_layout_video());
      }

      if (this.$vuetify.theme.dark) {
        layout.template = plotly_dark;
      }

      layout.modebar = {
        position: "left",
      };

      let options = {
        displayModeBar: true,
        modeBarButtons: [
          [],
          ["resetViews", "toImage", "zoomOut2d", "zoomIn2d"],
        ],
      };

      // plot element might not exist if we open RegimenReportCard then immidiately close it
      var plot_element = document.getElementById(this.plot_div_id);
      if (plot_element) {
        window.Plotly.newPlot(this.plot_div_id, data, layout, options);

        plot_element.on("plotly_hover", function (data) {
          let i = data.points[0].x;
          let report = self.reports[i];
          $(".hover_report_table_wrapper").fadeOut(100, "swing", function () {
            self.hover_report = report;
            $(".hover_report_table_wrapper").fadeIn(100, "swing");
          });
        });
      } else {
        // TODO Check if this component is mounted and raise an error if it is
      }
    },
    data_layout_questionnaire() {
      let data = [];
      var layout = {
        showlegend: false,
        xaxis: {
          tickvals: [],
          ticktext: [],
        },
      };

      var trace = {
        x: [],
        y: [],
        text: [],
        type: "scatter",
        name: "",
      };

      // Don't show any hover label for this trace
      var null_trace = {
        x: [],
        y: [],
        text: [],
        mode: "none",
        type: "scatter",
        hoverinfo: "text",
      };

      var trace2 = {
        x: [],
        y: [],
        text: [],
        type: "scatter",
        name: "",
        yaxis: "y2",
      };

      // Hover template
      trace.hovertemplate = "%{text}";

      let items = this.questionnaire_graphable_items;
      let num_items = items.length;

      if (num_items == 0) {
        layout.yaxis = {
          title: "Time Taken (seconds)",
          fixedrange: true,
        };
      }
      if (num_items >= 1) {
        layout.yaxis = {
          title: items[0].title,
        };
      }
      if (num_items >= 2) {
        layout.yaxis2 = {
          title: items[1].title,
          overlaying: "y",
          side: "right",
        };
      }

      // Calculate tick ratio;
      let tick_ratio = Math.round(this.reports.length / this.max_ticks);
      if (!tick_ratio) {
        tick_ratio = 1;
      }

      for (var i in this.reports) {
        let report = this.reports[i];
        let score = this.reports[i].score;

        if (!num_items) {
          let value = Math.round(score["time_taken_ms"] / 1000);
          let mins = Math.floor(score["time_taken_ms"] / 1000 / 60);
          let secs = value % 60;
          trace.text.push(mins + ":" + this.zeroPad(secs, 2));
          trace.x.push(i);
          trace.y.push(value);
        }
        if (num_items >= 1) {
          let value = score[items[0].id];
          trace.x.push(i);
          trace.y.push(value);

          if (items[0].type == "mood" && value) {
            trace.text.push(this.mood_map(value));
            layout.yaxis.range = [1, 5];
          } else {
            trace.text.push(value);
          }
        }
        if (num_items >= 2) {
          let value = score[items[1].id];
          trace2.x.push(i);
          trace2.y.push(value);

          if (items[1].type == "mood") {
            trace2.text.push(this.mood_map(value));
            layout.yaxis2.range = [1, 5];
          } else {
            trace2.text.push(value);
          }
        }

        // X-Axis
        let date = new Date(report.start_time);
        var options = { month: "short", day: "numeric" };
        let formatter = new Intl.DateTimeFormat(this.$i18n.lang(), options);
        let ticktext = formatter.format(date);
        if (report.feedback) {
          ticktext += " 💬";
        }
        if (i % tick_ratio == 0 || report.feedback) {
          layout.xaxis.tickvals.push(i);
          layout.xaxis.ticktext.push(ticktext);
        }

        null_trace.x.push(i);
        null_trace.y.push(0);
        null_trace.text.push(ticktext);
      }
      data.push(trace);
      data.push(null_trace);

      if (num_items >= 2) {
        data.push(trace2);
      }

      return { data, layout };
    },

    data_layout_interactive() {
      let data = [];
      var layout = {
        showlegend: false,
        xaxis: {
          tickvals: [],
          ticktext: [],
        },
      };

      var field_index = 1;
      let colorway_index = 0;
      let light_colors = ["blue", "orange", "#509e3d"];
      for (var field of Object.keys(this.exercise.reporting)) {
        let field_config = this.exercise.reporting[field];

        if (!field_config.graph) {
          continue;
        }

        let field_title =
          field_config.title[this.$i18n.lang()] || field_config.title["en"];

        let axis_title = field_title;
        if (field_config.axis && field_config.axis[this.$i18n.lang()]) {
          axis_title = field_config.axis[this.$i18n.lang()];
        } else if (field_config.axis && field_config.axis["en"]) {
          axis_title = field_config.axis["en"];
        }

        var trace = {
          x: [],
          y: [],
          type: "scatter",
          name: field_title,
        };

        // Hover template
        trace.hovertemplate = "%{y}";

        // Suffix
        let suffix = "";
        if ("suffix" in field_config) {
          suffix =
            field_config.suffix[this.$i18n.lang()] ||
            field_config.suffix["en"] ||
            "";
        }

        // Lay out the y-axis
        if (field_index == 1 && !field_config.secondary_graph) {
          let colour = light_colors[colorway_index];
          if (this.$vuetify.theme.dark) {
            colour = plotly_dark.layout.colorway[colorway_index];
          }

          layout.yaxis = {
            title:
              "<span style='color:" +
              colour +
              "'>•</span> " +
              axis_title +
              (suffix ? " (" + suffix + ")" : ""),
            fixedrange: true,
            _original_axis: axis_title,
          };
          if (field_config.display == "percent") {
            layout.yaxis.tickformat = ",.0%";
            layout.yaxis.range = [0, 1.05];
          }
          if (field_config.display == "ms_to_minutes_seconds") {
            layout.yaxis.tickformat = "%M:%S";
          }
        }

        if (field_index == 2 && !field_config.secondary_graph) {
          let colour = light_colors[colorway_index];
          if (this.$vuetify.theme.dark) {
            colour = plotly_dark.layout.colorway[colorway_index];
          }

          layout.yaxis2 = {
            title:
              "<span style='color:" +
              colour +
              "'>•</span> " +
              axis_title +
              (suffix ? " (" + suffix + ")" : ""),
            overlaying: "y",
            side: "right",
            fixedrange: true,
            showgrid: false,
            _original_axis: axis_title,
          };
          if (field_config.display == "percent") {
            layout.yaxis2.tickformat = ",.0%";
            layout.yaxis2.range = [0, 1.05];
          }
          if (field_config.display == "ms_to_minutes_seconds") {
            layout.yaxis2.tickformat = "%M:%S";
          }
          trace.yaxis = "y2";
        }

        if (field_config.secondary_graph) {
          let colour = light_colors[colorway_index];
          if (this.$vuetify.theme.dark) {
            colour = plotly_dark.layout.colorway[colorway_index];
          }

          if (layout.yaxis && layout.yaxis._original_axis == axis_title) {
            layout.yaxis.title +=
              "<br />" +
              "<span style='color:" +
              colour +
              "'>•</span> " +
              axis_title +
              (suffix ? " (" + suffix + ")" : "");
          }
          if (layout.yaxis2 && layout.yaxis2._original_axis == axis_title) {
            layout.yaxis2.title +=
              "<br />" +
              "<span style='color:" +
              colour +
              "'>•</span> " +
              axis_title +
              (suffix ? " (" + suffix + ")" : "");
          }
        }

        var current_level = 0;

        // Calculate tick ratio;
        let tick_ratio = Math.round(this.reports.length / this.max_ticks);
        if (!tick_ratio) {
          tick_ratio = 1;
        }

        for (var i in this.reports) {
          let report = this.reports[i];
          let score = this.reports[i].score;
          let value = score[field];

          if (typeof value == "undefined" || value === null) {
            continue;
          }

          if (field_config.display == "ms_to_seconds") {
            value = Math.round(value / 1000);
          } else if (field_config.display == "ms_to_decimal_1_seconds") {
            value = Math.round(value / 100) / 10;
          } else if (field_config.display == "ms_to_decimal_2_seconds") {
            value = Math.round(value / 10) / 100;
          } else if (field_config.display == "ms_to_minutes_seconds") {
            value = ms2dt(value);
          } else if (field_config.display == "integer" || field_config.display == "fraction" || field_config.display == "fraction_with_percent") {
            value = Math.round(value);
          }
          else if (field_config.display == "decimal_2") {
            value = Math.round(value * 100) / 100;
          }

          trace.x.push(i);
          trace.y.push(value);

          // X-Axis
          let date = new Date(report.start_time);
          var options = { month: "short", day: "numeric" };
          let formatter = new Intl.DateTimeFormat(this.$i18n.lang(), options);
          let ticktext = formatter.format(date);
          if (report.feedback) {
            ticktext += " 💬";
          }
          if (report.level != current_level) {
            ticktext += " △";
            current_level = report.level;
          }

          if (!field_config.secondary_graph) {
            if (
              i % tick_ratio == 0 ||
              report.feedback ||
              report.level != current_level
            ) {
              layout.xaxis.tickvals.push(i);
              layout.xaxis.ticktext.push(ticktext);
            }
          }
        }
        data.push(trace);

        if (!field_config.secondary_graph) {
          field_index++;
        }
        colorway_index++;
      }

      return { data, layout };
    },

    data_layout_video() {
      let data = [];
      var layout = {
        showlegend: false,
        xaxis: {
          tickvals: [],
          ticktext: [],
        },
      };

      var trace = {
        x: [],
        y: [],
        text: [],
        type: "scatter",
        name: "",
      };

      // Hover template
      trace.hovertemplate = "%{text}";

      layout.yaxis = {
        title: this.$t("regimen_report_card.time_spent") + " (" + this.$t('common.seconds') + ")",
        fixedrange: true,
      };

      // Calculate tick ratio;
      let tick_ratio = Math.round(this.reports.length / this.max_ticks);
      if (!tick_ratio) {
        tick_ratio = 1;
      }

      for (var i in this.reports) {
        let report = this.reports[i];
        let score = this.reports[i].score;

        let value = Math.round(score["time_taken_ms"] / 1000);

        // TODO: Change to minutes

        trace.x.push(i);
        trace.y.push(value);

        let mins = Math.floor(score["time_taken_ms"] / 1000 / 60);
        let secs = value % 60;
        trace.text.push(mins + ":" + this.zeroPad(secs, 2));

        // X-Axis
        let date = new Date(report.start_time);
        var options = { month: "short", day: "numeric" };
        let formatter = new Intl.DateTimeFormat(this.$i18n.lang(), options);
        let ticktext = formatter.format(date);
        if (report.feedback) {
          ticktext += " 💬";
        }
        if (i % tick_ratio == 0 || report.feedback) {
          layout.xaxis.tickvals.push(i);
          layout.xaxis.ticktext.push(ticktext);
        }
      }
      data.push(trace);

      return { data, layout };
    },

    load_plotly() {
      // Lazy load a big-ass JS library for plotting
      if (typeof window.plotly_script === "undefined") {
        window.plotly_script = document.createElement("script");
        window.plotly_script.src = "https://cdn.plot.ly/plotly-1.58.5.min.js";
        window.plotly_script.type = "text/javascript";
        window.plotly_script.crossorigin = "anonymous";
        document
          .getElementsByTagName("head")[0]
          .appendChild(window.plotly_script);
      }
    },
    wait_for_plotly() {
      let self = this;
      var waiter;
      waiter = setInterval(function () {
        if (typeof window.Plotly !== "undefined") {
          clearInterval(waiter);
          self.loading = false;
          self.plot();
        }
      }, 100);
    },
    zeroPad(num, places) {
      return String(num).padStart(places, "0");
    },
  },
};

function ms2dt(v) {
  v = v / 1000;

  var MIN = 60;
  var HOUR = 60 * 60;

  var h = Math.floor(v / HOUR);
  var m = Math.floor((v - h * HOUR) / MIN);
  var s = Math.floor(v - h * HOUR - m * MIN);

  // you have to provide YYYY-MM-DD
  // for plotly to understand it as a date
  return `2017-01-01 ${h}:${pad(m)}:${pad(s)}`;
}

function pad(v) {
  return v < 10 ? "0" + v : String(v);
}
</script>

<style>
.modebar .modebar-btn.plotlyjsicon {
  display: none !important;
}

.modebar .modebar-group a[data-title="Box Select"] {
  display: none !important;
}

.modebar {
  left: 0%;
  padding-left: 10px !important;
  padding-top: 10px !important;
}

.hover_report_table,
.hover_report_options,
.hover_report_events {
  border-collapse: collapse;
}

.hover_report_table tr,
.hover_report_options tr {
  border-bottom: 1px solid #ddd;
}

.hover_report_table .hover_report_label,
.hover_report_options .hover_report_label {
  font-weight: bold;
  width: 200px;
}

.hover_report_table .hover_report_label.hover_report_label_empty_value,
.hover_report_options .hover_report_label.hover_report_label_empty_value {
  font-weight: bold;
  width: 400px;
}

.hover_report_table .hover_report_label.hover_report_label_overview {
  width: 130px;
}

table.hover_report_table .hover_report_value {
  width: 200px;
}

.hover_report_table .hover_report_value.hover_report_value_overview {
  width: 270px;
}

.hover_report_events {
  width: 550px;
  margin-top: 30px;
}

.hover_report_events td {
  padding: 10px;
  border: 1px solid #ddd;
}

.hover_report_events .event_values:last-child .event_values_comma {
  display: none;
}

.regimen_report_card_plotly .main-svg g.axistext {
  display: none;
}
</style>
