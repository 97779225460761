<template>
  <div>
    <v-toolbar dark flat style="max-height: 65px" class="page_toolbar">
      <v-toolbar-title>
        <v-icon left>fa-ban</v-icon>{{ $t("permission_denied.permission_denied") }}
      </v-toolbar-title>
    </v-toolbar>
    <v-container>
      <v-layout align-center justify-space-around>
        <v-sheet elevation="2" style="padding: 50px; width:800px">
          <h2>{{ $t("permission_denied.permission_denied") }}</h2>
          <p></p>
          <p>{{ $t("permission_denied.it_looks_like_you_dont_have_permission") }}</p>

          <p>{{ $t("permission_denied.are_you_sure_youre_logged") }}</p>
          <p>{{ $t("permission_denied.you_are_currently_logged_in_as") }} {{ current_user.name_given }} {{ current_user.name_family }} ({{ current_user.email }})</p>
        </v-sheet>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
      ...mapState(["current_user"]),
    },
};
</script>
