<template>
  <v-card class="mx-auto" max-width="600">
    <v-toolbar :dense="smaller">
      <v-btn icon @click="close">
        <v-icon>close</v-icon>
      </v-btn>
      <v-toolbar-title>{{ $t("common.metronome") }}</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-card-text :class="{'metronome-smaller': smaller}">
      <v-container>
        <v-layout justify-space-between mb-3>
          <v-flex text-left>
            <span :class="smaller ? 'display-2 font-weight-light' :'display-3 font-weight-light'" v-text="bpm" />
            <span class="subheading font-weight-light mr-1">BPM</span>
            <v-fade-transition>
              <v-avatar
                v-if="isPlaying"
                :color="color"
                :style="{
                  animationDuration: animationDuration
                }"
                class="mb-1 v-avatar--metronome"
                size="12"
              />
            </v-fade-transition>
          </v-flex>
          <v-flex text-right>
            <v-btn :small="smaller" :color="color" dark depressed fab :disabled="!sound_loaded" @click="toggle">
              <v-icon large>{{ isPlaying ? 'pause' : 'play_arrow' }}</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>

      <v-layout justify-space-between :class="smaller ? 'mb-6' : 'mb-8'">
        <v-flex sm1 class="text-center mt-n1">
          <v-icon :color="color" @click="decrement">fa-minus</v-icon>
        </v-flex>

        <v-flex sm10>
          <vue-slider v-model="bpm" :min="40" :max="180" tooltip="none" ></vue-slider>
        </v-flex>

        <v-flex sm1 class="text-center mt-n1">
          <v-icon :color="color" @click="increment">fa-plus</v-icon>
        </v-flex>
      </v-layout>

      <v-select v-model="sound_asset" :label="$t('tool_metronome.sound')" :items="optionsSound" :dense="smaller" />
    </v-card-text>

    <!-- Preload audio files -->
    <div>
      <audio v-for="sound in optionsSound" :key="sound.value" :src="sound.value" muted preload />
    </div>
  </v-card>
</template>

<script>
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

export default {
  components: { VueSlider },
  props: {
    smaller: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    const ctx = new AudioContext();

    return {
      bpm: 80,
      interval: null,
      isPlaying: false,
      color: "primary",
      sound_loaded: false,
      buffers: {},
      ctx,
      sound_asset: "/assets/metronome/metronome.flac",
      optionsSound: [
        {
          text: this.$t("common.metronome"),
          value: "/assets/metronome/metronome.flac",
        },
        {
          text: this.$t("tool_metronome.drum_sticks"),
          value: "/assets/metronome/drumstick.flac",
        },
        {
          text: this.$t("tool_metronome.tin_can"),
          value: "/assets/metronome/tincan.flac",
        },
        {
          text: this.$t("tool_metronome.wood_block"),
          value: "/assets/metronome/woodblock.flac",
        },
      ],
    };
  },
  computed: {
    animationDuration() {
      return `${60 / this.bpm}s`;
    },
  },
  watch: {
    bpm() {
      this.metronome();
    },
  },
  mounted() {
    let self = this;
    // utility function to load an audio file and resolve it as a decoded audio buffer
    function getBuffer(url, audioCtx) {
      return new Promise((resolve, reject) => {
        if (!url) {
          reject("Missing url!");
          return;
        }

        if (!audioCtx) {
          reject("Missing audio context!");
          return;
        }

        let xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.responseType = "arraybuffer";

        xhr.onload = function () {
          let arrayBuffer = xhr.response;
          audioCtx.decodeAudioData(arrayBuffer, (decodedBuffer) => {
            self.buffers[url] = decodedBuffer;
            resolve(decodedBuffer);
          });
        };

        xhr.onerror = function () {
          reject("An error occurred.");
        };

        xhr.send();
      });
    }

    let promises = [];
    this.optionsSound.forEach((p) => {
      promises.push(getBuffer(p.value, self.ctx));
    });

    // Once all your sounds are loaded, mark us as ready to go.
    Promise.all(promises).then((_buffers) => {
      self.sound_loaded = true;
    });
  },
  methods: {
    decrement() {
      this.bpm--;
    },
    increment() {
      this.bpm++;
    },
    toggle() {
      this.isPlaying = !this.isPlaying;
      this.metronome();
    },
    close: function () {
      this.stop();
      this.$emit("close");
    },
    click() {
      if (this.isPlaying) {
        let source = this.ctx.createBufferSource();
        source.buffer = this.buffers[this.sound_asset];
        source.connect(this.ctx.destination);
        source.start();
      }
    },
    stop() {
      this.isPlaying = false;
      clearInterval(this.click_interval);
    },
    metronome() {
      let self = this;
      clearInterval(this.click_interval);
      if (this.isPlaying) {
        this.click_interval = setInterval(function () {
          self.click();
        }, 60000 / this.bpm);
      }
    },
  },
};
</script>

<style>
@keyframes metronome-example {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}

.v-avatar--metronome {
  animation-name: metronome-example;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
</style>