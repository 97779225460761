<template>
  <v-app>
    <UserSignUp :user_signup_token="user_signup_token" :require_tos_scroll="true" :tos_text="tos_text" />
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import UserSignUp from "../../shared/components/UserSignUp.vue";
import tos_text from "../tos.txt.yml";

export default {
  components: { UserSignUp },
  data() {
    return {
      user_signup_token: this.$route.params.token ? this.$route.params.token : "",
      tos_text: tos_text,
    };
  },
  computed: {
    ...mapState["current_user"],
  },
  setup() {
    // TODO: If we have a current user, redirect to main app page
    // TODO: If we don't have a token, redirect to default signup token
  }
};
</script>

<style>
</style>