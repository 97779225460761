<template>
  <div>
    <!-- Trial-end Notification -->
    <v-alert
      v-if="role != 'user' && !is_free && !no_trial_end_alert && clinic_notifications && notification_trial_end && num_users && !hide_billing_notification"
      style="margin-bottom: 0px"
      type="info"
      dense
    >
      <template v-slot:prepend>
        <v-btn dark color="indigo" fab x-small style="margin-right: 10px;" @click="close">
          <v-icon dark>close</v-icon>
        </v-btn>
      </template>

      <v-row align="center">
        <v-col class="grow">
          {{ notification_trial_end }}.
          <span
            v-if="role=='admin'"
          >{{ $t("clinic_page_notifications.enter_your_billing_information") }}</span>
          <span v-else>{{ $t("clinic_page_notifications.staff_contact_admin") }}</span>

          <p style="font-size: smaller; margin-top: 5px; margin-bottom: 0px">
            {{ $t("clinic_page_notifications.please") }}
            <a
              style="color: white; font-weight: 500; text-decoration: underline"
              @click="contact_us_dialog = true"
            >{{ $t("clinic_page_notifications.contact_us_lowercase") }}</a>
            {{ $t("clinic_page_notifications.if_you_require_more_time_to_assess") }}
          </p>
        </v-col>
        <v-col v-if="role == 'admin'" class="shrink">
          <v-btn
            small
            color="indigo"
            @click="billing_click"
          >{{ $t("clinic_page.enter_billing_info") }}</v-btn>
        </v-col>
      </v-row>

      <v-dialog v-model="contact_us_dialog" max-width="600px">
        <ContactUs v-if="contact_us_dialog" @close="contact_us_dialog = false" />
      </v-dialog>
    </v-alert>

    <!-- Cancelled Subscription Notification -->
    <v-alert
      v-else-if="!is_free && clinic_notifications && clinic_notifications.subscription_status"
      style="margin-bottom: 0px"
      type="info"
      dense
    >
      <template v-slot:prepend>
        <v-btn dark color="indigo" fab x-small style="margin-right: 10px;" @click="close">
          <v-icon dark>close</v-icon>
        </v-btn>
      </template>

      <v-row align="center">
        <v-col class="grow">

          <template v-if="clinic_notifications.payment_preference == 'send_invoice' && clinic_notifications.subscription_status == 'past_due'">
            {{ $t("clinic_page_notifications.invoice_past_due") }}
          </template>

          <template v-else>
            {{ $t("clinic_page_notifications.subscription_status_problem") }}
            <span
              v-if="role=='admin'"
            >{{ $t("clinic_page_notifications.update_your_billing_information") }}</span>
            <span v-else>{{ $t("clinic_page_notifications.staff_contact_admin_card") }}</span>
          </template>

          <p style="font-size: smaller; margin-top: 5px; margin-bottom: 0px">
            {{ $t("clinic_page_notifications.please") }}
            <a
              style="color: white; font-weight: 500; text-decoration: underline"
              @click="contact_us_dialog = true"
            >{{ $t("clinic_page_notifications.contact_us_lowercase") }}</a>
            {{ $t("clinic_page_notifications.contact_us_with_questions") }}
          </p>
        </v-col>
        <v-col v-if="role == 'admin'" class="shrink">
          <v-btn
            small
            color="indigo"
            @click="billing_click"
          >{{ $t("clinic_page.update_billing_info") }}</v-btn>
        </v-col>
      </v-row>

      <v-dialog v-model="contact_us_dialog" max-width="600px">
        <ContactUs v-if="contact_us_dialog" @close="contact_us_dialog = false" />
      </v-dialog>
    </v-alert>

    <!-- Clinic Credit-Card Notification -->
    <v-alert
      v-else-if="!is_free && clinic_notifications && clinic_notifications.card_expires && !hide_billing_notification"
      style="margin-bottom: 0px"
      type="info"
      dense
    >
      <template v-slot:prepend>
        <v-btn dark color="indigo" fab x-small style="margin-right: 10px;" @click="close">
          <v-icon dark>close</v-icon>
        </v-btn>
      </template>

      <v-row align="center">
        <v-col class="grow">
          {{ notification_card_expires('card_expires') }}.
          <span
            v-if="role=='admin'"
          >{{ $t("clinic_page_notifications.update_your_billing_information") }}</span>
          <span v-else>{{ $t("clinic_page_notifications.staff_contact_admin_card") }}</span>

          <p style="font-size: smaller; margin-top: 5px; margin-bottom: 0px">
            {{ $t("clinic_page_notifications.please") }}
            <a
              style="color: white; font-weight: 500; text-decoration: underline"
              @click="contact_us_dialog = true"
            >{{ $t("clinic_page_notifications.contact_us_lowercase") }}</a>
            {{ $t("clinic_page_notifications.contact_us_with_questions") }}
          </p>
        </v-col>
        <v-col v-if="role == 'admin'" class="shrink">
          <v-btn
            small
            color="indigo"
            @click="billing_click"
          >{{ $t("clinic_page.update_billing_info") }}</v-btn>
        </v-col>
      </v-row>

      <v-dialog v-model="contact_us_dialog" max-width="600px">
        <ContactUs v-if="contact_us_dialog" @close="contact_us_dialog = false" />
      </v-dialog>
    </v-alert>


    <!-- User Credit-Card Notification -->
    <v-alert
      v-else-if="!is_free && clinic_notifications && clinic_notifications.user_card_expires && !hide_billing_notification"
      style="margin-bottom: 0px"
      type="info"
      dense
    >
      <template v-slot:prepend>
        <v-btn dark color="indigo" fab x-small style="margin-right: 10px;" @click="close">
          <v-icon dark>close</v-icon>
        </v-btn>
      </template>

      <v-row align="center">
        <v-col class="grow">
          {{ notification_card_expires('user_card_expires') }}.
          <span>{{ $t("clinic_page_notifications.update_your_billing_information") }}</span>

          <p style="font-size: smaller; margin-top: 5px; margin-bottom: 0px">
            {{ $t("clinic_page_notifications.please") }}
            <a
              style="color: white; font-weight: 500; text-decoration: underline"
              @click="contact_us_dialog = true"
            >{{ $t("clinic_page_notifications.contact_us_lowercase") }}</a>
            {{ $t("clinic_page_notifications.contact_us_with_questions") }}
          </p>
        </v-col>
        <v-col class="shrink">
          <v-btn
            small
            color="indigo"
            link:
            :href="'/api/subscription/portal/user/' + current_user.id"
            target="_blank"
          >{{ $t("clinic_page.update_billing_info") }}</v-btn>
        </v-col>
      </v-row>

      <v-dialog v-model="contact_us_dialog" max-width="600px">
        <ContactUs v-if="contact_us_dialog" @close="contact_us_dialog = false" />
      </v-dialog>
    </v-alert>
  </div>
</template>

<script>
import axios from "axios";
import ContactUs from "./ContactUs";
import cookie from "js-cookie";
import { mapState } from "vuex";

export default {
  components: { ContactUs },
  props: {
    clinic_id: {
      type: String,
      required: true,
    },
    num_users: {
      type: Number,
      required: true,
    },
    is_free: {
      type: Boolean,
      required: true,
    },
    no_trial_end_alert: {
      type: Boolean,
      default: () => false,
    },
    role: {
      type: String,
      required: true,
    },
  },
  data() {
    let cookie_name = "opticalgym_hide_billing_notification_" + this.clinic_id;
    let hide_billing_notification = cookie.get(cookie_name);

    return {
      contact_us_dialog: false,
      clinic_notifications: null,
      hide_billing_notification: hide_billing_notification || false,
    };
  },
  computed: {
    ...mapState(["current_invite", "current_user"]),
    notification_trial_end() {
      if (this.clinic_notifications && this.clinic_notifications.trial_end) {
        let now = new Date();
        let trial_end = new Date(this.clinic_notifications.trial_end);
        if (trial_end < new Date("Aug 1, 2020")) {
          trial_end = new Date("Aug 1, 2020");
        }

        // To calculate the time difference of two dates
        var days = Math.round(
          (trial_end.getTime() - now.getTime()) / (1000 * 3600 * 24)
        );

        if (now < trial_end) {
          if (days <= 15) {
            let text =
              this.$t("clinic_page_notifications.your_free_trial_ends_in") +
              " " +
              days +
              " " +
              this.$t("common.days");
            // Admin
            if (this.role == "admin") {
              return text;
            }
            // Staff (only show if less than a week left)
            else if (days <= 7) {
              return text;
            } else {
              return "";
            }
          } else {
            return "";
          }
        } else {
          return this.$t("clinic_page_notifications.your_free_trial_has_ended");
        }
      } else return "";
    },
    hide_billing_notification_cookie() {
      return "opticalgym_hide_billing_notification_" + this.clinic_id;
    },
  },
  mounted() {
    this.load_clinic_notifications();
  },
  methods: {
    notification_card_expires(key) {
      if (this.clinic_notifications && this.clinic_notifications[key]) {
        let now = new Date();
        let card_expires = new Date(this.clinic_notifications[key]);

        // To calculate the time difference of two dates
        var days = Math.round(
          (card_expires.getTime() - now.getTime()) / (1000 * 3600 * 24)
        );

        if (now < card_expires) {
          let text =
            this.$t("clinic_page_notifications.your_card_expires_in") +
            " " +
            days +
            " " +
            this.$t("common.days");
          // Admin
          if (this.role == "admin" || this.role == "user") {
            return text;
          }
          // Staff (only show if less than a week left)
          else if (days <= 7) {
            return text;
          } else {
            return "";
          }
        } else {
          return this.$t("clinic_page_notifications.your_card_has_expired");
        }
      } else return "";
    },
    close() {
      cookie.set(this.hide_billing_notification_cookie, true, {
        expires: 7,
      });
      this.hide_billing_notification = true;
    },
    billing_click() {
      this.$emit("billing_click");
    },
    load_clinic_notifications() {
      // If our user_role is "user" and our billing_strategy isn't "user", skip loading notifications
      if (this.role == "user") {
        if (!this.current_invite || this.current_invite.billing_strategy != "user") {
          return;
        }
      }

      let self = this;
      axios
        .get("/api/notifications/clinic/" + self.clinic_id)
        .then(function (response) {
          if (response.data.status == "success") {
            self.clinic_notifications = response.data.data;
          } else {
            messageBus.$emit("error", response.data.error_message);
          }
        });
    },
  },
};
</script>