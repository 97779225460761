<template>
  <v-flex xs8 m6>
    <v-card>
      <v-toolbar v-if="!embed" dark color="primary">
        <v-toolbar-title>{{
          $t("account_archived.account_archived")
        }}</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-card-text style="text-align: center; padding: 30px">
        <p>{{ $t("account_archived.your_account_has_been_archived") }}</p>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="logout">
          <v-icon small left>fa-sign-out-alt</v-icon>
          {{ $t("common.log_out") }}
        </v-btn>

        <v-btn
          v-if="user_subscriptions && user_subscriptions.length"
          :href="stripe_user_portal_href"
          target="_blank"
        >
          <span>
            <v-icon small left>mdi-file-document-outline</v-icon>
            {{ $t("user_edit.view_invoices") }}
          </span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-flex>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  props: {},
  data() {
    let data = {};
    return data;
  },
  computed: {
    ...mapState(["user_subscriptions", "current_user"]),
    stripe_user_portal_href() {
      return "/api/subscription/portal/user/" + this.current_user.id;
    },
  },
  methods: {
    logout: function () {
      axios.post("/api/user/logout").then(function (response) {
        let resp = response.data;
        if (resp.status == "success") {
          window.location.reload();
        } else {
          messageBus.$emit("api-error", resp);
        }
      });
    },
  },
};
</script>
