<template>
  <v-flex id="opticalgym_login_modal" xs12 :sm8="!embed || framed" :md4="!embed && !framed">
    <v-card v-if="!show_tfa" class="elevation-12">
      <v-toolbar v-if="!embed" dark color="primary">
        <v-toolbar-title>{{ $t("login.login") }}</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-card-title v-if="email_reset_sent">
        <p>{{ $t("login.instructions_for_resetting_password") }}</p>
      </v-card-title>

      <v-card-title v-if="session_timeout">
        <p>{{ $t("login.session_timeout") }}</p>
      </v-card-title>

      <v-card-text>
        <v-alert
          v-if="login_error != ''"
          outlined
          :value="login_error != ''"
          type="error"
        >{{ login_error }}</v-alert>

        <v-text-field
          v-if="!email_reset_sent"
          ref="email"
          v-model="email"
          prepend-icon="person"
          :label="$t('common.email')"
          type="text"
          :rules="emailRules"
          validate-on-blur
          autofocus
          @unblur="emailRules = []"
        />
        <v-text-field
          v-if="!forgot_password"
          ref="password"
          v-model="password"
          prepend-icon="lock"
          :label="$t('common.password')"
          type="password"
          :rules="passwordRules"
          @unblur="passwordRules = []"
        />
      </v-card-text>

      <v-card-actions v-if="!forgot_password">
        <v-btn
          @click="forgot_password = true; email_reset_sent = false"
        >{{ $t("login.forgot_password") }}</v-btn>
        <v-spacer />
        <v-btn color="primary" @click="submit()">{{ $t("login.login") }}</v-btn>
      </v-card-actions>

      <v-card-actions v-if="forgot_password">
        <v-container>
          <v-row justify="space-between">
            <v-btn @click="forgot_password = false; email_reset_sent = false">
              <v-icon left>arrow_back</v-icon>
              {{ $t("login.back_to_login") }}
            </v-btn>
            <v-btn
              v-if="!email_reset_sent"
              color="primary"
              @click="submit()"
            >{{ $t("login.email_password_reset") }}</v-btn>
          </v-row>
        </v-container>
      </v-card-actions>
      <v-progress-linear v-if="in_flight" :indeterminate="true" />
    </v-card>

    <slot></slot>

    <v-card
      v-if="show_invite_link && !show_tfa"
      max-width="400"
      class="mx-auto"
      style="margin-top: 50px; text-align: center"
    >
      <v-card-text>{{ $t("login.have_invitation_code") }}</v-card-text>
      <v-card-actions>
        <v-btn
          text
          small
          class="text-none mx-auto"
          color="primary"
          href="/app/code"
        >{{ $t("login.redeem_invitation_code") }}</v-btn>
      </v-card-actions>
    </v-card>

    <v-card v-if="show_tfa" class="elevation-12">
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ $t("login.tfa_title") }}</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-card-text>
        <p>{{ $t('A verification code has been sent to your email. Please enter it here.') }}</p>
        <v-alert
          v-if="tfa_error != ''"
          outlined
          :value="tfa_error != ''"
          type="error"
        >{{ tfa_error }}</v-alert>

        <div class="d-flex justify-center mb-6">
          <v-text-field
            ref="tfacode"
            v-model="tfa_code"
            class="tfa_code"
            :placeholder="$t('login.tfa_placeholder')"
            maxlength="6"
            size="6"
            type="text"
            pattern="[0-9]{6}"
            rounded
            outlined
          />
        </div>
      </v-card-text>

      <v-card-actions v-if="!forgot_password">
        <v-spacer />
        <v-btn color="primary" @click="submit_tfa()">{{ $t("login.tfa_verify") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-flex>
</template>


<script>
import axios from "axios";
import cookie from "js-cookie";
import OpticalGymUI from "../lib/OpticalGymUI";

export default {
  props: {
    embed: {
      type: Boolean,
      default: false,
    },
    default_email: {
      type: String,
      default: "",
    },
    show_invite_link: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    let query = new URLSearchParams(window.location.search);
    let session_timeout;
    if (query.has("session_timeout")) {
      session_timeout = true;
    } else {
      session_timeout = false;
    }

    return {
      email: this.default_email,
      emailRules: [],
      password: "",
      passwordRules: [],
      forgot_password: false,
      show_tfa: false,
      tfa_code: "",
      in_flight: false,
      email_reset_sent: false,
      login_error: "",
      tfa_error: "",
      framed: this.inIframe(),
      session_timeout,
    };
  },
  watch: {
    email: function (mail) {
      if (mail !== "") {
        this.emailRules = [
          (v) => OpticalGymUI.validate_email(v) || "Invalid Email address",
        ];
      } else if (mail === "") {
        this.emailRules = [];
      }
    },
  },
  mounted() {
    let self = this;
    window.addEventListener("keyup", function (event) {
      // Enter Key
      if (event.keyCode === 13) {
        self.submit();
      }
    });

    let stored_email = cookie.get("opticalgym_login_email");
    if (stored_email && !this.default_email) {
      this.email = stored_email;
    }

    // Check if we're in an iframe
  },
  methods: {
    inIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    },
    submit: function () {
      if (this.forgot_password) {
        this.reset_password();
      } else {
        this.login();
      }
    },
    login: function () {
      let self = this;

      self.login_error = "";

      if (!self.email) {
        self.emailRules = [self.$t("common.missing_email")];
        self.$refs.email.focus();
        return;
      }
      if (!self.password) {
        self.passwordRules = [self.$t("login.missing_password")];
        if (self.$refs.password) {
          self.$refs.password.focus();
        }
        return;
      }

      self.emailRules = [];
      self.passwordRules = [];
      self.in_flight = true;
      axios
        .post("/api/user/login", {
          email: self.email,
          pass: self.password,
        })
        .then(function (response) {
          self.in_flight = false;
          let resp = response.data;
          if (resp.status == "success") {
            let login_result = resp.data;
            if (login_result.success) {
              cookie.set("opticalgym_login_email", self.email, {
                expires: 365,
              });
              if (login_result.tfa_required) {
                self.show_tfa = true;
                // TODO: Language
                axios.post("/api/tfa/generate/email").then((_response) => {
                  // TODO: Error handling
                });
              } else {
                window.location.reload();
              }
            } else {
              if (login_result.error_code == "UserEmailDoesNotExist") {
                self.login_error = self.$t("login.user_not_exist_with_email");
                self.$refs.email.$el.getElementsByTagName("input")[0].focus();
              } else if (login_result.error_code == "InvalidPassword") {
                self.passwordRules = [
                  self.$t("common.invalid_password_try_again"),
                ];
                self.password = "";
              } else if (login_result.error_code.RateLimited) {
                self.login_error = self.$t("common.locked_out", [
                  login_result.error_code.RateLimited,
                ]);
              } else {
                self.login_error = "Unknown Error: " + login_result.error_code;
              }
            }
          } else {
            self.login_error = resp.error_message;
          }
        });
    },
    reset_password: function () {
      let self = this;

      self.login_error = "";

      if (!self.email) {
        self.emailRules = ["Missing email"];
        self.$refs.email.$el.getElementsByTagName("input")[0].focus();
        return;
      }
      self.emailRules = [];
      self.passwordRules = [];
      self.in_flight = true;
      axios
        .post("/api/user/lost_password/" + self.email)
        .then(function (response) {
          self.in_flight = false;
          let resp = response.data;
          if (resp.status == "success") {
            self.email_reset_sent = true;
          } else {
            if (resp.error_code == "UserEmailDoesNotExist") {
              self.emailRules = ["User does not exist with that email"];
            } else {
              // TODO:
            }
          }
        });
    },

    submit_tfa: function () {
      let self = this;

      self.tfa_error = "";

      if (!self.tfa_code) {
        self.tfa_error = self.$t("login.missing_tfa_code");
        return;
      }

      self.in_flight = true;
      axios.post("/api/tfa/redeem/" + self.tfa_code).then(function (response) {
        self.in_flight = false;
        let resp = response.data;
        if (resp.status == "success") {
          window.location.reload();
        } else {
          if (resp.error_code == "EntityNotFound") {
            self.tfa_error = self.$t("login.tfa_error_bad_code");
          } else if (resp.error_code == "TFAExpired") {
            self.tfa_error = self.$t("login.tfa_error_expired");
          } else if (resp.error_code == "TFAMismatched") {
            self.tfa_error = self.$t("login.tfa_error_mismatched");
          } else {
            self.tfa_error = resp.error_message;
          }
        }
      });
    },
  },
};
</script>


<style>
#opticalgym_login_modal .v-messages__message {
  line-height: normal;
}

.tfa_code input[type="text"] {
  font-size: 24px;
}
</style>