<template>
  <div class="all_clinics_admin_table">
    <!-- Toolbar -->
    <v-toolbar grey dense>
      <v-toolbar-title>{{ $t("all_clinics.clinics") }}</v-toolbar-title>
      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      <v-spacer />
      <v-toolbar-items>
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small v-bind="attrs" v-on="on">Columns</v-btn>
          </template>
          <v-card>
            <v-card-text>
              <span v-for="header in headers" :key="header.value">
                <v-checkbox
                  v-if="header.text"
                  v-model="chosen_headers"
                  dense
                  :value="header.value"
                  :label="header.text"
                ></v-checkbox>
              </span>
            </v-card-text>
          </v-card>
        </v-menu>

        <v-btn small @click="trial_end_calendar_dialog = true"
          >View Calendar</v-btn
        >

        <v-btn small color="primary" @click="create_clinic_dialog = true">{{
          $t("all_clinics.create_clinic")
        }}</v-btn>
        <v-dialog v-model="create_clinic_dialog" max-width="1000">
          <ClinicEdit
            :clinic="default_clinic"
            @close-clinic-edit="create_clinic_dialog = false"
            @clinic-created="load_clinics"
          />
        </v-dialog>
      </v-toolbar-items>
    </v-toolbar>

    <!-- All Clinics List -->
    <v-data-table
      :items="clinics"
      :headers="enabled_headers"
      :items-per-page="1000"
      :search="search"
      group-by="Status"
      sort-by="_created_date"
      :sort-desc="true"
      fixed-header
      :height="data_table_height"
    >
      <template v-slot:item.navigate="{ item }">
        <v-icon small @click="navigate_to_clinic(item.id)"
          >fas fa-external-link-alt</v-icon
        >
      </template>

      <template v-slot:item.title="{ item }">
        <div class="all_clinics_clinic_title">{{ item.title }}</div>
        <div class="all_clinics_clinic_id">{{ item.id }}</div>
      </template>

      <template v-slot:item._created_date="{ item }">
        <div style="font-size: smaller">{{ item._created_date }}</div>
      </template>

      <template v-slot:item.num_active_users="{ item }">
        {{ item.num_active_users }} ({{
          item.num_active_users_admin + item.num_active_users_staff
        }}
        / {{ item.num_active_users_user }})
      </template>

      <template v-slot:item.billing_country="{ item }">
        {{ item.billing_country
        }}<template v-if="item.billing_district"
          >-{{ item.billing_district }}</template
        >
      </template>

      <template v-slot:item.tags="{ item }">
        <div style="max-width: 300px">
          <div
            v-for="tag in item.tags"
            :key="tag"
            style="
              font-size: x-small;
              font-weight: bold;
              font-family: 'Courier New', Courier, monospace;
            "
          >
            {{ tag }}
          </div>
        </div>
      </template>

      <template v-slot:item._trial_end_date="{ item }">
        <span style="font-size: smaller">{{ item._trial_end_date }}</span>
        <span
          v-if="!item.stripe_subscription_id && !item.tags.includes('free')"
          :style="
            trial_end_colour_style(item._trial_end_date) +
            '; font-size: x-small; vertical-align: 1px'
          "
          >&nbsp;⬤</span
        >
      </template>

      <template v-slot:item.activity_sparkline="{ item }">
        <div v-if="item.activity_sparkline && item.activity_sparkline.length">
          <v-sparkline
            :value="item.activity_sparkline"
            :line-width="3"
          ></v-sparkline>
        </div>
      </template>

      <template v-slot:item.spend_summary_amount="{ item }">
        <div v-if="item.spend_summary_amount">
          {{ item.spend_summary_amount.toLocaleString("en") }}
        </div>
      </template>

      <template v-slot:item.admins="{ item }">
        <div v-for="(admin_invite, index) in admin_invites" :key="index">
          <div v-if="admin_invite.clinic_id == item.id && admin_invite.user_id">
            {{ admin_invite.name }} {{ admin_invite.name_family }}
          </div>
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <!-- Stripe -->
        <v-btn
          icon
          x-small
          target="_blank"
          :href="
            'https://dashboard.stripe.com/subscriptions/' +
            item.stripe_subscription_id
          "
        >
          <v-icon
            v-if="
              item.stripe_subscription_status == 'active' &&
              item.num_active_users_user
            "
            x-small
            >fas fa-file-invoice-dollar</v-icon
          >
          <v-icon
            v-else-if="
              item.stripe_subscription_status == 'active' &&
              !item.num_active_users_user
            "
            x-small
            >fas fa-times</v-icon
          >
          <v-icon
            v-else-if="
              item.stripe_subscription_status == 'past_due' ||
              item.stripe_subscription_status == 'unpaid'
            "
            x-small
            color="red"
            >fas fa-exclamation</v-icon
          >
          <v-icon
            v-else-if="
              item.stripe_subscription_status &&
              item.stripe_subscription_status != 'trialing' &&
              !item.has_tag('free')
            "
            small
            >fas fa-times</v-icon
          >
        </v-btn>

        <!-- Notes -->
        <v-btn
          class="mr-2"
          icon
          x-small
          @click="
            current_notes = notes[item.id];
            notes_dialogs[item.id] = true;
          "
        >
          <v-icon x-small>{{
            item.notes ? "fas fa-clipboard" : "far fa-clipboard"
          }}</v-icon>
        </v-btn>
        <v-dialog
          v-model="notes_dialogs[item.id]"
          :transition="false"
          max-width="1400"
        >
          <v-card id="clinic_edit_modal">
            <v-card-title style="padding: 0px">
              <v-toolbar grey>
                <v-btn icon @click="notes_dialogs[item.id] = false">
                  <v-icon>close</v-icon>
                </v-btn>
                <v-toolbar-title>{{ item.title }} Notes</v-toolbar-title>
                <v-spacer />
                <v-btn color="primary" @click="save_notes(item.id)">Save</v-btn>
              </v-toolbar>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-textarea v-model="current_notes" outlined rows="20" />
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!-- Admin View -->
        <v-btn
          class="mr-2"
          icon
          x-small
          target="_blank"
          :href="'/app/admin/clinic/' + item.id"
        >
          <v-icon x-small>fas fa-tools</v-icon>
        </v-btn>

        <!-- Edit -->
        <v-icon small class="mr-2" @click="dialogs[item.id] = true"
          >mdi-pencil</v-icon
        >
        <v-dialog
          v-model="dialogs[item.id]"
          :transition="false"
          max-width="1400"
        >
          <ClinicEdit
            :clinic="item"
            show_delete
            @close-clinic-edit="dialogs[item.id] = false"
            @clinic-updated="load_clinics"
            @clinic-deleted="load_clinics"
          />
        </v-dialog>

        <v-btn
          v-if="item.stripe_customer_id"
          icon
          x-small
          target="_blank"
          :href="
            'https://dashboard.stripe.com/customers/' + item.stripe_customer_id
          "
        >
          <v-icon x-small>fab fa-stripe-s</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <!-- Calendar -->
    <v-dialog v-model="trial_end_calendar_dialog" max-width="1000">
      <v-card>
        <v-card-title style="padding: 0px">
          <v-toolbar grey flat>
            <v-btn icon @click="trial_end_calendar_dialog = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>Calendar</v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-sheet height="64">
            <v-toolbar flat>
              <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="$refs.calendar.prev()"
              >
                <v-icon small>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn
                fab
                text
                small
                color="grey darken-2"
                @click="$refs.calendar.next()"
              >
                <v-icon small>mdi-chevron-right</v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">{{
                $refs.calendar.title
              }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </v-sheet>
          <v-sheet height="600">
            <v-calendar
              ref="calendar"
              v-model="caldendar_focus"
              :event-more="false"
              color="primary"
              :events="calendar_events"
              :event-color="getEventColor"
              type="month"
              event-overlap-mode="stack"
              event-overlap-threshold="30"
            ></v-calendar>
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";
import ClinicEdit from "./../ClinicEdit.vue";
import Clinic from "../../classes/clinic";

export default {
  components: { ClinicEdit },
  props: {
    current_user: {
      type: Object,
      required: true,
    },
    default_columns: {
      type: Array,
      required: false,
      default: () => [
        "navigate",
        "id",
        "title",
        "billing_country",
        "_trial_end_date",
        "num_active_users",
        "tags",
        "stripe_subscription_status_link",
        "actions",
      ],
    },
  },
  data() {
    let trial_days = 62;
    let trial_end = new Date();
    trial_end.setTime(trial_end.getTime() + trial_days * 24 * 60 * 60 * 1000);
    let trial_end_date = trial_end.toISOString().substring(0, 19);

    return {
      trial_end_calendar_dialog: false,
      caldendar_focus: "",
      headers: [
        { text: "", value: "navigate", sortable: false },
        {
          text: "",
          value: "id",
          class: "clinic_id_col",
          cellClass: "clinic_id_col",
        },
        { text: "Title", value: "title" },
        { text: "Created", value: "_created_date" },
        { text: "Country", value: "billing_country" },
        { text: "Trial End", value: "_trial_end_date" },
        { text: "Users (S / P)", value: "num_active_users" },
        { text: "Spend (Y)", value: "spend_summary_amount" },
        { text: "Activity (M)", value: "staff_login_count" },
        { text: "Acivity / Spend", value: "activity_spend_ratio" },
        { text: "Tags", value: "tags", sortable: false },
        { text: "Admins", value: "admins", sortable: false },
        { text: "Activity Graph", value: "activity_sparkline", sortable: false },
        { text: "", value: "stripe_subscription_status_link", sortable: false },
        { text: "", value: "actions", sortable: false },
      ],
      chosen_headers: this.default_columns,
      clinics: [],
      search: "",
      rows_per_page: [-1],
      dialogs: {},
      notes_dialogs: {},
      notes: {},
      current_notes: "",
      create_clinic_dialog: false,
      calendar_events: [],
      activity_summary: {},
      logins_summary: {},
      spend_summary: {},
      admin_invites: [],
      default_clinic: {
        title: "",
        phone: "",
        website: "",
        physical_address_1: "",
        physical_address_2: "",
        physical_sub_locality: "",
        physical_locality: "",
        physical_sub_district: "",
        physical_district: "",
        physical_postal: "",
        physical_country: "XX",
        billing_address_1: "",
        billing_address_2: "",
        billing_sub_locality: "",
        billing_locality: "",
        billing_sub_district: "",
        billing_district: "",
        billing_postal: "",
        billing_country: "US",
        trial_end: trial_end_date,
        tags: [],
        app: window.app_config.id,
        billing_strategy: window.app_config.default_billing_strategy,
      },
    };
  },
  computed: {
    enabled_headers() {
      let headers = this.headers;

      headers = headers.filter((header) => {
        return this.chosen_headers.includes(header.value);
      });

      return headers;
    },
    data_table_height() {
      return window.innerHeight - 168;
    },
  },
  watch: {
    chosen_headers: function (new_val, old_val) {
      this.$emit("update:default_columns", new_val);

      if (new_val.includes("admins") && !old_val.includes("admins")) {
        this.load_admins();
      }
      if (new_val.includes("activity_sparkline") && !old_val.includes("activity_sparkline")) {
        this.load_activity_sparkline();
      }
      if ((new_val.includes("staff_login_count") || new_val.includes("activity_spend_ratio") && (!old_val.includes("staff_login_count") || !old_val.includes("activity_spend_ratio")))) {
        this.load_staff_login_count();
      }
      if ((new_val.includes("spend_summary_amount") || new_val.includes("activity_spend_ratio") && (!old_val.includes("spend_summary_amount") || !old_val.includes("activity_spend_ratio")))) {
        this.load_spend_summary_amount();
      }
    },
  },
  mounted() {
    this.load_clinics();
    if (this.chosen_headers.includes("admins")) {
      this.load_admins();
    }
  },
  methods: {
    load_admins() {
      axios.get("/api/invite?role=admin").then((response) => {
        if (response.data.status == "success") {
          this.admin_invites = response.data.data;
        }
      });
    },
    navigate_to_clinic: function (clinic_id) {
      this.$router.push("/app/clinic/" + clinic_id);
    },
    save_notes(clinic_id) {
      this.notes[clinic_id] = this.current_notes;
      axios.get("/api/clinic/" + clinic_id).then((response) => {
        if (response.data.status == "success") {
          let clinic = Clinic.fromJson(response.data.data);
          if (this.notes[clinic_id]) {
            clinic.notes = this.notes[clinic_id];
          } else {
            clinic.notes = "";
          }

          axios.put("/api/clinic/" + clinic_id, clinic).then((resp) => {
            if (resp.data.status == "success") {
              this.notes_dialogs[clinic_id] = false;
              for (var i in this.clinics) {
                if (this.clinic[i].id == clinic_id) {
                  Object.assign(this.clinics[i], clinic);
                }
              }
            } else {
              messageBus.$emit("error", response.data.error_message);
            }
          });
        } else {
          messageBus.$emit("error", response.data.error_message);
        }
      });
    },
    load_clinics: function () {
      let self = this;
      axios.get("/api/clinic").then(function (response) {
        if (response.data.status == "success") {
          var clinics = [];
          for (var i in response.data.data) {
            let clinic = Clinic.fromJson(response.data.data[i]);

            // Computed data
            clinic._created_date = clinic.created.substring(0, 10);
            if (clinic.trial_end) {
              clinic._trial_end_date = clinic.trial_end.substring(0, 10);
            }
            if (clinic.has_tag("archived")) {
              clinic["Status"] = "Archived";
            } else {
              clinic["Status"] = "Active";
            }

            clinics.push(clinic);
          }

          // Set up dialogs
          for (var j in clinics) {
            Vue.set(self.dialogs, clinics[j].id, false);
            Vue.set(self.notes_dialogs, clinics[j].id, false);

            Vue.set(self.notes, clinics[j].id, clinics[j].notes);
          }

          // Add clinics
          self.clinics = clinics;

          // Grab number of active users
          axios
            .get("/api/clinic/count_all_active_users")
            .then(function (response) {
              if (response.data.status == "success") {
                for (var i in self.clinics) {
                  let clinic = self.clinics[i];
                  Vue.set(clinic, "num_active_users", 0);
                  Vue.set(clinic, "num_active_users_user", 0);
                  Vue.set(clinic, "num_active_users_staff", 0);
                  Vue.set(clinic, "num_active_users_admin", 0);

                  if (response.data.data[clinic.id]) {
                    Vue.set(
                      clinic,
                      "num_active_users_user",
                      response.data.data[clinic.id].user || 0
                    );
                    Vue.set(
                      clinic,
                      "num_active_users_staff",
                      response.data.data[clinic.id].staff || 0
                    );
                    Vue.set(
                      clinic,
                      "num_active_users_admin",
                      response.data.data[clinic.id].admin || 0
                    );

                    let num_active_users =
                      clinic.num_active_users_user +
                      clinic.num_active_users_staff +
                      clinic.num_active_users_admin;
                    Vue.set(clinic, "num_active_users", num_active_users);
                  }
                }
              }
            });
          // Grab subscription info
          axios.get("/api/stripe/subscriptions").then(function (response) {
            if (response.data.status == "success") {
              for (var i in self.clinics) {
                let clinic = self.clinics[i];
                if (clinic.stripe_customer_id) {
                  for (var j in response.data.data) {
                    let sub = response.data.data[j];
                    if (clinic.stripe_customer_id == sub.customer) {
                      Vue.set(clinic, "stripe_subscription_status", sub.status);
                      Vue.set(clinic, "stripe_subscription_id", sub.id);
                    }
                  }
                }
              }

              // Set up calendar_events
              for (var c in self.clinics) {
                let clinic = self.clinics[c];
                if (
                  clinic.stripe_subscription_id ||
                  clinic.tags.includes("free")
                ) {
                  continue;
                }

                self.calendar_events.push({
                  name: clinic.title,
                  start: clinic._trial_end_date,
                  timed: false,
                });
              }
            }
          });
        }
      });
    },

    load_activity_sparkline() {
      // Grab activity summary for sparklines
      axios.get("/api/activity/summary/by_month").then(function (response) {
        const now_date = new Date();
        const now_month = now_date.toISOString().substr(0, 7);

        if (response.data.status == "success") {
          self.activity_summary = response.data.data;

          for (var i in self.clinics) {
            let clinic = self.clinics[i];
            if (response.data.data[clinic.id]) {
              let clinic_activity_summary = response.data.data[clinic.id];
              let clinic_sparkline = [];
              for (let clinic_activity_summary_data of clinic_activity_summary) {
                if (clinic_activity_summary_data.month != now_month) {
                  clinic_sparkline.push(clinic_activity_summary_data.count);
                }
              }
              Vue.set(clinic, "activity_sparkline", clinic_sparkline);
            }
          }
        }
      });
    },

    load_staff_login_count() {
      // Grab activity summary of staff / admin login numbers
      axios.get("/api/activity/summary/staff_admin").then(function (response) {
        if (response.data.status == "success") {
          self.logins_summary = response.data.data;

          for (var i in self.clinics) {
            let clinic = self.clinics[i];
            if (self.logins_summary[clinic.id]) {
              Vue.set(
                clinic,
                "staff_login_count",
                self.logins_summary[clinic.id]
              );
            }
          }
          if (Object.keys(self.spend_summary).length != 0) {
            self.calc_activity_spend_ratio();
          }
        }
      });
    },

    load_spend_summary_amount() {
      // Grab spend summary
      axios.get("/api/stripe/spend_summary").then(function (response) {
        if (response.data.status == "success") {
          self.spend_summary = response.data.data;

          for (var i in self.clinics) {
            let clinic = self.clinics[i];
            if (self.spend_summary.hasOwnProperty(clinic.id)) {
              clinic.spend_summary_amount = Math.round(
                self.spend_summary[clinic.id]
              );
            }
          }

          if (Object.keys(self.logins_summary).length != 0) {
            self.calc_activity_spend_ratio();
          }
        }
      });
    },

    calc_activity_spend_ratio() {
      for (var i in this.clinics) {
        let clinic = this.clinics[i];
        let spend_summary_amount = clinic.spend_summary_amount || 1;
        let staff_login_count = clinic.staff_login_count || 1;

        let ratio = staff_login_count / spend_summary_amount;
        if (ratio > 100) {
          Math.round(ratio);
        } else if (ratio > 10) {
          ratio = ratio.toFixed(1);
        } else {
          ratio = ratio.toFixed(2);
        }
        if (ratio == 1) {
          ratio = 1;
        }
        clinic.activity_spend_ratio = ratio;
      }
    },
    trial_end_colour(end_date) {
      let now = new Date();
      end_date = new Date(end_date);

      let time_diff = (end_date - now) / (1000 * 3600 * 24);

      if (time_diff > 14) {
        return "green";
      }
      if (time_diff > -1) {
        return "orange";
      }
      if (time_diff < -1) {
        return "red";
      }
    },
    trial_end_colour_style(end_date) {
      return "color: " + this.trial_end_colour(end_date);
    },
    getEventColor(event) {
      return this.trial_end_colour(event.start);
    },
  },
};
</script>

<style>
.all_clinics_admin_table .all_clinics_clinic_id {
  font-size: xx-small;
  font-weight: bold;
  font-family: "Courier New", Courier, monospace;
  color: grey;
  user-select: all;
}

.all_clinics_admin_table .all_clinics_clinic_title {
  user-select: all;
  width: 250px;
}

.all_clinics_admin_table .clinic_id_col {
  display: none;
}

.all_clinics_admin_table tbody tr .text-start:nth-child(2) {
  display: none;
}
</style>
