<template>
  <div>
    <!-- Inline -->
    <v-layout v-if="inline" row wrap>
      <v-flex xs5>
        <v-text-field
          ref="password"
          v-model="localValue"
          :label="label"
          type="password"
          :rules="passwordRules"
          @focusin="load_zxcvbn()"
        />
      </v-flex>
      <v-spacer xs2 />
      <v-flex xs5>
        <v-text-field
          v-if="show_confirm"
          ref="confirm"
          v-model="confirm_password"
          :label="confirmLabel"
          type="password"
          :rules="confirm_password_rules"
        />
      </v-flex>
    </v-layout>

    <!-- Not inline -->
    <div v-else>
      <v-text-field
        ref="password"
        v-model="localValue"
        :label="label"
        type="password"
        :rules="passwordRules"
        @focusin="load_zxcvbn()"
      />
      <v-text-field
        v-if="show_confirm"
        ref="confirm"
        v-model="confirm_password"
        :label="confirmLabel"
        type="password"
        :rules="confirm_password_rules"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    password: {
      type: String,
      default: "",
    },
    loadOnMount: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: function () {
        return this.$t("common.password");
      },
    },
    confirmLabel: {
      type: String,
      default: function () {
        return this.$t("common.confirm_password");
      },
    },
    alwaysShowConfirm: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      local_password: "",
      passwordRules: [],
      confirm_password: "",
      confirm_password_rules: [],
      show_confirm: this.alwaysShowConfirm,
    };
  },
  computed: {
    localValue: {
      get() {
        return this.local_password;
      },
      set(localValue) {
        this.$emit("input", localValue);
        this.local_password = localValue;
        this.show_confirm = true;
      },
    },
  },
  watch: {
    local_password: function (local_password) {
      this.check_confirm_password();

      var self = this;
      if (local_password !== "") {
        if (typeof window.zxcvbn !== "undefined") {
          let result = window.zxcvbn(self.local_password);
          if (result.score < 3) {
            if (result.feedback.warning) {
              this.passwordRules = [result.feedback.warning];
            } else if (result.feedback.suggestions.length > 0) {
              this.passwordRules = result.feedback.suggestions;
            } else {
              this.passwordRules = ["password is too short"];
            }
          } else {
            this.passwordRules = [];
          }
        } else {
          this.passwordRules = [];
        }
      } else {
        this.passwordRules = [];
      }
    },
    confirm_password: function (_confirm_password) {
      this.check_confirm_password();
    },
  },
  mounted() {
    if (this.loadOnMount) {
      this.load_zxcvbn();
    }
  },
  methods: {
    focus() {
      this.$nextTick(() => {
        if (this.$refs.password) {
          this.$refs.password.focus();
        }
      });
    },
    focus_confirm(clear = false) {
      if (this.show_confirm) {
        this.$refs.confirm.focus();
      }
      if (clear) {
        this.confirm_password = "";
      }
    },
    reset() {
      this.show_confirm = this.alwaysShowConfirm;
    },
    check_confirm_password() {
      var self = this;
      if (self.confirm_password !== "") {
        if (self.confirm_password !== self.local_password) {
          self.confirm_password_rules = [
            this.$t("double_password.passwords_do_not_match"),
          ];
          this.$emit("passwords-match", false);
        } else {
          self.confirm_password_rules = [];
          this.$emit("passwords-match", true);
        }
      } else {
        self.confirm_password_rules = [];
        this.$emit("passwords-match", false);
      }
    },
    load_zxcvbn() {
      // Lazy load a big-ass JS library for checking password strength
      if (typeof window.zxcvbn_script === "undefined") {
        window.zxcvbn_script = document.createElement("script");
        window.zxcvbn_script.src =
          "https://cdnjs.cloudflare.com/ajax/libs/zxcvbn/4.4.2/zxcvbn.js";
        window.zxcvbn_script.type = "text/javascript";
        window.zxcvbn_script.crossorigin = "anonymous";
        document
          .getElementsByTagName("head")[0]
          .appendChild(window.zxcvbn_script);
      }
    },
  },
};
</script>