import { Uuid } from "./uuid";
import { NaiveDateTime } from "./datetime";

export default class User {
    id: Uuid;
    created: NaiveDateTime;
    updated: NaiveDateTime;
    email: string;
    is_admin: boolean;
    name_prefix: string;
    name_given: string;
    name_family: string;
    name_credentials: string;
    affiliation: string;

    static fromJson(data: Object): User {
        let obj = new User();
        Object.assign(obj, data);
        return obj;
    }
};

