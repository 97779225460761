<template>
  <v-app class="assessment-meta">
    <v-dialog v-model="assessment_complete" persistent max-width="500">
      <v-sheet class="pa-12">
        <h1>Assessment Complete</h1>
        <p>Please Wait...</p>
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-sheet>
    </v-dialog> 
    
    <Assessment
      v-if="current_clinic && invite_id && assessment_id"
      :invite_id="invite_id"
      :assessment_id="assessment_id"
      @exercise-finished="asessment_complete"
    />
  </v-app>
</template>

<script>
import Assessment from "../../shared/components/Assessment.vue";
import { mapState } from "vuex";
import axios from "axios";

export default {
  components: { Assessment },
  data() {
    return {
      invite_id: this.$route.params.invite_id ? this.$route.params.invite_id : "",
      clinic_id: this.$route.params.clinic_id ? this.$route.params.clinic_id : "",
      assessment_id: this.$route.params.assessment_id ? this.$route.params.assessment_id : "",
      assessment_complete: false,
      assessment_grid: null,
    };
  },
  computed: {
    ...mapState(["grid_sets", "current_clinic", "current_clinic_id"]),
  },
  mounted() {
    if (this.clinic_id) {
      this.$store.dispatch("loadCurrentClinic", { clinic_id: this.clinic_id });
    }
    this.loadAssessmentGrid();
  },
  methods: {
    loadAssessmentGrid() {
      for (let gs of this.grid_sets) {
        if (gs.id == this.assessment_id) {
          this.assessment_grid = gs;
          break;
        }
      }

      if (!this.assessment_grid) {
        axios
          .get("/api/grid_set/" + this.assessment_id)
          .then((resp) => {
            if (resp.data.status == "success") {
              this.assessment_grid = resp.data.data;
            } else {
              messageBus.$emit("api-error", resp.data);
            }
          });
      }
    },
    asessment_complete(results) {
      this.assessment_complete = true;

      let assessment_complete = {
          assessment_id: results.assessment_id,
          invite_id: results.invite_id,
          assessment_instance_id: results.assessment_instance_id,
          clinic_id: this.current_clinic_id,
          grid_set: this.assessment_grid,
        };
      
      let pdf_path = "/app/assessment_report/" + results.assessment_id + "/" + results.invite_id + "/" + results.assessment_instance_id;
      let uri =
        "/api/assessment/" + results.assessment_id + "?pdf_path=" + encodeURIComponent(pdf_path);

      // Send assessment report
      axios.post(uri, assessment_complete).then((resp) => {
        if (resp.status == 200) {
          messageBus.$emit("success", "Assessment report sent to user.");
        } else {
          messageBus.$emit("error", resp.data.error_message);
        }
        this.assessment_complete = false;
        this.$router.push("/app/clinic/" + this.clinic_id);
      });
    },
  },
};
</script>

<style>
</style>