<template>
  <v-text-field
    ref="email"
    v-model="localValue"
    :label="label"
    :append-icon="append_icon"
    :hint="hint"
    :persistent-hint="persistent_hint"
    type="text"
    :rules="email_rules"
    validate-on-blur
    :outlined="outlined"
    @unblur="email_rules = []"
    @blur="check_email"
  />
</template>

<script>
import OpticalGymUI from "../lib/OpticalGymUI";

export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default() {
        return "Email";
      },
    },
    append_icon: {
      type: String,
      default() {
        return "";
      },
    },
    hint: {
      type: String,
      default() {
        return "";
      },
    },
    persistent_hint: {
      type: String,
      default() {
        return "";
      },
    },
    backend_checking: {
      type: Boolean,
      default: () => true,
    },
    outlined: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      local_email: this.value,
      email_rules: [],
    };
  },
  computed: {
    localValue: {
      get() {
        return this.local_email;
      },
      set(localValue) {
        this.$emit("input", localValue);
        this.local_email = localValue;
      },
    },
  },
  methods: {
    check_email: function () {
      let self = this;
      let email = this.localValue;
      this.email_rules = [];

      if (!OpticalGymUI.validate_email(email)) {
        this.email_rules = [this.$t("common.invalid_email_address")];
        this.$emit("valid-email", false);
        return;
      }

      if (this.backend_checking) {
        OpticalGymUI.check_email(email, function (check_results) {
          self.email_rules = check_results.email_rules;
          self.$emit("valid-email", check_results.valid);
        });
      } else {
        this.$emit("valid-email", true);
      }
    },
  },
};
</script>