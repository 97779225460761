<template>
  <div class="tools-list">
    <div
      v-if="
        sidebar &&
        $vuetify.breakpoint.name != 'xs' &&
        $vuetify.breakpoint.name != 'sm'
      "
      class="category-list"
      style="position: fixed"
    >
      <v-container>
        <v-navigation-drawer permanent>
          <v-list class="mt-1">
            <!-- Apps -->
            <v-list-item
              v-if="!printables_only"
              href="#miniapps"
              class="tools_jump_link"
            >
              <v-list-item-action>
                <v-icon>apps</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ $t("tools.apps") }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- Printables -->
            <v-list-item
              v-if="!apps_only"
              href="#printable"
              class="tools_jump_link"
            >
              <v-list-item-action>
                <v-icon>print</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{
                  $t("common.printable_sheets")
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-container>
    </div>

    <div
      :style="
        sidebar &&
        $vuetify.breakpoint.name != 'xs' &&
        $vuetify.breakpoint.name != 'sm'
          ? 'position: relative; left: 266px; padding-right: 270px'
          : ''
      "
    >
      <!-- Apps -->
      <div v-if="!printables_only">
        <v-card :class="is_small_screen ? 'ml-6 mt-6' : ''">
          <v-card-title v-if="!apps_only" primary-title>
            <h3 id="miniapps" class="headline mb-0">
              <v-icon left style="vertical-align: unset">apps</v-icon>
              {{ $t("tools.apps") }}
            </h3>
          </v-card-title>

          <div>
            <v-list>
              <div v-for="item in miniapps" :key="item.id">
                <v-list-item
                  v-if="apps.includes(item.id)"
                  three-line
                  style="cursor: pointer"
                  @click="item.dialog = true"
                >
                  <v-list-item-avatar>
                    <v-img
                      :src="item.avatar"
                      style="margin-top: 20px; filter: invert(0)"
                    ></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title style="font-weight: 550">{{
                      item.title
                    }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      item.description
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider />
                <v-dialog
                  v-model="item.dialog"
                  max-width="600"
                  :fullscreen="item.fullscreen"
                  @click:outside="close_tool_dialog"
                >
                  <div v-if="item.dialog">
                    <ToolMetronome
                      v-if="item.id == 'metronome'"
                      ref="metronome"
                      @close="item.dialog = false"
                    />
                    <ToolsStopWatch
                      v-if="item.id == 'stopwatch'"
                      ref="stopwatch"
                      @close="item.dialog = false"
                    />
                    <ToolFullColourAnaglyph
                      v-if="item.id == 'full_colour_anaglyph'"
                      ref="full_colour_anaglyph"
                      @close="item.dialog = false"
                    />
                  </div>
                </v-dialog>
              </div>
            </v-list>
          </div>
        </v-card>
      </div>

      <!-- Printables -->
      <div v-if="!apps_only">
        <v-card :class="is_small_screen ? 'ml-6 mt-6' : ''">
          <v-card-title v-if="!printables_only" primary-title>
            <h3 id="printable" class="headline mb-0">
              <v-icon left style="vertical-align: unset">print</v-icon>
              {{ $t("common.printable_sheets") }}
            </h3>
          </v-card-title>

          <div>
            <v-list>
              <div v-for="item in printables" :key="item.id">
                <v-list-item
                  three-line
                  style="cursor: pointer"
                  @click="open_printable_dialog(item.id)"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.title }}
                      <v-tooltip
                        v-if="admin_or_staff && item.type == 'clinic'"
                        right
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn icon v-on="on">
                            <v-icon small right>fa fa-user-lock</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("tools.private_sheet") }}</span>
                      </v-tooltip>
                    </v-list-item-title>
                    <v-list-item-subtitle>{{
                      item.description
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider />
                <v-dialog v-model="printable_dialogs[item.id]" max-width="600">
                  <div v-if="printable_dialogs[item.id]">
                    <template v-if="item.type == 'builtin'">
                      <ToolHartChart
                        v-if="item.id == 'hart_chart'"
                        @close="printable_dialogs[item.id] = false"
                      />
                      <ToolRacetracks
                        v-if="item.id == 'racetracks'"
                        @close="printable_dialogs[item.id] = false"
                      />
                      <ToolTrackLetters
                        v-if="item.id == 'saccadic_scanning'"
                        @close="printable_dialogs[item.id] = false"
                      />
                      <ToolAnaglyphChart
                        v-if="item.id == 'anaglyph_chart'"
                        @close="printable_dialogs[item.id] = false"
                      />
                      <ToolFusionCard
                        v-if="item.id == 'fusion_card'"
                        @close="printable_dialogs[item.id] = false"
                      />
                      <ToolSlapTap
                        v-if="item.id == 'slap_tap'"
                        @close="printable_dialogs[item.id] = false"
                      />
                      <ToolCircleSquare
                        v-if="item.id == 'circle_square'"
                        @close="printable_dialogs[item.id] = false"
                      />
                      <ToolStaticPDF
                        v-if="item.id == 'rotating_forms'"
                        uri="https://storage.googleapis.com/opticalgym-large-assets/pdfs/static/rotating_forms.pdf"
                        :title="item.title"
                        @close="printable_dialogs[item.id] = false"
                      />

                      <ToolCircleLineJump
                        v-if="item.id == 'circle_jump'"
                        @close="printable_dialogs[item.id] = false"
                      />

                      <ToolCircleTriangle
                        v-if="item.id == 'circle_triangle'"
                        @close="printable_dialogs[item.id] = false"
                      />

                      <ToolStaticPDF
                        v-if="item.id == 'arrows'"
                        uri="/api/tools/pdf/picture_grid/arrows/picture_grid.pdf?rows=8&cols=6&size=80&padding_sides=20&padding_tottom=20"
                        :title="item.title"
                        @close="printable_dialogs[item.id] = false"
                      />
                      <ToolStaticPDF
                        v-if="item.id == 'finger_counting'"
                        uri="/api/tools/pdf/picture_grid/finger_counting/picture_grid.pdf?rows=8&cols=6&size=90&padding_sides=20&padding_tottom=20"
                        :title="item.title"
                        @close="printable_dialogs[item.id] = false"
                      />
                      <ToolStaticPDF
                        v-if="item.id == 'happy_faces'"
                        uri="/api/tools/pdf/picture_grid/happy_faces/picture_grid.pdf?rows=16&cols=12&size=48&padding_sides=8&padding_tottom=6"
                        :title="item.title"
                        @close="printable_dialogs[item.id] = false"
                      />
                      <ToolPeripheralChart
                        v-if="item.id == 'peripheral_chart'"
                        @close="printable_dialogs[item.id] = false"
                      />
                      <ToolLadyBug
                        v-if="item.id == 'lady_bug_fixation'"
                        @close="printable_dialogs[item.id] = false"
                      />
                      <ToolPerceptualSpeed
                        v-if="item.id == 'perceptual_speed'"
                        @close="printable_dialogs[item.id] = false"
                      />
                      <ToolLineMaze
                        v-if="item.id == 'line_maze'"
                        @close="printable_dialogs[item.id] = false"
                      />
                    </template>
                    <ToolStaticPDF
                      v-else
                      :uri="
                        item.type == 'clinic'
                          ? clinic_printable_uri(item)
                          : item.uri
                      "
                      :title="item.title"
                      @close="printable_dialogs[item.id] = false"
                    />
                  </div>
                </v-dialog>
              </div>
            </v-list>
          </div>
        </v-card>
      </div>

      <v-banner
        v-if="admin_or_staff && show_missing_something"
        two-line
        elevation="2"
        style="margin-top: 40px"
        width="800px"
        icon="contact_support"
      >
        <p>{{ $t("common.missing_something") }}</p>
        <p style="font-size: smaller">{{ $t("common.contact_us_text") }}</p>

        <template v-slot:actions>
          <v-btn
            text
            color="primary accent-4"
            style="position: relative; top: -20px"
            @click="contact_us_dialog = true"
            >{{ $t("common.contact_us") }}</v-btn
          >
        </template>

        <v-dialog v-model="contact_us_dialog" max-width="600px">
          <ContactUs v-if="contact_us_dialog" @close="contact_us_dialog = false" />
        </v-dialog>
      </v-banner>
    </div>
  </div>
</template>

<script>
import ToolMetronome from "./ToolMetronome";
import ToolsStopWatch from "./ToolsStopWatch";

import ToolAnaglyphChart from "./ToolAnaglyphChart";
import ToolCircleTriangle from "./ToolCircleTriangle";
import ToolFullColourAnaglyph from "./ToolFullColourAnaglyph";
import ToolFusionCard from "./ToolFusionCard";
import ToolHartChart from "./ToolHartChart";
import ToolLadyBug from "./ToolLadyBug";
import ToolPeripheralChart from "./ToolPeripheralChart";
import ToolRacetracks from "./ToolRacetracks";
import ToolSlapTap from "./ToolSlapTap";
import ToolStaticPDF from "./ToolStaticPDF";
import ToolTrackLetters from "./ToolTrackLetters";
import ToolCircleSquare from "./ToolCircleSquare";
import ToolCircleLineJump from "./ToolCircleLineJump";
import ToolPerceptualSpeed from "./ToolPerceptualSpeed";
import ToolLineMaze from "./ToolLineMaze";

import ContactUs from "./ContactUs";
import Vue from "vue";
import { mapState } from "vuex";
import jQuery from "jquery";

export default {
  components: {
    ToolHartChart,
    ToolSlapTap,
    ToolTrackLetters,
    ToolMetronome,
    ToolRacetracks,
    ToolAnaglyphChart,
    ToolStaticPDF,
    ToolLadyBug,
    ToolCircleTriangle,
    ContactUs,
    ToolFullColourAnaglyph,
    ToolsStopWatch,
    ToolFusionCard,
    ToolPeripheralChart,
    ToolCircleSquare,
    ToolCircleLineJump,
    ToolPerceptualSpeed,
    ToolLineMaze,
  },
  props: {
    admin_or_staff: {
      type: Boolean,
      default: false,
    },
    sidebar: {
      type: Boolean,
      default: () => true,
    },
    apps_only: {
      type: Boolean,
      default: () => false,
    },
    printables_only: {
      type: Boolean,
      default: () => false,
    },
    show_missing_something: {
      type: Boolean,
      default: () => true,
    },
    apps: {
      type: Array,
      default: () => ["metronome", "stopwatch", "full_colour_anaglyph"],
    },
  },
  data() {
    return {
      rows_per_page: [-1],
      contact_us_dialog: false,
      printable_dialogs: {},
      miniapps: [
        {
          id: "metronome",
          title: this.$t("common.metronome"),
          description: this.$t("tools.description_metronome"),
          dialog: false,
          avatar: "/assets/metronome_icon_2.png",
          fullscreen: false,
        },
        {
          id: "stopwatch",
          title: this.$t("common.stopwatch"),
          description: this.$t("tools.description_stopwatch"),
          avatar: "/assets/stopwatch.avatar.png",
          dialog: false,
          fullscreen: false,
        },
        {
          id: "full_colour_anaglyph",
          title: this.$t("common.full_colour_anaglyph_images"),
          description: this.$t("tools.description_full_colour_anaglyph_images"),
          avatar: "/assets/red-blue-glasses-icon.avatar.png",
          dialog: false,
          fullscreen: true,
        },
      ],
      builtin_printables: [
        {
          id: "hart_chart",
          title: this.$t("common.hart_chart"),
          description: this.$t("tools.description_hart_chart"),
          dialog: false,
          type: "builtin",
        },
        {
          id: "saccadic_scanning",
          title: this.$t("common.saccadic_scanning"),
          description: this.$t("tools.description_saccadic_scanning"),
          dialog: false,
          type: "builtin",
        },
        {
          id: "racetracks",
          title: this.$t("common.racetracks"),
          description: this.$t("tools.description_racetracks"),
          dialog: false,
          type: "builtin",
        },
        {
          id: "anaglyph_chart",
          title: this.$t("common.anaglyph_charts"),
          description: this.$t("tools.description_anaglyph_charts"),
          dialog: false,
          type: "builtin",
        },
        {
          id: "fusion_card",
          title: this.$t("common.fusion_card"),
          description: "",
          dialog: false,
          type: "builtin",
        },
        {
          id: "slap_tap",
          title: this.$t("common.slap_tap"),
          description: this.$t("tools.description_slap_tap"),
          dialog: false,
          type: "builtin",
        },
        {
          id: "circle_square",
          title: this.$t("common.circle_square"),
          description: this.$t("tools.description_circle_square"),
          dialog: false,
          type: "builtin",
        },
        {
          id: "rotating_forms",
          title: this.$t("common.rotating_forms"),
          description: this.$t("tools.description_rotating_forms"),
          dialog: false,
          type: "builtin",
        },
        {
          id: "circle_jump",
          title: this.$t("common.circle_line_jump"),
          description: this.$t("tools.description_circle_line_jump"),
          dialog: false,
          type: "builtin",
        },
        {
          id: "circle_triangle",
          title: this.$t("common.circle_triangles"),
          description: this.$t("tools.description_circle_triangles"),
          dialog: false,
          type: "builtin",
        },
        {
          id: "arrows",
          title: this.$t("common.directional_arrow_chart"),
          description: this.$t("tools.description_directional_arrow_chart"),
          dialog: false,
          type: "builtin",
        },
        {
          id: "happy_faces",
          title: this.$t("common.happy_faces_chart"),
          description: this.$t("tools.description_happy_faces_chart"),
          dialog: false,
          type: "builtin",
        },
        {
          id: "finger_counting",
          title: this.$t("common.fingers_chart"),
          description: this.$t("tools.description_fingers_chart"),
          dialog: false,
          type: "builtin",
        },
        {
          id: "lady_bug_fixation",
          title: this.$t("common.ladybug_fixation"),
          description: this.$t("tools.description_lady_bug_fixation"),
          dialog: false,
          type: "builtin",
        },
        {
          id: "peripheral_chart",
          title: this.$t("common.peripheral_chart"),
          description: this.$t("tools.peripheral_chart_description"),
          dialog: false,
          type: "builtin",
        },
        {
          id: "perceptual_speed",
          title: this.$t("common.perceptual_speed"),
          description: this.$t("tools.perceptual_speed_description"),
          dialog: false,
          type: "builtin",
        },
        {
          id: "line_maze",
          title: this.$t("common.line_maze"),
          description: this.$t("tools.line_maze_description"),
          dialog: false,
          type: "builtin",
        }
      ],
    };
  },
  computed: {
    ...mapState(["current_clinic_id", "clinic_printables", "file_base_url"]),
    printables() {
      let printables = this.builtin_printables;
      printables = printables.concat(this.clinic_printables);
      printables.sort((a, b) => (a.title > b.title ? 1 : -1));
      return printables;
    },
    is_small_screen() {
      return (
        this.$vuetify.breakpoint.name == "xs" ||
        this.$vuetify.breakpoint.name == "sm"
      );
    },
  },
  mounted() {
    jQuery(window).scroll(function () {
      var $el = jQuery(".tools-list .category-list");

      let top = jQuery(this).scrollTop();
      let distance = 128;

      if (top > distance) {
        $el.css({ position: "fixed", top: "0px" });
      }
      if (top < distance) {
        $el.css({ position: "fixed", top: distance - top + "px" });
      }
    });

    this.load_clinic_printables();

    // Set dialogs for built-ins
    for (var i in this.builtin_printables) {
      let printable = this.builtin_printables[i];
      Vue.set(this.printable_dialogs, printable.id, false);
    }
  },
  methods: {
    close_tool_dialog() {
      if (this.$refs["metronome"]) {
        this.$refs["metronome"][0].stop();
      }
    },
    open_printable_dialog(item_id) {
      Vue.set(this.printable_dialogs, item_id, true);
    },
    load_clinic_printables() {
      if (this.current_clinic_id) {
        this.loading = true;
        this.$store.dispatch("loadClinicPrintables", () => {
          for (var i = 0; i < this.clinic_printables.length; i++) {
            let file = this.clinic_printables[i];
            Vue.set(this.printable_dialogs, file.id, false);
            this.clinic_printables[i].type = "clinic"; // Hmmmmm. TODO: Maybe do this in `loadClinicPrintables`
          }
        });
      }
    },
    clinic_printable_uri(file) {
      return (
        this.file_base_url +
        file.clinic_id +
        "/" +
        file.id +
        "/1." +
        file.filename
      );
    },
  },
};
</script>

<style scoped>
.theme--light a.tools_jump_link {
  background-color: transparent;
}
</style>
