<template>
  <v-card>
    <v-card-title style="padding: 0px;">
      <v-toolbar grey flat>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
    </v-card-title>
    <v-divider />
    <v-card-text class="mt-4">
      <div v-if="description"> {{ description }} </div>
    </v-card-text>

    <v-card-actions>
      <v-btn @click="view()">{{ $t("common.view") }}</v-btn>
      <v-spacer />
      <v-btn color="primary" @click="print()">{{ $t("common.print") }}</v-btn>
    </v-card-actions>
    <v-progress-linear v-if="in_flight" :indeterminate="true" />
  </v-card>
</template>

<script>
import printJS from "print-js";
import { Chance } from "chance";
let chance = Chance();

export default {
  components: {},
  props: {
    title: {
      type: String,
      required: true,
    },
    uri: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
      default: ""
    },
  },
  data() {
    let data = {
      in_flight: false,
    };
    return data;
  },
  methods: {
    view: function () {
      window.open(this.url(), "_blank");
    },
    url: function () {
      let uri = this.uri;

      if (uri.includes("?")) {
        uri += "&cache_buster=" + chance.hex();
      } else {
        uri += "?cache_buster=" + chance.hex();
      }

      return uri;
    },
    print: function () {
      let self = this;

      if (navigator.vendor.includes("Apple")) {
        window.open(this.url(), "_blank");
        return;
      }

      printJS({
        printable: this.url(),
        onLoadingStart: function () {
          self.in_flight = true;
        },
        onLoadingEnd: function () {
          self.in_flight = false;
        },
        style: "@page { size: Letter landscape; }",
      });
    },
    close: function () {
      this.$emit("close");
    },
  },
};
</script>
