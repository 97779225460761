<template>
  <v-card>
    <v-card-title style="padding: 0px">
      <v-toolbar grey flat>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span v-if="grid_set.title">{{ grid_set.title }}</span>
          <span v-else>{{ $t("grid_set_edit.new_set") }}</span>
          <v-chip
            v-if="grid_set.share_source"
            label
            small
            outlined
            class="mt-0 ml-5"
            :href="'/app/shared/clinic/' + grid_set.share_source.clinic_id"
            rget="_blank"
            >{{ $t("community_content.clinic_attribution", { clinic_title: grid_set.share_source.clinic_title }) }}</v-chip
          >
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <slot name="toolbar_items"></slot>
        </v-toolbar-items>
      </v-toolbar>
    </v-card-title>
    <v-divider />
    <v-card-text class="mt-4">
      <v-layout row wrap justify-space-around>
        <v-flex xs12 md4>
          <v-text-field ref="title" v-model="grid_set.title" required :readonly="readonly" :label="$t('common.title')" style="margin-top: 10px"></v-text-field>
          <v-text-field ref="description" v-model="grid_set.description" :label="$t('common.description')" :readonly="readonly"></v-text-field>
          <!-- <v-textarea v-model="grid_set.instructions" label="Patient Instructions"></v-textarea> -->
        </v-flex>
        <v-flex xs12 md7 class="mt-1">
          <ExerciseListEdit
            v-model="grid_set.exercises"
            :readonly="readonly"
            :additional_video_exercises="additional_video_exercises"
            @edit="edit"
            @add="add"
            @delete="unsaved_data = true"
            @dragged="unsaved_data = true" />
        </v-flex>
      </v-layout>

      <CommunitySharing
        v-if="app_config.community_sharing && !readonly && !grid_set.share_source"
        v-model="grid_set.shared"
        style="margin-top: 65px"
        :hint_disabled="$t('community_sharing.grid_hint_disabled')"
        :hint_enabled="$t('community_sharing.grid_hint_enabled')"
        :item_title="grid_set.title" />
    </v-card-text>
    <v-card-actions v-if="!readonly">
      <v-alert v-if="error_message != ''" outlined :value="error_message != ''" type="error">{{ error_message }}</v-alert>
      <p v-else-if="unsaved_data && grid_set.exercises.length > 0" style="margin-left: 20px">{{ $t("regimen_edit.edited_unsaved_changes") }}</p>
      <v-spacer />
      <v-btn text @click="discard_changes">{{ $t("regimen_edit.discard_changes") }}</v-btn>
      <v-btn v-if="grid_set_id" large color="primary lighten-1" :disabled="grid_set.exercises.length == 0 || in_flight" @click="submit(true)">
        <div>
          {{ $t("grid_set_edit.save_set") }}
          <div style="font-size: x-small; text-transform: none">{{ $t("grid_set_edit.update_associated_patients") }}</div>
        </div>
      </v-btn>
      <v-btn large color="primary" :disabled="grid_set.exercises.length == 0 || in_flight" @click="submit(false)">
        <div>
          {{ $t("grid_set_edit.save_set") }}
          <div v-if="grid_set_id" style="font-size: x-small; text-transform: none">{{ $t("grid_set_edit.dont_update_patients") }}</div>
        </div>
      </v-btn>
    </v-card-actions>
    <v-progress-linear v-if="in_flight" :indeterminate="true" />
    <v-dialog v-model="dialog" max-width="1200">
      <RegimenExerciseMetaEdit
        v-if="dialog"
        v-model="current_grid_set_exercise_edit"
        :exercises="exercises"
        :video_exercises="all_video_exercises"
        context="grid_set"
        @save="save_exercise" />
    </v-dialog>

    <v-dialog v-model="share_dependencies_dialog" max-width="800">
      <v-card v-if="share_dependencies_dialog">
        <v-card-title style="padding: 0px">
          <v-toolbar grey flat>
            <v-btn icon @click="share_dependencies_dialog = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ $t("common.community_sharing") }}</v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="mt-4">
          The following video exercises are part of this grid set, but are private to your clinic. To enable community sharing for this grid set, you must
          confirm that you wish to additionally share these video exercises.
          <ul style="font-size: 1.05rem" class="mt-4">
            <li v-for="(dep, index) in share_dependencies_needs_share" :key="index" style="margin-top: 5px">{{ dep.title }}</li>
          </ul>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="
              grid_set.shared = null;
              share_dependencies_dialog = false;
              submit_grid_set(share_dependencies_update_regimens);
            "
            >{{ $t("common.save_without_sharing") }}</v-btn
          >
          <v-btn
            color="primary"
            @click="
              share_dependencies_dialog = false;
              submit_grid_set(share_dependencies_update_regimens);
            "
            >{{ $t("common.confirm_additional_sharing") }}</v-btn
          >
        </v-card-actions>
        <v-progress-linear v-if="in_flight" :indeterminate="true" />
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import ExerciseListEdit from "./ExerciseListEdit.vue";
import uuid from "uuid";
import RegimenExerciseMetaEdit from "./RegimenExerciseMetaEdit.vue";
import CommunitySharing from "./CommunitySharing.vue";
import { category_icons } from "../lib/category";
import { mapState } from "vuex";

export default {
  components: { ExerciseListEdit, RegimenExerciseMetaEdit, CommunitySharing },
  props: {
    grid_set_id: {
      type: String,
      required: false,
      default: "",
    },
    clinic_id: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: () => false,
    },
    additional_video_exercises: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      error_message: "",
      dialog: false,
      unsaved_data: false,
      in_flight: false,
      grid_set: {
        clinic_id: this.clinic_id,
        title: "",
        description: "",
        instructions: "",
        exercises: [],
      },
      current_grid_set_exercise_edit: {
        exercise_id: "",
        level: 0,
        instructions: "",
        config: {},
      },
      icons: category_icons,
      share_dependencies: [],
      share_dependencies_dialog: false,
      share_dependencies_update_regimens: false,
    };
  },
  computed: {
    ...mapState(["app_config", "exercises", "video_exercises"]),
    localValue: {
      get() {
        return this.value;
      },
      set(localValue) {
        this.$emit("input", localValue);
      },
    },
    share_dependencies_needs_share() {
      return this.share_dependencies.filter((item) => item.needs_share);
    },
    all_video_exercises() {
      if (!this.additional_video_exercises) {
        return this.video_exercises;
      }

      // Deep clone
      let add_video_exercises = JSON.parse(JSON.stringify(this.additional_video_exercises));

      if (this.video_exercises) {
        return Object.assign(add_video_exercises, this.video_exercises);
      } else {
        return add_video_exercises;
      }
    },
  },
  mounted() {
    this.reset();
    this.load();
  },
  methods: {
    discard_changes() {
      if (confirm(this.$t("regimen_edit.discard_changes") + "?")) {
        this.reset();
        this.load();
      }
    },
    reset() {
      this.grid_set = {
        clinic_id: this.clinic_id,
        title: "",
        description: "",
        instructions: "",
        exercises: [],
      };
      this.current_grid_set_exercise_edit = {
        exercise_id: "",
        level: 0,
        instructions: "",
        config: {},
      };
      this.share_dependencies = [];
      this.share_dependencies_dialog = false;
      this.share_dependencies_update_regimens = false;
    },
    exercise_title(item) {
      if (item.title) {
        return item.title;
      }
      let exercise;
      if (item.exercise_type.startsWith("video")) {
        exercise = this.all_video_exercises[item.exercise_id];
      } else {
        exercise = this.exercises[item.exercise_id];
      }

      let locale = this.$i18n.lang();

      let title;
      if (exercise.title[locale]) {
        title = exercise.title[locale];
      } else {
        title = exercise.title.en;
      }

      if (item.video_exercise_variant && item.config && item.config.variants) {
        let variant = item.config.variants[item.video_exercise_variant];
        title += ": " + this.variant_title(variant);
      }

      return title;
    },
    variant_title(variant) {
      let locale = this.$i18n.lang();
      if (!variant.title) {
        return "";
      }

      if (variant.title[locale]) {
        return variant.title[locale];
      } else if (variant.title.en) {
        return variant.title.en;
      } else {
        return "";
      }
    },
    close() {
      this.$emit("close", {});
    },
    save_exercise() {
      this.dialog = false;

      // Deep clone via JSON
      let current_grid_set_exercise_edit = JSON.parse(JSON.stringify(this.current_grid_set_exercise_edit));
      if (current_grid_set_exercise_edit.new) {
        this.grid_set.exercises.push(current_grid_set_exercise_edit);
      } else {
        let index = current_grid_set_exercise_edit.index;
        Vue.set(this.grid_set.exercises, index, current_grid_set_exercise_edit);
      }
      this.unsaved_data = true;
      this.current_grid_set_exercise_edit = this.blank_grid_set_exercise_edit();
    },
    edit(index) {
      this.current_grid_set_exercise_edit = this.grid_set.exercises[index];
      this.current_grid_set_exercise_edit.new = false;
      this.current_grid_set_exercise_edit.index = index;
      this.dialog = true;
    },
    add() {
      this.current_grid_set_exercise_edit = this.blank_grid_set_exercise_edit();
      this.dialog = true;
    },
    blank_grid_set_exercise_edit() {
      return {
        uuid: uuid.v4(),
        exercise_id: "",
        customization: "",
        instructions: "",
        config: {},
        level: 0,
        new: true,
        index: null,
        config_version: 0,
      };
    },
    load() {
      let self = this;
      // Get
      if (self.grid_set_id) {
        self.in_flight = true;
        axios.get("/api/grid_set/" + self.grid_set_id).then(function (response) {
          self.in_flight = false;
          let resp = response.data;
          if (resp.status == "error") {
            messageBus.$emit("api-error", resp);
            self.$emit("close");
          } else {
            self.grid_set = resp.data;
          }
        });
      }
    },
    submit_grid_set(update_regimens = false) {
      let self = this;
      self.in_flight = true;

      // Remove all .config from video exercises
      // TODO: Remove this at some point in the future when it's irrelevant
      for (let exercise of this.grid_set.exercises) {
        if (exercise.exercise_type == "video/builtin" || exercise.exercise_type == "video/clinic" || exercise.exercise_type == "video/public") {
          exercise.config = {};
        }
      }

      if (this.grid_set.id) {
        // Update
        axios.put("/api/grid_set/" + self.grid_set.id + "?update_regimens=" + update_regimens, self.grid_set).then(function (response) {
          self.in_flight = false;
          let resp = response.data;
          if (resp.status == "error") {
            messageBus.$emit("api-error", resp);
          } else {
            self.unsaved_data = false;
            messageBus.$emit("success", self.$t("regimen_edit.exercises_updated"));
            self.$emit("grid-set-updated", self.grid_set);
            self.$emit("close");
          }
        });
      } else {
        // Create
        axios.post("/api/grid_set", self.grid_set).then(function (response) {
          self.in_flight = false;
          let resp = response.data;
          if (resp.status == "error") {
            messageBus.$emit("api-error", resp);
          } else {
            self.unsaved_data = false;
            messageBus.$emit("success", self.$t("regimen_edit.exercises_updated"));
            self.$emit("grid-set-created", self.grid_set);
            self.grid_set.id = resp.data;
            self.$emit("close");
          }
        });
      }
    },
    submit(update_regimens = false) {
      this.share_dependencies = [];
      this.share_dependencies.dialog = false;
      this.error_message = "";

      if (!this.grid_set.title) {
        this.error_message = this.$t("common.title_required");
        this.$refs.title.focus();
        return;
      }

      if (this.grid_set.shared) {
        if (!this.grid_set.description) {
          this.error_message = this.$t("common.description_required_for sharing");
          this.$refs.description.focus();
          return false;
        }
      }

      // If we're shared, check for dependencies
      if (this.grid_set.shared) {
        // Check for dependencies
        this.in_flight = true;
        let video_exercise_promises = [];
        for (let exercise of this.grid_set.exercises) {
          if (exercise.exercise_type == "video/clinic") {
            video_exercise_promises.push(axios.get("/api/video_exercise/" + exercise.exercise_id));
          }
        }
        Promise.all(video_exercise_promises).then((results) => {
          let needs_additional_sharing = false;
          for (let result of results) {
            if (result.data.status == "success") {
              let video_exercise = result.data.data;
              if (this.share_dependencies.filter((d) => d.id === video_exercise.id).length == 0) {
                this.share_dependencies.push({
                  id: video_exercise.id,
                  title: video_exercise.title,
                  needs_share: !video_exercise.shared,
                });
              }

              if (!video_exercise.shared) {
                needs_additional_sharing = true;
              }
            }
          }
          if (needs_additional_sharing) {
            this.share_dependencies_dialog = true;
            this.share_dependencies_update_regimens = update_regimens;
            this.in_flight = false;
          } else {
            this.submit_grid_set(update_regimens);
          }
        });
      } else {
        this.submit_grid_set(update_regimens);
      }
    },
  },
};
</script>
