import cookie from "js-cookie";
import * as queryString from "query-string";

export class OpticalGymScreen {
  // Get the device calibration stored in the cookie.
  // The cookie is keyed by screen-size so that plugging a monitor in will require recalibration.
  static getCalibration() {
    var calibration;

    // First try localStorage
    if (window.localStorage['opticalgym_calibration']) {
      try {
        calibration = JSON.parse(window.localStorage['opticalgym_calibration']);
      }
      catch (err) {
        if (window.OpticalGymLogError) {
          window.OpticalGymLogError(err);
        }
      }
    }

    // Next try cookie
    if (!calibration) {
      calibration = cookie.getJSON(
        "opticalgym_calibration_v2_" + screen.width + "-" + screen.height
      );
    }

    // Next just guess reasonable defaults
    if (!calibration) {
      calibration = OpticalGymScreen.guessCalibration();
    }

    calibration = OpticalGymScreen.override_from_query_params(calibration);

    // Set minimum values on colour correction
    if (calibration.colour_correction.red_brightness < 0.2) {
      calibration.colour_correction.red_brightness = 0.2;
    }
    if (calibration.colour_correction.green_brightness < 0.2) {
      calibration.colour_correction.green_brightness = 0.2;
    }
    if (calibration.colour_correction.blue_brightness < 0.2) {
      calibration.colour_correction.blue_brightness = 0.2;
    }

    return calibration;
  }

  static guessCalibration() {
    let default_anaglyph_orientation = "cyan-red";
    if (window.app_config && window.app_config.default_anaglyph_orientation) {
      default_anaglyph_orientation = window.app_config.default_anaglyph_orientation;
    }

    const height_cm_guess = Math.round(
      screen.height / (28 * window.devicePixelRatio)
    );
    let calibration = {
      width_px: screen.width,
      height_px: screen.height,
      pixel_ratio: window.devicePixelRatio,
      height_cm: height_cm_guess,
      distance_cm: height_cm_guess * 2.5,
      anaglyph_orientation: default_anaglyph_orientation,
      colour_correction: {
        red_brightness: 0.75,
        blue_brightness: 0.75,
        green_brightness: 0.75,
      }
    };
    calibration = OpticalGymScreen.override_from_query_params(calibration);
    return calibration;
  }

  static override_from_query_params(calibration) {
    // Override calibration from query params if specified.
    // Calibration can be overriden by passing in `calibration_override_<prop>`
    // For example '?calibration_override_distance_cm=500`
    let query_params = queryString.parse(location.search);
    for (var prop in calibration) {
      if ("calibration_override_" + prop in query_params) {
        let new_value = query_params["calibration_override_" + prop];
        if (typeof calibration[prop] == "number") {
          calibration[prop] = Number(new_value);
        } else if (typeof calibration[prop] == "boolean") {
          if (new_value == "true" || new_value == "1") {
            calibration[prop] = true;
          } else {
            calibration[prop] = false;
          }
        } else {
          calibration[prop] = new_value;
        }
      }
    }

    return calibration;
  }

  // Set the device calibration - saved into a cookie.
  // The cookie is keyed by screen-size so that plugging a monitor in will require recalibration.
  static setCalibration(calibration) {
    // Add auto-detected properties.
    (calibration.width_px = OpticalGymScreen.screen_width()),
      (calibration.height_px = OpticalGymScreen.screen_height()),
      (calibration.pixel_ratio = window.devicePixelRatio);

    cookie.set(
      "opticalgym_calibration_v2_" + screen.width + "-" + screen.height,
      calibration,
      { expires: 365 }
    );
  }

  // Some stupid devices (iOS cough cough) don't have width and height
  // correctly defined when they are in landscape mode.
  // So always use the largest as width and the smallest as height.
  // We enforce portait mode, so this should be fine.
  static screen_width() {
    return Math.max(screen.width, screen.height);
  }

  // TODO: Take into account devices that cannot go fullscreen.
  static screen_height() {
    return Math.min(screen.width, screen.height);
  }

  /**
   * Given an offset string, parse it, returning both the value and the unit
   */
  static parseOffset(offset_string, default_unit = '') {
    if (typeof offset_string == "number") {
      return {
        value: offset_string,
        unit: default_unit || 'px'
      };
    }

    offset_string = offset_string.trim();

    var rx = /(-?\d*\.?\d*)\s*(.*)/;
    var match = rx.exec(offset_string);
    if (!match[0] || !match[1]) {
      throw "Unable to parse " + offset_string;
    }
    var value = +match[1];
    var unit = "";

    // Trim any trailing 's' off the unit
    var raw_unit = match[2].replace(/s$/, "");

    // parse unit
    if (!raw_unit) {
      raw_unit = default_unit || "px";
    }
    if (["px", "pixel", "píxel"].includes(raw_unit)) {
      unit = "px";
    } else if (["cm", "centimeter", "centímetro", "centimetro", "centimètre", "centimetre"].includes(raw_unit)) {
      unit = "cm";
    } else if (["mm", "millimeter"].includes(raw_unit)) {
      unit = "cm";
      value = value / 10;
    } else if (
      [
        "Δ",
        "prism diopter",
        "prism",
        "prisme",
        "prism-diopter",
        "dioptría de prisma",
        "dioptria de prisma",
        "dioptría prismática",
        "dioptria prismática",
        "dioptria prismatica",
        "dioptria",
        "dioptría",
        "prisma",
        "prisme",
        "pdpt",
        "prdpt",
        "PD",
        "pd"
      ].includes(raw_unit)
    ) {
      unit = "pdpt"; // Prism diopter
    } else if (
      ["arcsecond", "seconde d'arc", "arc sec", "segundo de arco", "arcosegundo", "arc second", "arcsec", "asec", "as", "″", '"', "''", "second", "seconde", "segundo", "segunda"].includes(raw_unit)
    ) {
      unit = "arcsecond";
    } else if (
      ["arcminute", "arcmin", "amin", "am", "′", "'"].includes(raw_unit)
    ) {
      unit = "arcsecond";
      value = value * 60;
    } else if (["degree", "deg", "°"].includes(raw_unit)) {
      unit = "arcsecond";
      value = value * 3600;
    } else {
      throw "Unable to parse " + offset_string;
    }

    return {
      value: value,
      unit: unit
    };
  }
}
