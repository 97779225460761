<template>
  <v-app id="visionx" class="visionx-app">
    <!-- iPad rotate -->
    <v-system-bar
      v-if="is_ipad && !print_mode"
      id="rotate_banner"
      color="orange lighten-1"
      height="60px"
    >
      <v-btn icon style="margin-top: 10px; margin-left: 10px; float: left">
        <v-icon large left>rotate_90_degrees_ccw</v-icon>
      </v-btn>

      <h3 style="padding-top: 15px; margin-left: 60px">
        VisionX works best in Landscape mode. Please rotate your device.
      </h3>
    </v-system-bar>

    <!-- Browser upgrade notice -->
    <div v-if="browser_upgrade_needed">
      <v-container fluid fill-height style="text-align: center">
        <v-layout align-center justify-center>
          <v-sheet elevation="5" style="padding: 50px">
            <div class="headline mb-1">
              {{ $t("app.you_need_to_upgrade_your_browser") }}
            </div>

            <p>{{ $t("app.your_browser_is_out_of_date") }}</p>

            <div
              v-if="browser_upgrade_needed == 'edge'"
              style="margin-top: 50px"
            >
              <a href="https://www.microsoft.com/en-us/edge">
                <img src="/assets/edge-icon-256.png" />
              </a>
              <p>
                <a href="https://www.microsoft.com/en-us/edge">{{
                  $t("app.download_the_latest_version_of_microsoft_edge")
                }}</a>
              </p>
            </div>
          </v-sheet>
        </v-layout>
      </v-container>
    </div>

    <v-navigation-drawer
      v-if="current_user && !inIframe && !print_mode"
      stateless
      :value="true"
      app
      :mini-variant="drawer_mini"
    >
      <v-list>
        <v-list-item class="px-2">
          <img
            v-if="!drawer_mini"
            class="ml-1"
            style="height: 60px; width: auto"
            src="/assets/app/visionx/visionx_wordmark.png"
          />

          <v-list-item-title v-if="!drawer_mini"></v-list-item-title>

          <v-btn icon @click.stop="drawer_mini = !drawer_mini">
            <v-icon v-if="drawer_mini">mdi-menu</v-icon>
            <v-icon v-else>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>

        <v-list-item link style="margin-top: 20px">
          <v-list-item-content
            v-if="!drawer_mini && current_user"
            @click="account_dialog = true"
          >
            <v-list-item-title
              >{{ current_user.name_given }}
              {{ current_user.name_family }}</v-list-item-title
            >
            <v-list-item-subtitle>{{
              current_user.email
            }}</v-list-item-subtitle>
          </v-list-item-content>

          <v-icon v-if="drawer_mini" @click="account_dialog = true"
            >mdi-account-edit</v-icon
          >
          <v-list-item-action v-else>
            <v-icon @click="account_dialog = true">mdi-account-circle</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>

      <!-- Main Menu -->
      <MainMenu
        v-if="(user_clinics.length > 0 || current_user.is_admin) && !print_mode"
        :user="current_user"
        :clinics="user_clinics"
        :collapsed="drawer_mini"
      />

      <template v-slot:append>
        <v-list>
          <v-list-item link @click="calibration_dialog_model = true">
            <v-list-item-icon>
              <v-badge
                v-if="current_calibration"
                small
                overlap
                bordered
                color="success"
                icon="check"
              >
                <v-icon>settings</v-icon>
              </v-badge>
              <v-badge
                v-else
                small
                overlap
                bordered
                color="warning"
                icon="fas fa-exclamation"
              >
                <v-icon>settings</v-icon>
              </v-badge>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title style="font-size: 1.2rem">{{
                $t("main_menu.calibrate")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>

      <!-- User Account dialog -->
      <v-dialog v-model="account_dialog" scrollable max-width="800">
        <UserEdit
          :user="current_user"
          show_logout
          :limit_collection="false"
          @close-user-account="account_dialog = false"
        />
      </v-dialog>
    </v-navigation-drawer>

    <v-main>
      <!-- Masquarade Banner -->
      <v-system-bar
        v-if="masquerade && !print_mode"
        color="orange lighten-1"
        height="60px"
      >
        <v-btn icon @click="unmasquerade()">
          <v-icon large>close</v-icon>
        </v-btn>

        <!-- <v-icon style="margin-right: 8px">fa-mask</v-icon> -->
        {{ $t("app.viewing_application_as") }} {{ current_user.name_given }}
        {{ current_user.name_family }}
      </v-system-bar>

      <v-container fluid style="padding: 0px">
        <!-- Loading Spinner -->
        <div v-if="app_loading" style="margin-top: 300px">
          <v-container fluid fill-height>
            <v-layout align-center justify-center>
              <v-progress-circular
                size="200"
                width="20"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-layout>
          </v-container>
        </div>

        <!-- Main App UI -->
        <div v-else>
          <!-- Main content -->
          <div
            v-if="
              loaded &&
              ((current_user &&
                (user_clinics.length > 0 || current_user.is_admin)) ||
                password_reset ||
                accept_invite ||
                clinic_signup)
            "
            id="main_content"
            :style="print_mode ? '' : 'padding-bottom: 40px;'"
          >
            <router-view />
          </div>
        </div>

        <!-- Calibration dialog -->
        <v-dialog
          id="calibration_dialog"
          v-model="calibration_dialog_model"
          scrollable
        >
          <Calibration />
        </v-dialog>

        <!-- Vuex Error Snackbar -->
        <v-snackbar v-model="vuex_error_model" color="red" dark>
          Error: {{ vuex_error_message }}
          <template v-slot:action="{ attrs }">
            <v-btn
              color="red"
              text
              v-bind="attrs"
              @click="vuex_error_model = false"
              >Close</v-btn
            >
          </template>
        </v-snackbar>

        <!-- Login screen -->
        <v-container
          v-if="
            loaded &&
            !current_user &&
            !password_reset &&
            !accept_invite &&
            !clinic_signup
          "
          fluid
          fill-height
        >
          <v-layout align-center justify-center>
            <Login>
              <v-card
                max-width="400"
                class="mx-auto"
                style="margin-top: 50px; text-align: center"
              >
                <v-card-text>
                  Don't have an account?
                  <v-btn
                    text
                    style="font-size: 1rem; margin-top: -5px"
                    class="text-none"
                    color="primary lighten-1"
                    href="/app/signup-user"
                    >Sign Up</v-btn
                  >
                </v-card-text>
              </v-card>
            </Login>
          </v-layout>
        </v-container>

        <!-- No Clinics / Account archived -->
        <v-container
          v-if="
            loaded &&
            current_user &&
            !current_user.is_admin &&
            user_clinics.length == 0
          "
          fluid
          fill-height
        >
          <v-layout align-center justify-center>
            <AccountArchived></AccountArchived>
          </v-layout>
        </v-container>

        <!-- Success Messages -->
        <v-snackbar
          v-model="show_success"
          :timeout="show_success_timeout"
          bottom
          right
          color="success"
          style="margin-right: 100px"
        >
          {{ success_message }}
          <v-btn color="pink" text @click="show_success = false">Close</v-btn>
        </v-snackbar>

        <!-- API Error Messages -->
        <v-snackbar
          v-model="show_error"
          :timeout="show_error_timeout"
          bottom
          right
          color="error"
          style="margin-right: 100px"
        >
          {{ error_message }}
          <v-btn color="pink" text @click="show_error = false">Close</v-btn>
        </v-snackbar>

        <!-- Global Metronome -->
        <div
          v-if="global_metronome_display"
          style="
            position: fixed;
            bottom: 40px;
            left: 10px;
            width: 400px;
            z-index: 5000;
            box-shadow: 4px 4px 3px grey;
            border: 1px solid grey;
            border-radius: 5px;
          "
        >
          <ToolMetronome
            ref="global_metronome"
            smaller
            @close="close_global_metronome"
          />
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import cookie from "js-cookie";
import axios from "axios";
import MainMenu from "./components/MainMenu";
import Login from "../shared/components/Login";
import Calibration from "../shared/components/Calibration";
import ApplicationError from "../shared/components/ApplicationError";
import AccountArchived from "../shared/components/AccountArchived";
import ToolMetronome from "../shared/components/ToolMetronome";
import UserEdit from "../shared/components/UserEdit";
import UAParser from "ua-parser-js";
import axios_logger from "../shared/lib/axios_logger";
import jQuery from "jquery";
import * as Sentry from "@sentry/browser";

export default {
  name: "Vest",
  components: {
    MainMenu,
    Login,
    Calibration,
    ApplicationError,
    AccountArchived,
    ToolMetronome,
    UserEdit,
  },
  data() {
    let query_params = new URLSearchParams(window.location.search);
    return {
      window_height: window.innerHeight,
      window_width: window.innerWidth,
      app_loading: true,
      error: null,
      password_reset: false,
      accept_invite: false,
      clinic_signup: false,
      print_mode: query_params.get("print") == "true",
      user_loaded: false,
      show_success: false,
      show_success_timeout: 5000,
      success_message: "",
      show_error: false,
      show_error_timeout: 5000,
      error_message: "",
      drawer: null,
      drawer_mini:
        this.$vuetify.breakpoint.name == "xs" ||
        this.$vuetify.breakpoint.name == "sm" ||
        window.innerWidth < 1000,
      account_dialog: false, // Are we showing the account dialog?
      links: [
        ["mdi-inbox-arrow-down", "Inbox"],
        ["mdi-send", "Send"],
        ["mdi-delete", "Trash"],
        ["mdi-alert-octagon", "Spam"],
      ],
    };
  },
  computed: {
    ...mapState([
      "user_calibrations",
      "user_metadata_loading",
      "current_user",
      "current_calibration",
      "calibration_dialog",
      "vuex_error_message",
      "global_metronome_display",
      "current_clinic_id",
      "user_clinics",
      "user_metadata",
      "masquerade",
      "user_invites",
      "assigned_staff",
      "ip_country",
      "file_base_url",
      "highest_perm",
      "app_config",
    ]),

    navigation_drawer_width() {
      if (this.$vuetify.breakpoint.name == "xl") {
        return 256;
      }
      if (this.$vuetify.breakpoint.name == "lg") {
        return 208;
      }
      return 200;
    },

    calibration_dialog_model: {
      set(val) {
        this.$store.commit("setCalibrationDialog", val);
      },
      get() {
        return this.$store.state.calibration_dialog;
      },
    },
    vuex_error_model: {
      set(val) {
        this.$store.commit("setHasErrorMessage", val);
      },
      get() {
        return this.$store.state.vuex_has_error_message;
      },
    },
    loaded() {
      return this.user_loaded;
    },
    inIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    },
    browser_upgrade_needed() {
      let ua = UAParser(navigator.userAgent);
      if (ua.engine == "EdgeHTML") {
        return "edge";
      }

      return "";
    },
    is_ipad() {
      // Old iPad
      if (window.navigator.platform.includes("iPad")) {
        return true;
      }

      // New iPad
      else if (
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2 &&
        /MacIntel/.test(navigator.platform)
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    drawer_mini(val) {
      if (window.innerWidth <= 1600 && window.innerWidth >= 1300) {
        if (val) {
          this.$store.commit("setSmallScreen", false);
        } else {
          this.$store.commit("setSmallScreen", true);
        }
      }
    },
  },
  mounted() {
    let self = this;

    if (!this.print_mode) {
      Sentry.setTag("app", window.OpticalGymApp);
      Sentry.setTag("environment", window.OpticalGymMode);
    }

    // Check if we are password-resetting
    let router_path = this.$router.currentRoute.path;
    if (router_path == "/app/password_reset") {
      self.password_reset = true;
    }
    // Check if we are accepting an invite
    if (router_path.startsWith("/app/code")) {
      self.accept_invite = true;
    }
    // Check if we are accepting an invite
    if (router_path.startsWith("/app/signup")) {
      self.clinic_signup = true;
    }

    // Process user preferences
    let prefs = cookie.getJSON("user_preferences");
    if (prefs) {
      if (prefs.dark_mode && !this.inIframe) {
        this.$vuetify.theme.dark = prefs.dark_mode;
      }
    }

    // Some colour tweaking on the dark and light theme
    this.$vuetify.theme.themes.dark.accent = "#82B1FF";

    // Receive messages from the global message bus
    window.messageBus.$on("dark-mode", this.dark_mode);
    window.messageBus.$on("success", this.success);
    window.messageBus.$on("api-error", this.api_error);
    window.messageBus.$on("error", this.display_error);
    window.messageBus.$on("user-updated", this.user_updated);

    // Log backend errors via axios
    axios_logger(self.application_error);

    // If we are in an iframe, then set a class on the body to indicate this
    if (window.top.location != window.location) {
      jQuery("body").addClass("in-iframe");
    }

    // If we're in print mode, then set a class on the body to indicate this
    if (this.print_mode) {
      jQuery("body").addClass("print-mode");
    }

    self.$store.dispatch("loadCurrentUser", () => {
      // If we are in iframe, break out if we are logged in
      if (window.top.location != window.location && self.current_user) {
        window.top.location = window.location.href;
      }

      // Boot helpscout if we are not masquerading and not a user
      if (!self.masquerade && self.highest_perm != "user") {
        self.boot_helpscout();
      }

      // Load exercises and video exercises if we are logged in
      if (self.current_user) {
        if (this.current_clinic_id) {
          this.$store.dispatch("loadCurrentClinic", {
            clinic_id: this.current_clinic_id,
          });
        }
        self.$store.dispatch("loadExercises", () => {
          self.exercises_loaded = true;
          self.user_loaded = true;
          self.app_loading = false;
        });
      } else {
        self.user_loaded = true;
        self.app_loading = false;
      }
    });

    // Set the small-screen toggle if appropriate
    if (window.innerWidth <= 1600 && window.innerWidth >= 1300) {
      if (this.drawer_mini) {
        this.$store.commit("setSmallScreen", false);
      } else {
        this.$store.commit("setSmallScreen", true);
      }
    }
  },
  methods: {
    boot_helpscout() {
      // No HelpScout
    },
    dark_mode(value) {
      this.$vuetify.theme.dark = value;
      let prefs = cookie.getJSON("user_preferences");
      if (!prefs) {
        prefs = {};
      }
      prefs.dark_mode = value;
      cookie.set("user_preferences", prefs, 365);
    },
    success(message) {
      this.show_success = false;
      this.success_message = message;
      this.show_success = true;
    },
    api_error(api_response) {
      this.show_error = false;
      this.error_message = "API Error: " + api_response.error_message;
      this.show_error = true;
    },
    display_error(error_message) {
      this.show_error = false;
      this.error_message = error_message;
      this.show_error = true;
    },
    application_error(error) {
      if (
        error.response &&
        error.response.status >= 500 &&
        error.response.status != 503
      ) {
        this.app_loading = false;

        if (!this.print_mode) {
          Sentry.withScope((scope) => {
            scope.setExtra(
              "response",
              JSON.parse(JSON.stringify(error.response))
            );
            scope.setExtra("full_error", JSON.parse(JSON.stringify(error)));
            if (
              error.response &&
              error.response.headers &&
              error.response.headers["x-cloud-trace-context"]
            ) {
              scope.setExtra(
                "gcloud_trace_id",
                error.response.headers["x-cloud-trace-context"]
              );
            }
            const event_id = Sentry.captureException(error);
            let event = { eventId: event_id };

            if (this.current_user) {
              event.user = {
                email: this.current_user.email,
                name:
                  this.current_user.name_given +
                  " " +
                  this.current_user.name_family,
              };
            }

            if (this.$i18n.locale && this.$i18n.locale != "en") {
              event.lang = this.$i18n.locale;
            }

            event.onLoad = () => {
              setTimeout(() => {
                let close_elem = document.querySelector(
                  ".sentry-error-embed .close"
                );

                if (close_elem) {
                  close_elem.addEventListener("click", () => {
                    window.location.reload();
                  });
                }

                let powered_elem = document.querySelector(
                  ".sentry-error-embed .powered-by"
                );
                if (powered_elem) {
                  powered_elem.style.display = "none";
                }
              }, 50);
            };
            Sentry.showReportDialog(event);
          });
        }
        else {
          console.error(error);
        }
      } else {
        this.api_error(error.response.data);
      }
    },
    user_updated: function (updated_user) {
      let self = this;
      if (updated_user.id == this.current_user.id) {
        axios.get("/api/user/current").then(function (response) {
          if (response.data.status == "success") {
            self.$store.commit("setCurrentUser", response.data.data.user);
          }
        });
      }
    },
    unmasquerade: function () {
      axios.post("/api/user/unmasquerade").then(function (response) {
        let resp = response.data;
        if (resp.status == "success") {
          if (window.Beacon) {
            window.Beacon("logout");
          }
          window.location.reload();
        } else {
          messageBus.$emit("api-error", resp);
        }
      });
    },
    open_helpscout() {
      // No HelpScout
    },
    close_global_metronome() {
      if (this.$refs["global_metronome"]) {
        this.$refs["global_metronome"].stop();
      }
      this.$store.commit("setGlobalMetronomeDisplay", false);
    },
  },
};
</script>

<style>
/* latin-ext */
@font-face {
  font-family: "Bebas Neue";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/bebasneue/v9/JTUSjIg69CK48gW7PXoo9WdhyyTh89ZNpQ.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: "Bebas Neue";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/bebasneue/v9/JTUSjIg69CK48gW7PXoo9WlhyyTh89Y.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "omnes-pro";
  src: url("https://use.typekit.net/af/0bc945/000000000000000077359c15/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/0bc945/000000000000000077359c15/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/0bc945/000000000000000077359c15/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "omnes-pro";
  src: url("https://use.typekit.net/af/b42f49/000000000000000077359c30/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/b42f49/000000000000000077359c30/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/b42f49/000000000000000077359c30/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "omnes-pro";
  src: url("https://use.typekit.net/af/b6e4b0/000000000000000077359c3e/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/b6e4b0/000000000000000077359c3e/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/b6e4b0/000000000000000077359c3e/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "omnes-pro";
  src: url("https://use.typekit.net/af/228d07/000000000000000077359c44/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/228d07/000000000000000077359c44/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/228d07/000000000000000077359c44/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/efe4a5/00000000000000007735e609/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "industry";
  src: url("https://use.typekit.net/af/99114e/000000000000000077519a43/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/99114e/000000000000000077519a43/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/99114e/000000000000000077519a43/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "industry";
  src: url("https://use.typekit.net/af/7e0d3b/000000000000000077519a42/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/7e0d3b/000000000000000077519a42/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/7e0d3b/000000000000000077519a42/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

/* latin-ext */
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v24/S6u-w4BMUTPHjxsIPx-mPCLC79U11vU.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v24/S6u-w4BMUTPHjxsIPx-oPCLC79U1.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI9w2_FQftx9897sxZ.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI9w2_Gwftx9897g.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxsAUi-qNiXg7eU0.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHjxsAXC-qNiXg7Q.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wq_FQftx9897sxZ.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI5wq_Gwftx9897g.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI3wi_FQftx9897sxZ.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v24/S6u_w4BMUTPHjxsI3wi_Gwftx9897g.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHh30AUi-qNiXg7eU0.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v24/S6u8w4BMUTPHh30AXC-qNiXg7Q.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh7USSwaPGQ3q5d0N7w.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh7USSwiPGQ3q5d0.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjxAwXiWtFCfQ7A.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v24/S6uyw4BMUTPHjx4wXiWtFCc.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVSwaPGQ3q5d0N7w.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh6UVSwiPGQ3q5d0.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50XSwaPGQ3q5d0N7w.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/lato/v24/S6u9w4BMUTPHh50XSwiPGQ3q5d0.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Roboto Condensed";
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v27/ieVj2ZhZI2eCN5jzbjEETS9weq8-19eLAQM9QPFUex17.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Roboto Condensed";
  font-style: italic;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v27/ieVj2ZhZI2eCN5jzbjEETS9weq8-19eLDwM9QPFUew.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v27/ieVl2ZhZI2eCN5jzbjEETS9weq8-19y7DQk6YvNkeg.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotocondensed/v27/ieVl2ZhZI2eCN5jzbjEETS9weq8-19K7DQk6YvM.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.adminimal .content-header #app {
  height: 64px;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
}

/* HelpScout Beacon */

.hsds-beacon .BeaconFabButtonFrame {
  right: 10px !important;
  bottom: 30px !important;
  filter: brightness(110%) contrast(90%);
}

#toolbar-administration {
  display: none;
}

.theme--dark .page_toolbar {
  background: black;
}

body .visionx-app#visionx {
  background-image: url("../../assets/app/visionx/hexagon_background.webp");
  background-size: cover;
  font-family: "omnes-pro" !important;
}

body .v-application {
  font-family: "omnes-pro" !important;
}

body .v-application .headline,
body .v-application h1,
body .v-application h2,
body .v-application h3,
body .v-application h4,
body .v-application h5,
body .v-application h6,
.v-list:not(.v-select-list) .v-list-item__title,
.v-toolbar__title {
  font-family: "Bebas Neue" !important;
  letter-spacing: 0.11rem !important;
}

.v-application--wrap {
  background: none;
}

.theme--dark.v-card {
  background-color: #2e2e2e;
}

.v-toolbar__content {
  background-color: #141414;
}

div.v-dialog.v-dialog--active {
  box-shadow: 0 0 50px 10px #000;
}

.woot-widget-bubble {
  bottom: 30px !important;
}

#rotate_banner {
  display: none;
}

#calibration_dialog {
  width: 80%;
}

.v-application .v-alert.v-sheet.theme--dark.info {
  background-color: #0e3b5f !important;
}

.theme--dark.v-list {
  background: none;
}

.v-select-list.theme--dark.v-list {
  background: black;
}

.v-menu__content .v-list {
  background: black;
}

/* iPad in portriat */
@media only screen and (min-device-width: 740px) and (max-device-width: 1080px) and (orientation: portrait) {
  #rotate_banner {
    display: block !important;
  }
}
/* Small screen, or an iPad in landscape mode */
@media only screen and (max-width: 1300px),
  only screen and (max-height: 700px),
  only screen and (min-device-width: 740px) and (max-device-width: 1080px) and (orientation: landscape) {
  body:not(.in-iframe):not(.print-mode) .exercise-list-item,
  body:not(.in-iframe):not(.print-mode) h1,
  body:not(.in-iframe):not(.print-mode) h2,
  body:not(.in-iframe):not(.print-mode) h3,
  body:not(.in-iframe):not(.print-mode) h4,
  body:not(.in-iframe):not(.print-mode) .v-toolbar__title,
  body:not(.in-iframe):not(.print-mode) .v-toolbar__items a,
  body:not(.in-iframe):not(.print-mode) .v-toolbar__items button {
    zoom: 0.8;
  }

  body:not(.in-iframe):not(.print-mode) .clinic-page-user-line,
  body:not(.in-iframe):not(.print-mode) .clinic-page-invite-line {
    zoom: 0.8;
  }

  body:not(.in-iframe):not(.print-mode) .v-input:not(.v-select) {
    zoom: 0.9;
  }

  body:not(.in-iframe):not(.print-mode) .user_activity_calendar {
    zoom: 0.8;
  }

  body:not(.in-iframe):not(.print-mode) .calibration_sheet p {
    zoom: 0.85;
  }

  body:not(.in-iframe):not(.print-mode) .activity_calendar_event {
    zoom: 0.85;
  }
}

/* Athelte-facing team page (regimen view) */
div.regimen-item-header {
  margin-top: 20px;
  background-color: #ec2027;
  color: black;
}

div.regimen-item-header h1 {
  padding: 1px;
  padding-left: 6px;
}

div.regimen-item-header p {
  padding-left: 6px;
}

.regimen-item .v-card {
  margin: 0px;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  background-color: black;
}

div.regimen_exercise_title .v-list-item__title {
  font-size: 1.6rem;
}

div.reigmen-header p,
div.reigmen-header ul {
  font-weight: bold;
  font-size: 1.2rem;
}

div.reigmen-header ul {
  margin-left: 20px;
}

div.execise-custom-instructions {
  background-color: inherit !important;
}

div.grid_history .regimen_title {
  font-size: 32px;
}

div.grid_history div.regimen-item-header h1 {
  font-size: 24px;
}

h3 {
  font-size: 1.7rem;
}

.assessment.assessment-1fa92ee2-aec9-4330-9100-024bd8e0bf73
  .v-list-item__title {
  font-size: 1.2em;
  width: 850px;
  font-family: "omnes-pro" !important;
  letter-spacing: normal !important;
}

.assessment.assessment-1fa92ee2-aec9-4330-9100-024bd8e0bf73
  div[role="button"].v-input__slot {
  width: 200px;
}

.assessment.assessment-1fa92ee2-aec9-4330-9100-024bd8e0bf73
  .regimen_inline_question {
  width: 200px;
  min-width: 200px;
  margin-left: 420px;
  margin-top: 10px;
  margin-bottom: -20px;
}

.assessment.assessment-1fa92ee2-aec9-4330-9100-024bd8e0bf73
  .regimen_exercise_icon {
  display: none;
}

.assessment-meta
  .assessment.assessment-1fa92ee2-aec9-4330-9100-024bd8e0bf73
  .regimen_inline_question {
  margin-left: 480px;
}
</style>
