<template>
  <v-app>
    <v-container id="password_reset">
      <v-layout
        align-center
        justify-center
      >
        <PasswordRest />
      </v-layout>
    </v-container>
  </v-app>
</template>

<script>
import PasswordRest from "../../shared/components/PasswordReset";

export default {
  components: {
    PasswordRest
  }
};
</script>

<style>
</style>