<template>
  <div class="grid_history">
    <div v-if="current_clinic && current_user && current_invite">
      <div v-for="(regimen, week) of weekly_regimens" :key="week">
        <v-sheet v-if="regimen" elevation="5" style="margin: 40px; margin-bottom: 60px">
          <Regimen
            :title="regimen._title"
            :show_start_arrow="false"
            :regimen_override="regimen"
            :exercises="exercises"
            :video_exercises="video_exercises"
            :user="current_user"
            :clinic="current_clinic"
            :invite_id="current_invite.id"
          />
        </v-sheet>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Regimen from "../../shared/components/Regimen.vue";

export default {
  components: { Regimen },
  props: {},
  computed: {
    ...mapState([
      "app_config",
      "current_user",
      "current_clinic",
      "user_regimens",
      "current_clinic_id",
      "exercises",
      "video_exercises",
      "current_invite",
      "user_metadata",
      "grid_schedules",
    ]),
    user_regimen() {
      for (let user_regimen of this.user_regimens) {
        if (user_regimen.clinic_id == this.current_clinic_id) {
          return user_regimen;
        }
      }
      return null;
    },
    grid_schedule() {
      if (!this.user_regimen) return null;
      return this.user_regimen.grid_schedule;
    },
    completed_grid_schedules() {
      if (!this.grid_schedule || !this.grid_schedules || this.grid_schedules.length == 0) {
        return [];
      }

      let finished = false;
      let scheds = [];

      let sched = this.grid_schedule;
      while (finished == false) {
        if (!sched) {
          finished = true;
          break;
        }

        let next_grid_schedule = sched.id;
        sched = null;
        for (let check_schedule of this.grid_schedules) {
          if (next_grid_schedule == check_schedule.next_grid_schedule) {
            sched = check_schedule;
            scheds.push(sched);
            break;
          }
        }
      }
      
      return scheds.reverse();
    },
    weekly_regimens() {
      if (!this.grid_schedule) {
        return [];
      }

      let regimens = [];
      
      // Add the completed grid schedules to the list of regimens
      for (let completed_sched of this.completed_grid_schedules) {
        let i = 1;
        for (let grid of completed_sched.grid) {
          let regimen = JSON.parse(JSON.stringify(this.user_regimen));
          regimen.exercises = JSON.parse(JSON.stringify(grid.exercises));
          regimen.grid_schedule = JSON.parse(JSON.stringify(completed_sched));
          regimen.grid_schedule_id = completed_sched.id;
          regimen.grid_current_stage = grid.id;
          regimen.week_number = i;
          regimen._title = completed_sched.title + " - Week " + i;
          regimens.push(regimen);
          i++;
        }
      }

      // Add the current grid schedule to the list of regimens
      let i = 1;
      for (let grid of this.grid_schedule.grid) {
        if (this.user_regimen.grid_current_stage == grid.id) {
          break;
        }
        let regimen = JSON.parse(JSON.stringify(this.user_regimen));
        regimen.exercises = JSON.parse(JSON.stringify(grid.exercises));
        regimen.grid_current_stage = grid.id;
        regimen.week_number = i;
        regimen._title = this.grid_schedule.title + " - Week " + i;
        regimens.push(regimen);
        i++;
      }

      regimens.reverse();

      return regimens;
    },
  },
  mounted() {
    this.$store.dispatch("loadGrids");
  },
};
</script>
