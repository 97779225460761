<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex id="opticalgym_clinic_signup" sm8>
        <div v-if="!existing_user">
          <table class="clinic_sign_up">
            <tr>
              <td>{{ $t("clinic_sign_up.business_name") }}</td>
              <td>
                <v-text-field v-model="clinic.title" type="text" />
              </td>
            </tr>

            <!-- space -->

            <tr>
              <td>{{ $t("clinic_sign_up.your_name") }}</td>
              <td>
                <v-layout justify-space-around>
                  <v-flex xs2>
                    <v-text-field
                      ref="name_prefix"
                      v-model="user.name_prefix"
                      :label="$t('common.title')"
                      type="text"
                    />
                  </v-flex>
                  <v-flex xs4>
                    <v-text-field
                      ref="name_given"
                      v-model="user.name_given"
                      :label="$t('common.first_name')"
                      type="text"
                    />
                  </v-flex>
                  <v-flex xs4>
                    <v-text-field
                      ref="name_family"
                      v-model="user.name_family"
                      :label="$t('common.last_name')"
                      type="text"
                    />
                  </v-flex>
                  <v-flex xs2>
                    <v-text-field
                      ref="name_suffix"
                      v-model="user.name_credentials"
                      :label="$t('common.suffix')"
                      type="text"
                    />
                  </v-flex>
                </v-layout>
              </td>
            </tr>

            <tr>
              <td>{{ $t("clinic_sign_up.your_email") }}</td>
              <td>
                <EmailField
                  v-model="user.email"
                  label
                  :backend_checking="false"
                  :outlined="false"
                  @valid-email="update_valid_email"
                />
              </td>
            </tr>

            <tr>
              <td>{{ $t("common.country") }}</td>
              <td>
                <v-select v-model="clinic.billing_country" :items="country_options"></v-select>
              </td>
            </tr>

            <tr v-if="clinic.billing_country == '__'">
              <td>Other Country</td>
              <td style="font-size: smaller">
                {{ $t('clinic_signup.country_not_supported') }}
                <v-select v-model="unsupported_country" :items="extra_countries"></v-select>
              </td>
            </tr>

            <tr>
              <td>{{ $t("common.city") }} <span v-if="district_enabled"> / {{ district_name }}</span></td>
              <td>

                <v-layout justify-space-around>
                  <v-flex :xs5="district_enabled">
                    <v-text-field v-model="clinic.physical_locality" :label="$t('common.city')"></v-text-field>
                  </v-flex>
                  <v-flex xs1 v-if="district_enabled"></v-flex>
                  <v-flex xs6 v-if="district_enabled">
                    <v-select v-model="clinic.billing_district" :items="district_options" :label="district_name"></v-select>
                  </v-flex>
                </v-layout>
                
              </td>
            </tr>

            <tr v-if="collect_affiliation">
              <td>{{ $t("clinic_sign_up.affiliation") }}</td>
              <td>
                <v-select v-model="user.affiliation" :items="affiliations"></v-select>
              </td>
            </tr>

            <!-- space -->

            <tr>
              <td valign="top">
                <div style="padding-top: 15px">{{ $t("common.choose_a_password") }}</div>
              </td>
              <td>
                <DoublePassword
                  v-model="user.pass"
                  load-on-mount
                  always-show-confirm
                  @passwords-match="update_password_match"
                />
              </td>
            </tr>

            <tr v-if="collect_referral_code">
              <td valign="top">
                <div style="padding-top: 15px">
                  {{ $t("clinic_sign_up.referral-code") }}
                  <br />
                  <span style="font-size: 0.7em; color: grey">OPTIONAL</span>
                </div>
              </td>
              <td>
                <v-flex xs4>
                  <v-layout row wrap justify-space-around>
                    <v-flex xs5>
                      <v-text-field
                        ref="referral_input_first"
                        v-model="referral_first"
                        class="signup-referral-code-input"
                        autofocus
                        type="text"
                        maxlength="4"
                        size="4"
                        @keyup="referral_first_input_keyup"
                        @paste="referral_paste"
                      />
                    </v-flex>
                    <v-flex xs1>
                      <v-icon
                        small
                        style="text-align: middle; position: relative; top: 18px; left: 3px"
                      >fas fa-minus</v-icon>
                    </v-flex>
                    <v-flex xs5>
                      <v-text-field
                        ref="referral_input_second"
                        v-model="referral_second"
                        class="signup-referral-code-input"
                        type="text"
                        maxlength="4"
                        size="4"
                        @keyup="referral_second_input_keyup"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </td>
            </tr>
          </table>

          <v-checkbox v-if="require_attestation" v-model="certify" style="margin-top: 30px">
            <template v-slot:label>
              <span>{{ $t("clinic_sign_up.certify_that_am_an_optometrist") }}</span>
            </template>
          </v-checkbox>

          <p
            style="font-size: small; color: grey; margin:top: 5px; margin-bottom:5px; text-align:right;"
          >
            {{ $t("clinic_sign_up.by_signing_up") }}
            <a
              href="/terms"
              target="_blank"
            >{{ $t("common.terms_of_use") }}</a>.
          </p>

          <v-layout>
            <v-flex sm2>
              <v-btn
                large
                color="primary"
                :loading="in_flight"
                :disabled="in_flight"
                @click="submit"
              >{{ $t("clinic_sign_up.sign_up") }}</v-btn>
            </v-flex>
            <v-flex sm10>
              <v-alert v-if="errors.length > 0" type="error" outlined>
                <div v-for="(err, index) in errors" :key="index">{{ err }}</div>
              </v-alert>
            </v-flex>
          </v-layout>
        </div>
        <div v-else>
          <div v-if="existing_user">
            <p>{{ $t("clinic_sign_up.looks_like_the_email") }} {{ user.email }} {{ $t("clinic_sign_up.already_registered_on_neurovisual_trainer") }}</p>
            <p>{{ $t("clinic_sign_up.to_log_into_your_existing_account") }}</p>
            <p>{{ $t("clinic_sign_up.to_sign_up_a_new_business_and_start_free_trial") }}</p>
          </div>
          <ContactInfo style="margin-top: 50px; margin-left: 120px" :include_address="false" />
        </div>
      </v-flex>
    </v-layout>

    <v-dialog v-model="referral_code_is_invite_code" persistent max-width="600">
      <v-card v-if="referral_code_is_invite_code">
        <v-card-title class="headline">Are you a patient?</v-card-title>
        <v-card-text
          style="font-size: larger"
        >The referral code provided looks to be a patient invite code, please use the patient sign-up page instead.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            light
            text
            @click="referral_code_is_invite_code = false"
          >No, I'm a vision professional</v-btn>
          <v-btn color="primary" @click="go_to_patient_signup">Yes, take me there</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import EmailField from "./EmailField.vue";
import DoublePassword from "./DoublePassword.vue";
import ContactInfo from "./ContactInfo.vue";
import all_countries from "../../../data/all_countries_list.json";
import locales from "../../../data/locales.yml";
import { mapState } from "vuex";
import Clinic from "../classes/clinic";

// Grab the optional "tag", "affiliation", "country" and ref-code value from the URL parameters
// Try to grab it from window.top, but this might not work on cross-domain iframe embeds
let query;
try {
  query = new URLSearchParams(window.top.location.search);
}
catch (e) {
  query =  new URLSearchParams(window.location.search);
}

let tag = query.get("tag") || null;
let tags = tag ? tag.split(",") : [];
let affiliation = query.get("affiliation") || "";
let default_country = query.get("country") || "US";
let ref_code = query.get("ref_code") || "";

export default {
  components: { EmailField, DoublePassword, ContactInfo },
  props: {
    collect_affiliation: {
      type: Boolean,
      default: () => true,
    },
    collect_referral_code: {
      type: Boolean,
      default: () => true,
    },
    require_attestation: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    // See if we already have a referral code.
    var ref_code_first = "";
    var ref_code_second = "";
    if (ref_code) {
      let parts = ref_code.trim().split("-");
      ref_code_first = parts[0];
      ref_code_second = parts[1];
    }

    // Country options
    let country_options = [];
    for (const [_key, locale] of Object.entries(locales)) {
      if (!locale.hidden) {
        country_options.push({
          text: locale.name,
          value: locale.code,
        });
      }
    }
    country_options.sort((a, b) => (a.text > b.text && 1) || -1);

    country_options.push({
      text: "Other",
      value: "__",
    });

    let extra_countries = [];
    for (var country of all_countries) {
      if (!locales[country.value] || (locales[country.value] && locales[country.value].hidden)) {
        extra_countries.push(country);
      }
    }

    let data = {
      certify: false,
      errors: [],
      locales,
      country_options,
      extra_countries,
      ip_country: null,
      passwords_match: true,
      valid_email: true,
      in_flight: false,
      existing_user: false,
      show_visible_capcha: false,
      referral_first: ref_code_first,
      referral_second: ref_code_second,
      referral_code_is_invite_code: false,
      unsupported_country: "AF",
      blocked_countries: ["RU"],
      clinic: Clinic.fromJson({
        certify: "",
        title: "",
        phone: "",
        website: "",
        physical_address_1: "",
        physical_address_2: "",
        physical_locality: "",
        physical_district: "",
        physical_postal: "",
        billing_district: "",
        billing_country: default_country,
        status: "pending",
        tags: tags,
        app: window.app_config.id,
        billing_strategy: window.app_config.default_billing_strategy,
      }),
      user: {
        name_prefix: "",
        name_given: "",
        name_family: "",
        name_credentials: "",
        email: "",
        pass: "",
        affiliation,
      },
      affiliations: [
        { text: "AAO", value: "AAO" },
        { text: "ACBO", value: "ACBO" },
        { text: "BABO", value: "BABO" },
        { text: "BOAF", value: "BOAF" },
        { text: "COVD", value: "COVD" },
        { text: "VTC", value: "VTC" },
        { text: "COVD - Canada", value: "COVD:CA" },
        { text: "COVD - Israel", value: "COVD::IL" },
        { text: "COVD - Korea ", value: "COVD:KR" },
        { text: "COVT&R", value: "COVTR" },
        { text: "IOA", value: "IOA" },
        { text: "NORA", value: "NORA" },
        { text: "OEP", value: "OEP" },
        { text: "SAOA", value: "SAOA" },
      ],
    };

    return data;
  },
  computed: {
    ...mapState(["app_config"]),
    district_enabled() {
      if (this.locales[this.clinic.billing_country]) {
        if (this.locales[this.clinic.billing_country].districts) {
          return true;
        }
      }
      return false;
    },
    district_name() {
      if (this.district_enabled) {
        return this.locales[this.clinic.billing_country].district_name;
      }
      return "";
    },
    district_options() {
      let options = [];
      if (this.district_enabled) {
        // District is key => object
        for (const [key, dist] of Object.entries(this.locales[this.clinic.billing_country].districts)) {
          options.push({
            text: dist.name,
            value: key,
          });
        }
      }
      return options;
    }
  },
  mounted() {
    let self = this;

    axios.get("/api/locale/ip_country").then(function (response) {
      self.ip_country = response.data.data;

      if (self.locale) {
        self.assign_default_country();
      }
    });
  },
  methods: {
    assign_default_country() {
      let locale = self.locales[self.ip_country.code];
      if (locale && !locale.hidden) {
        self.clinic.billing_country = locale.code;
        if (locale.default_association) {
          self.user.affiliation = locale.default_association;
        }
      }
    },
    submit() {
      let self = this;

      let ok = this.check();
      if (!ok) {
        return;
      }

      let clinic = self.clinic;
      if (clinic.billing_country == "__") {
        clinic.billing_country = self.unsupported_country;
      }

      if (this.user.affiliation) {
        clinic.set_tag("affiliation", this.user.affiliation);
      }

      self.in_flight = true;
      let payload = {
        clinic: clinic,
        user: this.user,
        pass: this.user.pass,
      };

      if (this.referral_first && this.referral_second) {
        payload.referral_code = this.referral_first + "-" + this.referral_second;
      }

      axios.post("/api/clinic/signup", payload).then(function (response) {
        self.in_flight = false;
        let resp = response.data;

        self.existing_user = false;

        if (resp.status == "error") {
          if (resp.error_code == "InviteUserExists") {
            self.existing_user = true;
          } else if (resp.error_code == "InvalidRefCode") {
            self.errors.push("The provided referral code was not found. Please double-check it and try again.");
            return;
          } else if (resp.error_code == "InvalidRefCodeFoundMatchingInviteCode") {
            self.errors.push("The provided referral code was not found. Please double-check it and try again.");
            self.referral_code_is_invite_code = true;
            return;
          } else {
            if (typeof resp.error_code == "object") {
              if (resp.error_code["EmailHighRisk"]) {
                self.errors.push("Invalid Email, please check that the email provided is valid and try again.");
                return;
              } else {
                messageBus.$emit("api-error", resp);
              }
            } else {
              messageBus.$emit("api-error", resp);
            }
          }
        } else {
          window.location.href = "/app/clinic/" + resp.data + "?new=true";
        }
      });
    },

    check() {
      var errors = [];

      if (!this.clinic.title) {
        errors.push(this.$t("clinic_sign_up.please_provide_a_business_name"));
      }
      if (!this.user.name_given && !this.user.name_family) {
        errors.push(this.$t("clinic_sign_up.please_provide_your_name"));
      } else if (!this.user.name_given) {
        errors.push(this.$t("clinic_sign_up.please_provide_your_first_name"));
      } else if (!this.user.name_family) {
        errors.push(this.$t("clinic_sign_up.please_provide_your_last_name"));
      }
      if (!this.user.email) {
        errors.push(this.$t("clinic_sign_up.please_provide_an_email_address"));
      }
      if (this.user.email && !this.valid_email) {
        errors.push(this.$t("clinic_sign_up.there_was_a_problem_with_your_email_address"));
      }
      if (!this.clinic.title) {
        errors.push(this.$t("clinic_sign_up.please_provide_a_business_name"));
      }

      if (!this.user.affiliation && this.collect_affiliation) {
        errors.push(this.$t("clinic_sign_up.please_select_the_professional_body"));
      }

      if (!this.certify && this.require_attestation) {
        errors.push(this.$t("clinic_sign_up.please_certify_you_are_vision_professional"));
      }
      if (this.user.pass && !this.passwords_match) {
        errors.push(this.$t("clinic_sign_up.passwords_do_not_match"));
      }

      if ((this.referral_first && !this.referral_second) || (this.referral_second && !this.referral_first)) {
        errors.push(this.$t("clinic_sign_up.bad_referral_code"));
      }

      if (this.blocked_countries.includes(this.clinic.billing_country)) {
        errors.push(this.$t("clinic_sign_up.blocked_country"));
      }

      this.errors = errors;
      return errors.length == 0;
    },

    update_password_match(val) {
      this.passwords_match = val;
    },
    update_valid_email(val) {
      this.valid_email = val;
    },
    referral_first_input_keyup() {
      this.referral_first = this.remove_invalid_chars(this.referral_first);
      if (this.referral_first.length == 4) {
        if (this.$refs.referral_input_second) {
          this.$refs.referral_input_second.focus();
        }
      }
    },
    referral_second_input_keyup() {
      this.referral_second = this.remove_invalid_chars(this.referral_second);
    },
    referral_paste(e) {
      let token = e.clipboardData.getData("Text").trim();
      token = this.remove_invalid_chars(token);

      if (token.length == 9) {
        this.referral_first = token.substring(0, 4);
        this.referral_second = token.substring(5, 9);
      } else {
        this.referral_first = token.substring(0, 4);
        this.referral_second = token.substring(4, 8);
      }
    },
    remove_invalid_chars(token_part) {
      return token_part.replace(/[- ,."'_]/g, "");
    },
    go_to_patient_signup() {
      window.location.href = "/app/code/" + this.referral_first + "-" + this.referral_second;
    },
  },
};
</script>

<style>
table.clinic_sign_up tr > td:first-child {
  width: 200px;
}

.signup-referral-code-input input {
  text-transform: uppercase;
  margin-left: 12px;
  font-size: 1.2em;
}
</style>