import Vue from 'vue'
import VueI18n from 'vue-i18n'
import cookie from "js-cookie";

Vue.use(VueI18n)

export enum Locales {
  EN = 'en',
  FR = 'fr',
  ES = 'es',
  HE = 'he',
  PT = 'pt',
  EN_VISIONX = 'en-visionx',
  EN_VEST = 'en-vest',
}

export const LOCALES = [
  { value: Locales.EN, caption: 'English' },
  { value: Locales.FR, caption: 'Français' },
  { value: Locales.ES, caption: 'Español' },
  { value: Locales.HE, caption: 'עברית' },
  { value: Locales.PT, caption: 'Português (br)' },
  { value: Locales.EN_VISIONX, caption: 'English (VisionX)' },
  { value: Locales.EN_VEST, caption: 'English (vestibular.vision)' }
]

import en from "./locales/en.json";
import fr from "./locales/fr.json";
import es from "./locales/es.json";
import he from "./locales/he.json";
import pt from "./locales/pt.json";
import en_visionx from "./locales/en_visionx.json";
import en_vest from "./locales/en_vest.json";

export const messages = {
  [Locales.EN]: en,
  [Locales.FR]: fr,
  [Locales.ES]: es,
  [Locales.HE]: he,
  [Locales.PT]: pt,
  [Locales.EN_VISIONX]: en_visionx,
  [Locales.EN_VEST]: en_vest,
};

// Determine default language
let selected_locale = cookie.get("opticalgym_locale");
if (!selected_locale) {
  let full_locale = window.navigator.language;
  if (!full_locale) {
    selected_locale = 'en';
  }

  selected_locale = full_locale.substring(0, 2);

  if (selected_locale !== 'en' && selected_locale !== 'fr' && selected_locale !== 'es') {
    selected_locale = 'en';
  }
}

let lang = selected_locale.slice(0, 2);
let locale = lang;

if (window.OpticalGymApp == "visionx") {
  locale += "-visionx";
}
if (window.OpticalGymApp == "vest") {
  locale += "-vest";
}

// Extend the VueI18n class to add a lang() method, which is the language stripped of full locale info (e.g. 'en' from 'en-US', or 'fr' from 'fr-visionx')
export class OpticalgymVueI18n extends VueI18n {
  lang(): string {
    if (this.locale) {
      return this.locale.slice(0, 2) || "en";
    }
    return "en";
  }
}

let i18n = new OpticalgymVueI18n({
  locale: locale,
  fallbackLocale: 'en',
  messages: messages,
  silentTranslationWarn: true,
});

// Set the HTML 'lang' attribute
document.documentElement.lang = i18n.lang();

export default i18n;