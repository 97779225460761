<template>
  <v-card class="pb-2">
    <v-card-title style="padding: 0px">
      <v-toolbar grey flat>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>
          {{ local_text.title }}
          <v-chip
            v-if="local_text.share_source"
            label
            small
            outlined
            class="mt-0 ml-5"
            :href="'/app/shared/clinic/' + local_text.share_source.clinic_id"
            rget="_blank"
            >{{
              $t("community_content.clinic_attribution", {
                clinic_title: local_text.share_source.clinic_title,
              })
            }}</v-chip
          >
        </v-toolbar-title>
      </v-toolbar>
    </v-card-title>
    <v-divider />
    <v-card-text class="mt-4">
      <v-layout>
        <v-flex xs6 md8>
          <v-text-field
            ref="title"
            v-model="local_text.title"
            :label="$t('common.title')"
            type="text"
            outlined
            :readonly="readonly"
            :style="textDirectionStyle"
          />
        </v-flex>
        <v-flex xs3 md1 />
        <v-flex xs3>
          <v-autocomplete
            v-model="local_text.lang"
            :label="$t('common.language')"
            :readonly="readonly"
            :items="languageOptions"
          ></v-autocomplete>
        </v-flex>
      </v-layout>

      <v-layout>
        <v-flex xs6 md8>
          <v-textarea
            ref="fulltext"
            v-model="local_text.fulltext"
            outlined
            class="text-edit-text"
            :label="$t('reading_text_edit.text')"
            :style="textDirectionStyle"
            :readonly="readonly"
            :rows="readonly ? 10 : 6"
          />
        </v-flex>
        <v-flex xs3 md1 />
        <v-flex xs3>
          <v-text-field
            v-model="local_text.grade"
            type="number"
            :label="$t('reading_text_edit.reading_level')"
            :readonly="readonly"
            min="1"
            max="16"
            step="1"
          ></v-text-field>
          <v-select
            v-model="local_text.disposition"
            :items="content_disposition_items"
            :readonly="readonly"
            :label="$t('reading_text_edit.content_disposition')"
          ></v-select>
          <v-text-field
            v-model="local_text.attribution"
            :readonly="readonly"
            :label="$t('reading_text_edit.attribution')"
          ></v-text-field>
        </v-flex>
      </v-layout>

      <!-- Comprehension questions -->
      <v-sheet
        v-if="!readonly || (readonly && local_text.questions.length)"
        outlined
        rounded
        style="padding-bottom: 20px"
      >
        <v-subheader>{{
          $t("reading_text_edit.comprehension_questions")
        }}</v-subheader>
        <v-list v-if="local_text.questions && local_text.questions.length != 0">
          <div v-for="(item, qindex) in local_text.questions" :key="qindex">
            <v-list-item>
              <v-list-item-content>
                <v-text-field
                  v-model="item.question"
                  outlined
                  class="reading_text_question"
                  :readonly="readonly"
                  :label="$t('reading_text_edit.question')"
                  :style="textDirectionStyle"
                  type="text"
                  dense
                />
                <v-list-item-subtitle>
                  <v-chip
                    v-for="(answer, aindex) in item.answers"
                    :key="aindex"
                    v-model="answer.correct"
                    filter
                    :close="!readonly"
                    label
                    text-color="black"
                    :color="answer_color(answer)"
                    style="float: left; margin-right: 10px"
                    @click:close="delete_answer(qindex, aindex)"
                    @click="select_answer(qindex, aindex)"
                    >{{ answer.answer }}</v-chip
                  >
                  <v-text-field
                    v-if="!readonly"
                    v-model="new_answers[qindex]"
                    single-line
                    dense
                    :placeholder="$t('reading_text_edit.add_answer')"
                    type="text"
                    :hint="$t('reading_text_edit.add_answer_hint')"
                    :style="textDirectionStyle + ';float: left;'"
                    @keydown.enter.prevent="create_new_answer(qindex)"
                  />
                </v-list-item-subtitle>
              </v-list-item-content>

              <!-- Actions -->
              <v-list-item-action v-if="!readonly">
                <v-btn
                  x-small
                  tile
                  outlined
                  fab
                  color="red"
                  class="mb-14"
                  @click="delete_question(qindex)"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </div>
        </v-list>
        <v-layout v-else style="font-style: italic" justify-space-around>
          <p>{{ $t("reading_text_edit.click_button_to_add_question") }}</p>
        </v-layout>
        <v-layout v-if="!readonly" justify-space-around>
          <v-btn color="blue" small dark @click="add_question">
            <v-icon left>add</v-icon>
            {{ $t("reading_text_edit.add_new_question") }}
          </v-btn>
        </v-layout>
      </v-sheet>

      <CommunitySharing
        v-if="
          local_text &&
          app_config.community_sharing &&
          !readonly &&
          !local_text.share_source
        "
        v-model="local_text.shared"
        :hint_disabled="$t('community_sharing.reading_text_hint_disabled')"
        :hint_enabled="$t('community_sharing.reading_text_hint_enabled')"
        :item_title="local_text.title"
      />
    </v-card-text>

    <v-card-actions v-if="!readonly">
      <v-btn v-if="text.id" small color="red" outlined class="ml-3" @click="delete_text">
        <v-icon left>delete</v-icon>{{ $t("common.delete") }}
      </v-btn>
      <v-alert
        v-if="error_message"
        outlined
        dense
        class="ml-3 mt-2 mr-12"
        type="error"
        >{{ error_message }}</v-alert
      >
      <v-btn
        v-else-if="!text.id"
        class="ml-2"
        @click="generate_text_dialog = true"
        ><v-icon left>mdi-image-auto-adjust</v-icon> {{ $t('reading_text_edit.auto_generate_text') }} </v-btn
      >
      <v-spacer />
      <v-btn v-if="text.id" color="primary" @click="submit()">{{
        $t("reading_text_edit.update_reading_text")
      }}</v-btn>
      <v-btn v-else color="primary" @click="submit()">{{
        $t("common.create_reading_text")
      }}</v-btn>
    </v-card-actions>
    <v-progress-linear v-if="in_flight" :indeterminate="true" />

    <v-dialog v-model="generate_text_dialog" max-width="550">
      <v-card v-if="generate_text_dialog">
        <v-card-title style="padding: 0px">
          <v-toolbar grey flat>
            <v-btn icon @click="generate_text_dialog = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>
              <span> {{ $t('reading_text_generate.generate_reading_text') }}</span>
            </v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <ReadingTextGenerate class="pa-6" :default_grade="parseInt(local_text.grade)" :default_language="local_text.lang" @generated="reading_text_generated" />
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "axios";
import languages from "../../../data/lang_codes.json";
import CommunitySharing from "./CommunitySharing.vue";
import ReadingTextGenerate from "./ReadingTextGenerate.vue";
import { mapState } from "vuex";
import Vue from "vue";

export default {
  components: { CommunitySharing, ReadingTextGenerate },
  props: {
    text: {
      type: Object,
      required: true,
    },
    show_delete: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let data = {
      error_message: "",
      local_text: Object.assign({}, this.text),
      in_flight: false,
      new_answers: [],
      content_disposition_items: [
        { text: this.$t("common.all_ages"), value: "all" },
        { text: this.$t("common.children"), value: "child" },
        { text: this.$t("common.adult"), value: "adult" },
      ],
      generate_text_dialog: false,
    };
    return data;
  },
  computed: {
    ...mapState(["small_screen", "app_config"]),
    languageOptions() {
      let options = [];
      Object.keys(languages).forEach((key) => {
        let text = languages[key].nativeName;
        if (languages[key].nativeName != languages[key].name) {
          text += " (" + languages[key].name + ")";
        }
        options.push({
          value: key,
          text: text,
        });
      });
      return options;
    },
    textDirection() {
      let lang = this.local_text.lang;
      if (lang && languages[lang] && languages[lang].direction) {
        return languages[lang].direction;
      } else {
        return "ltr";
      }
    },
    textDirectionStyle() {
      return "direction: " + this.textDirection;
    },
  },
  watch: {
    text() {
      this.local_text = Object.assign({}, this.text);
    },
  },
  methods: {
    close() {
      this.error_message = "";
      this.local_text = Object.assign({}, this.text);
      this.$emit("close");
    },
    reading_text_generated(generated_text) {
      Vue.set(this.local_text, "title", generated_text.title);
      Vue.set(this.local_text, "fulltext", generated_text.fulltext);
      Vue.set(this.local_text, "grade", generated_text.grade);
      Vue.set(this.local_text, "lang", generated_text.lang);
      Vue.set(this.local_text, "questions", generated_text.questions);
      this.generate_text_dialog = false;
    },
    verify() {
      // Convert grade to int
      this.local_text.grade = parseInt(this.local_text.grade);

      // Check grade level
      if (
        !this.local_text.grade ||
        this.local_text.grade < 1 ||
        this.local_text.grade > 16
      ) {
        this.error_message = this.$t(
          "reading_text_edit.reading_level_must_be_whole_number"
        );
        return false;
      }

      // Check question answers
      for (var qindex in this.local_text.questions) {
        let question = this.local_text.questions[qindex];
        if (question.answers.length < 2) {
          this.error_message = this.$t(
            "reading_text_edit.comprehension_questions_two_answers"
          );
          return false;
        }
      }

      // Check required fields
      if (this.local_text.title == "") {
        this.error_message = this.$t("reading_text_edit.title_is_required");
        this.$refs.title.focus();
        return false;
      }
      if (this.local_text.fulltext == "") {
        this.error_message = this.$t("reading_text_edit.text_is_required");
        this.$refs.fulltext.focus();
        return false;
      }

      // All checks pass
      return true;
    },
    submit: function () {
      if (!this.verify()) {
        return;
      }

      let self = this;
      self.in_flight = true;
      let text_id = self.local_text.id || self.text.id;
      if (text_id) {
        // Update
        let url = "/api/reading_text/" + text_id;
        axios.put(url, self.local_text).then(function (response) {
          self.in_flight = false;
          let resp = response.data;
          if (resp.status == "success") {
            messageBus.$emit(
              "success",
              self.$t("reading_text_edit.text_updated")
            );
            self.$emit("text-updated", self.local_text);
            self.close();
          } else {
            messageBus.$emit("api-error", resp);
          }
        });
      } else {
        if (!this.verify()) {
          return;
        }

        // Create
        axios
          .post("/api/reading_text", self.local_text)
          .then(function (response) {
            self.in_flight = false;
            let resp = response.data;
            if (resp.status == "success") {
              messageBus.$emit(
                "success",
                self.$t("reading_text_edit.text_created")
              );
              self.local_text.id = resp.data;
              self.$emit("text-created", self.local_text);
              self.close();
            } else {
              messageBus.$emit("api-error", resp);
            }
          });
      }
    },
    delete_text() {
      let self = this;
      let confirm_message = self.$t(
        "reading_text_edit.are_you_sure_you_wish_to_delete_this_reading_text"
      );
      if (confirm(confirm_message)) {
        self.in_flight = true;
        axios
          .delete("/api/reading_text/" + self.local_text.id)
          .then(function (response) {
            self.in_flight = false;
            let resp = response.data;
            if (resp.status == "success") {
              messageBus.$emit(
                "success",
                self.$t("common.reading_text_deleted")
              );
              self.$emit("text-deleted", self.local_text.id);
              self.close();
            } else {
              messageBus.$emit("api-error", resp);
            }
          });
      }
    },
    add_question() {
      this.local_text.questions.push({
        question: "",
        answers: [],
      });
      this.new_answers.push("");
    },
    delete_question(index) {
      this.local_text.questions.splice(index, 1);
    },
    create_new_answer(qindex) {
      var default_correct = true;
      for (var i in this.local_text.questions[qindex].answers) {
        if (this.local_text.questions[qindex].answers[i].correct) {
          default_correct = false;
        }
      }

      this.local_text.questions[qindex].answers.push({
        answer: this.new_answers[qindex],
        correct: default_correct,
      });
      this.new_answers[qindex] = "";
    },
    delete_answer(qindex, aindex) {
      if (this.readonly) {
        return;
      }
      let is_correct =
        this.local_text.questions[qindex].answers[aindex].correct;

      this.local_text.questions[qindex].answers.splice(aindex, 1);

      if (is_correct && this.local_text.questions[qindex].answers.length > 1) {
        this.local_text.questions[qindex].answers[0].correct = true;
      }
    },
    select_answer(qindex, aindex) {
      if (this.readonly) {
        return;
      }

      for (var i in this.local_text.questions[qindex].answers) {
        if (i == aindex) {
          this.local_text.questions[qindex].answers[i].correct = true;
        } else {
          this.local_text.questions[qindex].answers[i].correct = false;
        }
      }
    },
    answer_color(answer) {
      if (answer.correct) {
        return "green lighten-3";
      } else {
        return "red lighten-3";
      }
    },
  },
};
</script>

<style>
.reading_text_question .v-text-field__details {
  display: none;
}

.text-edit-text textarea {
  font-size: 12pt;
}
</style>
