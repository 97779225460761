<template>
  <v-card class="mx-auto" max-width="600">
    <v-toolbar>
      <v-btn icon @click="close">
        <v-icon>close</v-icon>
      </v-btn>
      <v-toolbar-title>{{ $t("common.stopwatch") }}</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-card-text>
      <v-container>
        <StopWatch
          ref="stopwatch"
          no_reset_on_stop
          :include_pause="false"
          include_reset
          :start_label="$t('common.start')"
          :stop_label="$t('common.stop')"
          :reset_label="$t('common.reset')"
        />
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import StopWatch from "./StopWatch";
export default {
  components: { StopWatch },
  methods: {
    close() {
      this.$emit("close");
      this.$refs["stopwatch"].stop();
      this.$refs["stopwatch"].reset();
    },
  },
};
</script>