import Vue from "vue";

// @ts-ignore
window.OcudigitalLogError = function (
  error: Error,
  additional: { [key: string]: any } = {}
) {
  var xhr = new XMLHttpRequest();
  xhr.open("POST", "/api/util/js_error", true);
  xhr.setRequestHeader("Content-Type", "application/json");

  if (additional.trace_context) {
    xhr.setRequestHeader(
      "X-Cloud-Trace-Context",
      additional.trace_context
    );
  }

  let structured_error: { [key: string]: any } = {
    error_type: "opticalgym_js_error",
    error: error.toString(),
    additional: additional,
    url: window.location.href,
  };
  if (error.stack) {
    structured_error.stack = error.stack;
  } else {
    structured_error.stack = new Error().stack;
  }

  if (error.message) {
    structured_error.message = error.message;
  }
  if (error.name) {
    structured_error.name = error.name;
  }

  if (window.app_config) {
    structured_error.app_id = window.app_config.id;
  }

  // Try to serialize the error if possible and add it under error_object key
  try {
    structured_error.error_object = JSON.parse(JSON.stringify(error));
  } catch (e) {
    if (console) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  // Strip any passwords from the error object
  structured_error = strip_password(structured_error);

  try {
    xhr.send(JSON.stringify(structured_error));
  } catch (e) {
    if (console) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }
};

//Vue.config.errorHandler = function (err, _vm, info) {
// @ts-ignore
//  window.OcudigitalLogError(err, { info });
//  if (console) {
//    // eslint-disable-next-line no-console
//    console.error(err);
//  }
//};

// Strip password from the error object
// Resursively search for password in the object and replace it with ****
function strip_password(error_object: any) {
  if (error_object && typeof error_object === "object") {
    for (let key in error_object) {
      if (key === "password") {
        error_object[key] = "****";
      } else {
        error_object[key] = strip_password(error_object[key]);
      }
    }
  }
  // If it's a string that contains the word password, it could be a serialized object with a password in it, replace the entire thing with ****
  if (typeof error_object === "string") {
    if (error_object.indexOf("password") !== -1) {
      error_object = "****";
    }
  }

  return error_object;
}