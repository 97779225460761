const TAG_REGEX = /([a-z0-9_]+)\((.*)\)/;

export abstract class Taggable {
    tags: string[];

    has_tag(tag: string): Boolean {
        for (let entity_tag of this.tags) {
            if (tag == entity_tag) {
                return true;
            }
            if (entity_tag.startsWith(tag + "(")) {
                return true;
            }
        }
        return false;
    }

    tag_value(tag: string): any {
        for (let entity_tag of this.tags) {
            if (entity_tag == tag) {
                return true;
            }
        }

        for (let entity_tag of this.tags) {
            let caps = entity_tag.match(TAG_REGEX);
            if (caps && caps[1] && caps[2]) {
                if (tag == caps[1]) {
                    return JSON.parse(caps[2]);
                }
            }
        }
    }

    tag_index(tag: string): number | null {
        for (let i = 0; i < this.tags.length; i++) {
            if (this.tags[i] == tag) {
                return i;
            }
        }

        for (let j = 0; j < this.tags.length; j++) {
            let caps = this.tags[j].match(TAG_REGEX);
            if (caps && caps[1] && caps[2]) {
                if (tag == caps[1]) {
                    return j;
                }
            }
        }

        return null;
    }

    set_tag(tag: string, value: any) {
        let index = this.tag_index(tag);

        // Tag does not yet exist
        if (index === null) {
            if (value === true) {
                this.tags.push(tag);
            }
            else {
                this.tags.push(tag + "(" + JSON.stringify(value) + ")");
            }
        }

        // Tag exists, but value is different
        else {
            if (value === true) {
                this.tags[index] = tag;
            }
            else {
                this.tags[index] = tag + "(" + JSON.stringify(value) + ")";
            }
        }
    }

    remove_tag(tag: string) {
        let index = this.tag_index(tag);

        if (index !== null) {
            this.tags.splice(index, 1);
        }
    }
}