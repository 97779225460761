<template>
  <v-card>
    <v-card-title style="padding: 0px">
      <v-toolbar grey flat>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("common.hart_chart") }}</v-toolbar-title>
      </v-toolbar>
    </v-card-title>
    <v-card-text>
      <v-layout align-center justify-space-around row>
        <v-flex xs5>
          <v-select
            v-model="pages"
            :items="pages_options"
            :label="$t('tool_hart_chart.pages')"
          />
        </v-flex>

        <v-flex xs5>
          <v-select
            v-model="chars"
            :items="chars_options"
            :label="$t('tool_hart_chart.characters')"
          />
        </v-flex>
      </v-layout>

      <v-layout align-center justify-space-around row>
        <v-flex xs5>
          <v-text-field
            v-model="rows"
            :label="$t('tool_hart_chart.rows')"
            type="number"
            min="4"
            max="15"
          />
        </v-flex>

        <v-flex xs5>
          <v-text-field
            v-model="cols"
            :label="$t('tool_hart_chart.columns')"
            type="number"
            min="4"
            max="15"
          />
        </v-flex>
      </v-layout>

      <v-layout align-center justify-space-around row>
        <v-flex xs5>
          <v-text-field
            v-model="large_font_pt"
            :label="$t('tool_hart_chart.far_font_size')"
            type="number"
            min="6"
            max="60"
            suffix="pt"
          />
        </v-flex>

        <v-flex xs5>
          <v-text-field
            v-model="small_font_pt"
            :label="$t('tool_hart_chart.near_font_size')"
            type="number"
            min="6"
            max="60"
            suffix="pt"
          />
        </v-flex>

        <v-flex xs11>
          <v-select
            v-model="font"
            :items="fonts_options"
            :label="$t('common.font')"
          />
        </v-flex>
      </v-layout>
    </v-card-text>

    <v-card-actions>
      <v-btn @click="view()">{{ $t("common.view") }}</v-btn>
      <v-spacer />
      <v-btn color="primary" @click="print()">{{ $t("common.print") }}</v-btn>
    </v-card-actions>
    <v-progress-linear v-if="in_flight" :indeterminate="true" />
  </v-card>
</template>

<script>
import printJS from "print-js";
import { Chance } from "chance";
let chance = Chance();

export default {
  components: {},
  props: {},
  data() {
    let data = {
      rows: 10,
      cols: 10,
      large_font_pt: 42,
      small_font_pt: 12,
      pages: "both",
      font: "OpticalSans",
      pages_options: [
        { text: this.$t("tool_hart_chart.near_and_far"), value: "both" },
        { text: this.$t("tool_hart_chart.far_page_only"), value: "far" },
        { text: this.$t("tool_hart_chart.near_page_only"), value: "near" },
      ],
      chars: "both",
      chars_options: [
        { text: this.$t("tool_hart_chart.numbers_and_letters"), value: "both" },
        { text: this.$t("tool_hart_chart.numbers_only"), value: "numbers" },
        { text: this.$t("tool_hart_chart.letters_only"), value: "letters" },
        { text: this.$t("tool_hart_chart.s_5"), value: "sfive" },
      ],
      fonts_options: [
        { text: "Optical Sans", value: "OpticalSans" },
        { text: "Arial", value: "Arial" },
        { text: "Courier", value: "Courier" },
        { text: "Helvetica", value: "Helvetica" },
        { text: "Times New Roman", value: "Times New Roman" },
        { text: "Georgia", value: "Georgia" },
        { text: "Verdana", value: "Verdana" },
        { text: "Comic Sans", value: "Comic Sans MS"}
      ],
      in_flight: false,
    };
    return data;
  },
  methods: {
    view: function () {
      window.open(this.url(), "_blank");
    },
    url: function () {
      let uri = "/api/tools/pdf/hart_chart.pdf";
      uri += "?rows=" + this.rows;
      uri += "&cols=" + this.cols;
      uri += "&large_font_pt=" + this.large_font_pt;
      uri += "&small_font_pt=" + this.small_font_pt;
      uri += "&pages=" + this.pages;
      uri += "&chars=" + this.chars;
      uri += "&font=" + encodeURIComponent(this.font);
      uri += "&cache_buster=" + chance.hex();

      return uri;
    },
    print: function () {
      let self = this;

      if (navigator.vendor.includes("Apple")) {
        window.open(this.url(), "_blank");
        return;
      }

      printJS({
        printable: self.url(),
        onLoadingStart: function () {
          self.in_flight = true;
        },
        onLoadingEnd: function () {
          self.in_flight = false;
        },
      });
    },
    close: function () {
      this.$emit("close");
    },
  },
};
</script>

<style></style>
