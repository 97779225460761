<template>
  <v-app>
    <div>
      <AdminUser v-if="current_user.is_admin" :user_id="user_id" :current_user="current_user" />
      <PermissionDenied v-else />
    </div>
  </v-app>
</template>

<script>
import AdminUser from "../../shared/components/admin/AdminUser";
import PermissionDenied from "../../shared/components/PermissionDenied";

export default {
  components: {
    AdminUser,
    PermissionDenied,
  },
  data() {
    return {
      user_id: this.$route.params.id,
      current_user: this.$root.$children[0].current_user,
    };
  },
};
</script>

<style>
</style>