import { Uuid } from "./uuid";
import { NaiveDateTime } from "./datetime";

export class Selection {
    id: string;
    created: NaiveDateTime;
    updated: NaiveDateTime;
    clinic_id: Uuid;
    entity_id: Uuid;
    entity_type: string;
    entity_last_updated: NaiveDateTime | null;
    entity_data: any | null;
    selected: boolean;

    static fromJson(data: Object): Selection {
        let selection = new Selection();
        Object.assign(selection, data);
        return selection;
    }
}

export class SelectionList {
    items: Selection[] = [];

    constructor(items: Selection[] = []) {
        this.items = items;
    }

    static fromJson(data: Object[]): SelectionList {
        let selection_list = new SelectionList();
        for (let selection_data of data) {
            selection_list.items.push(Selection.fromJson(selection_data));
        }
        return selection_list;
    }

    all(): Selection[] {
        return this.items;
    }

    get_by_entity_id(entity_id: string, entity_type: String): Selection | null {
        for (let item of this.items) {
            if (item.entity_type == entity_type && item.entity_id == entity_id) {
                return item;
            }
        }
        return null;
    }

    get_by_type(entity_type: String): Selection[] {
        let items: Selection[] = [];
        for (let item of this.items) {
            if (item.entity_type == entity_type) {
                items.push(item);
            }
        }
        return items;
    }

    get_index(id: Uuid): number | null {
        for (var i = 0; i < this.items.length; i++) {
            if (this.items[i].id == id) {
                return i;
            }
        }
        return null;
    }

    upsert(item: Selection) {
        let index = this.get_index(item.id);
        if (index !== null) {
            this.items[index] = item;
        } else {
            this.items.push(item);
        }
    }
}