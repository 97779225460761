<template>
  <div>
    <v-tooltip top open-delay="500">
      <template v-slot:activator="{ on }">
        <v-btn
          v-if="exercise.exercise_type != 'header'"
          :icon="!label"
          :outlined="!label"
          v-on="on"
          @click="launch_exercise"
        >
          {{ label }}
          <v-icon :right="!!label" color="blue">mdi-play</v-icon>
        </v-btn>
      </template>
      {{ $t("common.preview") }}
    </v-tooltip>
    <v-dialog v-model="dialog" :transition="false" max-width="1200">
      <div v-if="dialog">
        <v-card v-if="exercise.exercise_type == 'interactive'">
          <v-toolbar>
            <v-btn icon @click="exercise_finished()">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>
              {{ exercise_title(exercise) }}
              <span v-if="exercise_config && exercise_config.level"
                >&nbsp;- {{ $t("common.level") }}
                {{ exercise_config.level }}</span
              >
            </v-toolbar-title>
          </v-toolbar>

          <ExerciseLaunch
            ref="interactive_exercise"
            :config="exercise_config"
            :exercise="exercises[exercise.exercise_id]"
            :custom_instruction="exercise.instructions"
            :config_version="exercise.config_version || 0"
            :fully_configured="
              fully_configured ||
              !exercises[exercise.exercise_id].has_launch_config
            "
            @exercise-done="exercise_finished"
          />
        </v-card>

        <VideoExercise
          v-else-if="exercise.exercise_type.startsWith('video')"
          ref="video_exercise"
          :exercise="all_video_exercises[exercise.exercise_id]"
          :variant="exercise.video_exercise_variant || ''"
          :custom_instruction="exercise.instructions"
          :include_stopwatch="!exercise.timer || exercise.timer == 'stopwatch'"
          @exercise-finished="exercise_finished"
          @close="exercise_finished"
        />
        <VideoExercise
          v-else-if="exercise.exercise_type == 'custom'"
          ref="custom_exercise"
          :exercise="exercise"
          :include_stopwatch="!exercise.timer || exercise.timer == 'stopwatch'"
          @exercise-finished="exercise_finished"
          @close="exercise_finished"
        />

        <QuestionnaireExercise
          v-else-if="exercise.exercise_type == 'questionnaire'"
          ref="questionnaire_exercise"
          :exercise="exercise"
          @exercise-finished="exercise_finished"
          @close="exercise_finished"
        />

        <QuestionExercise
          v-else-if="exercise.exercise_type == 'question'"
          ref="question_exercise"
          :exercise="exercise"
          @exercise-finished="exercise_finished"
          @close="exercise_finished"
        />

        <AssessmentExercise
          v-else-if="exercise.exercise_type == 'assessment'"
          ref="assessment_exercise"
          :exercise="exercise"
          :invite_id="current_invite ? current_invite.id : null"
          @exercise-finished="exercise_finished"
          @close="exercise_finished"
        />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ExerciseLaunch from "./ExerciseLaunch.vue";
import VideoExercise from "./VideoExercise.vue";
import QuestionnaireExercise from "./QuestionnaireExercise.vue";
import QuestionExercise from "./QuestionExercise.vue";
import AssessmentExercise from "./AssessmentExercise.vue";
import Vue from "vue";

export default {
  components: {
    ExerciseLaunch,
    VideoExercise,
    QuestionnaireExercise,
    QuestionExercise,
    AssessmentExercise,
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    exercise: {
      type: Object,
      required: true,
    },
    // Is the exercise fully configured? Do we need to show additional config with has_launch_config?
    fully_configured: {
      type: Boolean,
      default() {
        return true;
      },
    },
    additional_video_exercises: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialog: false,
      on: false,
    };
  },
  computed: {
    ...mapState(["exercises", "video_exercises", "current_invite"]),
    all_video_exercises() {
      if (!this.additional_video_exercises) {
        return this.video_exercises;
      }

      // Deep clone
      let add_video_exercises = JSON.parse(
        JSON.stringify(this.additional_video_exercises)
      );

      if (this.video_exercises) {
        return Object.assign(add_video_exercises, this.video_exercises);
      } else {
        return add_video_exercises;
      }
    },
    exercise_config() {
      let config = this.exercise.config;

      if (!config) {
        return null;
      }

      if (this.exercise.level) {
        config.level = this.exercise.level;
      }

      return config;
    },
  },
  watch: {
    exercise: {
      handler() {
        if (this.$refs.interactive_exercise) {
          this.$refs.interactive_exercise.clear_exercise();
        }
      },
      deep: true,
    },
  },
  methods: {
    launch_exercise() {
      this.dialog = true;
      Vue.nextTick(() => {
        if (this.exercise.exercise_type == "interactive") {
          this.$refs.interactive_exercise.load();
        } else if (this.exercise.exercise_type.startsWith("video")) {
          this.$refs.video_exercise.load();
        } else if (this.exercise.exercise_type == "custom") {
          this.$refs.custom_exercise.load();
        }
      });
    },
    exercise_finished() {
      this.dialog = false;
    },
    exercise_title(item) {
      if (item.title) {
        return item.title;
      }
      let exercise;
      if (item.exercise_type.startsWith("video")) {
        exercise = this.all_video_exercises[item.exercise_id];
      } else {
        exercise = this.exercises[item.exercise_id];
      }

      if (!exercise) {
        return "";
      }

      if (typeof exercise.title === "string") {
        return exercise.title;
      }

      let locale = this.$i18n.lang();
      let title;
      if (exercise.title[locale]) {
        title = exercise.title[locale];
      } else {
        title = exercise.title.en;
      }

      if (item.video_exercise_variant && item.config && item.config.variants) {
        let variant = item.config.variants[item.video_exercise_variant];
        title += ": " + this.variant_title(variant);
      }

      return title;
    },
    variant_title(variant) {
      let locale = this.$i18n.lang();
      if (!variant.title) {
        return "";
      }

      if (variant.title[locale]) {
        return variant.title[locale];
      } else if (variant.title.en) {
        return variant.title.en;
      } else {
        return "";
      }
    },
  },
};
</script>
