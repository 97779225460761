<template>
  <v-container fluid grid-list-lg class="distance-selector-wrapper">
    <v-layout row wrap>
      <v-flex xs4>
        <v-text-field
          v-model="localValue"
          class="mt-0"
          type="number"
          suffix="cm"
          @change="$emit('change', value)"
        />
      </v-flex>
      <v-flex xs4 offset-xs4>
        <v-text-field v-model="inches_display" class="mt-0" :suffix="this.$t('common.inches')" readonly />
      </v-flex>
    </v-layout>
    <v-flex xs12>
      <v-slider
        v-model="localValue"
        :min="min"
        :max="max"
        step="0.5"
        style="margin-top: 20px"
        @change="$emit('change', value)"
      >
        <template v-slot:prepend>
          <v-icon @click="decrement">mdi-minus</v-icon>
        </template>

        <template v-slot:append>
          <v-icon @click="increment">mdi-plus</v-icon>
        </template>
      </v-slider>
    </v-flex>
  </v-container>
</template>

<script>
export default {
  props: {
    min: {
      type: Number,
      default: 10,
    },
    max: {
      type: Number,
      default: 5000,
    },
    value: {
      type: Number,
      required: true,
    },
  },
  computed: {
    inches_display: function () {
      return this.cm_to_inches(this.value);
    },
    localValue: {
      get() {
        return this.value;
      },
      set(localValue) {
        this.$emit("input", localValue);
      },
    },
  },
  methods: {
    increment() {
      if (this.localValue != this.max) {
        if (this.localValue % 1 == 0) {
          this.localValue += 1;
        } else {
          this.localValue += 0.5;
        }
      }
    },
    decrement() {
      if (this.localValue != this.min) {
        if (this.localValue % 1 == 0) {
          this.localValue -= 1;
        } else {
          this.localValue -= 0.5;
        }
      }
    },

    // Translate cm to inches, rounded to the nearest 1/4
    cm_to_inches: function (cm) {
      var decimal_inches = cm / 2.54;
      var integer = Math.floor(decimal_inches);
      var mantissa = decimal_inches % 1;

      if (mantissa <= 0.125) {
        mantissa = 0;
      } else if (mantissa <= 0.375) {
        mantissa = "¼";
      } else if (mantissa <= 0.625) {
        mantissa = "½";
      } else if (mantissa <= 0.875) {
        mantissa = "¾";
      } else {
        integer += 1;
        mantissa = 0;
      }

      if (mantissa) {
        return "" + integer + mantissa;
      } else {
        return "" + integer;
      }
    },
  },
};
</script>

<style scoped>
.distance-selector-wrapper {
  padding: 0px;
}
</style>