import { Uuid } from "./uuid";
import { NaiveDateTime } from "./datetime";
import { Taggable } from "./taggable";

export enum App { "nvt", "vest", "visionx" }

export enum BillingStrategy { "clinic", "user" }

export default class Clinic extends Taggable {
    id: Uuid;
    created: NaiveDateTime;
    updated: NaiveDateTime;
    title: string;
    phone: string;
    website: string;
    physical_address_1: string;
    physical_address_2: string;
    physical_sub_locality: string;
    physical_locality: string;
    physical_sub_district: string;
    physical_district: string;
    physical_postal: string;
    physical_country: string;
    billing_address_1: string;
    billing_address_2: string;
    billing_sub_locality: string;
    billing_locality: string;
    billing_sub_district: string;
    billing_district: string;
    billing_postal: string;
    billing_country: string;
    billing_contact: Uuid | null;
    stripe_customer_id: string | null;
    stripe_subscription_id: string | null;
    stripe_subscription_item: string | null;
    trial_end: NaiveDateTime | null;
    app: App;
    billing_strategy: BillingStrategy;

    // Calculated properties that are contextual to the running program, not stored in the database.
    // These are calculated in the Vuex store on clinic load
    user_role: string | null;
    invite_id: Uuid | null;

    static fromJson(data: Object): Clinic {
        let calibration = new Clinic();
        Object.assign(calibration, data);
        return calibration;
    }

    physical_address() {
        let parts = [];

        if (this.physical_address_1) {
            parts.push(this.physical_address_1);
        }
        if (this.physical_address_2) {
            parts.push(this.physical_address_2);
        }
        if (this.physical_sub_locality) {
            parts.push(this.physical_sub_locality);
        }
        if (this.physical_locality) {
            parts.push(this.physical_locality);
        }
        if (this.physical_sub_district) {
            parts.push(this.physical_sub_district);
        }
        if (this.physical_district) {
            parts.push(this.physical_district);
        }
        return parts.join(", ");
    }
};

