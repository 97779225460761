import { OpticalgymVueI18n } from "shared/i18n";

export default interface Exercise {
  id: string;
  title: { [key: string]: string };
  group: string[];
  demo_only: boolean;
  deprecated: boolean;
  experimental: boolean;
  requires_calibration: boolean;
  disable_fullscreen_ios: boolean;
  config_version: number;
  num_levels: number;
  level_deprecated: boolean;
  description: { [key: string]: string };
  has_launch_config: boolean;
  instructions: { [key: string]: string };
  guide: { [key: string]: string };
  level_hint: { [key: string]: string };
  global: { [key: string]: any };
  reporting: { [key: string]: ExerciseFieldDisplay };
  labels: { [key: string]: ExerciseLabel };
  variant: { [key: string]: { [key: string]: any } };
  level1: { [key: string]: any } | null;
  level2: { [key: string]: any } | null;
  level3: { [key: string]: any } | null;
  level4: { [key: string]: any } | null;
  level5: { [key: string]: any } | null;
  level6: { [key: string]: any } | null;
  level7: { [key: string]: any } | null;
  level8: { [key: string]: any } | null;
  level9: { [key: string]: any } | null;
  level10: { [key: string]: any } | null;
}

export interface ExerciseLabel {
  label: { [key: string]: string }; // Language -> label
  unit: { [key: string]: string }; // Language -> unit
}

// TODO: Replace with your Lang type definition
type Lang = string;

interface ExerciseFieldDisplay {
  // Score Title in langcode -> title format
  title: { [key: Lang ]: string };

  // Display type for this field
  display: FieldDisplayType;

  // Should this field be displayed in the score summary
  summary?: boolean;

  // Should this field be displayed in the graph
  graph?: boolean;

  // Graph axis label in langcode -> label format
  axis?: { [key: Lang ]: string };

  // Suffix for the value in langcode -> suffix format
  suffix?: { [key: Lang ]: string };

  // If set, this field should only display if the specified field is non-zero / non-empty
  // Set to self to display only if this field is non-zero / non-empty
  display_if?: string | null;

  // If the display field is "Label", this is the value labels map for the field
  value_labels?: { [key: string ]: { [key: Lang ]: string } };

  // If the display field is "Fraction", this is the denominator field for the fraction
  fraction_denominator?: string | null;
}

enum FieldDisplayType {
  // It's a text value that maps to value_labels for display
  Label = "label",

  // A simple numeric integer value
  Integer = "integer",

  // Decimal value with two decimal places
  Decimal2 = "decimal_2",

  // A fraction, the denominator is in the "fraction_denominator" field
  Fraction = "fraction",

  // A percent, the value is stored as a float from 0-1, but is displayed as a percentage
  Percent = "percent",

  // A fraction, the denominator is in the "fraction_denominator" field
  // Also displays the value as a percentage (e.g., 1/4 (25%))
  FractionWithPercent = "fraction_with_percent",

  // A boolean value, displayed as true or false
  Boolean = "boolean",

  // A boolean value, displayed as the label with no true/false value displayed
  // If false, the label is not displayed
  BooleanLabelOnly = "boolean_label_only",

  // Value is milliseconds, displayed as MM:SS
  MsToMinutesSeconds = "ms_to_minutes_seconds",

  // Value is milliseconds, displayed as seconds (S)
  MsToSeconds = "ms_to_seconds",

  // Value is milliseconds, displayed as seconds with two decimal places (S.SS)
  MsToDecimal2Seconds = "ms_to_decimal_2_seconds",
}

// Utility function to display exercise score
export function exercise_score_display(
  exercise: Exercise,
  display_field: string,
  score: { [key: string]: any },
  i18n: OpticalgymVueI18n, 
  include_suffix: boolean = true
): string {
    const field_config = exercise.reporting?.[display_field] ?? null;

    if (!field_config) {
      return "";
    }

    const field_value = score[display_field];

    let suffix = "";
    if (include_suffix && field_config.suffix) {
      suffix = field_config.suffix[i18n.lang()] ?? "";
      if (suffix) {
        suffix = " " + suffix;
      }
    }

    switch (field_config.display) {
      case "label":
        // Ensure value_labels and field_value exist
        if (field_config.value_labels && field_config.value_labels[field_value]) {
          let label_display = field_config.value_labels[field_value][i18n.lang()] || "";
          if (label_display) {
            return label_display + suffix;
          }
          else {
            return field_value;
          }
        }
        return "";

      case "integer":
        return String(Math.round(Number(field_value))) + suffix;

      case "decimal_2":
        return Number(field_value).toFixed(2) + suffix;

      case "fraction":
        // Ensure fraction_denominator exists
        if (
          field_config.fraction_denominator &&
          score[field_config.fraction_denominator]
        ) {
          const fraction_display = `${field_value}/${score[field_config.fraction_denominator]}`;
          return fraction_display + suffix;
        }
        return field_value;

      case "percent":
        const percent_display = `${(Number(field_value) * 100).toFixed(0)}%`;
        return percent_display + suffix;

      case "fraction_with_percent":
        // Ensure fraction_denominator exists
        if (
          field_config.fraction_denominator &&
          score[field_config.fraction_denominator]
        ) {
          let percent_value = Number(field_value) / Number(score[field_config.fraction_denominator]);
          return `${field_value}/${score[field_config.fraction_denominator]}${suffix} (${(percent_value * 100).toFixed(0)}%)`;
        }
        return field_value;

        case "boolean":
          return field_value === "true" ? String(i18n.t("common.boolean_true")) : String(i18n.t("common.boolean_false"));

      case "boolean_label_only":
        return "";

      case "ms_to_minutes_seconds": {
        const valueInMs = Number(field_value);
        const minutes = Math.floor(valueInMs / 60000);
        const seconds = Math.floor((valueInMs % 60000) / 1000);
        return `${minutes < 10 ? "0" : ""}${minutes}:${
          seconds < 10 ? "0" : ""
        }${seconds}${suffix}`;
      }

      case "ms_to_seconds":
        return String(Math.floor(Number(field_value) / 1000)) + suffix;

      case "ms_to_decimal_2_seconds": {
        const seconds_2 = (Number(field_value) / 1000).toFixed(2);
        const ms_to_decimal_2_seconds_dispaly = Number(seconds_2) < 10 ? `0${seconds_2}` : `${seconds_2}`;
        return ms_to_decimal_2_seconds_dispaly + suffix;
      }

      default:
        return "";
    }
}
