<template>
  <table class="contact_us_dialog_table">
    <tr v-if="app_config.contact.email">
      <td>{{ $t("common.email") }}</td>
      <td>
        <a :href="email_link">{{ app_config.contact.email }}</a>
      </td>
    </tr>
    <tr v-if="app_config.contact.phone">
      <td>{{ $t("common.phone") }}</td>
      <td>
        <a :href="phone_link">{{ app_config.contact.phone }}</a>
      </td>
    </tr>
    <tr v-if="include_chat">
      <td>{{ $t("common.online_chat") }}</td>
      <td>
        <v-btn
          text
          small
          class="contact_us_open_chat"
          @click="open_chat"
        >{{ $t("contact_info.click_start_online_chat") }}</v-btn>
      </td>
    </tr>
    <tr v-if="include_address && app_config.contact.address">
      <td>{{ $t("common.mailing_address") }}</td>
      <td style="font-size:smaller;">
        <div>
          <span v-for="(line, index) in app_config.contact.address" :key="index">
            {{ line }}
            <br />
          </span>
        </div>
      </td>
    </tr>
  </table>
</template> 


<script>
import { mapState } from "vuex";

export default {
  props: {
    include_address: {
      type: Boolean,
      default: () => true,
    },
    include_chat: {
      type: Boolean,
      default: () => true,
    },
    email: {
      type: String,
      default: "info@ocudigital.com",
    },
  },
  computed: {
    ...mapState(["app_config"]),
    email_link() {
      return "mailto:" + this.app_config.contact.email;
    },
    phone_link() {
      return "tel:" + this.app_config.contact.phone;
    },
  },

  methods: {
    open_chat() {
      this.$root.$children[0].open_helpscout();
    },
  },
};
</script>

<style scoped>

.contact_us_dialog_table {
  font-size: 16px;
}

.contact_us_dialog_table td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.contact_us_dialog_table td:first-child {
  font-weight: bold;
  vertical-align: top;
}
</style>