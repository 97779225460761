<template>
  <div v-if="active_item && active_item != {}">
    <v-layout justify-space-between :class="allow_edit_required ? 'pr-4' : ''">
      <v-flex v-if="allow_edit_title" xs6>
        <v-text-field
          v-model="active_item.title"
          :label="$t('vuetify_survey.title_question')"
          class="vuetify-survey-editor-item-title"
          outlined
        />
      </v-flex>

      <v-flex v-if="allow_edit_title" xs1></v-flex>

      <v-flex v-if="$slots.topright" xs5>
        <slot name="topright"></slot>
      </v-flex>

      <v-flex
        v-if="!allow_edit_title || !$slots.topright"
        :xs3="allow_edit_title"
      >
        <v-select
          v-model="active_item.type"
          :label="$t('vuetify_survey.type')"
          class="vuetify-survey-editor-item-type"
          :items="type_items"
          outlined
          :prepend-inner-icon="item_icon(active_item.type)"
        >
          <template v-slot:item="{ item }">
            <v-list-item-avatar>
              <v-icon>{{ item_icon(item.value) }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </template>
        </v-select>
      </v-flex>

      <v-flex v-if="allow_edit_required" xs1></v-flex>

      <v-flex v-if="allow_edit_required" xs1>
        <div>
          <v-switch
            v-model="active_item.required"
            :label="$t('vuetify_survey.required')"
          />
        </div>
      </v-flex>
    </v-layout>

    <v-select
      v-if="allow_edit_title && $slots.topright"
      v-model="active_item.type"
      :label="$t('vuetify_survey.type')"
      class="vuetify-survey-editor-item-type"
      :items="type_items"
      outlined
      :prepend-inner-icon="item_icon(active_item.type)"
    > 
      <template v-slot:item="{ item }">
        <v-list-item-avatar>
          <v-icon>{{ item_icon(item.value) }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item-content>
      </template>
    </v-select>

    <p
      v-if="show_id && !id_changed"
      @click="id_changed = true"
      style="min-height: 22px; margin-left: 5px; margin-top: -10px"
    >
      id:
      <span style="font-family: Courier, monospace">{{ active_item.id }}</span>
    </p>

    <v-text-field
      v-if="show_id && id_changed"
      :label="$t('vuetify_survey.identifier')"
      dense
      outlined
      class="vuetify-survey-editor-item-id"
      style="font-family: Courier, monospace"
      v-model="active_item.id"
      @change="id_changed = true"
    />

    <v-expansion-panels v-model="expansion_panels">
      <v-expansion-panel>
        <v-expansion-panel-header>{{
          $t("vuetify_survey.configuration")
        }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <!-- Select components for select, checkboxes, and radio-group -->
          <div
            v-if="
              active_item.type == 'select' ||
              active_item.type == 'checkboxes' ||
              active_item.type == 'radio-group'
            "
          >
            <div class="text-h6 mb-4" style="font-weight: 400">Options</div>
            <v-layout
              v-for="(option, option_idx) in active_item.items"
              :key="option_idx"
              justify-space-between
              class="mb-n3"
            >
              <v-flex xs4>
                <v-text-field
                  outlined
                  dense
                  :label="$t('vuetify_survey.text')"
                  v-model="active_item.items[option_idx].text"
                  @change="maybe_suggest_item_value(option_idx)"
                ></v-text-field>
              </v-flex>
              <v-flex xs2>
                <v-text-field
                  v-if="allow_edit_value"
                  outlined
                  dense
                  :label="$t('vuetify_survey.value')"
                  v-model="active_item.items[option_idx].value"
                ></v-text-field>
              </v-flex>
              <v-flex xs2>
                <v-text-field
                  v-if="allow_edit_class"
                  outlined
                  dense
                  :label="$t('vuetify_survey.class')"
                  v-model="active_item.items[option_idx].class"
                ></v-text-field>
              </v-flex>
              <v-flex xs2>
                <v-text-field
                  v-if="allow_edit_style"
                  outlined
                  dense
                  :label="$t('vuetify_survey.style')"
                  v-model="active_item.items[option_idx].style"
                ></v-text-field>
              </v-flex>
              <v-flex xs1>
                <v-btn
                  v-if="active_item.items.length > 2"
                  icon
                  @click="delete_option_item(option_idx)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
            <v-btn
              class="mt-3"
              color="primary"
              small
              @click="active_item.items.push({ value: '', text: '' })"
              >{{ $t("vuetify_survey.add_option") }}</v-btn
            >
          </div>

          <!-- Label component for checkbox and switch -->
          <v-text-field
            v-if="
              active_item &&
              active_item.props &&
              (active_item.type == 'checkbox' || active_item.type == 'switch')
            "
            :label="$t('vuetify_survey.label')"
            outlined
            v-model="active_item.props.label"
            @change="label_changed = true"
          />

          <!-- Number options -->
          <v-layout
            v-if="
              active_item &&
              active_item.props &&
              active_item.type == 'number-field'
            "
            justify-space-between
          >
            <v-flex xs2>
              <v-switch
                :label="$t('vuetify_survey.integers_only')"
                v-model="active_item.integer_only"
              />
            </v-flex>
            <v-flex xs2>
              <v-text-field
                :label="$t('vuetify_survey.minimum')"
                type="number"
                :step="active_item.step ? active_item.step : 'any'"
                outlined
                v-model="active_item.min"
              />
            </v-flex>
            <v-flex xs2>
              <v-text-field
                :label="$t('vuetify_survey.maximum')"
                type="number"
                :step="active_item.step ? active_item.step : 'any'"
                outlined
                v-model="active_item.max"
              />
            </v-flex>
            <v-flex xs2>
              <v-text-field
                :label="$t('vuetify_survey.step')"
                type="number"
                outlined
                :step="active_item.integer_only ? 1 : 'any'"
                :hint="$t('vuetify_survey.step_hint')"
                v-model="active_item.step"
              />
            </v-flex>
          </v-layout>

          <!-- Text Options -->
          <v-layout
            v-if="
              (active_item &&
                active_item.props &&
                active_item.type == 'text-field') ||
              active_item.type == 'textarea'
            "
            justify-space-between
          >
            <v-flex xs3>
              <v-text-field
                :label="$t('vuetify_survey.maximum_length')"
                type="number"
                step="1"
                min="1"
                dense
                outlined
                v-model="active_item.maxlength"
              />
            </v-flex>
            <v-flex xs3></v-flex>
            <v-flex xs3></v-flex>
          </v-layout>

          <!-- Rating options -->
          <v-layout
            v-if="
              active_item && active_item.props && active_item.type == 'rating'
            "
            justify-space-between
          >
            <v-flex xs2>
              <v-text-field
                :label="$t('vuetify_survey.length')"
                type="number"
                :step="1"
                outlined
                v-model="active_item.props.length"
              />
            </v-flex>

            <v-flex xs2>
              <v-text-field
                :label="$t('vuetify_survey.size')"
                type="number"
                :step="1"
                outlined
                v-model="active_item.props.size"
              />
            </v-flex>

            <v-flex xs2>
              <v-select
                outlined
                :label="$t('vuetify_survey.icon')"
                :items="rating_icons"
                v-model="active_item.rating_icon"
                :prepend-inner-icon="active_item.rating_icon"
              >
                <template v-slot:item="{ item }">
                  <v-list-item-avatar>
                    <v-icon>{{ item.value }}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>
            </v-flex>

            <v-flex xs2>
              <ColorPicker v-model="active_item.props.color" />
            </v-flex>
          </v-layout>

          <!-- Mood options -->
          <v-layout
            v-if="
              active_item && active_item.props && active_item.type == 'mood'
            "
            justify-space-between
          >
            <v-flex xs2>
              <v-text-field
                :label="$t('vuetify_survey.size')"
                type="number"
                :step="1"
                outlined
                v-model="active_item.props.size"
              />
            </v-flex>
          </v-layout>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="allow_edit_instructions">
        <v-expansion-panel-header>{{
          $t("vuetify_survey.instructions")
        }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-textarea
            v-model="active_item.instructions"
            :label="$t('vuetify_survey.instructions')"
            class="vuetify-survey-editor-item-instructions"
            outlined
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel
        v-if="
          [
            'checkbox',
            'checkboxes',
            'select',
            'text-field',
            'number-field',
            'textarea',
            'rating',
            'radio-group',
            'switch',
          ].includes(active_item.type)
        "
      >
        <v-expansion-panel-header>{{
          $t("vuetify_survey.default_value")
        }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-checkbox
            v-if="active_item.type === 'checkbox'"
            v-model="active_item.default_value"
            :label="active_item.props.label || active_item.title"
          ></v-checkbox>
          <div v-if="active_item.type === 'checkboxes'">
            <v-checkbox
              v-for="(checkbox_item, checkbox_item_idx) in active_item.items"
              :key="checkbox_item_idx"
              v-model="active_item.default_value"
              :label="checkbox_item.text"
              :value="checkbox_item.value"
            ></v-checkbox>
          </div>
          <v-select
            v-if="active_item.type === 'select'"
            v-model="active_item.default_value"
            outlined
            :items="active_item.items"
          ></v-select>
          <v-text-field
            v-if="active_item.type === 'text-field'"
            v-model="active_item.default_value"
            outlined
          ></v-text-field>
          <v-text-field
            v-if="active_item.type === 'number-field'"
            v-model="active_item.default_value"
            type="number"
            outlined
            :min="active_item.min"
            :max="active_item.max"
            :step="active_item.step ? item.step : 'any'"
          ></v-text-field>
          <v-textarea
            v-if="active_item.type === 'textarea'"
            v-model="active_item.default_value"
          ></v-textarea>
          <v-switch
            v-if="active_item.type === 'switch'"
            v-model="active_item.default_value"
            :label="active_item.props.label || active_item.title"
          ></v-switch>
          <v-rating
            v-if="active_item.type === 'rating'"
            v-model="active_item.default_value"
            :length="active_item.props.length"
          ></v-rating>
          <v-radio-group
            v-if="active_item.type === 'radio-group'"
            v-model="active_item.default_value"
            :items="active_item.items"
          >
            <v-radio
              v-for="(radio_item, radio_item_idx) in active_item.items"
              :key="radio_item_idx"
              :label="radio_item.text"
              :value="radio_item.value"
            ></v-radio>
          </v-radio-group>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel
        v-if="
          active_item.maxlength ||
          active_item.type === 'text-field' ||
          active_item.type === 'number-field' ||
          active_item.type === 'select'
        "
      >
        <v-expansion-panel-header>{{
          $t("vuetify_survey.properties")
        }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-checkbox
            v-if="active_item.maxlength"
            v-model="active_item.props.counter"
            :label="$t('vuetify_survey.counter_desc')"
          />
          <v-text-field
            v-if="
              active_item.type === 'text-field' ||
              active_item.type === 'number-field' ||
              active_item.type === 'select'
            "
            v-model="active_item.props.prefix"
            :label="$t('vuetify_survey.prefix')"
            outlined
            :hint="$t('vuetify_survey.prefix_hint')"
          />
          <v-text-field
            v-if="
              active_item.type === 'text-field' ||
              active_item.type === 'number-field' ||
              active_item.type === 'select'
            "
            v-model="active_item.props.suffix"
            :label="$t('vuetify_survey.suffix')"
            outlined
            :hint="$t('vuetify_survey.suffix_hint')"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!--
      <v-expansion-panel>
        <v-expansion-panel-header> {{ $t('vuetify_survey.validation') }} </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-checkbox v-model="active_item.required" label="Required" />
          <v-text-field
            v-if="active_item.type === 'text-field' || active_item.type === 'number-field' || active_item.type === 'textarea'"
            label="Regex"
            outlined
            dense
            hint="Enter a regular expression to validate the input"
            v-model="active_item.regex"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
      
      -->

      <v-expansion-panel
        v-if="allow_edit_class || allow_edit_style || allow_edit_visible"
      >
        <v-expansion-panel-header>{{
          $t("vuetify_survey.visibility_and_css")
        }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-text-field
            v-if="allow_edit_visible"
            v-model="active_item.visible"
            outlined
            dense
            :label="$t('vuetify_survey.visibility')"
            class="vuetify-survey-editor-item-visible"
            :hint="$t('vuetify_survey.visibility_hint')"
          />
          <v-text-field
            v-if="allow_edit_class"
            v-model="active_item.class"
            outlined
            dense
            :label="$t('vuetify_survey.class')"
            class="vuetify-survey-editor-item-class"
          />
          <v-text-field
            v-if="allow_edit_style"
            v-model="active_item.style"
            outlined
            dense
            :label="$t('vuetify_survey.style')"
            class="vuetify-survey-editor-item-style"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-alert
      v-if="error_message"
      outlined
      type="error"
      elevation="2"
      class="mt-6"
      >{{ error_message }}</v-alert
    >
  </div>
</template>

<script>
import Vue from "vue";
import ColorPicker from "./ColorPicker.vue";

export default {
  name: "VuetifySurveyItemEditor",
  components: { ColorPicker },
  props: {
    value: {
      type: Object,
      required: true,
    },
    allow_edit_style: {
      type: Boolean,
      default: true,
    },
    allow_edit_class: {
      type: Boolean,
      default: true,
    },
    allow_edit_visible: {
      type: Boolean,
      default: true,
    },
    allow_edit_value: {
      type: Boolean,
      default: true,
    },
    allow_edit_required: {
      type: Boolean,
      default: true,
    },
    disallow_same_option_value: {
      type: Boolean,
      default: false,
    },
    allow_edit_title: {
      type: Boolean,
      default: true,
    },
    allow_edit_instructions: {
      type: Boolean,
      default: true,
    },
    show_id: {
      type: Boolean,
      default: true,
    },
    immediate_mode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active_item: JSON.parse(JSON.stringify(this.value)),
      id_changed: false,
      expansion_panels: 0,
      label_changed: false,
      error_message: "",
      type_items: [
        { text: this.$t("vuetify_survey.text_field"), value: "text-field" },
        { text: this.$t("vuetify_survey.number_field"), value: "number-field" },
        { text: this.$t("vuetify_survey.textarea"), value: "textarea" },
        { text: this.$t("vuetify_survey.rating"), value: "rating" },
        { text: this.$t("vuetify_survey.mood"), value: "mood" },
        { text: this.$t("vuetify_survey.single_checkbox"), value: "checkbox" },
        { text: this.$t("vuetify_survey.switch"), value: "switch" },
        { text: this.$t("vuetify_survey.checkboxes"), value: "checkboxes" },
        { text: this.$t("vuetify_survey.select"), value: "select" },
        { text: this.$t("vuetify_survey.radio_buttons"), value: "radio-group" },
        { text: this.$t("vuetify_survey.date"), value: "date" },
        { text: this.$t("vuetify_survey.birthday"), value: "birthday" },
      ],
      rating_icons: [
        { text: this.$t("vuetify_survey.star"), value: "mdi-star" },
        { text: this.$t("vuetify_survey.heart"), value: "mdi-heart" },
        {
          text: this.$t("vuetify_survey.happy_face"),
          value: "mdi-emoticon-happy",
        },
      ],
    };
  },
  watch: {
    value() {
      this.active_item = JSON.parse(JSON.stringify(this.value));

      if (this.active_item && !this.active_item.props) {
        Vue.set(this.active_item, "props", {});
      }
    },
    active_item: {
      handler() {
        if (!this.active_item || this.active_item == {}) {
          return;
        }
        if (!this.id_changed && !this.value.id) {
          this.active_item.id = this.suggested_id;
        }
        if (
          this.active_item.type == "checkboxes" ||
          this.active_item.type == "select" ||
          this.active_item.type == "radio-group"
        ) {
          if (!this.active_item.items) {
            Vue.set(this.active_item, "items", [
              { value: "", text: "" },
              { value: "", text: "" },
            ]);
          }
          if (!this.active_item.default_value) {
            Vue.set(this.active_item, "default_value", []);
          }
        } else {
          delete this.active_item.items;
        }

        if (
          this.active_item.type == "switch" ||
          this.active_item.type == "checkbox"
        ) {
          if (!this.active_item.props) {
            Vue.set(this.active_item, "props", {});
          }
          if (
            this.active_item.title &&
            !this.active_item.props.label &&
            !this.label_changed
          ) {
            Vue.set(this.active_item.props, "label", this.active_item.title);
          }
        }

        if (this.active_item.type == "number-field") {
          if (this.active_item.integer_only) {
            if (this.active_item.max) {
              Vue.set(
                this.active_item,
                "max",
                Math.round(this.active_item.max)
              );
            }
            if (this.active_item.min) {
              Vue.set(
                this.active_item,
                "min",
                Math.round(this.active_item.min)
              );
            }
            if (this.active_item.step) {
              Vue.set(
                this.active_item,
                "step",
                Math.round(this.active_item.step)
              );
            } else {
              Vue.set(this.active_item, "step", 1);
            }
          }
        } else {
          delete this.active_item.integer_only;
          delete this.active_item.min;
          delete this.active_item.max;
          delete this.active_item.step;
        }

        if (this.active_item.type == "rating") {
          if (!this.active_item.props) {
            Vue.set(this.active_item, "props", {});
          }
          if (!this.active_item.props.length) {
            Vue.set(this.active_item.props, "length", 5);
            Vue.set(this.active_item.props, "hover", true);
          }
          if (!this.active_item.props.color) {
            Vue.set(this.active_item.props, "color", "#FFD700");
          }
          if (!this.active_item.props.size) {
            Vue.set(this.active_item.props, "size", 32);
          }
          if (!this.active_item.rating_icon) {
            Vue.set(this.active_item, "rating_icon", "mdi-star");
          }
        }
        else {
          // Remove color prop, only used by rating but "applies" to others.
          if (this.active_item.props && this.active_item.props.color) {
            delete this.active_item.props.color;
          }
        }

        if (this.active_item.type == "mood") {
          if (!this.active_item.props) {
            Vue.set(this.active_item, "props", {});
          }
          if (!this.active_item.props.size) {
            Vue.set(this.active_item.props, "size", 32);
            Vue.set(this.active_item.props, "hover", true);
          }
        }

        if (this.immediate_mode) {
          this.$emit("input", this.active_item);
        }
        else {
          this.$emit("change", this.active_item);
        }
      },
      deep: true,
    },
  },
  computed: {
    suggested_id() {
      return this.suggest_id(this.active_item.title);
    },
  },
  methods: {
    reset() {
      this.id_changed = false;
      this.expansion_panels = 0;
      this.label_changed = false;
      this.error_message = "";
    },
    maybe_suggest_item_value(idx) {
      if (!this.active_item.items[idx].value) {
        Vue.set(
          this.active_item.items[idx],
          "value",
          this.suggest_id(this.active_item.items[idx].text)
        );
      }
    },
    suggest_id(label) {
      return label
        .toLowerCase()
        .replace(/[^a-z0-9 ]/g, "")
        .replace(/ +/g, "_");
    },
    validate() {
      this.error_message = "";
      if (
        this.active_item.type == "checkboxes" ||
        this.active_item.type == "select" ||
        this.active_item.type == "radio-group"
      ) {
        if (this.active_item.items.length < 2) {
          this.error_message = this.$t(
            "vuetify_survey.provide_at_least_two_options"
          );
          this.expansion_panels = 0;
          Vue.nextTick(() => {
            //this.$refs.options.focus();
          });
          return false;
        }

        for (let item of this.active_item.items) {
          if (!item.text) {
            this.error_message = this.$t(
              "vuetify_survey.provide_text_for_option"
            );
            this.expansion_panels = 0;
            Vue.nextTick(() => {
              //this.$refs.options.focus();
            });
            return false;
          }
        }

        if (this.disallow_same_option_value) {
          let values = [];
          for (let item of this.active_item.items) {
            let item_fix_index = 0;
            if (values.indexOf(item.value) != -1) {
              if (this.allow_edit_value) {
                this.error_message = this.$t(
                  "vuetify_survey.provide_unique_values"
                );
                this.expansion_panels = 0;
                Vue.nextTick(() => {
                  //this.$refs.options.focus();
                });
                return false;
              } else {
                // Fix it automatically
                item.value = this.suggest_id(item.text);
                while (values.indexOf(item.value) != -1) {
                  item_fix_index++;
                  item.value =
                    this.suggest_id(item.text) + "_" + item_fix_index;
                }
              }
            }
            values.push(item.value);
          }
        }
      }

      return true;
    },
    get_active_item() {
      return this.active_item;
    },
    set_active_item(item) {
      this.active_item = item;
    },
    save() {
      // Validate
      if (!this.validate()) {
        return false;
      }

      // Validate
      if (this.active_item.type == "number-field") {
        if (typeof this.active_item.min == "string") {
          if (this.active_item.integer_only) {
            this.active_item.min = parseInt(this.active_item.min);
          } else {
            this.active_item.min = parseFloat(this.active_item.min);
          }
        }
        if (typeof this.active_item.max == "string") {
          if (this.active_item.integer_only) {
            this.active_item.max = parseInt(this.active_item.max);
          } else {
            this.active_item.max = parseFloat(this.active_item.max);
          }
        }
        if (typeof this.active_item.step == "string") {
          if (this.active_item.integer_only) {
            this.active_item.step = parseInt(this.active_item.step);
          } else {
            this.active_item.step = parseFloat(this.active_item.step);
          }
        }
      }

      let item = JSON.parse(JSON.stringify(this.active_item));
      this.reset();
      return item;
    },
    delete_option_item(idx) {
      Vue.delete(this.active_item.items, idx);
    },
    item_icon(item_type) {
      if (item_type == "text-field") {
        return "mdi-textbox";
      }
      if (item_type == "number-field") {
        return "mdi-numeric";
      }
      if (item_type == "textarea") {
        return "mdi-card-text";
      }
      if (item_type == "checkbox") {
        return "mdi-checkbox-marked";
      }
      if (item_type == "switch") {
        return "mdi-toggle-switch";
      }
      if (item_type == "checkboxes") {
        return "mdi-checkbox-multiple-marked";
      }
      if (item_type == "select") {
        return "mdi-format-list-checks";
      }
      if (item_type == "radio-group") {
        return "mdi-radiobox-marked";
      }
      if (item_type == "autocomplete") {
        return "mdi-text-search";
      }
      if (item_type == "rating") {
        return "mdi-star";
      }
      if (item_type == "mood") {
        return "mdi-emoticon-happy";
      }
      if (item_type == "birthday") {
        return "mdi-calendar-account";
      }
      if (item_type == "date") {
        return "mdi-calendar";
      }
    },
  },
};
</script>

<style scoped></style>
