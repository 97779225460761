<template>
  <div>
    <AssessmentReportASCI
      v-if="assessment_id == '1fa92ee2-aec9-4330-9100-024bd8e0bf73'"
      :assessment_id="assessment_id"
      :invite_id="invite_id"
      :assessment_instance_id="assessment_instance_id"
    />
    <AssessmentReportVision
      v-else
      :assessment_id="assessment_id"
      :invite_id="invite_id"
      :assessment_instance_id="assessment_instance_id"
    />
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import Vue from "vue";

export default {
  name: "AssessmentReportRoute",
  components: {
    AssessmentReportASCI: () => import("../components/AssessmentReportASCI.vue"),
    AssessmentReportVision: () => import("../components/AssessmentReportVision.vue"),
  },
  props: {},
  data() {
    let data = {
      assessment_id: this.$route.params.assessment_id,
      invite_id: this.$route.params.invite_id,
      assessment_instance_id: this.$route.params.assessment_instance_id,
    };
    return data;
  },
};
</script>
