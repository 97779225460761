<template>
  <v-card>
    <v-card-title style="padding: 0px">
      <v-toolbar grey flat>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("common.line_maze") }}</v-toolbar-title>
      </v-toolbar>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-container>
        <v-select
          v-model="level"
          :items="level_options"
          :label="$t('common.level')"
        />
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-btn @click="view()">{{ $t("common.view") }}</v-btn>
      <v-spacer />
      <v-btn color="primary" @click="print()">{{ $t("common.print") }}</v-btn>
    </v-card-actions>
    <v-progress-linear v-if="in_flight" :indeterminate="true" />
  </v-card>
</template>

<script>
import printJS from "print-js";

export default {
  components: {},
  props: {},
  data() {
    let data = {
      level: 1,
      level_options: [
        { text: this.$t("common.level_x", { level: 1 }), value: 1 },
        { text: this.$t("common.level_x", { level: 2 }), value: 2 },
        { text: this.$t("common.level_x", { level: 3 }), value: 3 },
        { text: this.$t("common.level_x", { level: 4 }), value: 4 },
        { text: this.$t("common.level_x", { level: 5 }), value: 5 },
        { text: this.$t("common.level_x", { level: 6 }), value: 6 },
      ],
      in_flight: false,
      level_map: [
        [
          "1_1_flipped_with_1_4.pdf",
          "1_2_flipped_with_1_4_flipped.pdf",
          "1_4_with_1_1_flipped.pdf",
          "1_4_flipped_with_1_2.pdf",
          "1_2_flipped_with_1_1_flipped.pdf",
          "1_1_flipped_with_1_4_flipped.pdf",
          "1_1_with_1_4_flipped.pdf",
          "1_1_flipped_with_1_3.pdf",
          "1_1_flipped_with_1_2.pdf",
          "1_2_with_1_3_flipped.pdf",
          "1_4_flipped_with_1_3.pdf",
          "1_4_flipped_with_1_2_flipped.pdf",
          "1_4_flipped_with_1_1.pdf",
          "1_1_with_1_4.pdf",
          "1_4_flipped_with_1_1_flipped.pdf",
          "1_1_with_1_3.pdf",
          "1_4_with_1_2_flipped.pdf",
          "1_1_flipped_with_1_2_flipped.pdf",
          "1_1_with_1_2.pdf",
          "1_1_with_1_2_flipped.pdf",
          "1_2_with_1_4.pdf",
          "1_3_flipped_with_1_2.pdf",
          "1_4_with_1_3.pdf",
          "1_4_with_1_2.pdf",
          "1_3_with_1_4_flipped.pdf",
          "1_3_with_1_2.pdf",
          "1_4_flipped_with_1_3_flipped.pdf",
          "1_3_flipped_with_1_1.pdf",
          "1_4_with_1_1.pdf",
          "1_3_with_1_1.pdf",
          "1_3_flipped_with_1_4.pdf",
          "1_3_with_1_4.pdf",
          "1_2_with_1_3.pdf",
          "1_3_flipped_with_1_2_flipped.pdf",
          "1_2_with_1_1.pdf",
          "1_3_with_1_1_flipped.pdf",
          "1_3_with_1_2_flipped.pdf",
          "1_2_flipped_with_1_1.pdf",
          "1_3_flipped_with_1_1_flipped.pdf",
          "1_1_flipped_with_1_3_flipped.pdf",
          "1_1_with_1_3_flipped.pdf",
          "1_2_with_1_4_flipped.pdf",
          "1_2_flipped_with_1_3.pdf",
          "1_4_with_1_3_flipped.pdf",
          "1_2_flipped_with_1_3_flipped.pdf",
          "1_2_with_1_1_flipped.pdf",
          "1_2_flipped_with_1_4.pdf",
          "1_3_flipped_with_1_4_flipped.pdf",
        ],
        [
          "2_2_flipped_with_2_3_flipped.pdf",
          "2_3_flipped_with_2_4_flipped.pdf",
          "2_4_with_2_1_flipped.pdf",
          "2_1_with_2_4_flipped.pdf",
          "2_3_flipped_with_2_1_flipped.pdf",
          "2_2_with_2_3_flipped.pdf",
          "2_1_flipped_with_2_3_flipped.pdf",
          "2_1_with_2_4.pdf",
          "2_4_flipped_with_2_1.pdf",
          "2_4_flipped_with_2_3.pdf",
          "2_1_flipped_with_2_2.pdf",
          "2_1_flipped_with_2_3.pdf",
          "2_4_flipped_with_2_2.pdf",
          "2_3_flipped_with_2_2_flipped.pdf",
          "2_4_with_2_2_flipped.pdf",
          "2_4_flipped_with_2_3_flipped.pdf",
          "2_1_with_2_3.pdf",
          "2_1_with_2_2.pdf",
          "2_1_with_2_2_flipped.pdf",
          "2_1_flipped_with_2_4.pdf",
          "2_4_with_2_3.pdf",
          "2_1_flipped_with_2_2_flipped.pdf",
          "2_2_with_2_4.pdf",
          "2_3_with_2_2.pdf",
          "2_3_with_2_4_flipped.pdf",
          "2_4_with_2_2.pdf",
          "2_2_flipped_with_2_4.pdf",
          "2_3_with_2_1.pdf",
          "2_4_with_2_1.pdf",
          "2_2_flipped_with_2_3.pdf",
          "2_4_flipped_with_2_1_flipped.pdf",
          "2_2_with_2_3.pdf",
          "2_3_with_2_4.pdf",
          "2_2_with_2_1.pdf",
          "2_3_with_2_1_flipped.pdf",
          "2_2_flipped_with_2_1.pdf",
          "2_3_with_2_2_flipped.pdf",
          "2_2_flipped_with_2_1_flipped.pdf",
          "2_1_flipped_with_2_4_flipped.pdf",
          "2_1_with_2_3_flipped.pdf",
          "2_2_with_2_4_flipped.pdf",
          "2_3_flipped_with_2_4.pdf",
          "2_4_with_2_3_flipped.pdf",
          "2_4_flipped_with_2_2_flipped.pdf",
          "2_3_flipped_with_2_1.pdf",
          "2_2_with_2_1_flipped.pdf",
          "2_3_flipped_with_2_2.pdf",
          "2_2_flipped_with_2_4_flipped.pdf",
        ],
        [
          "3_4_flipped_with_3_1_flipped.pdf",
          "3_1_with_3_2.pdf",
          "3_1_with_3_3.pdf",
          "3_3_with_3_4_flipped.pdf",
          "3_1_flipped_with_3_2_flipped.pdf",
          "3_1_with_3_4.pdf",
          "3_3_with_3_1_flipped.pdf",
          "3_1_with_3_3_flipped.pdf",
          "3_4_with_3_3_flipped.pdf",
          "3_2_with_3_4_flipped.pdf",
          "3_3_with_3_2_flipped.pdf",
          "3_2_flipped_with_3_4_flipped.pdf",
          "3_1_flipped_with_3_4.pdf",
          "3_4_flipped_with_3_1.pdf",
          "3_1_flipped_with_3_4_flipped.pdf",
          "3_2_flipped_with_3_1_flipped.pdf",
          "3_1_flipped_with_3_3.pdf",
          "3_4_flipped_with_3_2.pdf",
          "3_4_flipped_with_3_3.pdf",
          "3_4_flipped_with_3_2_flipped.pdf",
          "3_2_with_3_1_flipped.pdf",
          "3_1_flipped_with_3_2.pdf",
          "3_3_flipped_with_3_1_flipped.pdf",
          "3_2_flipped_with_3_3.pdf",
          "3_4_with_3_1_flipped.pdf",
          "3_2_flipped_with_3_1.pdf",
          "3_1_flipped_with_3_3_flipped.pdf",
          "3_2_flipped_with_3_4.pdf",
          "3_2_flipped_with_3_3_flipped.pdf",
          "3_3_flipped_with_3_4_flipped.pdf",
          "3_1_with_3_4_flipped.pdf",
          "3_2_with_3_3_flipped.pdf",
          "3_3_with_3_4.pdf",
          "3_4_flipped_with_3_3_flipped.pdf",
          "3_3_flipped_with_3_1.pdf",
          "3_2_with_3_3.pdf",
          "3_3_flipped_with_3_2.pdf",
          "3_2_with_3_1.pdf",
          "3_3_with_3_2.pdf",
          "3_4_with_3_2.pdf",
          "3_2_with_3_4.pdf",
          "3_4_with_3_3.pdf",
          "3_3_with_3_1.pdf",
          "3_4_with_3_1.pdf",
          "3_3_flipped_with_3_4.pdf",
          "3_4_with_3_2_flipped.pdf",
          "3_1_with_3_2_flipped.pdf",
          "3_3_flipped_with_3_2_flipped.pdf",
        ],
        [
          "4_4_flipped_with_4_2_flipped.pdf",
          "4_3_with_4_1_flipped.pdf",
          "4_4_flipped_with_4_1.pdf",
          "4_1_flipped_with_4_3.pdf",
          "4_1_flipped_with_4_4_flipped.pdf",
          "4_2_flipped_with_4_1_flipped.pdf",
          "4_4_flipped_with_4_2.pdf",
          "4_4_flipped_with_4_3.pdf",
          "4_1_flipped_with_4_2.pdf",
          "4_2_flipped_with_4_4_flipped.pdf",
          "4_3_with_4_4_flipped.pdf",
          "4_1_flipped_with_4_4.pdf",
          "4_1_with_4_4.pdf",
          "4_2_with_4_1_flipped.pdf",
          "4_1_flipped_with_4_2_flipped.pdf",
          "4_4_with_4_3_flipped.pdf",
          "4_2_with_4_4_flipped.pdf",
          "4_1_with_4_2.pdf",
          "4_1_with_4_3_flipped.pdf",
          "4_1_with_4_3.pdf",
          "4_3_with_4_2_flipped.pdf",
          "4_4_flipped_with_4_1_flipped.pdf",
          "4_4_with_4_2.pdf",
          "4_3_with_4_2.pdf",
          "4_3_flipped_with_4_2_flipped.pdf",
          "4_2_with_4_4.pdf",
          "4_4_with_4_3.pdf",
          "4_4_with_4_1.pdf",
          "4_2_with_4_3_flipped.pdf",
          "4_3_with_4_1.pdf",
          "4_3_flipped_with_4_4.pdf",
          "4_1_with_4_4_flipped.pdf",
          "4_3_flipped_with_4_1.pdf",
          "4_3_with_4_4.pdf",
          "4_4_with_4_1_flipped.pdf",
          "4_2_with_4_3.pdf",
          "4_3_flipped_with_4_2.pdf",
          "4_2_with_4_1.pdf",
          "4_4_flipped_with_4_3_flipped.pdf",
          "4_2_flipped_with_4_4.pdf",
          "4_3_flipped_with_4_4_flipped.pdf",
          "4_1_with_4_2_flipped.pdf",
          "4_2_flipped_with_4_3_flipped.pdf",
          "4_4_with_4_2_flipped.pdf",
          "4_1_flipped_with_4_3_flipped.pdf",
          "4_2_flipped_with_4_3.pdf",
          "4_2_flipped_with_4_1.pdf",
          "4_3_flipped_with_4_1_flipped.pdf",
        ],
        [
          "5_2_with_5_3_flipped.pdf",
          "5_1_with_5_3.pdf",
          "5_1_with_5_4_flipped.pdf",
          "5_1_with_5_2.pdf",
          "5_1_flipped_with_5_4.pdf",
          "5_4_flipped_with_5_3_flipped.pdf",
          "5_3_flipped_with_5_2_flipped.pdf",
          "5_1_with_5_4.pdf",
          "5_4_flipped_with_5_1.pdf",
          "5_4_flipped_with_5_3.pdf",
          "5_4_with_5_1_flipped.pdf",
          "5_1_flipped_with_5_2.pdf",
          "5_1_flipped_with_5_3.pdf",
          "5_4_flipped_with_5_2.pdf",
          "5_1_with_5_2_flipped.pdf",
          "5_1_flipped_with_5_3_flipped.pdf",
          "5_4_with_5_2_flipped.pdf",
          "5_3_flipped_with_5_1_flipped.pdf",
          "5_3_flipped_with_5_4_flipped.pdf",
          "5_2_flipped_with_5_3_flipped.pdf",
          "5_2_flipped_with_5_4_flipped.pdf",
          "5_3_flipped_with_5_1.pdf",
          "5_3_with_5_1_flipped.pdf",
          "5_3_flipped_with_5_2.pdf",
          "5_4_flipped_with_5_2_flipped.pdf",
          "5_3_with_5_4_flipped.pdf",
          "5_2_flipped_with_5_1_flipped.pdf",
          "5_1_flipped_with_5_4_flipped.pdf",
          "5_3_flipped_with_5_4.pdf",
          "5_2_flipped_with_5_3.pdf",
          "5_2_with_5_1_flipped.pdf",
          "5_2_with_5_3.pdf",
          "5_3_with_5_4.pdf",
          "5_2_with_5_1.pdf",
          "5_4_flipped_with_5_1_flipped.pdf",
          "5_2_flipped_with_5_1.pdf",
          "5_4_with_5_3.pdf",
          "5_2_with_5_4.pdf",
          "5_4_with_5_2.pdf",
          "5_3_with_5_2_flipped.pdf",
          "5_3_with_5_2.pdf",
          "5_2_flipped_with_5_4.pdf",
          "5_2_with_5_4_flipped.pdf",
          "5_4_with_5_3_flipped.pdf",
          "5_1_with_5_3_flipped.pdf",
          "5_4_with_5_1.pdf",
          "5_1_flipped_with_5_2_flipped.pdf",
          "5_3_with_5_1.pdf",
        ],
        [
          "6_1_with_6_3.pdf",
          "6_2_with_6_3_flipped.pdf",
          "6_1_with_6_4_flipped.pdf",
          "6_1_with_6_2.pdf",
          "6_1_flipped_with_6_2_flipped.pdf",
          "6_1_with_6_4.pdf",
          "6_4_with_6_1_flipped.pdf",
          "6_4_flipped_with_6_1_flipped.pdf",
          "6_4_flipped_with_6_2.pdf",
          "6_1_flipped_with_6_3.pdf",
          "6_1_with_6_2_flipped.pdf",
          "6_4_flipped_with_6_2_flipped.pdf",
          "6_1_flipped_with_6_2.pdf",
          "6_4_with_6_2_flipped.pdf",
          "6_4_flipped_with_6_3.pdf",
          "6_4_flipped_with_6_1.pdf",
          "6_2_flipped_with_6_1_flipped.pdf",
          "6_1_flipped_with_6_4_flipped.pdf",
          "6_1_flipped_with_6_4.pdf",
          "6_2_flipped_with_6_4_flipped.pdf",
          "6_3_flipped_with_6_4_flipped.pdf",
          "6_2_flipped_with_6_4.pdf",
          "6_3_with_6_1_flipped.pdf",
          "6_2_flipped_with_6_3_flipped.pdf",
          "6_3_with_6_4_flipped.pdf",
          "6_2_flipped_with_6_1.pdf",
          "6_1_flipped_with_6_3_flipped.pdf",
          "6_3_flipped_with_6_1_flipped.pdf",
          "6_2_flipped_with_6_3.pdf",
          "6_2_with_6_1_flipped.pdf",
          "6_3_flipped_with_6_4.pdf",
          "6_3_flipped_with_6_2_flipped.pdf",
          "6_3_with_6_4.pdf",
          "6_2_with_6_3.pdf",
          "6_2_with_6_1.pdf",
          "6_2_with_6_4.pdf",
          "6_4_with_6_3.pdf",
          "6_3_flipped_with_6_2.pdf",
          "6_3_with_6_2_flipped.pdf",
          "6_3_with_6_2.pdf",
          "6_4_with_6_2.pdf",
          "6_4_flipped_with_6_3_flipped.pdf",
          "6_2_with_6_4_flipped.pdf",
          "6_3_flipped_with_6_1.pdf",
          "6_4_with_6_3_flipped.pdf",
          "6_3_with_6_1.pdf",
          "6_4_with_6_1.pdf",
          "6_1_with_6_3_flipped.pdf",
        ],
      ],
    };
    return data;
  },
  methods: {
    url() {
      let uri =
        "https://storage.googleapis.com/opticalgym-large-assets/pdfs/line_maze/";
      let level_pdfs = this.level_map[this.level - 1];
      uri += level_pdfs[Math.floor(Math.random() * level_pdfs.length)];
      return uri;
    },
    view: function () {
      window.open(this.url(), "_blank");
    },
    print: function () {
      let self = this;

      if (navigator.vendor.includes("Apple")) {
        window.open(this.url(), "_blank");
        return;
      }

      printJS({
        printable: self.url(),
        onLoadingStart: function () {
          self.in_flight = true;
        },
        onLoadingEnd: function () {
          self.in_flight = false;
        },
      });
    },
    close: function () {
      this.$emit("close");
    },
  },
};
</script>
