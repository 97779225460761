<template>
  <div>
    <h2 v-if="survey.title">{{ survey.title }}</h2>

    <!-- preserve whitepace and newlines -->
    <p v-if="survey.instructions" style="white-space: pre">{{ survey.instructions }}</p>

    <component :is="layout == 'table' ? 'table' : 'div'" class="vuetify-survey-items">
      <template v-for="(item, index) in survey.items">
        <component
          :is="layout == 'table' ? 'tr' : 'div'"
          v-if="item_visible(item)"
          :key="index"
          :class="item.class"
          :style="item.style"
        >
          <component :is="layout == 'table' ? 'td' : 'div'" class="vuetify-survey-item-title-instructions">
            <slot name="before-item" v-bind:item="item"></slot>
            <h3 v-if="item.title" :class="(index && layout == 'page') ? 'mt-8' : ''">
              {{ item.title }}
            </h3>

            <p v-if="item.instructions" style="white-space: pre">{{ item.instructions }}</p>
          </component>

          <component :is="layout == 'table' ? 'td' : 'div'" class="vuetify-survey-item-input">
            <v-checkbox
              v-if="item.type === 'checkbox'"
              :ref="'item_' + item.id"
              v-model="active_data[item.id]"
              :class="
                'vuetify-survey-item vuetify-survey-item-checkbox ' + item.class
              "
              :style="item.style"
              :error-messages="item_error_messages[item.id]"
              v-bind="translate_props(item.props)"
              @change="
                $emit('change', { id: item.id, value: active_data[item.id] })
              "
            ></v-checkbox>

            <div
              v-if="item.type === 'checkboxes'"
              :ref="'item_' + item.id"
              :class="
                'vuetify-survey-item vuetify-survey-item-checkboxes ' +
                item.class
              "
              :style="item.style"
            >
              <v-checkbox
                v-for="(checkbox_item, checkbox_item_idx) in item.items"
                :key="checkbox_item_idx"
                v-model="active_data[item.id]"
                :label="checkbox_item.text"
                :value="checkbox_item.value"
                :class="checkbox_item.class"
                :style="checkbox_item.style"
                v-bind="translate_props(item.props)"
                @change="
                  $emit('change', { id: item.id, value: active_data[item.id] })
                "
              ></v-checkbox>
            </div>

            <v-select
              v-if="item.type === 'select'"
              :ref="'item_' + item.id"
              v-model="active_data[item.id]"
              :class="
                'vuetify-survey-item vuetify-survey-item-select ' + item.class
              "
              :style="item.style"
              :items="item.items"
              :error-messages="item_error_messages[item.id]"
              v-bind="translate_props(item.props)"
              @change="
                $emit('change', { id: item.id, value: active_data[item.id] })
              "
            ></v-select>

            <v-radio-group
              v-if="item.type === 'radio-group'"
              :ref="'item_' + item.id"
              v-model="active_data[item.id]"
              :class="
                'vuetify-survey-item vuetify-survey-item-radio-group ' +
                item.class
              "
              :style="item.style"
              :items="item.items"
              :error-messages="item_error_messages[item.id]"
              v-bind="translate_props(item.props)"
            >
              <v-radio
                v-for="(radio_item, radio_item_idx) in item.items"
                :key="radio_item_idx"
                :label="radio_item.text"
                :value="radio_item.value"
                :class="radio_item.class"
                :style="radio_item.style"
                v-bind="translate_props(item.props)"
                @change="
                  $emit('change', { id: item.id, value: active_data[item.id] })
                "
              ></v-radio>
            </v-radio-group>

            <v-text-field
              v-if="item.type === 'text-field'"
              :ref="'item_' + item.id"
              v-model="active_data[item.id]"
              :class="
                'vuetify-survey-item vuetify-survey-item-text-field ' +
                item.class
              "
              :style="item.style"
              :error-messages="item_error_messages[item.id]"
              v-bind="translate_props(item.props)"
              @change="
                $emit('change', { id: item.id, value: active_data[item.id] })
              "
            ></v-text-field>

            <v-text-field
              v-if="item.type === 'number-field'"
              :ref="'item_' + item.id"
              v-model="active_data[item.id]"
              type="number"
              :min="item.min"
              :max="item.max"
              :step="item.step ? item.step : 'any'"
              :class="
                'vuetify-survey-item vuetify-survey-item-number-field ' +
                item.class
              "
              :style="item.style"
              :size="number_field_size(item)"
              :error-messages="item_error_messages[item.id]"
              v-bind="translate_props(item.props)"
              @change="
                $emit('change', { id: item.id, value: active_data[item.id] })
              "
            ></v-text-field>

            <v-textarea
              v-if="item.type === 'textarea'"
              :ref="'item_' + item.id"
              v-model="active_data[item.id]"
              :class="
                'vuetify-survey-item vuetify-survey-item-textarea ' + item.class
              "
              :style="item.style"
              :error-messages="item_error_messages[item.id]"
              v-bind="translate_props(item.props)"
              @change="
                $emit('change', { id: item.id, value: active_data[item.id] })
              "
            ></v-textarea>

            <v-switch
              v-if="item.type === 'switch'"
              :ref="'item_' + item.id"
              v-model="active_data[item.id]"
              :class="
                'vuetify-survey-item vuetify-survey-item-switch ' + item.class
              "
              :style="item.style"
              :error-messages="item_error_messages[item.id]"
              v-bind="translate_props(item.props)"
              @change="
                $emit('change', { id: item.id, value: active_data[item.id] })
              "
            ></v-switch>

            <v-rating
              v-if="item.type === 'rating'"
              :ref="'item_' + item.id"
              v-model="active_data[item.id]"
              :class="
                'vuetify-survey-item vuetify-survey-item-rating ' + item.class
              "
              :style="item.style"
              :error-messages="item_error_messages[item.id]"
              :background-color="item.props.background_color || 'grey darken-1'"
              :full-icon="item.rating_icon || 'mdi-star'"
              :empty-icon="
                item.rating_icon
                  ? item.rating_icon + '-outline'
                  : 'mdi-star-outline'
              "
              v-bind="translate_props(item.props)"
              @input="
                $emit('change', { id: item.id, value: active_data[item.id] })
              "
            ></v-rating>

            <v-rating
              v-if="item.type === 'mood'"
              :ref="'item_' + item.id"
              v-model="active_data[item.id]"
              :class="
                'vuetify-survey-item vuetify-survey-item-mood ' + item.class
              "
              :style="item.style"
              :error-messages="item_error_messages[item.id]"
              v-bind="translate_props(item.props)"
              @input="
                $emit('change', { id: item.id, value: active_data[item.id] })
              "
            >
              <template v-slot:item="props">
                <v-icon
                  v-if="props.index == 0"
                  :color="props.color || 'primary'"
                  @click="props.click"
                  :size="props.size || 32"
                  >{{
                    props.value === 1 || props.isHovered
                      ? "mdi-emoticon-sad"
                      : "mdi-emoticon-sad-outline"
                  }}</v-icon
                >
                <v-icon
                  v-if="props.index == 1"
                  :color="props.color || 'primary'"
                  @click="props.click"
                  :size="props.size || 32"
                  >{{
                    props.value === 2 || props.isHovered
                      ? "mdi-emoticon-confused"
                      : "mdi-emoticon-confused-outline"
                  }}</v-icon
                >
                <v-icon
                  v-if="props.index == 2"
                  :color="props.color || 'primary'"
                  @click="props.click"
                  :size="props.size || 32"
                  >{{
                    props.value === 3 || props.isHovered
                      ? "mdi-emoticon-neutral"
                      : "mdi-emoticon-neutral-outline"
                  }}</v-icon
                >
                <v-icon
                  v-if="props.index == 3"
                  :color="props.color || 'primary'"
                  @click="props.click"
                  :size="props.size || 32"
                  >{{
                    props.value === 4 || props.isHovered
                      ? "mdi-emoticon-happy"
                      : "mdi-emoticon-happy-outline"
                  }}</v-icon
                >
                <v-icon
                  v-if="props.index == 4"
                  :color="props.color || 'primary'"
                  @click="props.click"
                  :size="props.size || 32"
                  >{{
                    props.value === 5 || props.isHovered
                      ? "mdi-emoticon"
                      : "mdi-emoticon-outline"
                  }}</v-icon
                >
              </template>
            </v-rating>

            <v-menu
              v-if="item.type == 'date'"
              :ref="'item_' + item.id"
              v-model="item_menus[item.id]"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              :class="
                'vuetify-survey-item vuetify-survey-item-date ' + item.class
              "
              :style="item.style"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :ref="'item_' + item.id"
                  v-model="active_data[item.id]"
                  prepend-icon="mdi-calendar"
                  readonly
                  :error-messages="item_error_messages[item.id]"
                  v-bind="attrs"
                  v-on="on"
                  @change="
                    $emit('change', {
                      id: item.id,
                      value: active_data[item.id],
                    })
                  "
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="active_data[item.id]"
                @input="item_menus[item.id] = false"
              ></v-date-picker>
            </v-menu>

            <v-menu
              v-if="item.type == 'birthday'"
              v-model="item_menus[item.id]"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              :class="
                'vuetify-survey-item vuetify-survey-item-birthday ' + item.class
              "
              :style="item.style"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :ref="'item_' + item.id"
                  v-model="active_data[item.id]"
                  :error-messages="item_error_messages[item.id]"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @change="
                    $emit('change', {
                      id: item.id,
                      value: active_data[item.id],
                    })
                  "
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="active_data[item.id]"
                :active-picker.sync="item_date_pickers[item.id]"
                :max="
                  new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                    .toISOString()
                    .substr(0, 10)
                "
                min="1900-01-01"
                v-bind="translate_props(item.props)"
                @change="close_menu(item.id)"
              ></v-date-picker>
            </v-menu>

            <slot name="before-after" v-bind:item="item"></slot>
          </component>
        </component>
      </template>
    </component>

    <v-alert
      v-if="error_message"
      outlined
      type="error"
      elevation="2"
      class="mt-6"
      >{{ error_message }}</v-alert
    >
  </div>
</template>

<script>
// eslint-disable-next-line
import Interpreter from "../interpreter.js";
import * as acorn from "acorn";
import Vue from "vue";

export default {
  name: "VuetifySurvey",
  components: {},
  props: {
    survey: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      required: false,
      default: () => {},
    },
    layout: {
      type: String,
      required: false,
      default: "page", // Can be "page" or "table"
    },
  },
  data() {
    let default_data = this.get_default_data();

    // Assign all key => value from this.value to default_data array
    // Don't assign if it's not valid for the type
    if (this.value) {
      for (let key in this.value) {
        if (key in default_data && this.validate_value_for_type(this.value[key], this.survey.items.find(item => item.id == key))) {
          Vue.set(default_data, key, this.value[key]);
        }
      }
    }

    let data = {
      item_menus: {},
      item_date_pickers: {},
      error_message: "",
      item_error_messages: {},
      active_data: default_data,
    };

    return data;
  },
  watch: {
    survey: {
      handler: function (survey) {
        let default_data = this.get_default_data();

        survey.items.forEach((item) => {
          let item_id = item.id;
          if (!(item_id in this.active_data)) {
            if (!default_data) default_data = this.get_default_data();
            Vue.set(this.active_data, item_id, default_data[item_id]);
          }

          // Check to see if new definition renders item value invalid
          if (this.active_data.hasOwnProperty(item_id) && !this.validate_value_for_type(this.active_data[item_id], item)) {
            if (!default_data) default_data = this.get_default_data();
            Vue.set(this.active_data, item_id, default_data[item_id]);
          }
        });
      },
      deep: true,
    },
    active_data: {
      handler: function (newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
    item_menus: {
      handler: function () {
        for (let key in this.item_menus) {
          if (this.item_menus[key]) {
            setTimeout(() => Vue.set(this.item_date_pickers, key, "YEAR"));
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    validate() {
      this.error_message = "";
      this.item_error_messages = {};
      if (!this.survey || !this.survey.items) {
        return true;
      }
      for (let item of this.survey.items) {
        if (item.required) {
          if (
            !this.active_data[item.id] ||
            (this.active_data[item.id].length &&
              this.active_data[item.id].length == 0)
          ) {
            this.error_message =
              "Please provide an answer for '" + item.title + "'";
            if (
              this.$refs["item_" + item.id] &&
              this.$refs["item_" + item.id].focus
            ) {
              this.$refs["item_" + item.id].focus();
            }
            Vue.set(
              this.item_error_messages,
              item.id,
              "Please provide an answer"
            );
            return false;
          }
        }
        if (item.min !== null && item.min !== undefined) {
          if (
            this.active_data[item.id] !== null &&
            this.active_data[item.id] !== undefined &&
            this.active_data[item.id] < item.min
          ) {
            if (
              this.$refs["item_" + item.id] &&
              this.$refs["item_" + item.id].focus
            ) {
              this.$refs["item_" + item.id].focus();
            }
            this.error_message = "Invalid answer for '" + item.title + "'";
            Vue.set(this.item_error_messages, item.id, "Invalid answer");
            return false;
          }
        }
        if (item.max !== null && item.max !== undefined) {
          if (
            this.active_data[item.id] !== null &&
            this.active_data[item.id] !== undefined &&
            this.active_data[item.id] > item.max
          ) {
            if (
              this.$refs["item_" + item.id] &&
              this.$refs["item_" + item.id].focus
            ) {
              this.$refs["item_" + item.id].focus();
            }
            this.error_message = "Invalid answer for '" + item.title + "'";
            Vue.set(this.item_error_messages, item.id, "Invalid answer");
            return false;
          }
        }
        if (item.maxlength) {
          if (
            this.active_data[item.id] &&
            this.active_data[item.id].length &&
            this.active_data[item.id].length > item.maxlength
          ) {
            if (
              this.$refs["item_" + item.id] &&
              this.$refs["item_" + item.id].focus
            ) {
              this.$refs["item_" + item.id].focus();
            }
            this.error_message = "Invalid answer for '" + item.title + "'";
            Vue.set(this.item_error_messages, item.id, "Invalid answer");
            return false;
          }
        }
        if (item.integers_only) {
          if (
            this.active_data[item.id] !== null &&
            this.active_data[item.id] !== undefined &&
            !Number.isInteger(this.active_data[item.id])
          ) {
            if (
              this.$refs["item_" + item.id] &&
              this.$refs["item_" + item.id].focus
            ) {
              this.$refs["item_" + item.id].focus();
            }
            this.error_message = "Invalid answer for '" + item.title + "'";
            Vue.set(this.item_error_messages, item.id, "Invalid answer");
            return false;
          }
        }
      }

      return true;
    },

    validate_value_for_type(value, item) {
      if (!item) {
        return true;
      }

      if (item.type == "date") {
        return value.match(/^\d{4}-\d{2}-\d{2}$/);
      }
      if (item.type == "birthday") {
        return value.match(/^\d{4}-\d{2}-\d{2}$/);
      }
      if (item.type == "number-field") {
        // If it's a string, check using regex if it's numeric
        if (typeof value === "string") {
          return value.match(/^-?\d*\.?\d*$/);
        }
      }
      if (item.type == "mood") {
        // Needs to be an integer between 1 and 5
        return Number.isInteger(value) && value >= 1 && value <= 5;
      }
      if (item.type == "rating") {
        return Number.isInteger(value);
      }
      return true;
    },
    get_default_data() {
      let default_data = {};
      if (!this.survey.items) {
        return default_data;
      }
      this.survey.items.forEach((item) => {
        if (item.default_value) {
          Vue.set(default_data, item.id, item.default_value);
        } else {
          if (item.type === "checkboxes") {
            Vue.set(default_data, item.id, []);
          } else {
            Vue.set(default_data, item.id, null);
          }
        }
      });

      return default_data;
    },
    item_visible(item) {
      if (item.visible === true) {
        return true;
      }
      if (item.visible === false) {
        return false;
      }
      if (typeof item.visible === "string") {
        let parsed_code = acorn.parse(item.visible, {
          ecmaVersion: 2020,
        });

        let interpreter = new Interpreter(parsed_code, (int, glb) => {
          // For each key => value in data, set it in the global scope
          for (let key in this.active_data) {
            let value = int.nativeToPseudo(this.active_data[key]);
            int.setProperty(glb, key, value);
          }
        });

        interpreter.run();

        let result = interpreter.value;

        if (typeof result === "object") {
          if (
            result.class == "Array" &&
            result.properties &&
            result.properties.length == 0
          ) {
            return false;
          }
          return true;
        }

        return result;
      }
      return true;
    },
    translate_props(props) {
      // Translate all snake_case keys to kebab-case keys in the passed object
      let new_props = {};
      for (let key in props) {
        new_props[key.replace(/_/g, "-")] = props[key];
      }
      return new_props;
    },
    number_field_size(item) {
      if (!item.integer_only) {
        return 20;
      }
      if (
        item.min &&
        typeof item.min == "number" &&
        item.max &&
        typeof item.max == "number"
      ) {
        return (
          Math.max(Math.abs(item.min), Math.abs(item.max)).toString().length + 2
        );
      }
      if (item.min && typeof item.min == "number") {
        return Math.abs(item.min).toString().length + 2;
      }
      if (item.max && typeof item.max == "number") {
        return Math.abs(item.max).toString().length + 2;
      }
      return 20;
    },
    close_menu(item_id) {
      Vue.set(this.item_menus, item_id, false);
    },
  },
};
</script>

<style scoped></style>
