export interface AppConfig {
    id: string,
    default_billing_strategy: string
    free_trial_length: number
    stripe_public_key: string,
    title: string,
    subscription_first: boolean,
    regimen_progress_expires: string, // "midnight" or "indefinite"
}

// global window properties
declare global {
    interface Window {
        app_config: AppConfig,
    }
}

export const default_app_config: AppConfig = {
    id: "nvt",
    default_billing_strategy: "clinic",
    free_trial_length: 62,
    stripe_public_key: "",
    title: "NeuroVisual Trainer",
    subscription_first: false,
    regimen_progress_expires: "midnight",
}

// Set global window.app_config
// @ts-ignore
import app_config from "../../../data/app_config.yml";
if (app_config[process.env.VUE_APP_OPTICALGYM_ID]) {
    window.app_config = app_config[process.env.VUE_APP_OPTICALGYM_ID];
}
else {
    window.app_config = default_app_config;
}

const current_app_config = window.app_config;
export default current_app_config;

// Set-up axios to send app-id on every request
import axios from "axios";
axios.interceptors.request.use(function (config) {
    config.headers["X-Opticalgym-App"] = window.app_config.id;
    return config;
});