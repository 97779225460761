<template>
  <v-card>
    <v-card-title style="padding: 0px;">
      <v-toolbar grey flat>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("common.contact_us") }}</v-toolbar-title>
      </v-toolbar>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-alert v-if="$i18n.lang() != 'en'" type="info" style="margin-top: 10px">
        <p style="margin-bottom: -3px">{{ $t('contact_us.english_only_support_message') }}</p>
      </v-alert>
      <ContactInfo :email="email" />
    </v-card-text>
  </v-card>
</template>

<script>
import ContactInfo from "./ContactInfo";

export default {
  components: { ContactInfo },
  props: {
    email: {
      type: String,
      default: "info@ocudigital.com"
    }
  },
  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>