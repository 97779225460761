export default class IPCountry {
    code: string;
    is_in_european_union: boolean;
    names: Record<string, string>;

    static fromJson(data: Object): IPCountry {
        let obj = new IPCountry();
        Object.assign(obj, data);
        return obj;
    }

    constructor() {
        this.code = 'XX';
    }
};