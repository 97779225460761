import hartchart from "./components/ToolHartChart.vue";
import trackletters from "./components/ToolTrackLetters.vue";
import racetracks from "./components/ToolRacetracks.vue";
import metronome from "./components/ToolMetronome.vue";

export default {
  hartchart,
  trackletters,
  racetracks,
  metronome
};
