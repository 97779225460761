
// V1
import phoria from "./v1/src/phoria/phoria.score.vue";
import directional_response from "./v1/src/directional_response/directional_response.score.vue";
import motion_detection from "./v1/src/motion_detection/motion_detection.score.vue";
import react from "./v1/src/react/react.score.vue";


export default {
  directional_response: directional_response,
  associated_phoria: phoria, // use phoria
  motion_detection,
  phoria,
  react,
};
