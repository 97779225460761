<template>
  <v-card>
    <v-card-text>
      <v-container>
        <v-text-field v-model="localValue.title" :label="$t('common.title')" type="text" />

        <p>{{ $t("common.instructions") }}</p>
        <quillEditor
          v-model="localValue.instructions"
          :options="quill_config"
          style="height: 200px; margin-bottom: 20px"
        />
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" @click="save">{{ $t("common.save") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

import { quillEditor } from "vue-quill-editor";

export default {
  components: { quillEditor },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    let data = {
      quill_config: {
        placeholder: this.$t("common.instructions"),
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],

            ["clean"], // remove formatting button
          ],
        },
      },
    };
    return data;
  },
  computed: {
    localValue: {
      get() {
        let value = this.value;
        value.default = value.default || {};
        return value;
      },
      set(localValue) {
        if (!localValue.default) {
          localValue.default = {};
        }
        this.$emit("input", localValue);
      },
    },
  },
  mounted() {},
  methods: {
    save() {
      this.$emit("save");
    },
  },
};
</script>


<style>
#invite_edit_modal .v-messages__message {
  line-height: normal;
}

.ql-editor {
  font-size: large;
}

.theme--dark .ql-snow .ql-stroke {
  stroke: gray;
}

.theme--light .ql-editor p {
  color: black;
}
</style>
