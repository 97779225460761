<template>
  <v-card id="invite_edit_modal">
    <v-toolbar>
      <v-btn icon @click="close">
        <v-icon>close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span>{{ user.name_given }} {{ user.name_family }}</span>
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <div style="margin-top: 20px">
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr>
                <td>{{ $t("invite_active_edit.name") }}</td>
                <td>{{ user.name_given }} {{ user.name_family }}</td>
                <td></td>
              </tr>
              <tr>
                <td>{{ $t("common.email") }}</td>
                <td>{{ user.email }}</td>
                <td>
                  <v-btn small @click="update_email_dialog = true">
                    <v-icon small left>edit</v-icon>
                    {{ $t("invite_active_edit.update_email") }}
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td>{{ $t("invite_active_edit.invited_on") }}</td>
                <td>{{ invite_created_date }}</td>
                <td></td>
              </tr>
              <tr>
                <td>{{ $t("invite_active_edit.expires") }}</td>
                <td>{{ invite_expires }}</td>
                <td>
                  <v-btn
                    v-if="active_invite"
                    small
                    @click="date_picker_dialog = true"
                  >
                    <v-icon small left>edit</v-icon>
                    <span v-if="!active_invite.expiry_date">{{
                      $t("invite_active_edit.set_expiry_date")
                    }}</span>
                    <span v-else>{{
                      $t("invite_active_edit.update_expiry_date")
                    }}</span>
                  </v-btn>
                </td>
              </tr>
              <tr>
                <td>{{ $t("invite_active_edit.compatibility_mode") }}</td>
                <td>
                  <v-select
                    v-model="compat_mode"
                    :items="on_off_options"
                    dense
                    solo
                    style="margin-top: 10px; margin-bottom: -10px"
                    @change="change_compat_mode"
                  />
                </td>
                <td>{{ $t("invite_active_edit.compatibility_mode_desc") }}</td>
              </tr>
              <tr>
                <td>{{ $t("invite_active_edit.billing") }}</td>
                <td>
                  <v-select
                    v-model="billing_strategy"
                    :items="billingStrategyOptions"
                    dense
                    solo
                    style="margin-top: 10px; margin-bottom: -10px"
                    @change="billing_strategy_updated"
                  >
                    <template v-slot:item="{ item, index }">
                      <v-list-item-content>
                        <v-list-item-title v-text="item.text" />
                        <v-list-item-subtitle v-if="item.subtext">
                          {{ item.subtext }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </td>
                <td class="pt-2">
                  {{ $t("invite_active_edit.billing_desc") }}
                </td>
              </tr>
              <tr
                v-if="
                  clinic_user_info &&
                  clinic_user_info_survey &&
                  clinic_user_info_survey.items
                "
              >
                <td>{{ $t("invite_active_edit.user_info") }}</td>
                <td>
                  <table>
                    <tr
                      v-for="item in clinic_user_info_survey.items"
                      :key="item.id"
                    >
                      <td class="pr-3">{{ item.title }}</td>
                      <td>{{ clinic_user_info[item.id] }}</td>
                    </tr>
                  </table>
                </td>
                <td>
                  <v-btn small @click="clinic_user_info_dialog = true">
                    <v-icon small left>edit</v-icon>
                    {{ $t("invite_active_edit.update_user_info") }}
                  </v-btn>

                  <v-dialog v-model="clinic_user_info_dialog" max-width="800">
                    <v-card v-if="clinic_user_info_dialog">
                      <v-toolbar>
                        <v-btn icon @click="clinic_user_info_dialog = false">
                          <v-icon>close</v-icon>
                        </v-btn>
                        <v-toolbar-title>
                          <span>{{
                            $t("invite_active_edit.update_user_info")
                          }}</span>
                        </v-toolbar-title>
                      </v-toolbar>
                      <v-card-text class="mt-6">
                        <VuetifySurvey
                          v-model="clinic_user_info"
                          :survey="clinic_user_info_survey"
                          layout="table"
                        />
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer />
                        <v-btn color="primary" @click="change_clinic_user_info()">{{ $t("invite_active_edit.update_user_info_button") }}</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-card-text>

    <v-card-actions>
      <v-btn @click="archive_user">
        <v-icon small left>archive</v-icon>
        {{ $t("common.archive") }}
      </v-btn>

      <v-dialog v-model="password_reset_dialog" max-width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" style="margin-left: 10px" v-on="on">
            <v-icon small left>cached</v-icon>
            {{ $t("common.reset_password") }}
          </v-btn>
        </template>
        <v-card v-if="password_reset_dialog">
          <v-card-title class="headline">{{
            $t("invite_active_edit.send_password_reset_email")
          }}</v-card-title>
          <v-card-text>{{
            $t("invite_active_edit.password_reset_description")
          }}</v-card-text>
          <v-card-actions>
            <v-select
              v-model="lang"
              style="width: 50px"
              :items="languageOptions"
              :label="$t('common.language')"
              type="text"
            />
            <v-spacer></v-spacer>
            <v-btn text @click="password_reset_dialog = false">{{
              $t("common.cancel")
            }}</v-btn>
            <v-btn
              color="primary"
              @click="
                password_reset_dialog = false;
                user_reset_password();
              "
              >{{ $t("common.ok") }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-spacer />
      <v-btn color="primary" :diabled="in_flight" @click="close">{{
        $t("invite_active_edit.close")
      }}</v-btn>
    </v-card-actions>
    <v-progress-linear v-if="in_flight" :indeterminate="true" />

    <v-dialog v-model="date_picker_dialog" max-width="350">
      <v-card v-if="date_picker_dialog" id="invite_edit_modal">
        <v-toolbar>
          <v-btn icon @click="date_picker_dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <span>{{ $t("invite_active_edit.choose_expiry_date") }}</span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-banner style="margin-top: 10px">
            <p>{{ $t("invite_active_edit.choose_expiry_date_text") }}</p>
          </v-banner>
          <v-date-picker
            v-model="expiry_date"
            :locale="$i18n.lang()"
            :min="allowed_min_date"
            @click:date="
              date_updated();
              date_picker_dialog = false;
            "
          ></v-date-picker>

          <v-btn
            v-if="expiry_date"
            small
            style="margin-top: 20px; margin-left: 75px"
            @click="remove_date"
            >{{ $t("invite_active_edit.remove_expiry") }}</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="update_email_dialog" max-width="800">
      <v-card v-if="update_email_dialog" id="update_email_dialog_card">
        <v-toolbar>
          <v-btn icon @click="update_email_dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <span>{{ $t("invite_active_edit.update_email") }}</span>
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text style="padding-top: 60px; padding-bottom: 60px">
          <EmailField v-model="update_email" />
        </v-card-text>
        <v-card-actions>
          <v-alert
            v-if="email_error_message != ''"
            outlined
            :value="email_error_message != ''"
            type="error"
            >{{ email_error_message }}</v-alert
          >
          <v-spacer></v-spacer>
          <v-btn text @click="update_email_dialog = false">{{
            $t("common.cancel")
          }}</v-btn>
          <v-btn color="primary" @click="user_update_email()">{{
            $t("common.ok")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "axios";
import OpticalGymUI from "../lib/OpticalGymUI";
import EmailField from "./EmailField";
import Invite from "../classes/invite";
import Vue from "vue";
import { mapState } from "vuex";
import VuetifySurvey from "./vuetify-survey/components/VuetifySurvey.vue";

export default {
  components: { EmailField, VuetifySurvey },
  props: {
    invite_id: {
      type: String,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    clinic: {
      type: Object,
      required: true,
    },
    allow_test_users: {
      type: Boolean,
      default: true,
    },
    allow_edit_billing_strategy: {
      type: Boolean,
      default: true,
    },
    clinic_user_info_survey: {
      type: Object,
      default: null,
    },
  },

  data() {
    let data = {
      active_invite: null,
      in_flight: false,
      password_reset_dialog: false,
      update_email_dialog: false,
      email_error_message: "",
      expiry_date: "",
      update_email: "",
      date_picker_dialog: false,
      languageOptions: [
        { value: "en", text: "English" },
        { value: "es", text: "Español" },
      ],
      lang: this.$i18n.lang() == "fr" ? "en" : this.$i18n.lang(), // TODO: Remove this logic when french is ready,
      user_metadata: [], // All metadata or this user / clinic combination
      compat_mode: false,
      billing_strategy: this.user.billing_strategy,
      on_off_options: [
        { text: this.$t("common.off"), value: false },
        { text: this.$t("common.on"), value: true },
      ],
      clinic_user_info_dialog: false,
      clinic_user_info: null,
    };
    return data;
  },
  computed: {
    ...mapState(["app_config"]),
    invite_created_date: function () {
      if (!this.active_invite) {
        return "";
      }
      var date = new Date(this.active_invite.created + "Z");
      return date.toLocaleDateString(this.$i18n.lang(), {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    invite_expires: function () {
      if (!this.active_invite) {
        return "";
      }
      if (!this.active_invite.expiry_date) {
        return this.$t("invite_active_edit.no_expiry_set");
      }

      var date = new Date(this.active_invite.expiry_date + "Z");
      return date.toLocaleDateString(this.$i18n.lang(), {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    allowed_min_date: function () {
      let today = new Date();
      return today.toISOString();
    },
    billingStrategyOptions() {
      let options;
      if (!this.allow_edit_billing_strategy) {
        return [];
      }
      if (this.clinic.billing_strategy == "clinic") {
        options = [
          {
            value: null,
            text: `${this.$t("common.default")} (${this.$t(
              "common.clinic_pays"
            )})`,
          },
          {
            value: "user",
            text: this.$t("common.user_pays"),
          },
        ];
      } else if (this.clinic.billing_strategy == "user") {
        options = [
          {
            value: null,
            text: `${this.$t("common.default")} (${this.$t(
              "common.user_pays"
            )})`,
          },
        ];
        let opt = {
          value: "clinic",
          text: this.$t("common.clinic_pays"),
        };
        if (!this.clinic.stripe_subscription_id) {
          opt.disabled = true;
          opt.subtext = this.$t("invite_edit.clinic_pays_disabled");
        }
        options.push(opt);
      } else {
        return [];
      }

      if (this.allow_test_users) {
        options.push({ value: "free", text: this.$t("common.test_user_free") });
      }

      return options;
    },
  },
  watch: {},
  methods: {
    remove_date: function () {
      this.load_invite(() => {
        this.active_invite.expiry_date = null;
        this.expiry_date = "";
        this.update_invite();
        this.date_picker_dialog = false;
      });
    },

    update_clinic_user_info() {
      let metadata = this.user_metadata.filter(
        (m) => m.category == "clinic_user_info"
      );

      if (metadata.length != 0) {
        this.clinic_user_info = metadata[0].data;
      }
    },
    date_updated: function () {
      let date = new Date(this.expiry_date);
      date.setHours(22); // They expire at hour 22 of the expiry date
      let utc_datetime = new Date(
        date.toLocaleString("en-US", {
          timeZone: "UTC",
        })
      );
      this.load_invite(() => {
        this.active_invite.expiry_date = utc_datetime
          .toISOString()
          .slice(0, 19);
        this.update_invite();
      });
    },
    billing_strategy_updated(newval) {
      this.load_invite(() => {
        Vue.set(this.active_invite, "billing_strategy", newval);
        this.update_invite();
      });
    },
    load_metadata() {
      // Load metadata associated with this user / clinic
      axios
        .get(
          "/api/user/" +
            this.active_invite.user_id +
            "/metadata/*?clinic_id=" +
            this.active_invite.clinic_id
        )
        .then((response) => {
          let resp = response.data;
          if (resp.status == "error") {
            messageBus.$emit("api-error", resp);
          } else {
            this.user_metadata = resp.data;
            this.update_compat_mode();
            this.update_clinic_user_info();
          }
        });
    },
    update_compat_mode() {
      this.compat_mode = false;
      for (let metadata of this.user_metadata) {
        if (metadata.category == "compat") {
          if (metadata.data === true) {
            this.compat_mode = true;
          }
        }
      }
    },
    change_compat_mode(new_value) {
      this.in_flight = true;

      if (new_value) {
        let metadata = {
          category: "compat",
          data: true,
          user_id: this.user.id,
          clinic_id: this.clinic.id,
        };
        axios.post("/api/user_metadata", metadata).then((response) => {
          this.in_flight = false;
          let resp = response.data;
          if (resp.status == "error") {
            messageBus.$emit("api-error", resp);
          } else {
            messageBus.$emit("success", "Updated");
            this.load_metadata();
          }
        });
      } else {
        for (let metadata of this.user_metadata) {
          if (metadata.category == "compat") {
            axios
              .delete("/api/user_metadata/" + metadata.id)
              .then((response) => {
                this.in_flight = false;
                let resp = response.data;
                if (resp.status == "error") {
                  messageBus.$emit("api-error", resp);
                } else {
                  messageBus.$emit("success", "Updated");
                  this.load_metadata();
                }
              });
          }
        }
      }
    },
    change_clinic_user_info() {
      this.in_flight = true;
      this.clinic_user_info_dialog = false;

      // Find the metadata entity for this user / clinic
      let user_metadata = this.user_metadata.filter(
        (m) => m.category == "clinic_user_info"
      );
      
      if (user_metadata.length == 0) {
        let metadata = {
          category: "clinic_user_info",
          data: this.clinic_user_info,
          user_id: this.user.id,
          clinic_id: this.clinic.id,
        };
        axios.post("/api/user_metadata", metadata).then((response) => {
          this.in_flight = false;
          let resp = response.data;
          if (resp.status == "error") {
            messageBus.$emit("api-error", resp);
          } else {
            messageBus.$emit("success", "Updated");
            this.load_metadata();
          }
        });
      } else {
        let metadata = user_metadata[0];
        metadata.data = this.clinic_user_info;
        axios
          .put("/api/user_metadata/" + metadata.id, metadata)
          .then((response) => {
            this.in_flight = false;
            let resp = response.data;
            if (resp.status == "error") {
              messageBus.$emit("api-error", resp);
            } else {
              messageBus.$emit("success", "Updated");
              this.load_metadata();
            }
          });
      }
    },
    load_invite(callback = null) {
      this.in_flight = true;
      axios.get("/api/invite/" + this.invite_id).then((response) => {
        this.in_flight = false;
        let resp = response.data;
        if (resp.status == "error") {
          messageBus.$emit("api-error", resp);
        } else {
          this.active_invite = Invite.fromJson(resp.data);

          if (this.active_invite.expiry_date) {
            let expiry_date = new Date(this.active_invite.expiry_date + "Z");
            this.expiry_date = expiry_date.toISOString().slice(0, 10);
          }

          this.load_metadata();

          if (callback) {
            callback();
          }
        }
      });
    },
    update_invite() {
      this.in_flight = true;
      axios
        .put("/api/invite/" + this.invite_id, this.active_invite)
        .then((response) => {
          this.in_flight = false;
          let resp = response.data;
          if (resp.status == "error") {
            if (resp.error_code == "TooManyMockInvites") {
              this.load_invite(() => {
                this.billing_strategy = this.active_invite.billing_strategy;
              });
              messageBus.$emit(
                "error",
                this.$t("invite_active_edit.too_many_mock_invites")
              );
            } else {
              messageBus.$emit("api-error", resp);
            }
          } else {
            messageBus.$emit("success", "Updated");
            this.$emit("invite_updated");
          }
        });
    },
    user_update_email() {
      this.email_error_message = false;
      if (!OpticalGymUI.validate_email(this.update_email)) {
        this.email_error_message = this.$t("common.invalid_email_address");
        return false;
      }

      OpticalGymUI.check_email(this.update_email, (check_result) => {
        if (!check_result.valid) {
          this.email_error_message = check_result.email_rules.join(", ");
          return false;
        }
      });

      // Load the user, set the email,and update the user
      let self = this;
      axios.get("/api/user/" + self.user.id).then(function (response) {
        let resp = response.data;
        if (resp.status == "error") {
          messageBus.$emit("api-error", resp);
          return;
        }
        let update_user = resp.data;
        update_user.email = self.update_email;
        axios
          .put("/api/user/" + update_user.id, update_user)
          .then(function (response) {
            let resp = response.data;
            if (resp.status == "error") {
              if (resp.error_code == "InviteUserExists") {
                self.email_error_message =
                  "A user already exists with this email. Please contact us to help resolve the situation.";
              } else if (
                response.status == 403 ||
                resp.error_code == "PermissionDenied"
              ) {
                self.email_error_message =
                  "Permission Denied. The most likely cause of this error is that this user belongs to multiple clinics, at least one of which you do you not belong to. Please contact us to help resolve this situation.";
              } else {
                messageBus.$emit("api-error", resp);
              }
            } else {
              self.$emit("user_updated", update_user);
              messageBus.$emit("success", "Updated");
              self.update_email_dialog = false;
            }
          });
      });
    },
    user_reset_password() {
      let self = this;
      axios
        .post(
          "/api/user/lost_password/" + this.user.email + "?lang=" + this.lang
        )
        .then(function (response) {
          let resp = response.data;
          if (resp.status == "success") {
            messageBus.$emit(
              "success",
              self.$t("invite_active_edit.password_reset_email_sent")
            );
          } else {
            messageBus.$emit("api-error", resp);
          }
        });
    },
    close: function () {
      this.$emit("close");
    },
    archive_user() {
      this.$emit("archive_user");
      this.$emit("close");
    },
  },
};
</script>

<style>
table.vuetify-survey-items {
  width: 100%;
  border-collapse: collapse;
}

table.vuetify-survey-items td {
  vertical-align: middle;
}

table.vuetify-survey-items tr {
  border-bottom: 1px solid grey;
}

.vuetify-survey-item-title-instructions h3 {
  margin-top: 0px;
}
</style>
