<template>
  <div :class="'assessment' + ' ' + 'assessment-' + assessment_id">
    <div
      v-if="
        current_clinic && current_user && loaded_assessment_grid && regimen && current_progress_reports
      "
    >
      <sub-regimen
        :show_title="show_title"
        :title="loaded_assessment_grid.title"
        :regimen_override="regimen"
        :exercises="exercises"
        :video_exercises="video_exercises"
        :user="current_user"
        :clinic="current_clinic"
        :invite_id="invite_id"
        :report_tags="[
          'assessment',
          'assessment_exercise',
          'assessment_id(&quot;' + assessment_id + '&quot;)',
          'assessment_instance_id(&quot;' + assessment_instance_id + '&quot;)',
        ]"
        ephemeral
        @exercise_launched="exercise_launched"
        @exercise_finished="exercise_finished"
        @regimen_complete="regimen_complete"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
//import Regimen from "./Regimen.vue";
import axios from "axios";
import uuid from "uuid";

// TODO: Build out an assessment configurator.

export default {
  name: "Assessment",
  components: {
    // We import it asynchronously so that we can use Regimen recursively
    "sub-regimen": () => import('./Regimen.vue') ,
  },
  props: {
    invite_id: {
      type: String,
      required: true,
    },
    assessment_id: {
      type: String,
      required: true,
    },
    show_title: {
      type: Boolean,
      default: () => true,
      required: false,
    },
    assessment_grid: {
      type: Object,
      required: false,
      default: () => null,
    }
  },
  data() {
    const date = new Date();
    return {
      loaded_assessment_grid: this.assessment_grid,
      invite: null,
      assessment_instance_id: uuid.v4(),
      backend_reports: [],
      exercise_result: [],
      start_time: date.getTime(),
      events: {
        assessment_start: [
          {
            timestamp: date.getTime(),
            event_type: "assessment_start",
            assessment_id: this.assessment_id,
            assessment_instance_id: this.assessment_instance_id,
          }
        ],
        exercise_launched: [],
        exercise_finished: [],
        assessment_complete: [],
      },
    };
  },
  computed: {
    ...mapState([
      "app_config",
      "current_user",
      "current_clinic",
      "user_regimens",
      "current_clinic_id",
      "exercises",
      "video_exercises",
      "current_progress_reports"
    ]),
    user_regimen() {
      for (let user_regimen of this.user_regimens) {
        if (user_regimen.clinic_id == this.current_clinic_id) {
          return user_regimen;
        }
      }
      return null;
    },
    assessment() {
      if (!this.app_config.assessments) return null;
      return this.app_config.assessments[this.assessment_id];
    },
    regimen() {
      if (!this.loaded_assessment_grid) {
        return null;
      }

      let regimen = {
        id: uuid.NIL,
        invite_id: this.invite_id,
        clinid_id: this.clinid_id,
        instructions: this.assessment.instructions,
        exercises: this.loaded_assessment_grid.exercises,
        assignment: "manual",
      };

      return regimen;
    },
  },
  mounted() {
    if (!this.assessment_grid) {
      this.loadAssessmentGrid();
    }
  },
  methods: {
    exercise_launched(event_data) {
      const date = new Date();
      let event = {
        timestamp: date.getTime(),
        event_type: "exercise_launched",
        exercise_index: event_data.index,
        exercise_type: event_data.instance.exercise_type,
        exercise_id: event_data.instance.exercise_id,
        uuid: event_data.instance.uuid
      }

      this.events["exercise_launched"].push(event);
    },
    exercise_finished(event_data) {
      const date = new Date();
      let event = {
        timestamp: date.getTime(),
        event_type: "exercise_finished",
        exercise_index: event_data.index,
        exercise_type: event_data.instance.exercise_type,
        exercise_id: event_data.instance.exercise_id,
        uuid: event_data.instance.uuid,
        report_id: event_data.backend_report.id,
        score: event_data.backend_report.score,
      }

      this.events["exercise_finished"].push(event);
    },
    regimen_complete(results) {
      const date = new Date();
      this.events["assessment_complete"].push({
        timestamp: date.getTime(),
        event_type: "assessment_complete",
        assessment_id: this.assessment_id,
        assessment_instance_id: this.assessment_instance_id,
      })

      this.$emit("exercise-finished", {
        start_time: this.start_time,
        time_played: date.getTime() - this.start_time,
        results: results.results,
        reports: results.reports,
        invite_id: this.invite_id,
        assessment_id: this.assessment_id,
        assessment_instance_id: this.assessment_instance_id,
        assessment_grid: this.loaded_assessment_grid,
        events: this.events,
      });
    },
    loadAssessmentGrid() {
      axios.get("/api/grid_set/" + this.assessment_id).then((resp) => {
        if (resp.data.status == "success") {
          this.loaded_assessment_grid = resp.data.data;
        } else {
          messageBus.$emit("api-error", resp.data);
        }
      });
    },
  },
};
</script>
