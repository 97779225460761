<template>
  <v-container>
    <v-layout align-center justify-space-around>
      <v-sheet elevation="2" style="padding: 50px; width:1000px">
        <h2 v-if="include_title"> {{ $t('referal_code.referral_progam') }}</h2>
        <p v-if="include_title"></p>
        <p style="font-weight: light; font-size: larger">
          {{ $t('referal_code.your_clinics_code') }}:
          <strong>{{ referral_code }}</strong>
        </p>
        <p></p>

        <p style="font-size: larger; font-style: italic">{{ $t('referal_code.buy_one_get_one_give_one') }} </p>

        <p>{{ $t('referal_code.how_it_works') }} : 
          <ol>
            <li>{{ $t('referal_code.how_it_works_p1') }} </li>
            <li>{{ $t('referal_code.how_it_works_p2') }} </li>
            <li>{{ $t('referal_code.how_it_works_p3') }}</li>
          </ol>
        </p>

      </v-sheet>
    </v-layout>
  </v-container>
</template>

<script>
import Clinic from "../classes/clinic";

export default {
  props: {
    clinic: {
      type: Object,
      required: true,
    },
    include_title: {
      type: Boolean,
      default: () => true,
    }
  },
  data() {
    // TODO: Remove this and pass in an actual clinic class to this component
    let clinic = new Clinic();
    Object.assign(clinic, this.clinic);

    let referral_code = clinic.tag_value("ref_code");

    return { referral_code };
  }
}
</script>