<template>
  <div v-if="current_clinic">
    <!-- Toolbar -->
    <v-toolbar dark flat class="page_toolbar">
      <template>
        <v-toolbar-title style="cursor:pointer; " @click="subpage = ''">
          <v-icon left>business</v-icon>
          <span>{{ current_clinic.title }}</span>
        </v-toolbar-title>
        <v-spacer />
      </template>
      <v-toolbar-items>
        <v-btn class="clinic_page_menu_button" small text @click="clinic_edit_dialog = true">
          <v-icon small left>edit</v-icon>
          {{ $t("clinic_page.edit_info") }}
        </v-btn>

        <v-btn class="clinic_page_menu_button" small text @click="view_clinic">
          <v-icon small left>fas fa-external-link-alt</v-icon>View Clinic
        </v-btn>

        <v-btn class="clinic_page_menu_button" small text @click="view_activities">
          <v-icon small left>fas fa-asterisk</v-icon>Activities
        </v-btn>

        <v-btn class="clinic_page_menu_button" small text @click="view_revisions">
          <v-icon small left>fas fa-clone</v-icon>Revisions
        </v-btn>

        <v-btn
          v-if="current_clinic.stripe_subscription_id"
          class="clinic_page_menu_button"
          small
          text
          target="_blank"
          :href="
            'https://dashboard.stripe.com/subscriptions/' + current_clinic.stripe_subscription_id
          "
        >
          <v-icon left>fab fa-stripe</v-icon>Subscription
        </v-btn>

        <v-btn
          v-if="current_clinic.stripe_customer_id"
          class="clinic_page_menu_button"
          small
          text
          target="_blank"
          :href="
            'https://dashboard.stripe.com/customers/' + current_clinic.stripe_customer_id
          "
        >
          <v-icon left>fab fa-stripe</v-icon>Customer
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <!-- Edit clinic dialog -->
    <v-dialog v-model="clinic_edit_dialog" max-width="1400" scrollable>
      <ClinicEdit
        :clinic="current_clinic"
        @close-clinic-edit="clinic_edit_dialog = false"
        @clinic-updated="window.reload()"
      />
    </v-dialog>

    <div v-if="!show_activities_table && !show_revisions_table">
      <vue-json-pretty :data="current_clinic"></vue-json-pretty>
    </div>

    <v-card v-if="show_activities_table">
      <v-card-title>
        Activities
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="activities_headers"
        :items="activities"
        show-expand
        :expanded.sync="expanded"
        class="elevation-1"
        :items-per-page="100"
        :search="search"
        :sort-by.sync="sortByActivities"
        :sort-desc.sync="sortDescActivities"
      >
        <template v-slot:item.user="{ item }">
          <span
            v-if="users[item.user_id]"
            style="cursor: pointer;"
            @click="set_detailed_view(users[item.user_id])"
          >
            {{ users[item.user_id].name_given }}
            {{ users[item.user_id].name_family }}
          </span>
          <span v-else>{{ item.user_id }}</span>
        </template>

        <template v-slot:item.invite="{ item }">
          <span
            v-if="invites[item.invite_id]"
            style="cursor: pointer;"
            @click="set_detailed_view(invites[item.invite_id])"
          >
            {{ invites[item.invite_id].name }}
            {{ invites[item.invite_id].name_family }}
          </span>
          <span v-else>{{ item.invite_id }}</span>
        </template>

        <template v-slot:item.trace="{ item }">
          <v-btn
            v-if="item.trace"
            icon
            x-small
            target="_blank"
            :href="'https://console.cloud.google.com/logs/query;query='+ encodeURIComponent(item.trace) + ';timeRange=%2F' + now + '?project=steadfast-tesla-262204'"
          >
            <v-icon x-small>fas fa-list</v-icon>
          </v-btn>
        </template>

        <template v-slot:expanded-item="{ item }">
          <vue-json-pretty :data="item"></vue-json-pretty>
        </template>
      </v-data-table>
    </v-card>

    <v-card v-if="show_revisions_table">
      <v-card-title>
        Revisions
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="revisions_headers"
        :items="revisions"
        show-expand
        :expanded.sync="expanded"
        class="elevation-1"
        :items-per-page="100"
        :search="search"
        :sort-by.sync="sortByRevisions"
        :sort-desc.sync="sortDescRevisions"
      >
        <template v-slot:item.user="{ item }">
          <span
            v-if="users[item.user_id]"
            style="cursor: pointer;"
            @click="set_detailed_view(users[item.user_id])"
          >
            {{ users[item.user_id].name_given }}
            {{ users[item.user_id].name_family }}
          </span>
          <span v-else>{{ item.user_id }}</span>
        </template>

        <template v-slot:item.trace="{ item }">
          <v-btn
            v-if="item.trace"
            icon
            x-small
            target="_blank"
            :href="'https://console.cloud.google.com/logs/query;query='+ encodeURIComponent(item.trace) + ';timeRange=%2F' + now + '?project=steadfast-tesla-262204'"
          >
            <v-icon x-small>fas fa-list</v-icon>
          </v-btn>
        </template>

        <template v-slot:expanded-item="{ item }">
          <vue-json-pretty :data="item"></vue-json-pretty>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="detailed_view_dialog" width="1000" :transition="false">
      <v-card>
        <v-toolbar>
          <v-btn icon @click="detailed_view_dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <vue-json-pretty :data="detailed_view"></vue-json-pretty>
        </v-card-text>
      </v-card>
    </v-dialog>

    <div style="height:100px"></div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import ClinicEdit from "../ClinicEdit";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";

export default {
  components: { ClinicEdit, VueJsonPretty },
  props: {
    clinic_id: {
      type: String,
      required: true,
    },
    current_user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      clinic_edit_dialog: false,
      show_activities_table: false,
      show_revisions_table: false,
      search: "",
      activities: [],
      revisions: [],
      users: {},
      invites: {},
      expanded: [],
      detailed_view_dialog: false,
      detailed_view: null,
      sortByRevisions: "created",
      sortDescRevisions: true,
      sortByActivities: "created",
      sortDescActivities: true,
      activities_headers: [
        {
          text: "Activity",
          value: "activity",
        },
        {
          text: "Date",
          value: "created",
        },
        { text: "User", value: "user" },
        { text: "Invite", value: "invite" },
        { text: "", value: "trace" },
        { text: "", value: "data-table-expand" },
      ],
      revisions_headers: [
        {
          text: "Entity Type",
          value: "entity_type",
        },
        {
          text: "Entity Type",
          value: "entity_id",
        },
        {
          text: "Action",
          value: "action",
        },
        {
          text: "Date",
          value: "created",
        },
        { text: "User", value: "user" },
        { text: "", value: "trace" },
        { text: "", value: "data-table-expand" },
      ],
    };
  },
  computed: {
    ...mapState(["current_clinic"]),
    now() {
      let now = new Date();
      return now.toISOString();
    },
  },
  mounted() {
    this.load_users();
    this.load_invites();
  },
  methods: {
    view_clinic() {
      window.location = "/app/clinic/" + this.clinic_id;
      window.reload();
    },
    view_activities() {
      let self = this;
      self.show_revisions_table = false;
      self.show_activities_table = true;
      axios
        .get("/api/activity/clinic/" + this.clinic_id)
        .then(function (response) {
          if (response.data.status == "success") {
            self.activities = response.data.data;
          }
        });
    },
    view_revisions() {
      let self = this;
      self.show_revisions_table = true;
      self.show_activities_table = false;
      axios
        .get("/api/revision/clinic/" + this.clinic_id)
        .then(function (response) {
          if (response.data.status == "success") {
            self.revisions = response.data.data;
          }
        });
    },
    load_users() {
      let self = this;
      axios
        .get("/api/clinic/" + this.clinic_id + "/users")
        .then(function (response) {
          self.loaded = true;
          if (response.data.status == "success") {
            let users = response.data.data;
            for (let user of users) {
              self.users[user.id] = user;
            }
          }
        });
    },
    load_invites() {
      let self = this;
      axios
        .get("/api/clinic/" + this.clinic_id + "/invites")
        .then(function (response) {
          self.loaded = true;
          if (response.data.status == "success") {
            let invites = response.data.data;
            for (let invite of invites) {
              self.invites[invite.id] = invite;
            }
          }
        });
    },
    set_detailed_view(data) {
      this.detailed_view = data;
      this.detailed_view_dialog = true;
    },
  },
};
</script>