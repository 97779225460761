<template>
  <v-app>
    <v-toolbar style="max-height: 65px" dark flat class="page_toolbar">
      <v-toolbar-title>
        <v-icon left>build</v-icon>{{ $t("common.tools") }}
      </v-toolbar-title>
    </v-toolbar>
    <Tools 
      :admin_or_staff="admin_or_staff"
      :sidebar="false" 
      :apps_only="true" 
      :show_missing_something="false"
      :apps="['metronome', 'stopwatch']"
    />
  </v-app>
</template>

<script>
import Tools from "../../shared/components/Tools";

export default {
  name: "App",
  components: {
    Tools
  },
  data() {
    return {
      current_user: this.$root.$children[0].current_user,
      user_invites: this.$root.$children[0].user_invites
    };
  },
  computed: {
    admin_or_staff() {
      if (this.current_user.is_admin) {
        return true;
      }
      for (var i in this.user_invites) {
        let invite = this.user_invites[i];
        if (invite.user_role == "admin" || invite.user_role == "staff") {
          return true;
        }
      }
      return false;
    }
  }
};
</script>

<style>
</style>