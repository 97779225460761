<template>
  <v-card>
    <v-card-title style="padding: 0px;">
      <v-toolbar grey flat>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("common.racetracks") }}</v-toolbar-title>
      </v-toolbar>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-container>
        <v-select
          v-model="thickness"
          :items="thickness_option"
          :label="$t('tool_race_track_circle_square.line_thickness')"
        />
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-btn @click="view()">{{ $t("common.view") }}</v-btn>
      <v-spacer />
      <v-btn color="primary" @click="print()">{{ $t("common.print") }}</v-btn>
    </v-card-actions>
    <v-progress-linear v-if="in_flight" :indeterminate="true" />
  </v-card>
</template>

<script>
import printJS from "print-js";
import { Chance } from "chance";
let chance = Chance();

export default {
  components: {},
  props: {},
  data() {
    let data = {
      thickness: "mid",
      thickness_option: [
        { text: this.$t("common.medium"), value: "mid" },
        { text: this.$t("tool_race_track_circle_square.thin"), value: "thin" },
        {
          text: this.$t("tool_race_track_circle_square.thick"),
          value: "thick",
        },
      ],
      in_flight: false,
    };
    return data;
  },
  methods: {
    url() {
      let uri = "/api/tools/pdf/racetracks.pdf";
      uri += "?thickness=" + this.thickness;
      uri += "&cache_buster=" + chance.hex();

      return uri;
    },
    view: function () {
      window.open(this.url(), "_blank");
    },
    print: function () {
      let self = this;

      if (navigator.vendor.includes("Apple")) {
        window.open(this.url(), "_blank");
        return;
      }
      
      printJS({
        printable: self.url(),
        onLoadingStart: function () {
          self.in_flight = true;
        },
        onLoadingEnd: function () {
          self.in_flight = false;
        },
        style: "@page { size: Letter landscape; }",
      });
    },
    close: function () {
      this.$emit("close");
    },
  },
};
</script>
