<template>
  <v-card id="clinic_edit_modal">
    <v-card-title style="padding: 0px">
      <v-toolbar grey flat>
        <v-btn icon @click="close_clinic_edit">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ local_clinic.title }}</v-toolbar-title>
      </v-toolbar>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-layout>
          <v-flex xs4>
            <h3>{{ $t("clinic_edit.public_information") }}</h3>
            <v-text-field
              v-model="local_clinic.title"
              :label="$t('common.business_name')"
              type="text"
            />
            <v-text-field
              v-model="local_clinic.website"
              :label="$t('clinic_edit.website')"
              type="text"
            />
            <v-text-field
              v-model="local_clinic.phone"
              :label="$t('clinic_edit.phone_number')"
              type="text"
            />
          </v-flex>

          <v-flex xs2></v-flex>

          <v-flex xs4>
            <h3>{{ $t("clinic_edit.walk_in_address") }}</h3>
            <div v-if="locales">
              <v-text-field
                v-model="local_clinic.physical_address_1"
                :label="$t('common.address')"
                type="text"
                :dense="current_user.is_admin"
              />
              <v-text-field
                v-model="local_clinic.physical_address_2"
                :dense="current_user.is_admin"
                type="text"
              />
              <v-text-field
                v-model="local_clinic.physical_locality"
                :label="$t('common.city')"
                type="text"
                :dense="current_user.is_admin"
              />
              <v-text-field
                v-model="local_clinic.physical_district"
                :label="$t('clinic_edit.state_province')"
                type="text"
                :dense="current_user.is_admin"
              />
              <v-text-field
                v-model="local_clinic.physical_postal"
                :label="$t('clinic_edit.postal_zip_code')"
                type="text"
                :dense="current_user.is_admin"
              />
              <v-select
                v-model="local_clinic.billing_country"
                :disabled="!current_user.is_admin"
                :items="all_countries"
                :label="$t('common.country')"
              />
            </div>
          </v-flex>
        </v-layout>

        <v-expansion-panels>
          <v-expansion-panel v-if="current_user.is_admin">
            <v-expansion-panel-header>{{
              $t("common.administration")
            }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-menu
                v-model="trial_end_picker"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="trial_end"
                    :label="$t('clinic_edit.trial_end_date')"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="trial_end"
                  @input="trial_end_picker = false"
                ></v-date-picker>
              </v-menu>

              <v-textarea
                v-model="local_clinic.notes"
                outlined
                label="notes"
              ></v-textarea>

              <v-textarea
                v-model="clinic_tags"
                outlined
                label="tags"
              ></v-textarea>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-btn v-if="show_delete" text color="error" @click="remove">
        <v-icon small left>delete_forever</v-icon>
        {{ $t("common.delete") }}
      </v-btn>
      <v-spacer />
      <v-alert
        v-if="error_message != ''"
        outlined
        :value="error_message != ''"
        type="error"
        >{{ error_message }}</v-alert
      >
      <v-spacer />
      <v-btn color="primary" @click="submit()">{{
        $t("common.save_changes")
      }}</v-btn>
    </v-card-actions>
    <v-progress-linear v-if="in_flight" :indeterminate="true" />
  </v-card>
</template>

<script>
import axios from "axios";
import all_countries from "../../../data/all_countries_list.json";
import Clinic from "../classes/clinic";
import { mapState } from "vuex";

export default {
  components: {},
  props: {
    clinic: {
      type: Object,
      required: true,
    },
    show_delete: {
      type: Boolean,
      default: false,
    },
    admin_users: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    let local_clinic = new Clinic();
    Object.assign(local_clinic, this.clinic);
    let data = {
      local_clinic,
      all_countries: all_countries,
      in_flight: false,
      locales: null,
      error_message: "",
      trial_end_picker: false,
    };

    // Assign billing contact if it's missing
    if (!data.local_clinic.billing_contact && this.admin_users.length > 0) {
      data.local_clinic.billing_contact = this.admin_users[0].id;
    }

    return data;
  },
  computed: {
    ...mapState(["current_user"]),
    has_trial_end() {
      return this.local_clinic && this.local_clinic.trial_end;
    },
    trial_end: {
      get: function () {
        if (this.has_trial_end) {
          return this.local_clinic.trial_end.substring(0, 10);
        } else return "";
      },
      set: function (newValue) {
        this.local_clinic.trial_end = newValue + "T12:00:00";
      },
    },
    clinic_tags: {
      get: function () {
        let tags = "";
        for (var i in this.local_clinic.tags) {
          let tagval = this.local_clinic.tags[i].trim();
          tags += tagval + "\n";
        }

        return tags.trim();
      },
      set: function (newValue) {
        let tags = newValue.trim().split("\n");

        this.local_clinic.tags = [];
        for (var i in tags) {
          let tag = tags[i].trim();
          if (tag) {
            this.local_clinic.tags.push(tag);
          }
        }
      },
    },
    billing_contact_options() {
      let options = [];
      for (var i in this.admin_users) {
        let user = this.admin_users[i];
        options.push({
          value: user.id,
          text: user.name_given + " " + user.name_family,
        });
      }
      return options;
    },
  },
  watch: {
    clinic() {
      let local_clinic = new Clinic();
      Object.assign(local_clinic, this.clinic);
      this.local_clinic = local_clinic;
    },
  },
  mounted() {
    this.load_locales();
  },
  methods: {
    get_errors: function () {
      // Phone
      if (
        this.local_clinic.phone &&
        !this.local_clinic.phone.match("^[0-9()\\- +.]+$")
      ) {
        return this.$t("clinic_edit.invalid_phone_number");
      }

      // Use regex to test tags with named capture groups
      let re = /^(?<tag_name>[a-z_]+)(\((?<tag_value>.+)\))?$/;
      for (let tag of this.local_clinic.tags) {
        let match = tag.match(re);

        // If the tag doesn't match the pattern, return an error
        if (!match) {
          return "Invalid Clinic Tag";
        }

        // Try to parse the tag value if present
        if (match.groups.tag_value) {
          try {
            JSON.parse(match.groups.tag_value); // Attempt to parse tag_value as JSON
          } catch (e) {
            return "Invalid Clinic Tag Value: " + match.groups.tag_value;
          }
        }
      }

      return false;
    },
    close_clinic_edit: function () {
      let local_clinic = new Clinic();
      this.local_clinic = Object.assign(local_clinic, this.clinic);
      this.$emit("close-clinic-edit");
    },
    submit: function () {
      let self = this;

      this.error_message = "";
      let error_message = this.get_errors();
      if (error_message) {
        this.error_message = error_message;
        return;
      }

      self.in_flight = true;
      if (this.clinic.id) {
        // Update
        axios
          .put("/api/clinic/" + self.clinic.id, self.local_clinic)
          .then(function (response) {
            self.in_flight = false;
            let resp = response.data;
            if (resp.status == "error") {
              messageBus.$emit("api-error", resp);
            } else {
              messageBus.$emit(
                "success",
                self.$t("clinic_edit.clinic_updated")
              );
              self.$emit("clinic-updated", self.local_clinic);
              self.$emit("close-clinic-edit");
            }
          });
      } else {
        // Create
        axios.post("/api/clinic", self.local_clinic).then(function (response) {
          self.in_flight = false;
          let resp = response.data;
          if (resp.status == "error") {
            messageBus.$emit("api-error", resp);
          } else {
            messageBus.$emit("success", self.$t("clinic_edit.clinic_created"));
            self.local_clinic.id = resp.data;
            self.$emit("clinic-created", self.local_clinic);
            self.$emit("close-clinic-edit");
          }
        });
      }
    },
    remove: function () {
      let self = this;
      let confirm_message = this.$t("clinic_edit.delete_clinic_message");
      if (confirm(confirm_message)) {
        self.in_flight = true;
        axios.delete("/api/clinic/" + self.clinic.id).then(function (response) {
          self.in_flight = false;
          let resp = response.data;
          if (resp.status == "error") {
            messageBus.$emit("api-error", resp);
          } else {
            messageBus.$emit("success", self.$t("clinic_edit.clinic_deleted"));
            self.$emit("clinic-deleted", self.local_clinic);
            self.$emit("close-clinic-edit");
          }
        });
      }
    },
    load_locales() {
      let self = this;
      axios.get("/api/locales").then((response) => {
        let resp = response.data;
        if (resp.status == "success") {
          self.locales = resp.data;
        } else {
          messageBus.$emit("api-error", resp);
        }
      });
    },
  },
};
</script>

<style>
#clinic_edit_modal .v-messages__message {
  line-height: normal;
}
</style>
