<template>
  <v-card>
    <v-toolbar>
      <v-btn icon @click="$emit('close')">
        <v-icon>close</v-icon>
      </v-btn>
      <v-toolbar-title>{{ $t("common.custom_exercises") }}</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-btn
          small
          color="primary"
          @click="open_create_video_dialog"
        >{{ $t("clinic_page_manage_videos.create_exercise") }}</v-btn>
        <v-dialog
          v-model="create_video_dialog"
          max-width="1200"
          persistent
          @click:outside="confim_close_create_video_dialog"
        >
          <VideoExerciseEdit
            v-if="create_video_dialog"
            ref="create_video_dialog"
            :video="new_video"
            :video_exercises="video_exercises"
            :clinic_title="clinic_title"
            @close="close_create_video_dialog"
            @video-created="load_videos"
            @video-updated="load_videos"
          ></VideoExerciseEdit>
        </v-dialog>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text>
      <v-banner two-line style="margin-top:20px">
        <p>{{ $t("clinic_page_manage_videos.custom_exercise_first_text") }}</p>

        <p>{{ $t("common.copy_right_text") }}</p>
      </v-banner>
      <v-list v-if="clinic_video_exercises && clinic_video_exercises.length != 0">
        <div v-for="(item, index) in clinic_video_exercises" :key="index">
          <v-list-item>
            <v-list-item-icon>
              <v-badge
                v-if="item.shared && !item.shared.hidden"
                overlap
                bottom
                bordered
                color="grey"
                icon="mdi-share-variant"
              >
                <v-icon>ondemand_video</v-icon>
              </v-badge>
              <v-icon v-else>ondemand_video</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.title }}
                <v-chip
                  v-if="item.share_source"
                  label
                  small
                  outlined
                  class="mt-0 ml-5"
                  :href="'/app/shared/clinic/' + item.share_source.clinic_id"
                  rget="_blank"
                >{{ $t('community_content.clinic_attribution', {'clinic_title': item.share_source.clinic_title }) }}</v-chip>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title>{{ item.exercise_group }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <ExercisePreviewButton :exercise="video_to_builtin(item)" class="mr-5" />
            </v-list-item-action>
            <v-list-item-action>
              <v-btn small outlined fab color="green" @click="open_video_dialog(index)">
                <v-icon>edit</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action>
              <v-tooltip top open-delay="500">
                <template v-slot:activator="{ on }">
                  <v-btn small outlined fab color="red" v-on="on" @click="delete_video(item)">
                    <v-icon>delete</v-icon>
                  </v-btn>
                </template>
                {{ $t("clinic_page_manage_videos.delete_video") }}
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
          <v-divider inset />

          <v-dialog
            v-model="edit_video_dialog[index]"
            max-width="1200"
            persistent
            @click:outside="confim_close_edit_video_dialog(index)"
          >
            <VideoExerciseEdit
              v-if="edit_video_dialog[index]"
              ref="edit_video_dialog"
              :video="item"
              :video_exercises="video_exercises"
              :clinic_title="clinic_title"
              @close="close_video_dialog(index)"
              @video-updated="load_videos"
            ></VideoExerciseEdit>
          </v-dialog>
        </div>
      </v-list>
      <v-banner v-else-if="!loading" two-line style="margin-top:20px; font-style:italic">
        <p>{{ $t("clinic_page_manage_videos.no_created_exercise_text") }}</p>
      </v-banner>
      <v-layout v-else-if="loading" align-center justify-center>
        <v-progress-circular style="margin: 30px" indeterminate color="primary"></v-progress-circular>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import VideoExerciseEdit from "./VideoExerciseEdit";
import ExercisePreviewButton from "./ExercisePreviewButton";
import Vue from "vue";
import { clinic_to_builtin } from "../classes/video_exercise";
import { mapState } from "vuex";

export default {
  components: { VideoExerciseEdit, ExercisePreviewButton },
  props: {
    clinic_id: {
      type: String,
      required: true,
    },
    clinic_title: {
      type: String,
      required: true,
    },
    video_exercises: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      loading: true,
      create_video_dialog: false,
      edit_video_dialog: [],
      new_video: {
        title: "",
        description: "",
        instructions: "",
        video: "",
        variant: "default",
        exercise_group: "",
        tools: [],
        clinic_id: this.clinic_id,
      },
    };
  },
  computed: {
    ...mapState(["clinic_video_exercises"]),
  },
  mounted() {
    this.load_videos();
  },
  methods: {
    close_create_video_dialog() {
      this.create_video_dialog = false;
      this.new_video = {
        title: "",
        description: "",
        instructions: "",
        video: "",
        variant: "default",
        exercise_group: "",
        tools: [],
        clinic_id: this.clinic_id,
      };
    },
    close_existing_video_dialog(index) {
      Vue.set(this.edit_video_dialog, index, false);
    },
    load_videos() {
      this.close_create_video_dialog();
      this.loading = true;
      this.$store.dispatch("loadClinicVideoExercises", () => {
        this.loading = false;
        this.edit_video_dialog = [];
        for (var i = 0; i < this.clinic_video_exercises.length; i++) {
          this.edit_video_dialog.push(false);
        }
      });
    },
    open_create_video_dialog() {
      this.create_video_dialog = true;
      Vue.nextTick(() => {
        if (this.$refs.create_video_dialog) {
          this.$refs.create_video_dialog.reset();
        }
      });
    },
    open_video_dialog(index) {
      Vue.set(this.edit_video_dialog, index, true);
      Vue.nextTick(() => {
        for (var elem of this.$refs.edit_video_dialog) {
          elem.reset();
        }
      });
    },
    close_video_dialog(index) {
      Vue.set(this.edit_video_dialog, index, false);
    },
    confim_close_edit_video_dialog(index) {
      let uploading_video = false;
      for (var elem of this.$refs.edit_video_dialog) {
        if (elem.video_upload_in_progress) {
          uploading_video = true;
          break;
        }
      }
      if (uploading_video) {
        if (!window.confirm(this.$t("video_exercise_edit.confirm_close"))) {
          return;
        }
        for (var cancel_elem of this.$refs.edit_video_dialog) {
          cancel_elem.cancel_upload();
        }
      }
      this.close_existing_video_dialog(index);
    },
    confim_close_create_video_dialog() {
      if (this.$refs.create_video_dialog && this.$refs.create_video_dialog.video_upload_in_progress) {
        if (!window.confirm(this.$t("video_exercise_edit.confirm_close"))) {
          return;
        }
        this.$refs.create_video_dialog.cancel_upload();
      }
      this.close_create_video_dialog();
    },
    delete_video(video) {
      if (!confirm(this.$t("common.delete") + " " + video.title + "?")) {
        return;
      }

      let self = this;
      axios.delete("/api/video_exercise/" + video.id).then(function (response) {
        if (response.data.status == "success") {
          messageBus.$emit("success", self.$t("clinic_page_manage_videos.video_deleted"));
          self.load_videos();
        } else {
          messageBus.$emit("error", response.data.error_message);
        }
      });
    },
    video_to_builtin(item) {
      let converted_video = clinic_to_builtin(item);
      converted_video.exercise_type = "custom"; // It's not actually a custom, but we're passing the data directly in instead of loading it via the backend, so we need to set it to custom.
      return converted_video;
    },
  },
};
</script>

<style>
</style>