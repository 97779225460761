<template>
  <v-text-field v-model="localValue" outlined :label="label">
    <template v-slot:append>
      <v-menu v-model="menu" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
        <template v-slot:activator="{ on }">
          <div :style="swatchStyle" v-on="on" />
        </template>
        <v-card>
          <v-card-text class="pa-0">
            <v-color-picker v-model="localValue" flat hide-inputs hide-mode-switch mode="hex" show-swatches />
          </v-card-text>
        </v-card>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: "Color",
    },
  },
  data() {
    return {
      localValue: this.value || this.$vuetify.theme.themes.light.primary.substring(0, 7),
      mask: "!#XXXXXXXX",
      menu: false,
    };
  },
  computed: {
    swatchStyle() {
      const { localValue, menu } = this;
      return {
        backgroundColor: localValue,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },
  },
  watch: {
    localValue() {
      this.$emit("input", this.localValue);
    },
    value() {
      this.localValue = this.value;
    },
  },
};
</script>
