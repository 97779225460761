<template>
  <v-autocomplete
    v-model="localValue"
    :append-icon="append_icon"
    :prepend-icon="prepend_icon"
    :label="label"
    :items="exercise_options"
    :filter="exercise_filter"
    outlined
    @change="$emit('change', localValue)"
  >
    <template v-slot:item="data">
      <template v-if="typeof data.item !== 'object'">
        <v-list-item-content v-text="data.item"></v-list-item-content>
      </template>
      <template v-else>
        <v-list-item-avatar>
          <v-icon>{{ data.item.icon }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="data.item.text"></v-list-item-title>
          <v-list-item-subtitle
            v-html="data.item.subtitle"
          ></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
import { category_icons } from "../lib/category";
import { mapState } from "vuex";

export default {
  components: {},
  props: {
    exercise_type: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    prepend_icon: {
      type: String,
      default: "category",
    },
    append_icon: {
      type: String,
      default: "category",
    },
    label: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      icons: category_icons,
    };
  },
  computed: {
    ...mapState(["current_clinic_selections", "exercises", "video_exercises"]),
    localValue: {
      get() {
        return this.value;
      },
      set(localValue) {
        this.$emit("input", localValue);
      },
    },
    video_selections() {
      let selections = {};

      if (!this.current_clinic_selections || this.exercise_type != "video") {
        return selections;
      }

      for (let builtin_select of this.current_clinic_selections.get_by_type(
        "video/builtin"
      )) {
        selections[builtin_select.entity_id] = builtin_select.selected;
      }
      for (let video_select of this.current_clinic_selections.get_by_type(
        "video_exercise"
      )) {
        selections[video_select.entity_id] = video_select.selected;
      }

      return selections;
    },
    // Interactive exercise options
    exercise_options() {
      let self = this;
      let options = [];

      var exercises;
      if (this.exercise_type == "interactive") {
        exercises = this.exercises;
      }
      if (this.exercise_type == "video") {
        exercises = this.video_exercises;
      }

      // Group them and them to an array
      let grouped = [];
      for (var exercise_id in exercises) {
        var exercise = exercises[exercise_id];
        if (
          exercise.demo_only ||
          exercise.canary ||
          (exercise.deprecated && this.localValue != exercise_id) ||
          this.video_selections[exercise_id] === false
        ) {
          continue;
        }

        let group_name = exercise.group[0];
        if (!group_name) {
          group_name = "Uncategorized";
        }
        let group_exists = false;
        var group;
        for (var i in grouped) {
          if (grouped[i].group == group_name) {
            group_exists = true;
            group = grouped[i];
          }
        }

        if (!group_exists) {
          group = {
            group: group_name,
            anchor: "#" + group_name.replace(/\s+/g, ""),
            id: group_name.replace(/\s+/g, ""),
            exercises: [],
            icon: this.icons[group_name],
          };
        }

        group.exercises.push(exercise);
        if (!group_exists) {
          grouped.push(group);
        }
      }

      // Sort the groups and exercises
      grouped.sort((a, b) => {
        if (a.group == "Uncategorized") {
          return 1;
        } else
          return self.$t("group." + a.group) > self.$t("group." + b.group)
            ? 1
            : -1;
      });

      // Sort exercises in groups
      let locale = this.$i18n.lang();
      for (var g in grouped) {
        grouped[g].exercises.sort((a, b) =>
          a.title[locale] > b.title[locale] ? 1 : -1
        );
      }

      for (var group_g in grouped) {
        let group = grouped[group_g];
        if (group_g != 0) {
          options.push({ divider: true });
        }
        options.push({ header: this.$t("group." + group.group) });

        for (var e_id in group.exercises) {
          let exercise = group.exercises[e_id];
          // Only use the first sentance of the description.
          let desc = this.exercise_description(exercise);
          desc = desc.split(".")[0];

          options.push({
            value: exercise.id,
            text: this.exercise_title(exercise),
            subtitle: desc,
            icon: group.icon,
          });
        }
      }

      return options;
    },
  },
  methods: {
    exercise_filter(item, queryText, _displayTitle) {
      if (item.header || item.divider) {
        return false;
      }
      let filter = queryText.trim().toLowerCase();
      let title = item.text.trim().toLowerCase();
      let title_stripped = title.replace(/\./g, "");

      if (
        title.toLowerCase().includes(filter) ||
        title_stripped.toLowerCase().includes(filter)
      ) {
        return true;
      }

      return false;
    },
    exercise_title(exercise) {
      if (!exercise.title) {
        return "";
      }

      if (typeof exercise.title == "string") {
        return exercise.title;
      }

      let locale = this.$i18n.lang();
      if (exercise.title[locale]) {
        return exercise.title[locale];
      } else {
        return exercise.title.en;
      }
    },
    exercise_description(exercise) {
      if (!exercise.description) {
        return "";
      }

      if (typeof exercise.description == "string") {
        return exercise.description;
      }

      let locale = this.$i18n.lang();
      if (exercise.description[locale]) {
        return exercise.description[locale];
      } else {
        return exercise.description.en;
      }
    },
  },
};
</script>

<style></style>
