<template>
  <v-card>
    <v-card-title style="padding: 0px">
      <v-toolbar grey flat>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>{{
          $t("common.circle_line_jump")
        }}</v-toolbar-title>
      </v-toolbar>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-container>
        <p>{{ $t("tools.description_circle_line_jump") }}</p>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-btn @click="view()">{{ $t("common.view") }}</v-btn>
      <v-btn @click="download()">{{ $t("common.download") }}</v-btn>
      <v-spacer />
      <v-btn color="primary" @click="print()">{{ $t("common.print") }}</v-btn>
    </v-card-actions>
    <v-progress-linear v-if="in_flight" :indeterminate="true" />
  </v-card>
</template>

<script>
import printJS from "print-js";
import { Chance } from "chance";
let chance = Chance();

export default {
  components: {},
  props: {},
  data() {
    let data = {
      picture: "circle_triange_single",
      picture_options: [
        {
          text: this.$t("tool_circle_triangle.circle_triangle_singles"),
          value: "circle_triange_single",
        },
        {
          text: this.$t("tool_circle_triangle.circle_triangle_doubles"),
          value: "circle_triange_double",
        },
      ],
      in_flight: false,
    };
    return data;
  },
  methods: {
    view() {
      window.open(this.url("landscape"), "_blank");
    },
    download() {
      window.open(this.url("portrait", true), "_blank");
    },
    url(orientation = "portrait", download = false) {
      let uri = "/api/tools/pdf/picture_grid/circle_jump/picture_grid.pdf?rows=8&cols=5&size=128&padding_sides=25";
      uri += "&cache_buster=" + chance.hex();

      if (orientation === "landscape") {
        uri += "&orientation=landscape&margin_top=60";
      }
      else {
        uri += "&margin_top=20";
      }
      if (download) {
        uri += "&download=true";
      }

      return uri;
    },
    print: function () {
      let self = this;

      if (navigator.vendor.includes("Apple")) {
        window.open(this.url(), "_blank");
        return;
      }

      printJS({
        printable: this.url(),
        onLoadingStart: function () {
          self.in_flight = true;
        },
        onLoadingEnd: function () {
          self.in_flight = false;
        },
        style: "@page { size: Letter portrait; }",
      });
    },
    close: function () {
      this.$emit("close");
    },
  },
};
</script>
