export default class OpticalGymBaseClass {
    toJsonObj(): Object {
        let obj: Object = {};
        Object.assign(obj, this);

        // Delete the ID if it's null or otherwise empty
        // @ts-ignore
        if (!obj.id) {
            // @ts-ignore
            delete obj.id;
        }

        return obj;
    }
}

