var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:'assessment' + ' ' + 'assessment-' + _vm.assessment_id},[(
      _vm.current_clinic && _vm.current_user && _vm.loaded_assessment_grid && _vm.regimen && _vm.current_progress_reports
    )?_c('div',[_c('sub-regimen',{attrs:{"show_title":_vm.show_title,"title":_vm.loaded_assessment_grid.title,"regimen_override":_vm.regimen,"exercises":_vm.exercises,"video_exercises":_vm.video_exercises,"user":_vm.current_user,"clinic":_vm.current_clinic,"invite_id":_vm.invite_id,"report_tags":[
        'assessment',
        'assessment_exercise',
        'assessment_id("' + _vm.assessment_id + '")',
        'assessment_instance_id("' + _vm.assessment_instance_id + '")',
      ],"ephemeral":""},on:{"exercise_launched":_vm.exercise_launched,"exercise_finished":_vm.exercise_finished,"regimen_complete":_vm.regimen_complete}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }