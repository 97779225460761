<template>
  <v-card v-if="exercise">
    <v-toolbar v-if="show_toolbar">
      <v-btn icon @click="$emit('close')">
        <v-icon>close</v-icon>
      </v-btn>

      <v-toolbar-title>{{
        localValue.title
          ? localValue.title
          : $t("exercise_preset_edit.new_preset", {
              exercise_title: exercise_title,
            })
      }}</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-card-text>
      <v-layout>
        <v-flex xs4>
          <v-text-field
            v-model="localValue.title"
            outlined
            :label="
              localValue.exercise_id == 'question'
                ? $t('vuetify_survey.title_question')
                : $t('common.title')
            "
          />

          <v-text-field
            v-if="localValue.exercise_id != 'question'"
            v-model="localValue.description"
            outlined
            :label="$t('common.description')"
          />
        </v-flex>

        <v-flex xs7 offset-xs1>
          <v-textarea
            v-if="localValue.exercise_id != 'question'"
            v-model="localValue.instructions"
            outlined
            rows="5"
            :label="$t('common.special_instructions')"
            :hint="$t('common.special_instructions_for_exercise')"
          />
          <v-text-field
            v-else
            v-model="localValue.description"
            outlined
            :label="$t('common.description')"
          />
        </v-flex>
      </v-layout>

      <div v-if="localValue.exercise_id">
        <VuetifySurveyItemEditor
          v-if="localValue.exercise_id == 'question'"
          ref="VuetifySurveyItemEditor"
          v-model="question_config"
          class="mt-2"
          :allow_edit_title="false"
          :allow_edit_instructions="false"
          :allow_edit_visible="false"
          :show_id="false"
          :allow_edit_style="false"
          :allow_edit_class="false"
          :allow_edit_required="false"
          :allow_edit_value="false"
          :disallow_same_option_value="true"
        />

        <component
          :is="localValue.exercise_id"
          v-else
          ref="exerciseConfig"
          v-model="localValue.config"
          regimen_config
        />
      </div>
    </v-card-text>

    <v-alert
      v-for="error in errors"
      :key="error"
      :value="true"
      type="error"
      outlined
      class="ml-4 mr-4"
      >{{ error }}</v-alert
    >

    <v-card-actions>
      <v-btn
        v-if="show_delete && value.id"
        small
        outlined
        color="red"
        @click="delete_exercise_preset"
      >
        <v-icon small left>delete</v-icon>{{ $t("common.delete") }}
      </v-btn>
      <v-spacer />
      <v-btn v-if="localValue.exercise_id" color="primary" @click="save">{{
        $t("common.save")
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import OpticalGymConfigForms from "../../../exercises/OpticalGymConfigForms.js";
import VuetifySurveyItemEditor from "./vuetify-survey/components/VuetifySurveyItemEditor.vue";
import { mapState } from "vuex";
import axios from "axios";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

import { quillEditor } from "vue-quill-editor";

export default {
  components: Object.assign(OpticalGymConfigForms, {
    quillEditor,
    VuetifySurveyItemEditor,
  }),
  props: {
    value: {
      type: Object,
      required: true,
    },
    show_toolbar: {
      type: Boolean,
      default: true,
    },
    show_delete: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    let default_question_config = {
      type: "text-field",
      props: {},
      id: "question",
      title: this.value.title,
    };
    let data = {
      errors: [],
      quill_config: {
        placeholder: this.$t("common.education"),
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],

            ["clean"], // remove formatting button
          ],
        },
      },
      question_config:
        this.value.exercise_id == "question"
          ? this.value.config
          : default_question_config,
    };
    return data;
  },
  computed: {
    ...mapState(["exercises", "app_config"]),
    exercise_title() {
      if (this.value.exercise_id == "question") {
        return this.$t("common.question");
      } else if (this.exercise && this.exercise.title) {
        return this.exercise.title[this.$i18n.lang()];
      } else {
        return "";
      }
    },
    localValue: {
      get() {
        let value = this.value;

        if (value.exercise_id == "question") {
          // Questions have their own specific processing needs
          if (value.title && value.title.trim()) {
            value.config.title = value.title;
          }
          return value;
        }

        if (value.exercise_id && Object.entries(value.config).length === 0) {
          value.config = this.exercises[value.exercise_id].global;
        }

        let exercise = this.exercises[value.exercise_id];
        let exercise_config_form = OpticalGymConfigForms[value.exercise_id];
        let current_config_version = value.config_version || 0;

        // Upgrade the config if needed
        if (
          current_config_version < exercise.config_version &&
          exercise_config_form &&
          exercise_config_form.methods &&
          exercise_config_form.methods.upgrade_config
        ) {
          exercise_config_form.methods.upgrade_config(
            value.config,
            current_config_version,
            exercise.config_version
          );
        }
        value.config_version = exercise.config_version;

        // Preprocess the config if OpticalGymConfigForms has a config preprocessor
        if (
          exercise_config_form &&
          exercise_config_form.methods &&
          exercise_config_form.methods.preprocess_config
        ) {
          OpticalGymConfigForms[value.exercise_id].methods.preprocess_config(
            value.config
          );
        }

        // Delete the level and texturl params
        // TODO: This should be part of exercise settings defined in params.yml
        if ('level' in value.config) {
          delete value.config.level;
        }
        if ('texturl' in value.config) {
          delete value.config.texturl;
        }

        return value;
      },
      set(localValue) {
        this.$emit("input", localValue);
      },
    },
    has_levels: function () {
      if (
        this.localValue.exercise_id &&
        this.localValue.exercise_id != "question"
      ) {
        return this.exercises[this.localValue.exercise_id].num_levels > 1;
      }
      return false;
    },
    exercise() {
      if (this.localValue.exercise_id == "question") {
        return {};
      }
      return this.exercises[this.localValue.exercise_id];
    },
  },
  methods: {
    // Enforce min-max and allowed values
    enforce_allowed_values() {
      if (this.localValue.exercise_id == "question") {
        // TODO: Checking of question config?
        return;
      }

      let exercise = this.exercises[this.localValue.exercise_id];
      if (exercise.params) {
        for (const [key, param_config] of Object.entries(exercise.params)) {
          if (this.localValue.config[key]) {
            if (
              param_config.min !== null &&
              this.localValue.config[key] < param_config.min
            ) {
              this.localValue.config[key] = param_config.min;
            }
            if (
              param_config.max !== null &&
              this.localValue.config[key] > param_config.max
            ) {
              this.localValue.config[key] = param_config.max;
            }
            if (
              param_config.allowed !== null &&
              !param_config.allowed.includes(this.localValue.config[key])
            ) {
              this.localValue.config[key] = this.exercise.global[key];
              if (
                this.exercise.num_levels &&
                this.localValue.config.level &&
                this.exercise["level" + this.localValue.config.level] &&
                this.exercise["level" + this.localValue.config.level][key] !==
                  undefined
              ) {
                this.localValue.config[key] =
                  this.exercise["level" + this.localValue.config.level][key];
              }
            }
          }
        }
      }
    },

    delete_exercise_preset() {
      // Confirm box
      if (
        confirm(
          this.$t("exercise_preset_edit.confirm_delete", {
            exercise_title: this.exercise_title,
            preset_title: this.localValue.title,
          })
        )
      ) {
        // Delete
        axios
          .delete("/api/exercise_preset/" + this.localValue.id)
          .then((response) => {
            let resp = response.data;
            if (resp.status == "error") {
              messageBus.$emit("api-error", resp);
            } else {
              messageBus.$emit(
                "success",
                this.$t("exercise_preset_edit.preset_deleted")
              );
              this.$emit("deleted", this.localValue);
              this.$emit("close");
            }
          });
      }
    },

    save() {
      // Enforce allowed values and min / max
      this.enforce_allowed_values();

      // Delete the level and texturl params
      // TODO: This should be part of exercise settings defined in params.yml
      if ('level' in this.localValue.config) {
        delete this.localValue.config.level;
      }
      if ('texturl' in this.localValue.config) {
        delete this.localValue.config.texturl;
      }

      // Check for errors
      this.errors = [];

      // Check config for errors a
      if (this.localValue.exercise_id != "question") {
        let exercise = this.$refs.exerciseConfig;
        if (typeof exercise.validate == "function") {
          this.errors = exercise.validate(this.localValue.config);
        }
      }

      if (this.localValue.title.trim().length == 0) {
        this.errors.push(this.$t("common.title_required"));
      }


      if (this.errors.length) {
        return;
      }

      // If it's a question, copy from question_config
      if (this.localValue.exercise_id == "question") {
        let config_value = this.$refs.VuetifySurveyItemEditor.save();
        this.localValue.config = config_value;
      }

      if (this.localValue.id) {
        // Update
        axios
          .put("/api/exercise_preset/" + this.localValue.id, this.localValue)
          .then((response) => {
            let resp = response.data;
            if (resp.status == "error") {
              messageBus.$emit("api-error", resp);
            } else {
              messageBus.$emit(
                "success",
                this.$t("exercise_preset_edit.preset_updated")
              );
              this.$emit("value", this.localValue);
              this.$emit("updated", this.localValue);
              this.$emit("close");
            }
          });
      } else {
        // Create

        axios.post("/api/exercise_preset", this.localValue).then((response) => {
          let resp = response.data;
          if (response.status != 200 || resp.status == "error") {
            messageBus.$emit("api-error", resp);
          } else {
            messageBus.$emit(
              "success",
              this.$t("exercise_preset_edit.preset_created")
            );
            this.localValue.id = resp.data;
            this.$emit("value", this.localValue);
            this.$emit("created", this.localValue);
            this.$emit("close");
          }
        });
      }
    },
  },
};
</script>
