<template>
  <v-card>
    <v-card-title style="padding: 0px;">
      <v-toolbar grey flat>
        <v-btn v-if="toolbar_close" icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>

        <v-toolbar-title>
          {{ $t("common.progress_report") }}
          <span
            v-if="user"
          >- {{ user.name_given }} {{ user.name_family[0] }}</span>
        </v-toolbar-title>
      </v-toolbar>

      <v-spacer />

      <!-- Export -->
      <div :class="$vuetify.rtl ? 'ml-4' : 'mr-4'">
        <v-menu :offset-y="true">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on">
              <v-icon left small>fas fa-file-export</v-icon>{{ $t("common.export") }}
            </v-btn>
          </template>
          <v-list>
            <div v-for="(item, index) in export_formats" :key="index">
              <a
                :href="format_urls[item.value]"
                :download="item.filename"
                class="report_download_link"
                style="text-decoration: none"
              >
                <v-list-item>
                  <v-list-item-title>
                    <v-icon left small>{{ item.icon }}</v-icon>
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>
              </a>
            </div>
          </v-list>
        </v-menu>
      </div>
    </v-card-title>

    <v-divider />

    <v-card-text>
      <v-row v-if="loading || reports.length" justify="space-between" class="mt-2">
        <v-col md="4">
          <v-btn-toggle mandatory>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn ref="exercise_menu_button" small v-bind="attrs" height="40px" v-on="on">
                  <span v-if="select_all">{{ $t('regimen_report.view_all') }}</span>
                  <span v-else-if="selected_exercise_node">
                    <v-icon
                      v-if="selected_exercise_node.exercise_type == 'interactive'"
                      left
                    >{{ app_config.icons.interactive_exercise }}</v-icon>
                    <v-icon v-else left>{{ app_config.icons.video_exercise }}</v-icon>

                    <span
                      v-if="selected_exercise_node.node_title.length > 30"
                      style="font-size: xx-small"
                    >{{ selected_exercise_node.node_title }}</span>
                    <span
                      v-else-if="selected_exercise_node.node_title.length > 25"
                      style="font-size: x-small"
                    >{{ selected_exercise_node.node_title }}</span>
                    <span v-else>{{ selected_exercise_node.node_title }}</span>
                  </span>
                  <span v-else>{{ $t("regimen_report.select_exercise") }}</span>
                </v-btn>
              </template>
              <v-list dense class="regimen_report_exercise_list">
                <div v-for="(node, index) in exercise_tree" :key="index">
                  <v-list-item
                    class="mt-n1 mb-n1 regimen_report_exercise_click"
                    @click="select_node(node)"
                  >
                    <v-icon v-if="selected_node_id == node.id" :left="!$vuetify.rtl" :right="$vuetify.rtl" color="green">{{ $vuetify.rtl ? 'mdi-arrow-left' : 'mdi-arrow-right' }} </v-icon>
                    <span v-else class="pl-8" />

                    <v-list-item-icon :class="$vuetify.rtl ? 'ml-3' : 'mr-3'">
                      <v-icon
                        v-if="node.exercise_type == 'interactive'"
                      >{{ app_config.icons.interactive_exercise }}</v-icon>
                      <v-icon v-else>{{ app_config.icons.video_exercise }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-title>{{ node.node_title }}</v-list-item-title>
                  </v-list-item>
                </div>

                <v-list-item
                  class="mt-n1 mb-n1 regimen_report_exercise_click"
                  @click="set_select_all"
                >
                  <span class="pl-8" />

                  <v-list-item-icon class="mr-3">
                    <v-icon small right>fas fa-ellipsis-v</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title style="font-weight: bold">{{ $t('regimen_report.view_all') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-menu
              v-if="!select_all && selected_exercise_node && (selected_exercise_node.children.length > 1 || (selected_exercise_node.children.length == 1 && selected_exercise_node.children[0].children.length > 1))"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn x-small v-bind="attrs" height="40px" v-on="on">
                  <span v-if="exercise_node_child_type == 'variant'">
                    <span v-if="selected_variant_node">{{ selected_variant_node.node_title }}</span>
                    <span v-else>{{ $t('regimen_report.all_variants') }}</span>
                  </span>
                  <span v-if="exercise_node_child_type == 'instance'">
                    <span v-if="selected_instance_node">{{ selected_instance_node.node_title }}</span>
                    <span v-else>{{ $t('regimen_report.all_configs') }}</span>
                  </span>
                </v-btn>
              </template>
              <v-list dense class="regimen_report_exercise_list">
                <v-list-item
                  v-if="exercise_node_child_type == 'variant'"
                  class="mt-n1 mb-n1 regimen_report_exercise_click"
                  @click="unselect_variant()"
                >
                  <v-list-item-title>{{ $t('regimen_report.all_variants') }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="exercise_node_child_type == 'instance'"
                  class="mt-n1 mb-n1 regimen_report_exercise_click"
                  @click="unselect_instance()"
                >
                  <v-list-item-title>{{ $t('regimen_report.all_configs') }}</v-list-item-title>
                </v-list-item>
                <div v-for="(node, index) in selected_exercise_node.children" :key="index">
                  <v-list-item
                    class="mt-n1 mb-n1 regimen_report_exercise_click"
                    @click="select_node(node)"
                  >
                    <v-list-item-title>
                      <span>{{ node.node_title }}</span>
                      <span
                        v-if="node.instance_title && node.instance_title != selected_exercise_node.node_title"
                      >: {{ node.instance_title }}</span>
                    </v-list-item-title>
                  </v-list-item>
                </div>
              </v-list>
            </v-menu>

            <v-menu
              v-if="selected_variant_node && selected_variant_node.children.length > 1"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn x-small v-bind="attrs" height="40px" v-on="on">
                  <span v-if="selected_instance_node">{{ selected_instance_node.node_title }}</span>
                  <span v-else>{{ $t('regimen_report.all_configs') }}</span>
                </v-btn>
              </template>
              <v-list dense class="regimen_report_exercise_list">
                <v-list-item
                  class="mt-n1 mb-n1 regimen_report_exercise_click"
                  @click="unselect_instance()"
                >
                  <v-list-item-title>{{ $t('regimen_report.all_configs') }}</v-list-item-title>
                </v-list-item>
                <div v-for="(node, index) in selected_variant_node.children" :key="index">
                  <v-list-item
                    class="mt-n1 mb-n1 regimen_report_exercise_click"
                    @click="select_node(node)"
                  >
                    <v-list-item-title>{{ node.node_title }}</v-list-item-title>
                  </v-list-item>
                </div>
              </v-list>
            </v-menu>
          </v-btn-toggle>
        </v-col>

        <v-col>
          <v-btn-toggle v-model="toggle_date_select" mandatory class="mb-8">
            <v-btn value="all_time" x-small height="40px">{{ $t('regimen_report.all_time') }}</v-btn>

            <v-btn value="last_month" x-small height="40px">{{ $t('regimen_report.last_month') }}</v-btn>

            <v-btn value="last_week" x-small height="40px">{{ $t('regimen_report.last_week') }}</v-btn>

            <v-btn value="custom" x-small height="40px">{{ $t('regimen_report.custom_range') }}</v-btn>
          </v-btn-toggle>
        </v-col>

        <v-col>
          <div>
            <v-menu
              v-if="toggle_date_select == 'custom'"
              ref="custom_date_start"
              v-model="custom_date_start_menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="custom_date_start"
                  outlined
                  dense
                  small
                  :label="$t('common.start')"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="custom_date_start" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  height="40px"
                  @click="$refs.custom_date_start.save()"
                >{{ $t('common.ok') }}</v-btn>
              </v-date-picker>
            </v-menu>
          </div>
        </v-col>

        <v-col>
          <div>
            <v-menu
              v-if="toggle_date_select == 'custom'"
              ref="custom_date_end"
              v-model="custom_date_end_menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="custom_date_end"
                  outlined
                  dense
                  small
                  :label="$t('common.end')"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="custom_date_end" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  height="40px"
                  @click="$refs.custom_date_end.save()"
                >{{ $t('common.ok') }}</v-btn>
              </v-date-picker>
            </v-menu>
          </div>
        </v-col>
      </v-row>
      <div v-if="select_all" style="margin-top: 30px">
        <div v-for="(item, index) in exercise_tree" :key="index" style="min-height: 550px">
          <RegimenReportCard
            v-if="item.reports.length > 0 && get_exercise(item)"
            ref="regimen_report_card_list"
            :exercise="get_exercise(item)"
            :reports="filter_reports(item.reports)"
            :plotly_style="plotly_style"
            :max_ticks="20"
            :regimen="regimen"
            :allow_edit_assigned="allow_edit_assigned"
            @regimen-updated="load_regimen"
          />
        </div>
      </div>
      <div v-else>
        <div style="min-height: 550px">
          <RegimenReportCard
            v-if="filtered_reports.length > 0 && get_exercise(selected_node)"
            ref="regimen_report_card"
            :exercise="get_exercise(selected_node)"
            :reports="filtered_reports"
            :plotly_style="plotly_style"
            :max_ticks="20"
            :show_toolbar="false"
            :regimen="regimen"
            @regimen-updated="load_regimen"
          />
          <div v-else style="min-height: 550px">
            <v-sheet
              v-if="loading"
              elevation="5"
              style="padding: 20px; margin-right: 20px;  margin-left: 20px"
            >
              {{ $t('regimen_report.loading_exercise_reports') }}
              <v-progress-circular indeterminate color="primary" :size="20"></v-progress-circular>
            </v-sheet>
            <v-sheet
              v-if="!loading && !reports.length"
              elevation="5"
              style="padding: 20px; margin-right: 20px;  margin-left: 20px; margin-top: 20px"
            >{{ $t('regimen_report.user_not_completed_exercises_text') }}</v-sheet>

            <v-sheet
              v-if="!loading && reports.length && !selected_node"
              elevation="5"
              style="margin-left: 500px; padding: 20px; margin-right: 20px"
            >{{ $t('regimen_report.select_exercise_get_started') }}</v-sheet>

            <v-sheet
              v-if="!loading && reports.length && selected_node"
              elevation="5"
              style="padding: 20px; margin-right: 20px;  margin-left: 20px"
            >{{ $t('regimen_report.none_in_date_range') }}</v-sheet>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import hat from "hat";
import Vue from "vue";
import RegimenReportCard from "./RegimenReportCard";
import { mapState } from "vuex";
import jQuery from "jquery";

export default {
  components: { RegimenReportCard },
  props: {
    invite_id: {
      type: String,
      required: true,
    },
    clinic_id: {
      type: String,
      required: true,
    },
    exercises: {
      type: Object,
      required: true,
      validator: function (value) {
        return typeof value == "object" || typeof value == "undefined";
      },
    },
    video_exercises: {
      type: Object,
      required: true,
      validator: function (value) {
        return typeof value == "object" || typeof value == "undefined";
      },
    },
    user: {
      type: Object,
      default: null,
      required: false,
    },
    toolbar_close: {
      type: Boolean,
      default: () => true,
    },
    allow_edit_assigned: {
      type: Boolean,
      default: () => true,
    },
  },
  data: () => ({
    reports: [],
    exercise_tree: [],
    earliest_report_start: 32509585958 * 1000,
    latest_report_start: 0,
    selected_node_id: "",
    selected_node: null,
    selected_exercise_node: null,
    selected_variant_node: null,
    selected_instance_node: null,
    export_menu: false,
    toggle_date_select: "all_time",
    custom_date_start: "",
    custom_date_end: "",
    custom_date_start_menu: false,
    custom_date_end_menu: false,
    available_date_start: "",
    available_date_end: "",
    loading: true,
    select_all: false,
    regimen: null,
    export_formats: [
      {
        value: "csv",
        title: "CSV",
        icon: "fas fa-file-csv",
        filename: "report.csv",
      },
      {
        value: "json",
        title: "JSON",
        icon: "fas fa-file-alt",
        filename: "report.json",
      },
    ],
  }),
  computed: {
    ...mapState(["small_screen", "current_clinic", "app_config"]),
    plotly_style() {
      let style = "height: 400px; width: 800px; margin-left: -8px; margin-right: -10px";

      if (this.small_screen) {
        style = "height: 400px; width: 580px; margin-left: -8px; margin-right: -10px";
      }

      return style;
    },
    exercise_node_child_type() {
      if (!this.selected_exercise_node) {
        return "";
      }
      if (this.selected_exercise_node.children.length == 0) {
        return "";
      } else return this.selected_exercise_node.children[0].type;
    },
    format_urls() {
      return {
        csv: this.csv_url,
        json: this.report_url,
      };
    },
    report_url() {
      return "/api/invite/" + this.invite_id + "/regimen/reports";
    },
    csv_url() {
      return "/api/invite/" + this.invite_id + "/regimen/report.csv";
    },
    filtered_reports() {
      if (!this.selected_node) {
        return [];
      }
      return this.filter_reports(this.selected_node.reports);
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.load_reports();
      this.load_regimen();
    },
    load_regimen() {
      if (this.clinic_id == this.current_clinic.id && this.current_clinic.tag_value("canary") === true) {
        axios.get("/api/user/" + this.user.id + "/regimen/" + this.clinic_id).then((response) => {
          let resp = response.data;
          if (resp.status == "error") {
            messageBus.$emit("api-error", resp);
          } else {
            if (resp.data) {
              this.regimen = resp.data;
            }
          }
        });
      }
    },
    filter_reports(reports) {
      if (this.toggle_date_select == "all_time") {
        return reports;
      } else if (this.toggle_date_select == "last_month") {
        return reports.filter((report) => {
          return report.start_time > Date.now() - 30 * 24 * 60 * 60 * 1000;
        });
      } else if (this.toggle_date_select == "last_week") {
        return reports.filter((report) => {
          return report.start_time > Date.now() - 7 * 24 * 60 * 60 * 1000;
        });
      } else if (this.toggle_date_select == "custom") {
        let date_start = Date.parse(this.custom_date_start) - 12 * 60 * 60 * 1000;
        let date_end = Date.parse(this.custom_date_end) + 24 * 60 * 60 * 1000;
        return reports.filter((report) => {
          return report.start_time >= date_start && report.start_time <= date_end;
        });
      }
    },
    set_select_all() {
      this.select_all = true;
      this.selected_node_id = "";
      this.selected_node = null;

      this.toggle_date_select = "all_time";
      this.compute_available_date();
      this.custom_date_start = this.available_date_start;
      this.custom_date_end = this.available_date_end;

      Vue.nextTick(() => {
        if (this.$refs.regimen_report_card_list) {
          for (let ref of this.$refs.regimen_report_card_list) {
            ref.load();
          }
        }
      });
    },
    compute_available_date() {
      let reports = [];
      if (this.select_all) {
        reports = this.reports;
      } else {
        if (!this.selected_node || this.selected_node.reports.length == 0) {
          return;
        }
        reports = this.selected_node.reports;
      }

      if (reports.length == 0) {
        return;
      }

      this.available_date_start = reports[0].created.substr(0, 10);
      this.available_date_end = reports[0].created.substr(0, 10);

      for (let report of reports) {
        let report_date = report.created.substr(0, 10);
        if (report_date < this.available_date_start) {
          this.available_date_start = report_date;
        }
        if (report_date > this.available_date_end) {
          this.available_date_end = report_date;
        }
      }
    },
    get_exercise(node) {
      if (node.exercise_type == "interactive" || !node.exercise_type) {
        return this.exercises[node.exercise_id];
      } else if (node.exercise_type == "custom") {
        return {
          title: this.$t("common.custom_one_off"),
          exercise_type: "custom",
        };
      } else if (node.exercise_type == "questionnaire") {
        return {
          title: node.title,
          exercise_type: "questionnaire",
        };
      } else if (node.exercise_type == "question") {
        return {
          title: node.title,
          exercise_type: "question",
        };
      } else {
        return this.video_exercises[node.exercise_id];
      }
    },
    close() {
      this.$emit("close", {});
    },
    get_tree_node(exercise_id, variant_id = null, instance_id = null) {
      for (var i = 0; i < this.exercise_tree.length; i++) {
        let exercise_node = this.exercise_tree[i];
        if (exercise_node.exercise_id == exercise_id) {
          if (!variant_id && !instance_id) {
            return exercise_node;
          } else {
            for (var j = 0; j < exercise_node.children.length; j++) {
              let child_node = exercise_node.children[j];
              if (variant_id && !instance_id) {
                if (child_node.type == "variant" && child_node.variant_id == variant_id) {
                  return child_node;
                }
              } else if (instance_id && !variant_id) {
                if (child_node.type == "instance" && child_node.instance_id == instance_id) {
                  return child_node;
                }
              } else {
                // Decend into grandchild nodes if the variant matches
                if (child_node.type == "variant" && child_node.variant_id == variant_id) {
                  for (var k = 0; k < child_node.children.length; k++) {
                    let instance_node = child_node.children[k];
                    if (instance_node.instance_id == instance_id) {
                      return instance_node;
                    }
                  }
                }
              }
            }
          }
        }
      }

      // Not found
      return null;
    },
    exercise_title(exercise) {
      // TODO: This should be possible since exercise should always exist
      if (!exercise) {
        return "Unknown or Deleted Exercise";
      }
      if (exercise.exercise_type == "questionnaire") {
        return "Questionnaire";
      }
      if (exercise.exercise_type == "question") {
        return "Question";
      }
      if (typeof exercise.title == "string") {
        return exercise.title;
      }
      let locale = this.$i18n.lang();
      if (exercise.title[locale]) {
        return exercise.title[locale];
      } else {
        return exercise.title.en;
      }
    },
    select_node(node) {
      this.select_all = false;
      this.selected_node_id = node.id;
      this.selected_node = node;

      if (node.type == "exercise") {
        this.selected_exercise_node = node;
        this.selected_variant_node = null;
        this.selected_instance_node = null;
      }
      if (node.type == "variant") {
        this.selected_variant_node = node;
        this.selected_instance_node = null;
      }
      if (node.type == "instance") {
        this.selected_instance_node = node;
      }

      this.toggle_date_select = "all_time";
      this.compute_available_date();
      this.custom_date_start = this.available_date_start;
      this.custom_date_end = this.available_date_end;

      Vue.nextTick(() => {
        if (this.$refs.regimen_report_card) {
          this.$refs.regimen_report_card.load();
        }
      });
    },
    unselect_variant() {
      this.selected_variant_node = null;
      this.selected_instance_node = null;
      this.selected_node = this.selected_exercise_node;

      this.toggle_date_select = "all_time";
      this.compute_available_date();
      this.custom_date_start = this.available_date_start;
      this.custom_date_end = this.available_date_end;
    },
    unselect_instance() {
      this.selected_instance_node = null;
      if (this.selected_variant_node) {
        this.selected_node = this.selected_variant_node;
      } else {
        this.selected_node = this.selected_exercise_node;
      }

      this.toggle_date_select = "all_time";
      this.compute_available_date();
      this.custom_date_start = this.available_date_start;
      this.custom_date_end = this.available_date_end;
    },
    build_exercise_tree() {
      // Date Formatter
      var date_formatter_options = {
        month: "short",
        day: "numeric",
        year: "numeric",
      };
      let date_formatter = new Intl.DateTimeFormat(this.$i18n.lang(), date_formatter_options);

      this.exercise_tree = [];
      for (var i in this.reports) {
        let report = this.reports[i];

        // Filter out all assessment reports
        // TODO: Add support for assessment reports
        if (report.tags.includes("assessment")) continue;

        this.earliest_report_start = Math.min(report.start_time, this.earliest_report_start);
        this.latest_report_start = Math.max(report.start_time, this.latest_report_start);

        let exercise_node = null;
        let variant_node = null;
        let instance_node = null;

        let exercise_id = report.exercise_id;
        let variant_id = report.params.variant ? report.params.variant : null;
        let instance_id = report.regimen_instance_id ? report.regimen_instance_id : null;

        let instance_title = report.regimen_instance_title;

        var exercise;
        if (report.exercise_type == "interactive") {
          exercise = this.exercises[exercise_id];
        }
        if (report.exercise_type.includes("video")) {
          exercise = this.video_exercises[exercise_id];
        }
        if (report.exercise_type == "questionnaire") {
          exercise = report.params;
          exercise.exercise_type = "questionnaire";
        }
        if (report.exercise_type == "question") {
          exercise = report.params;
          exercise.exercise_type = "question";
        }
        if (report.exercise_type == "custom") {
          exercise = {
            title: this.$t("common.custom_one_off"), // TODO: The title needs to be added to report
            exercise_type: "custom",
          };
        }
        let exercise_title = this.exercise_title(exercise);

        exercise_node = this.get_tree_node(exercise_id);
        if (exercise_node) {
          exercise_node.reports.push(report);
        } else {
          let new_node = {
            id: hat(),
            exercise_id: exercise_id,
            exercise_type: report.exercise_type,
            node_title: exercise_title,
            variant_id: null,
            instance_id: null,
            instance_title: null,
            reports: [report],
            children: [],
            type: "exercise",
            start: report.start_time,
            end: report.start_time,
            expanded: false,
            selected: false,
          };
          this.exercise_tree.push(new_node);
          exercise_node = this.get_tree_node(exercise_id);

          if (exercise_node) {
            exercise_node.start = Math.min(exercise_node.start, report.start_time);
            exercise_node.end = Math.max(exercise_node.end, report.start_time);
          }
        }

        if (variant_id) {
          variant_node = this.get_tree_node(exercise_id, variant_id);
          if (variant_node) {
            variant_node.reports.push(report);
          } else {
            let node_title = variant_id;
            let exercise = this.exercises[exercise_id];
            if (exercise && exercise.variants[variant_id]) {
              let locale = this.$i18n.lang();
              if (exercise.variants[variant_id][locale]) {
                node_title = exercise.variants[variant_id][locale];
              } else {
                node_title = exercise.variants[variant_id].en;
              }
            }

            let new_node = {
              id: hat(),
              exercise_id: exercise_id,
              exercise_type: report.exercise_type,
              node_title: node_title,
              variant_id: variant_id,
              instance_id: null,
              instance_title: null,
              reports: [report],
              children: [],
              type: "variant",
              start: report.start_time,
              end: report.start_time,
              expanded: false,
              selected: false,
            };
            exercise_node.children.push(new_node);
            variant_node = this.get_tree_node(exercise_id, variant_id);
          }

          if (variant_node) {
            variant_node.start = Math.min(variant_node.start, report.start_time);
            variant_node.end = Math.max(variant_node.end, report.start_time);
          }
        }

        if (report.regimen_instance_id) {
          instance_node = this.get_tree_node(exercise_id, variant_id, instance_id);

          if (instance_node) {
            instance_node.reports.push(report);

            if (!instance_node.instance_title && report.regimen_instance_title) {
              instance_node.instance_title = report.regimen_instance_title;
            }
          } else {
            let new_node = {
              id: hat(),
              exercise_id: exercise_id,
              exercise_type: report.exercise_type,
              node_title: "",
              variant_id: variant_id,
              instance_id: instance_id,
              instance_title: instance_title,
              reports: [report],
              children: [],
              type: "instance",
              start: report.start_time,
              end: report.start_time,
              expanded: false,
              selected: false,
            };
            if (variant_node) {
              variant_node.children.push(new_node);
            } else {
              exercise_node.children.push(new_node);
            }
            instance_node = this.get_tree_node(exercise_id, variant_id, instance_id);
          }

          if (instance_node) {
            instance_node.start = Math.min(instance_node.start, report.start_time);
            instance_node.end = Math.max(instance_node.end, report.start_time);

            let start_date = new Date(instance_node.start);
            let end_date = new Date(instance_node.end);
            if (instance_node.start == instance_node.end) {
              instance_node.node_title = date_formatter.format(start_date);
            } else {
              instance_node.node_title = date_formatter.format(start_date) + " - " + date_formatter.format(end_date);
            }
          }
        }
      }

      // Sort the exercise tree
      this.exercise_tree.sort(function (a, b) {
        if (a.node_title < b.node_title) {
          return -1;
        }
        if (a.node_title > b.node_title) {
          return 1;
        }
        return 0;
      });
      // Sort exercise tree children and grandchildren
      for (let c_i in this.exercise_tree) {
        let exercise_node = this.exercise_tree[c_i];
        exercise_node.children.sort(function (a, b) {
          if (a.node_title < b.node_title) {
            return -1;
          }
          if (a.node_title > b.node_title) {
            return 1;
          }
          return 0;
        });

        if (exercise_node.children && exercise_node.children.length) {
          for (let gc_i in exercise_node.children) {
            let gc_exercise_node = exercise_node.children[gc_i];

            if (gc_exercise_node.children && gc_exercise_node.children.length) {
              gc_exercise_node.children.sort(function (a, b) {
                if (a.node_title < b.node_title) {
                  return -1;
                }
                if (a.node_title > b.node_title) {
                  return 1;
                }
                return 0;
              });
            }
          }
        }
      }
    },
    load_reports: function () {
      this.loading = true;
      axios.get(this.report_url).then((response) => {
        this.loading = false;
        if (response.data.status == "success") {
          this.reports = response.data.data;
          this.build_exercise_tree();

          // If there's only one node in the first part of the tree, select it, otherwise pop the menu
          if (this.exercise_tree.length == 1) {
            this.select_node(this.exercise_tree[0]);
          } else {
            Vue.nextTick(() => {
              if (jQuery(".regimen_report_exercise_list").length == 0) {
                if (this.$refs.exercise_menu_button) {
                  this.$refs.exercise_menu_button.$el.click();
                }
              }
            });
          }
        }
      });
    },
  },
};
</script>

<style>
.regimen_report_exercise_list {
  overflow-y: scroll;
  max-height: 580px;
  max-width: 400px;
}

.regimen_report_exercise_list .v-list-group__header__prepend-icon {
  display: none;
}

.regimen_report_exercise_click {
  cursor: pointer;
}
</style>