<template>
  <div>
    <v-sheet outlined rounded shaped style="margin-top: 16px">
      <div
        style="left: 35px; right: auto; margin-top: -16px; position: absolute; color: rgba(0, 0, 0, 0.6); background: white; padding: 5px;"
      >
        <v-icon small style="margin-top: -2px; margin-right: 3px;">mdi-share-variant</v-icon>
        {{ $t('common.community_sharing') }}
      </div>
      <v-layout justify-space-between>
        <v-flex xs4>
          <v-checkbox
            v-model="enabled"
            class="ml-3"
            :label="$t('community_sharing.share_with_community')"
            :indeterminate="value && value.auto_import"
            @change="value_change"
          ></v-checkbox>
        </v-flex>
        <v-flex :xs4="value && !value.auto_import" :xs8="!value || (value && value.auto_import)">
          <div class="mt-3" style="color: grey; padding-right: 20px">
            <p v-if="!value">{{ hint_disabled }} {{ $t('community_sharing.hint_second') }}</p>
            <p v-else :style="value.auto_import ? '' : 'padding-top: 10px'">
              {{ hint_enabled }} <template v-if="value && (value.auto_import || value.quality)">.</template>
              <span
                v-if="value && value.auto_import"
              >{{ $t('community_sharing.hint_auto_import') }}</span>
              <span
                v-else-if="value && !value.quality"
              > {{ $t('community_sharing.under_review') }}</span>
            </p>
          </div>
        </v-flex>
        <v-flex v-if="value && !value.auto_import" xs3 style="text-align: right">
          <v-btn
            small
            class="mt-5 mr-4"
            @click="open_description_tags_dialog"
          >{{ $t('community_sharing.edit_description_tags') }}</v-btn>
        </v-flex>
      </v-layout>
    </v-sheet>

    <v-dialog v-model="description_tags_dialog" max-width="800">
      <v-card v-if="description_tags_dialog">
        <v-card-title style="padding: 0px;">
          <v-toolbar grey flat>
            <v-btn icon @click="description_tags_dialog = false;">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ $t('common.community_sharing') }}<span v-if="item_title">: {{ item_title }}</span></v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="mt-4">
          <ol style="font-size: larger;" class="mt-6 mb-2 mr-6">
            <p class="mb-3">{{ $t('community_sharing.guidelines_1') }}  </p>
            <p class="mb-3"><strong>{{ $t('community_sharing.guidelines_2') }}</strong> {{ $t('community_sharing.guidelines_3') }}  </p>
            <p class="mb-3">{{ $t('community_sharing.guidelines_4') }}</p>
          </ol>

          <v-combobox
            v-model="tags"
            :items="tag_items"
            chips
            :label="$t('common.tags')"
            multiple
            solo
            outlined
            elevation="0"
            class="mt-6 ml-6 mr-6"
          >
            <template v-slot:selection="{ attrs, item, select, selected }">
              <v-chip
                v-bind="attrs"
                :input-value="selected"
                close
                @click="select"
                @click:close="remove_tag(item)"
              >{{ item }}</v-chip>
            </template>
          </v-combobox>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" class="mb-2" @click="add_descriptiom_tags();">{{ $t("common.ok") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";

export default {
  props: {
    value: {
      type: Object,
      default: null,
      validator: (prop) => typeof prop === "object" || prop === null || prop === undefined,
    },
    hint_disabled: {
      type: String,
      required: false,
      default: function () {
        return "This exercise is private to your clinic.";
      },
    },
    hint_enabled: {
      type: String,
      required: false,
      default: function () {
        return this.$t('community_sharing.hint_enabled_default')
      },
    },
    item_title: {
      type: String,
      required: false,
      default: function () {
        return "";
      },
    },
  },
  data() {
    return {
      enabled: !!this.value,
      stashed_value: null,
      auto_import: this.value && this.value.auto_import,
      description_tags_dialog: false,
      description: this.value ? this.value.description : "",
      tags: this.value ? this.value.tags : [],
      tag_items: [],
    };
  },
  watch: {
    value() {
      this.stashed_value = JSON.parse(JSON.stringify(this.value));
      this.enabled = !!this.value;
      this.auto_import = this.auto_import || (this.value && this.value.auto_import); // sticky

      if (this.value) {
        this.description = this.value.description;
        this.tags = this.value.tags;
      }
    },
  },
  methods: {
    value_change(enabled) {
      if (this.value) {
        this.stashed_value = JSON.parse(JSON.stringify(this.value));
      }

      if (enabled) {
        if (!this.value) {
          Vue.nextTick(() => {
            this.open_description_tags_dialog();
          });
        }

        if (this.stashed_value) {
          this.stashed_value.auto_import = false;
          this.stashed_value.description = this.description;
          this.stashed_value.tags = this.tags;
          this.$emit("input", this.stashed_value);
        } else {
          this.$emit("input", {
            hidden: false,
          });
        }
      } else {
        if (!this.auto_import) {
          this.$emit("input", null);
        } else if (this.stashed_value && !this.stashed_value.auto_import) {
          this.stashed_value.auto_import = true;
          this.$emit("input", this.stashed_value);
        } else {
          // They clicked from an indeterminate state, so make this like they clicked to enable
          Vue.nextTick(() => {
            this.enabled = true;
            this.value_change(true);
          });
        }
      }
    },

    open_description_tags_dialog() {
      axios.get("/api/shared/tags").then((resp) => {
        if (resp.data.status == "success") {
          this.tag_items = resp.data.data;
        }
        this.description_tags_dialog = true;
      });
    },

    add_descriptiom_tags() {
      this.description_tags_dialog = false;
      if (this.value) {
        this.stashed_value = JSON.parse(JSON.stringify(this.value));
        this.stashed_value.description = this.description;
        this.stashed_value.tags = this.tags;
        this.$emit("input", this.stashed_value);
      }
    },

    remove_tag(tag) {
      this.tags = this.tags.filter((t) => t !== tag);
    },
  },
};
</script>