<template>
  <v-stepper v-model="current_step" non-linear>
    <v-stepper-header>
      <v-stepper-step :complete="current_step > 1" step="1">
        {{ $t("common.select_exercise") }}
      </v-stepper-step>
      <v-divider />

      <v-stepper-step :complete="current_step > 2" step="2">
        {{ $t("regimen_exercise_meta_edit.configure_exercise") }}
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-container style="min-height: 400px">
          <p>
            {{ $t("regimen_exercise_meta_edit.select_type_exercise_text") }}
          </p>

          <v-layout>
            <v-flex md4>
              <SelectExercise
                v-model="localValue.exercise_id"
                prepend_icon="category"
                append_icon
                :label="$t('common.interactive_exercises')"
                exercise_type="interactive"
                @change="exercise_change()"
              />

              <SelectExercise
                v-model="localValue.exercise_id"
                prepend_icon="ondemand_video"
                append_icon
                :label="$t('common.video_exercises')"
                exercise_type="video"
                @change="video_change()"
              />

              <v-select v-model="select_other" prepend-icon="view_headline" :items="select_other_options" outlined>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title v-html="item.text"></v-list-item-title>
                    <v-list-item-subtitle v-html="item.subtitle"></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-select>
            </v-flex>
          </v-layout>
        </v-container>
      </v-stepper-content>

      <v-stepper-content step="2">
        <div style="min-height: 400px">
          <RegimenExerciseEdit v-if="localValue.exercise_type == 'interactive'" v-model="localValue" @save="save" />

          <RegimenVideoEdit
            v-if="localValue.exercise_type == 'video/clinic' || localValue.exercise_type == 'video/builtin' || localValue.exercise_type == 'video/public'"
            v-model="localValue"
            @save="save"
          />

          <CustomExerciseEdit v-if="localValue.exercise_type == 'custom'" v-model="localValue" @save="save" />

          <RegimenHeaderEdit v-if="localValue.exercise_type == 'header'" v-model="localValue" @save="save" />

          <RegimenQuestionnaireEdit v-if="localValue.exercise_type == 'questionnaire'" v-model="localValue" @save="save" />

          <RegimenQuestionEdit v-if="localValue.exercise_type == 'question'" v-model="localValue" @save="save" />

          <RegimenAssessmentEdit v-if="localValue.exercise_type == 'assessment'" v-model="localValue" @save="save" />

          <RegimenEmailEdit v-if="localValue.exercise_type == 'email'" v-model="localValue" @save="save" />
        </div>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import RegimenExerciseEdit from "./RegimenExerciseEdit";
import CustomExerciseEdit from "./CustomExerciseEdit";
import RegimenVideoEdit from "./RegimenVideoEdit";
import RegimenHeaderEdit from "./RegimenHeaderEdit";
import RegimenQuestionnaireEdit from "./RegimenQuestionnaireEdit";
import RegimenQuestionEdit from "./RegimenQuestionEdit";
import RegimenAssessmentEdit from "./RegimenAssessmentEdit";
import RegimenEmailEdit from "./RegimenEmailEdit";
import SelectExercise from "./SelectExercise";

import Vue from "vue";

import { mapState } from "vuex";

export default {
  components: {
    RegimenExerciseEdit,
    CustomExerciseEdit,
    RegimenVideoEdit,
    RegimenHeaderEdit,
    RegimenQuestionnaireEdit,
    RegimenQuestionEdit,
    RegimenAssessmentEdit,
    RegimenEmailEdit,
    SelectExercise,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    context: {
      type: String,
      required: true,
    },
  },
  data() {
    var current_step;
    if (this.value.exercise_type == "none" || !this.value.exercise_type) {
      current_step = 1;
    } else {
      current_step = 2;
    }

    let data = {
      exercise_type: this.value.exercise_type || "none", // [none, interactive, video/clinic, video/builtin, video/public, custom]
      errors: [],
      current_step,
    };

    // TODO: Remove this at some point in the future
    // Handle old data-format where we didn't have this.value.exercise_type defined and everything was implicitly 'interactive'.
    if (data.exercise_type == "none" && this.value.exercise_id) {
      data.exercise_type = "interactive";
    }

    // Select Other
    data.select_other = "";
    return data;
  },
  computed: {
    ...mapState(["exercises", "video_exercises", "app_config"]),
    localValue: {
      get() {
        return this.value;
      },
      set(localValue) {
        // TODO: Remove this at some point in the future
        // Handle old data-format where we didn't have value.exercise_type defined and everything was implicitly 'interactive'.
        if ((!localValue.exercise_type || localValue.exercise_type == "none") && this.value.exercise_id) {
          localValue.exercise_type = "interactive";
        }

        this.$emit("input", localValue);
      },
    },

    select_other_options() {
      let select_other_options = [
        { text: this.$t("regimen_exercise_meta_edit.other_misc"), value: "" },
        {
          text: this.$t("regimen_exercise_meta_edit.one_off"),
          subtitle: this.$t("regimen_exercise_meta_edit.one_off_desc"),
          value: "custom",
        },
        {
          text: this.$t("regimen_exercise_meta_edit.section_header"),
          subtitle: this.$t("regimen_exercise_meta_edit.section_header_desc"),
          value: "header",
        },
        {
          text: this.$t("regimen_exercise_meta_edit.single_question"),
          subtitle: this.$t("regimen_exercise_meta_edit.single_question_subheader"),
          value: "question",
        },
        {
          text: this.$t("regimen_exercise_meta_edit.questionnaire"),
          subtitle: this.$t("regimen_exercise_meta_edit.questionnaire_subheader"),
          value: "questionnaire",
        },
        {
          text: this.$t("common.assessment"),
          subtitle: this.$t("regimen_exercise_meta_edit.assessment_subheader"),
          value: "assessment",
        },
      ];

      // Email is only valid on grid schedules and makes no sense in other contexts
      if (this.context == "grid_schedule") {
        select_other_options.push({
          text: this.$t("common.email"),
          subtitle: this.$t("regimen_exercise_meta_edit.email_desc"),
          value: "email",
        });
      }

      // Filter select_other_options by what is enabled in the app_config.exercises
      select_other_options = select_other_options.filter((option) => {
        return this.app_config.exercise_types.includes(option.value);
      });

      return select_other_options;
    },
  },
  watch: {
    select_other(val) {
      if (val == "custom") {
        this.localValue.exercise_type = "custom";
        this.current_step = 2;
      }
      if (val == "header") {
        this.localValue.exercise_type = "header";
        this.current_step = 2;
      }
      if (val == "question") {
        this.localValue.exercise_type = "question";
        this.current_step = 2;
      }
      if (val == "questionnaire") {
        this.localValue.exercise_type = "questionnaire";
        this.current_step = 2;
      }
      if (val == "assessment") {
        this.localValue.exercise_type = "assessment";
        this.current_step = 2;
      }
      if (val == "email") {
        this.localValue.exercise_type = "email";
        this.current_step = 2;
      }
      Vue.nextTick(() => (this.select_other = ""));
    },
    localValue() {
      if (this.value.exercise_type == "none" || !this.value.exercise_type) {
        this.current_step = 1;
      } else {
        this.current_step = 2;
      }
    },
  },
  methods: {
    // We've selected an interactive exercise
    exercise_change() {
      this.localValue.exercise_type = "interactive";

      // On exercise change, we replace the config wholesale
      // TODO: Leave this for RegimenExerciseEdit to handle ??
      let exercise = this.exercises[this.localValue.exercise_id];
      this.localValue.config = JSON.parse(JSON.stringify(exercise.global));
      if (exercise.num_levels > 1) {
        this.localValue.level = 1;
        this.localValue.max_level = exercise.num_levels;
      } else {
        this.localValue.level = 0;
        this.localValue.max_level = 0;
      }

      this.localValue.config_version = exercise.config_version;

      this.current_step = 2;
    },

    // We've selected a video exercise
    video_change() {
      let exercise_id = this.localValue.exercise_id;

      if (!exercise_id) {
        return;
      }

      let video = this.video_exercises[exercise_id];
      this.localValue.exercise_type = "video/" + video.origin;

      // TODO - configure it in step 2
      this.current_step = 2;
    },

    save() {
      this.$emit("save", this.localValue);
    },
  },
};
</script>

<style></style>
