<template>
  <v-card>
    <v-card-title style="padding: 0px;">
      <v-toolbar grey flat>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span>
            {{ local_video.title || $t("video_exercise_edit.new_video_exercise") }}
            <v-chip
              v-if="local_video.share_source"
              label
              small
              outlined
              class="mt-0 ml-5"
              :href="'/app/shared/clinic/' + local_video.share_source.clinic_id"
              rget="_blank"
            >{{ $t('community_content.clinic_attribution', {'clinic_title': local_video.share_source.clinic_title }) }}</v-chip>
          </span>
        </v-toolbar-title>
      </v-toolbar>
    </v-card-title>
    <v-divider />
    <v-card-text class="mt-6">
      <v-layout>
        <v-flex xs8>
          <v-text-field
            ref="title"
            v-model="local_video.title"
            outlined
            :label="$t('common.title')"
            type="text"
          />
        </v-flex>
        <v-flex xs3 offset-xs1>
          <!-- TOOD: group vs exercise_group -->
          <v-select
            ref="category"
            v-model="local_video.exercise_group"
            :label="$t('common.category')"
            :items="group_options"
            outlined
          />
        </v-flex>
      </v-layout>

      <v-text-field
        ref="description"
        v-model="local_video.description"
        outlined
        :label="$t('common.description')"
      />

      <v-layout justify-space-between>
        <v-flex xs3>
          <v-select
            v-model="upload_type"
            :items="upload_type_options"
            outlined
            :disabled="video_upload_in_progress"
          ></v-select>
        </v-flex>
        <v-flex xs8>
          <div v-if="upload_type == 'link'">
            <v-text-field
              v-model="local_video.video"
              :label="$t('common.video_link')"
              type="text"
              outlined
              prepend-icon="mdi-link"
              :rules="video_rules"
              @change="check_video_link"
            />

            <p style="margin-bottom: 20px; margin-top: -15px; margin-left: 40px">
              <i18n path="video_exercise_edit.video_link_help">
                <a
                  href="https://help.ocudigital.com/article/22-custom-exercises"
                  target="_blank"
                >https://help.ocudigital.com/article/22-custom-exercises</a>
              </i18n>
            </p>
          </div>

          <div v-else-if="upload_type == 'sprout'">
            <v-file-input
              v-model="file_upload"
              accept="video/*"
              :label="$t('video_exercise_edit.selet_video')"
              prepend-icon="mdi-video"
              outlined
            ></v-file-input>

            <v-layout v-if="video_upload_in_progress" justify-space-between class="ml-8">
              <v-flex xs8>
                <v-progress-linear
                  v-model="loaded_percent"
                  class="ml-1"
                  color="light-blue accent-4"
                  striped
                  height="20"
                >{{ loaded_percent == 100 ? $t('video_exercise_edit.processing') : Math.ceil(loaded_percent) + '%' }}</v-progress-linear>
              </v-flex>

              <v-flex xs2>
                <v-btn style="margin-top: -10px" @click="cancel_upload">
                  <v-icon left color="red">mdi-cancel</v-icon>
                  {{ $t('common.cancel') }}
                </v-btn>
              </v-flex>
            </v-layout>

            <v-alert
              v-if="video_error"
              type="error"
            >{{ $t('video_exercise_edit.video_error') + ": " + video_error }}</v-alert>

            <v-alert
              v-if="upload_success"
              type="success"
              outlined
              dense
              class="ml-8"
            >{{ $t('video_exercise_edit.video_upload_success') }}</v-alert>
          </div>

          <div v-else-if="upload_type == 'copy'">
            <SelectExercise
              v-model="copy_video_id"
              prepend_icon="ondemand_video"
              :append_icon="''"
              exercise_type="video"
              :label="$t('common.select_exercise')"
              @change="copy_existing_selected"
            />

            <v-select
              v-if="copy_video_id && variant_options.length > 0"
              v-model="copy_video_variant"
              outlined
              class="ml-8"
              :items="variant_options"
              :label="$t('common.variant')"
              @change="copy_existing_variant_selected"
            ></v-select>
          </div>
        </v-flex>
      </v-layout>

      <p>{{ $t("video_exercise_edit.instructions") }}</p>
      <quillEditor
        v-model="local_video.instructions"
        :options="quill_config"
        :disabled="images_uploading"
        style="height: 200px; margin-bottom: 20px"
      />

      <CommunitySharing
        v-if="app_config.community_sharing && (video_upload_in_progress || local_video.video || local_video.shared) && upload_type != 'copy' && !local_video.share_source"
        v-model="local_video.shared"
        :item_title="local_video.title"
        style="margin-top: 65px"
      />
    </v-card-text>

    <v-card-actions>
      <v-btn v-if="show_delete && local_video.id" small color="red" outlined class="ml-3" @click="delete_exercise">
        <v-icon left>delete</v-icon>{{ $t("common.delete") }}
      </v-btn>

      <v-alert
        v-if="error_message"
        outlined
        dense
        class="ml-3 mt-2"
        type="error"
      >{{ error_message }}</v-alert>
      <v-spacer />

      <v-alert
        v-if="submit_entity_on_upload_finish"
        class="mr-4 mt-2"
        type="info"
        outlined
        dense
      >{{ $t('video_exercise_edit.waiting_for_upload_to_finish') }}</v-alert>
      <v-alert
        v-else-if="images_uploading"
        class="mr-4 mt-2"
        type="info"
        outlined
        dense
      >{{ $t('video_exercise_edit.waiting_for_image_upload_to_finish') }}</v-alert>
      <v-btn
        v-if="video.id"
        class="ma-4"
        color="primary"
        :disabled="submit_entity_on_upload_finish || images_uploading"
        @click="submit()"
      >{{ $t("video_exercise_edit.update_video_exercise") }}</v-btn>
      <v-btn
        v-else
        class="ma-4"
        color="primary"
        :disabled="submit_entity_on_upload_finish || images_uploading"
        @click="submit()"
      >{{ $t("video_exercise_edit.create_video_exercise") }}</v-btn>
    </v-card-actions>
    <v-progress-linear v-if="in_flight || images_uploading" :indeterminate="true" />
  </v-card>
</template>

<script>
import "quill/dist/quill.snow.css";
import axios from "axios";
import urlParser from "js-video-url-parser";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

import { Quill, quillEditor } from "vue-quill-editor";
import ImageResize from "quill-image-resize-vue";
Quill.register("modules/imageResize", ImageResize);

import SelectExercise from "./SelectExercise";
import CommunitySharing from "./CommunitySharing";
import { mapState } from "vuex";

import { category_items } from "../lib/category";

export default {
  components: { quillEditor, SelectExercise, CommunitySharing },
  props: {
    video: {
      type: Object,
      required: true,
    },
    clinic_title: {
      type: String,
      required: true,
    },
    show_delete: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let upload_type_options = [
      { text: this.$t("video_exercise_edit.upload_video"), value: "sprout" },
      { text: this.$t("video_exercise_edit.link_video"), value: "link" },
    ];
    if (!this.video.id) {
      upload_type_options.push({
        text: this.$t("video_exercise_edit.copy_existing"),
        value: "copy",
      });
    }

    let upload_type = "sprout";
    if (this.video.id && this.video.video && !this.video.video.includes("sproutvideo")) {
      upload_type = "link";
    }

    let data = {
      error_message: "",
      local_video: Object.assign({}, this.video),
      in_flight: false,
      video_rules: [],
      video_type: "",
      video_error: "",
      file_upload: null,
      upload_type,
      upload_type_options,
      copy_video_id: "",
      copy_video_variant: "",
      loaded_percent: 0,
      upload_success: false,
      xhr: null,
      video_upload_in_progress: false,
      submit_entity_on_upload_finish: false,
      images_uploading: false,
      quill_config: {
        placeholder: this.$t("common.instructions"),
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],
            [{ indent: "-1" }, { indent: "+1" }],
            ["image"],

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],

            // [{ 'direction': 'rtl' }], TODO: For Hebrew etc

            ["clean"], // remove formatting button
          ],
          imageResize: {},
        },
      },
      group_options: category_items(this, false, window.app_config.id),
    };
    return data;
  },
  computed: {
    ...mapState(["video_exercises", "app_config", "file_base_url"]),
    variant_options() {
      if (this.copy_video_id && this.video_exercises[this.copy_video_id]) {
        let copy_video = this.video_exercises[this.copy_video_id];
        if (copy_video.variants && Object.keys(copy_video.variants).length > 0) {
          let lang = this.$i18n.lang();
          let options = [];
          for (const [key, variant] of Object.entries(copy_video.variants)) {
            let option = {
              text: variant.title[lang],
              value: key,
            };
            options.push(option);
          }
          return options;
        }
      }

      return [];
    },
  },
  watch: {
    video() {
      this.local_video = Object.assign({}, this.video);
    },
    file_upload() {
      if (this.upload_type == "sprout" && this.file_upload) {
        this.upload_sprout_video();
      }
    },
  },
  methods: {
    cancel_upload(video_error = "") {
      if (this.xhr) {
        this.xhr.abort();
      }
      this.video_upload_in_progress = false;
      this.submit_entity_on_upload_finish = false;
      this.file_upload = null;
      this.loaded_percent = 0;
      this.video_error = video_error;
      this.upload_success = false;
    },
    copy_existing_selected() {
      if (this.copy_video_id && this.video_exercises[this.copy_video_id]) {
        let lang = this.$i18n.lang();
        let copy_value = this.video_exercises[this.copy_video_id];

        this.local_video.exercise_group = copy_value.group[0];
        this.local_video.video = copy_value.default.video;

        if (copy_value.title[lang]) {
          this.local_video.title = copy_value.title[lang];
        } else {
          this.local_video.title = copy_value.title["en"];
        }

        if (copy_value.instructions[lang]) {
          this.local_video.instructions = copy_value.instructions[lang];
        } else {
          this.local_video.instructions = copy_value.instructions["en"];
        }

        if (copy_value.description[lang]) {
          this.local_video.description = copy_value.description[lang];
        } else {
          this.local_video.description = copy_value.description["en"];
        }

        this.copy_video_variant = "";
      }
    },
    copy_existing_variant_selected() {
      if (
        this.copy_video_id &&
        this.video_exercises[this.copy_video_id] &&
        this.video_exercises[this.copy_video_id].variants[this.copy_video_variant]
      ) {
        let variant = this.video_exercises[this.copy_video_id].variants[this.copy_video_variant];
        this.local_video.video = variant.video;
      }
    },
    check_video_link() {
      if (this.local_video.video !== "") {
        let video_url = this.local_video.video.trim();
        let url_parts = this.parse_url(video_url);
        if (url_parts.pathname.endsWith(".mp4")) {
          this.video_rules = [];
          this.video_type = "mp4";
          return true;
        }
        if (url_parts.pathname.endsWith(".webm")) {
          this.video_rules = [];
          this.video_type = "webm";
          return true;
        } else if (url_parts.host == "drive.google.com") {
          this.video_rules = [];
          let match = video_url.match(/https:\/\/drive\.google\.com\/file\/d\/([\w-]+)\/?.*/);
          if (!match || match.length < 2) {
            this.video_rules.push(this.$t("video_exercise_edit.not_valid_google_drive_url"));
            return false;
          }
          this.video_type = "google_drive";
          return true;
        } else if (url_parts.host == "videos.sproutvideo.com") {
          this.video_type = "sproutvideo";
          return true;
        } else {
          let parsed = urlParser.parse(video_url);
          if (!parsed) {
            this.video_rules = [this.$t("common.currently_support_videos")];
            return false;
          }
          if (parsed.provider != "youtube" && parsed.provider != "vimeo") {
            this.video_rules = [this.$t("common.currently_support_videos")];
            return false;
          }
          this.video_type = parsed.provider;
          this.video_rules = [];
          video_url = urlParser.create({
            videoInfo: parsed,
            format: "embed",
          });
          return true;
        }
      } else if (this.local_video.video === "") {
        this.video_rules = [];
        return true;
      }
    },
    close: function () {
      if (this.video_upload_in_progress) {
        if (!window.confirm(this.$t("video_exercise_edit.confirm_close"))) {
          return;
        }
        this.cancel_upload();
      }

      this.reset();
      this.$emit("close");
    },
    delete_exercise() {
      if (!confirm(this.$t("common.delete_item", { item_title: this.local_video.title }))) {
        return;
      }

      axios.delete("/api/video_exercise/" + this.local_video.id).then((response) => {
        if (response.data.status == "success") {
          messageBus.$emit(
            "success",
            this.$t("clinic_page_manage_videos.video_deleted")
          );
          this.reset();
          this.$emit("video-deleted", this.local_video.id);
        } else {
          messageBus.$emit("error", response.data.error_message);
        }
      });
    },
    verify: function () {
      // Todo: optionally rewrite exercise_group to [group]

      if (!this.local_video.title) {
        this.error_message = this.$t("common.title_required");
        this.$refs.title.focus();
        return false;
      }

      // Require description and category if sharing
      if (this.local_video.shared) {
        if (!this.local_video.description) {
          this.error_message = this.$t("common.description_required_for sharing");
          this.$refs.description.focus();
          return false;
        }

        if (!this.local_video.exercise_group) {
          this.error_message = this.$t("video_exercise_edit.category_required_for sharing");
          this.$refs.category.focus();
          return false;
        }
      }

      if (this.upload_type != "sprout") {
        return this.check_video_link();
      } else {
        return true;
      }
    },
    submit: function () {
      this.error_message = "";
      if (!this.verify()) {
        return;
      }
      if (this.upload_type == "sprout" && this.file_upload) {
        if (this.video_upload_in_progress) {
          this.submit_entity_on_upload_finish = true;
        } else if (this.upload_success) {
          this.post_video_entity();
        } else {
          this.upload_sprout_video(this.post_video_entity);
        }
      } else {
        this.post_video_entity();
      }
    },
    upload_sprout_video(callback) {
      // Get Upload token
      let self = this;
      self.video_upload_in_progress = true;
      self.video_error = "";
      self.upload_success = false;
      axios.post("/api/sprout/upload_token").then(function (response) {
        let resp = response.data;
        if (resp.status == "success") {
          let upload_token = resp.data;

          var formData = new FormData();

          formData.set("source_video", self.file_upload);
          formData.set("token", upload_token.token);
          formData.set("folder_path", "nvt");
          formData.set("description", self.clinic_title);
          formData.set("tag_names", "clinic_id:" + self.local_video.clinic_id);

          self.xhr = new XMLHttpRequest();
          self.xhr.open("POST", "https://api.sproutvideo.com/v1/videos");
          self.xhr.responseType = "json";
          self.xhr.upload.onprogress = self.upload_progress;

          self.xhr.onload = function (_e) {
            if (this.status == 201) {
              let id = this.response.id;
              let security_token = this.response.security_token;
              self.local_video.video = "https://videos.sproutvideo.com/embed/" + id + "/" + security_token;

              self.loaded_percent = 0;
              self.video_upload_in_progress = false;
              self.upload_success = true;

              if (callback) {
                callback();
              }

              if (self.submit_entity_on_upload_finish) {
                self.submit_entity_on_upload_finish = false;
                self.post_video_entity();
              }
            } else {
              self.cancel_upload(this.response.error ? this.response.error : self.$t("common.unknown_error"));
            }
          };
          self.xhr.onerror = function (_e) {
            self.cancel_upload(self.$t("common.network_error"));
            self.video_upload_in_progress = false;
          };

          self.xhr.send(formData);
        } else {
          self.cancel_upload(self.$t("video_exercise_edit.token_error"));
          messageBus.$emit("api-error", resp);
        }
      });
    },
    image_upload_progress(progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      this.image_loaded_percent = percentCompleted;
    },
    upload_progress(progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      this.loaded_percent = percentCompleted;
    },
    reset() {
      if (this.xhr) {
        this.xhr.abort();
      }

      this.loaded_percent = 0;
      this.image_loaded_percent = 0;
      this.video_upload_in_progress = false;
      this.file_upload = null;
      this.error_message = "";
      this.submit_entity_on_upload_finish = false;
      this.local_video = Object.assign({}, this.video);
      this.video_error = "";
      this.upload_success = false;

      this.xhr = null;
    },
    extract_upload_image(callback) {
      // First find all base64 encoded images
      let regex = /src="data:(image\/[^;]+);base64,([^"]+)"/g;
      let promises = [];
      for (let match of this.local_video.instructions.matchAll(regex)) {
        let mime = match[1];
        let filetype = mime.split("/")[1];
        let image_binary = atob(match[2]);

        const byteNumbers = new Array(image_binary.length);
        for (let i = 0; i < image_binary.length; i++) {
          byteNumbers[i] = image_binary.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const image_file = new File([byteArray], "custom_exercise_image." + filetype);

        // Upload
        let formData = new FormData();
        formData.append("file", image_file);
        formData.append("title", "Image from custom exercise: " + this.local_video.title);
        formData.append("description", "");
        formData.append("category", "custom_exercise_image");
        formData.append("clinic_id", this.local_video.clinic_id);
        formData.append("filename", "custom_exercise_image." + filetype);
        formData.append("mime", mime);
        formData.append("public", true);

        let promise = axios.post("/api/file/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: self.image_upload_progress,
        });
        promises.push(promise);
      }

      if (promises.length > 0) {
        this.images_uploading = true;
        let replacements = [];
        let index = 0;
        Promise.all(promises).then((results) => {
          for (let response of results) {
            let resp = response.data;
            if (resp.status == "success") {
              let file_id = resp.data;
              replacements[index] = file_id;
            } else {
              messageBus.$emit("api-error", resp);
              replacements[index] = false;
            }
            index++;
          }

          let replace_index = 0;
          this.local_video.instructions = this.local_video.instructions.replaceAll(regex, (match, contents, _offset, _input_string) => {
            if (!replacements[replace_index]) {
              return "";
            }
            let filetype = contents.split("/")[1];
            let file_url =
              this.file_base_url + this.local_video.clinic_id + "/" + replacements[replace_index] + "/1.custom_exercise_image." + filetype;
            let replace = 'src="' + file_url + '"';
            replace_index++;
            return replace;
          });

          this.images_uploading = false;
          callback();
        });
      } else {
        this.images_uploading = false;
        callback();
      }
    },
    post_video_entity() {
      // TODO: Look at video_type, and reach out to check that it exists and is publically accessible
      let self = this;
      self.in_flight = true;
      let video_id = self.local_video.id || self.video.id;

      // Extract and upload images
      this.extract_upload_image(() => {
        if (video_id) {
          // Update
          let url = "/api/video_exercise/" + video_id;
          axios.put(url, self.local_video).then(function (response) {
            self.in_flight = false;
            let resp = response.data;
            if (resp.status == "success") {
              messageBus.$emit("success", self.$t("video_exercise_edit.video_exercise_updated"));
              self.$emit("video-updated", self.local_video);
              self.close();
            } else {
              messageBus.$emit("api-error", resp);
            }
          });
        } else {
          if (!this.verify()) {
            return;
          }

          // Create
          axios.post("/api/video_exercise", self.local_video).then(function (response) {
            self.in_flight = false;
            let resp = response.data;
            if (resp.status == "success") {
              messageBus.$emit("success", self.$t("video_exercise_edit.video_exercise_created"));
              self.local_video.id = resp.data;
              self.$emit("video-created", self.local_video);
              self.close();
            } else {
              messageBus.$emit("api-error", resp);
            }
          });
        }
      });
    },
    parse_url(url) {
      var parser = document.createElement("a"),
        searchObject = {},
        queries,
        split,
        i;

      // Let the browser do the work
      parser.href = url;

      // Convert query string to object
      queries = parser.search.replace(/^\?/, "").split("&");
      for (i = 0; i < queries.length; i++) {
        split = queries[i].split("=");
        searchObject[split[0]] = split[1];
      }

      return {
        protocol: parser.protocol,
        host: parser.host,
        hostname: parser.hostname,
        port: parser.port,
        pathname: parser.pathname,
        search: parser.search,
        searchObject: searchObject,
        hash: parser.hash,
      };
    },
  },
};
</script>

<style>
.ql-editor {
  font-size: large;
}

.theme--dark .ql-snow .ql-stroke {
  stroke: gray;
}

.theme--light .ql-editor p {
  color: black;
}
</style>
