<template>
  <div>
    <v-sheet>
      <v-container>
        <v-layout justify-space-around>
          <v-flex xs4>
            <v-select v-model="entity_type" outlined dense label="Type" :items="type_items" />
          </v-flex>
          <v-flex xs4>
            <v-text-field v-model="entity_id" outlined dense placeholder="Entity ID" />
          </v-flex>
          <!-- <v-flex xs4>
            <v-text-field outlined dense placeholder="Clinic ID" v-model="clinic_id" />
          </v-flex>
          -->
        </v-layout>
      </v-container>
    </v-sheet>

    <div class="text-center pt-2">
      <v-pagination v-model="page" :length="pageCount" :total-visible="11"></v-pagination>
    </div>

    <v-data-table
      :headers="headers"
      :items="revisions"
      :page.sync="page"
      :items-per-page="100"
      hide-default-footer
      class="elevation-1"
      show-expand
    >
      <template v-slot:item.actions="{ item }">
        <v-btn
          v-if="item.trace"
          icon
          x-small
          target="_blank"
          :href="'https://console.cloud.google.com/logs/query;query='+ encodeURIComponent(item.trace) + ';timeRange=%2F' + now + '?project=steadfast-tesla-262204'"
        >
          <v-icon x-small>fas fa-list</v-icon>
        </v-btn>
      </template>

      <template v-slot:item.user_id="{ item }">
        <a
          v-if="item.user_id && item.user_id != '00000000-0000-0000-0000-000000000000'"
          style="font-size:smaller"
          :href="'/app/admin/user/' + item.user_id"
          target="_blank"
        >{{ item.user_id }}</a>
      </template>

      <template v-slot:item.clinic_id="{ item }">
        <a
          v-if="item.clinic_id"
          style="font-size:smaller"
          :href="'/app/admin/clinic/' + item.clinic_id"
          target="_blank"
        >{{ item.clinic_id }}</a>
        <a
          v-else-if="item.entity_data && item.entity_data.clinic_id"
          style="font-size:smaller"
          :href="'/app/admin/clinic/' + item.entity_data.clinic_id"
          target="_blank"
        >{{ item.entity_data.clinic_id }}</a>
      </template>

      <template v-slot:expanded-item="{ item }">
        <td :colspan="headers.length">
          <v-container style="width: 100%">
            <v-layout justify-space-between>
              <v-flex xs4>
                <vue-json-pretty style="width: 800px" :data="item.entity_data"></vue-json-pretty>
              </v-flex>
              <v-flex xs4></v-flex>
              <v-flex xs4>
                <v-lazy v-model="lazy_diff[item.id]">
                  <div v-html="diff_html(item.id)" />
                </v-lazy>
              </v-flex>
            </v-layout>
          </v-container>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import axios from "axios";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";

import * as jsondiffpatch from "jsondiffpatch";
import "jsondiffpatch/dist/formatters-styles/html.css";

//let differ = jsondiffpatch.create();

export default {
  components: { VueJsonPretty },

  props: {
    initial_entity_id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      entity_type: "",
      entity_id: this.initial_entity_id,
      clinic_id: "",
      revisions: [],
      lazy_diff: {},
      headers: [
        {
          text: "Date",
          sortable: false,
          value: "created",
        },
        { text: "Entity", value: "entity_id" },
        { text: "Type", value: "entity_type" },
        { text: "Action", value: "action" },
        { text: "User ID", value: "user_id" },
        { text: "Clinic ID", value: "clinic_id" },
        { text: "Message", value: "log_message" },
        { text: "", value: "actions" },
      ],
      type_items: [
        { text: "All", value: "" },
        { text: "Clinic", value: "clinic" },
        { text: "User", value: "user" },
        { text: "Regimen", value: "regimen" },
        { text: "Invite", value: "invite" },
        { text: "Grid Schedule", value: "grid_schedule" },
        { text: "Grid Set", value: "grid_set" },
      ],
    };
  },
  computed: {
    now() {
      let now = new Date();
      return now.toISOString();
    },
  },
  watch: {
    page() {
      this.get_data();
    },
    entity_type() {
      this.get_data();
    },
    entity_id() {
      this.get_data();
    },
  },
  mounted() {
    this.get_data();
  },
  methods: {
    get_data() {
      let self = this;
      let query = "/api/revision?page=" + this.page;

      if (self.entity_id) {
        query += "&entity_id=" + self.entity_id.trim();
      } else {
        if (self.entity_type) {
          query += "&entity_type=" + self.entity_type;
        }

        if (self.clinic_id) {
          query += "&clinic_id=" + self.clinic_id.trim();
        }
      }

      axios.get(query).then((response) => {
        self.revisions = response.data.data.revisions;
        self.pageCount = Math.floor(response.data.data.count / 100);

        for (let rev of self.revisions) {
          self.lazy_diff[rev.id] = false;
        }
      });
    },

    diff_html(revision_id) {
      if (!this.revisions) {
        return "";
      }
      var revision_1, revision_2;
      let entity_id = null;
      for (let rev of this.revisions) {
        if (rev.id == revision_id) {
          revision_1 = rev.entity_data;
          entity_id = rev.entity_id;
          continue;
        }
        if (revision_1 && rev.entity_id == entity_id) {
          revision_2 = rev.entity_data;
          break;
        }
      }

      if (!revision_1 || !revision_2) {
        return "";
      }

      var delta = jsondiffpatch.diff(revision_2, revision_1);

      let html = jsondiffpatch.formatters.html.format(delta, revision_1);
      jsondiffpatch.formatters.html.hideUnchanged();

      return html;
    },
  },
};
</script>

<style>
  .theme--dark .jsondiffpatch-value {
    color: black;
  }
</style>