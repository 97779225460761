<template>
  <div>
    <v-autocomplete
      v-model="generate_reading_text.language"
      :label="$t('common.language')"
      :items="languageOptions"
      outlined
    ></v-autocomplete>

    <v-text-field
      ref="topic_input"
      v-model="generate_reading_text.topic"
      :label="$t('generate_reading_text.topic')"
      outlined
    ></v-text-field>

    <v-select
      v-model="generate_reading_text.fiction"
      :items="[
        { text: $t('reading_text_generate.non_fiction'), value: 'non_fiction' },
        { text: $t('reading_text_generate.fiction'), value: 'fiction' },
      ]"
      outlined
    />

    <v-text-field
      v-model="generate_reading_text.grade"
      type="number"
      :label="$t('reading_text_edit.reading_level')"
      min="1"
      max="16"
      step="1"
      outlined
    ></v-text-field>

    <v-text-field
      v-model="generate_reading_text.words"
      type="number"
      :label="$t('common.words')"
      min="100"
      max="1600"
      step="100"
      outlined
    ></v-text-field>

    <v-card-actions>
      <v-progress-linear
        v-if="loading"
        :value="progress_value"
        class="mr-6"
      ></v-progress-linear>
      <v-alert v-else-if="error_message" type="error" dense outlined class="mb-n1">
        {{ error_message }}
      </v-alert>
      <v-spacer />
      <v-btn :loading="loading" color="primary" @click="generate">
        {{ $t("reading_text_generate.generate_text") }}
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import axios from "axios";
import languages from "../../../data/lang_codes.json";
import Vue from "vue";

export default {
  props: {
    default_grade: {
      type: Number,
      default: 1,
    },
    default_language: {
      type: String,
      default: () => {
        return "";
      },
    },
  },
  data() {
    let data = {
      generate_reading_text: {
        language: this.default_language || this.$i18n.lang(),
        grade: this.default_grade,
        words: this.default_grade * 100,
        topic: "",
        fiction: "non_fiction",
      },
      loading: false,
      progress_value: 0,
      error_message: "",
    };
    return data;
  },
  computed: {
    languageOptions() {
      let options = [];
      Object.keys(languages).forEach((key) => {
        let text = languages[key].nativeName;
        if (languages[key].nativeName != languages[key].name) {
          text += " (" + languages[key].name + ")";
        }
        options.push({
          value: key,
          text: text,
        });
      });
      return options;
    },
  },
  watch: {
    "generate_reading_text.grade": function (new_val, old_val) {
      let update_words = false;

      old_val = parseInt(old_val);
      new_val = parseInt(new_val);
      let num_words = parseInt(this.generate_reading_text.words);

      if (old_val * 100 == num_words) {
        update_words = true;
      }

      if (new_val < 1) {
        new_val = 1;
        Vue.set(this.generate_reading_text, "grade", new_val);
      } else if (new_val > 16) {
        new_val = 16;
        Vue.set(this.generate_reading_text, "grade", new_val);
      }

      if (update_words) {
        Vue.set(this.generate_reading_text, "words", new_val * 100);
      }
    },
  },
  methods: {
    generate() {
      this.error_message = "";

      // Subject / Topic is required
      if (!this.generate_reading_text.topic) {
        this.error_message = this.$t("reading_text_generate.topic_required");
        this.$refs.topic_input.focus();
        return;
      }

      // Ensure grade and words are numeric and within range
      this.generate_reading_text.grade = parseInt(
        this.generate_reading_text.grade
      );
      this.generate_reading_text.words = parseInt(
        this.generate_reading_text.words
      );
      if (this.generate_reading_text.grade < 1) {
        this.generate_reading_text.grade = 1;
      } else if (this.generate_reading_text.grade > 16) {
        this.generate_reading_text.grade = 16;
      }
      if (this.generate_reading_text.words < 100) {
        this.generate_reading_text.words = 100;
      } else if (this.generate_reading_text.words > 1600) {
        this.generate_reading_text.words = 1600;
      }

      // Automatically advance progress value to 100 over 20 seconds
      this.progress_value = 0;
      let interval = setInterval(() => {
        this.progress_value += 1;
        if (this.progress_value >= 100) {
          clearInterval(interval);
        }
      }, 220);

      this.loading = true;
      axios
        .post("/api/reading_text/generate", this.generate_reading_text)
        .then((resp) => {
          this.loading = false;
          this.progress_value = 0;
          if (resp && resp.data.status == "success") {
            this.$emit("generated", resp.data.data);
          } else {
            messageBus.$emit("api-error", resp);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.progress_value = 0;
          messageBus.$emit("api-error", error);
        });
    },
  },
};
</script>
