<template>
  <div>
    <v-text-field
      v-if="allow_edit_title"
      v-model="active_survey.title"
      outlined
      :label="$t('vuetify_survey.title')"
      class="vuetify-survey-editor-title"
      @change="changed"
    />
    <v-textarea
      v-if="allow_edit_instructions"
      v-model="active_survey.instructions"
      outlined
      :label="$t('vuetify_survey.instructions')"
      class="vuetify-survey-editor-instructions"
      @change="changed"
    />

    <!-- Align to end -->
    <v-layout justify-end class="mb-6">
      <v-btn color="primary" class="vuetify-survey-editor-add-item" @click="add_item">
        <v-icon left>add</v-icon>
        {{ add_item_text }}
      </v-btn>
    </v-layout>

    <v-list subheader two-line>
      <draggable v-model="active_survey.items">
        <div v-for="(item, idx) in active_survey.items" :key="idx">
          <v-sheet outlined rounded class="mb-1" style="cursor: move; cursor: grab;">
            <v-list-item>
              <v-list-item-avatar>
                <v-icon class="grey lighten-1" dark>{{ item_icon(item) }}</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
                <v-list-item-subtitle v-if="show_item_id" v-text="item.id"></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon @click="edit_item(idx)">
                  <v-icon color="grey lighten-1">mdi-pencil</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-action>
                <v-btn icon @click="delete_item(idx)">
                  <v-icon color="grey lighten-1">mdi-delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-sheet>
        </div>
      </draggable>
    </v-list>

    <v-dialog v-model="item_editor_dialog" max-width="1200">
      <v-card>
        <v-card-title style="padding: 0px">
          <v-toolbar grey flat>
            <v-btn icon @click="active_item = null; item_editor_dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ active_item ? active_item.title : $t('vuetify_survey.new_item') }}</v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <VuetifySurveyItemEditor
            ref="active_item_editor"
            :allow_edit_style="allow_edit_item_style"
            :allow_edit_class="allow_edit_item_class"
            :allow_edit_visible="allow_edit_visible"
            :allow_edit_value="allow_edit_item_value"
            :disallow_same_option_value="disallow_same_option_value"
            :show_id="show_item_id"
            :value="active_item"
            class="mt-6"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn @click="active_item = null; item_editor_dialog = false">{{ $t('vuetify_survey.cancel') }}</v-btn>
          <v-btn color="primary" @click="save_active_item">{{ $t('vuetify_survey.save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VuetifySurveyItemEditor from "./VuetifySurveyItemEditor.vue";
import draggable from "vuedraggable";
import Vue from "vue";

export default {
  name: "VuetifySurveyEditor",
  components: { VuetifySurveyItemEditor, draggable },
  props: {
    value: {
      type: Object,
      required: true,
    },
    show_item_id: {
      type: Boolean,
      default: true,
    },
    allow_edit_item_class: {
      type: Boolean,
      default: true,
    },
    allow_edit_item_style: {
      type: Boolean,
      default: true,
    },
    allow_edit_item_value: {
      type: Boolean,
      default: true,
    },
    allow_edit_visible: {
      type: Boolean,
      default: true,
    },
    allow_edit_title: {
      type: Boolean,
      default: true,
    },
    allow_edit_instructions: {
      type: Boolean,
      default: true,
    },
    disallow_same_option_value: {
      type: Boolean,
      default: false,
    },
    add_item_text: {
      type: String,
      default: () => {
        return this.$t("vuetify_survey.add_item")
      },
    },
    default_new_item: {
      type: Function,
      default: (index) => {
        return {
          id: "question_" + (index + 1),
          title: "",
          type: "text-field",
          props: {},
        };
      },
    },
  },
  data() {
    let active_survey = this.value;
    if (!active_survey.items) {
      Vue.set(active_survey, "items", []);
    }

    return {
      active_survey,
      active_item: null,
      active_item_idx: null,
      item_editor_dialog: false,
      dark_mode: this.$vuetify.theme.dark,
    };
  },
  watch: {
    value() {
      this.active_survey = this.value;
    },
  },
  methods: {
    changed() {
      this.$emit("input", this.value);
    },
    item_icon(item) {
      if (item.type == "text-field") {
        return "mdi-textbox";
      }
      if (item.type == "number-field") {
        return "mdi-numeric";
      }
      if (item.type == "textarea") {
        return "mdi-card-text";
      }
      if (item.type == "checkbox") {
        return "mdi-checkbox-marked";
      }
      if (item.type == "switch") {
        return "mdi-toggle-switch";
      }
      if (item.type == "checkboxes") {
        return "mdi-checkbox-multiple-marked";
      }
      if (item.type == "select") {
        return "mdi-format-list-checks";
      }
      if (item.type == "radio-group") {
        return "mdi-radiobox-marked";
      }
      if (item.type == "autocomplete") {
        return "mdi-text-search";
      }
      if (item.type == "rating") {
        return "mdi-star";
      }
      if (item.type == "mood") {
        return "mdi-emoticon-happy";
      }
      if (item.type == "birthday") {
        return "mdi-calendar-account";
      }
      if (item.type == "date") {
        return "mdi-calendar";
      }
    },
    delete_item(idx) {
      Vue.delete(this.active_survey.items, idx);
    },
    edit_item(idx) {
      // Deep clone active_item
      this.active_item_idx = idx;
      this.active_item = JSON.parse(JSON.stringify(this.active_survey.items[idx]));

      this.item_editor_dialog = true;
    },
    add_item() {
      if (this.default_new_item instanceof Function) {
        this.active_item = JSON.parse(JSON.stringify(this.default_new_item(this.active_survey.items.length)));
      } else {
        this.active_item = JSON.parse(JSON.stringify(this.default_new_item));
      }

      this.active_item_idx = null;
      this.item_editor_dialog = true;
    },
    save_active_item() {
      let saved_item = this.$refs["active_item_editor"].save();

      if (saved_item) {
        if (this.active_item_idx !== null) {
          Vue.set(this.active_survey.items, this.active_item_idx, saved_item);
        } else {
          this.active_survey.items.push(saved_item);
        }

        this.active_item = null;
        this.item_editor_dialog = false;
      }
    },
  },
};
</script>

<style scoped>
</style>