<template>
  <v-app>
    <sub-regimen
      v-if="current_clinic && loaded_grid"
      :show_title="true"
      :title="loaded_grid.description"
      :regimen_override="regimen"
      :exercises="exercises"
      :video_exercises="video_exercises"
      :user="current_user"
      :clinic="current_clinic"
      :invite_id="''"
      :show_start_arrow="false"
      ephemeral
    />
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import uuid from "uuid";

export default {
  components: {
    // We import it asynchronously so that we can use Regimen recursively
    "sub-regimen": () => import("../../shared/components/Regimen.vue"),
  },
  data() {
    return {
      loaded_grid: null,
      id: this.$route.params.id
        ? this.$route.params.id
        : "",
    };
  },
  computed: {
    ...mapState(["current_user", "current_clinic", "current_clinic_id", "exercises", "video_exercises"]),
    uuid_nil() {
      return uuid.NIL;
    },
    regimen() {
      if (!this.loaded_grid) {
        return null;
      }

      let regimen = {
        id: uuid.NIL,
        invite_id: null,
        clinid_id: null,
        exercises: this.loaded_grid.exercises,
        assignment: "manual",
      };

      return regimen;
    },
  },
  mounted() {
    this.loadGrid();

    if (!this.current_clinic) {
      this.$store.dispatch("loadCurrentClinic", { clinic_id: this.current_clinic_id });
    }
  },
  methods: {
    loadGrid() {
      axios.get("/api/grid_set/" + this.id).then((resp) => {
        if (resp.data.status == "success") {
          this.loaded_grid = resp.data.data;
        } else {
          messageBus.$emit("api-error", resp.data);
        }
      });
    },
  },
};
</script>

<style></style>
