<template>
  <v-app>
    <div>
      <AdminClinic
        v-if="current_user.is_admin"
        :clinic_id="clinic_id"
        :current_user="current_user"
      />
      <PermissionDenied v-else />
    </div>
  </v-app>
</template>

<script>
import AdminClinic from "../../shared/components/admin/AdminClinic";
import PermissionDenied from "../../shared/components/PermissionDenied";

export default {
  components: {
    AdminClinic,
    PermissionDenied,
  },
  data() {
    return {
      clinic_id: this.$route.params.id,
      current_user: this.$root.$children[0].current_user,
    };
  },
  mounted() {
    let clinic_id = this.$route.params.id;
    this.$store.commit("setCurrentClinicId", clinic_id);
    this.$store.dispatch("loadCurrentClinic", {
      clinic_id,
      callback: () => {},
    });
  },
};
</script>

<style>
</style>