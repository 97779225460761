<template>
  <div>
    <v-select
      v-model="selected_preset_id"
      :label="exercise_presets.length == 0 ? $t('common.presets') : $t('common.preset')"
      :items="preset_options"
      :no-data-text="$t('exercise_preset_select.no_presets')"
      outlined
      :dense="dense"
      :style="width ? 'width: ' + width : ''"
      @change="preset_selected"
    >
      <template #item="{ item }">
        <v-list-item-content>
          <v-list-item-title>
            <v-layout justify-space-between>
              <div>
                {{ item.text }}
                <v-list-item-subtitle v-if="item.description">
                  {{ item.description }}
                </v-list-item-subtitle>
              </div>
              <v-chip
                v-if="item.value"
                small
                label
                outlined
                color="blue"
                style="cursor: pointer"
                :class="item.description ? 'mt-2' : ''"
                @click.stop="edit_preset(item.value)"
              >
                <v-icon x-small left>edit</v-icon>{{ $t("common.edit") }}
              </v-chip>
            </v-layout>
          </v-list-item-title>
        </v-list-item-content>
      </template>

      <template #append-item>
        <div
          v-if="exercise_presets.length == 0"
          style="font-size: smaller"
          class="mb-4 ml-4 mr-4"
        >
          {{ $t("exercise_preset_select.presets_desc") }}
        </div>
        <v-btn
          :x-small="exercise_presets.length > 0 && dense"
          :small="exercise_presets.length == 0 || !dense"
          class="mb-3 ml-4 mr-4 mt-4"
          color="primary"
          @click="new_preset"
        >
          {{ $t("exercise_preset_select.new_preset") }}
        </v-btn>
      </template>
    </v-select>

    <v-dialog v-model="exercise_preset_edit_dialog" max-width="1000">
      <div v-if="exercise_preset_edit_dialog">
        <ExercisePresetEdit
          v-model="current_preset"
          @close="exercise_preset_edit_dialog = false"
          @created="preset_created_updated"
          @updated="preset_created_updated"
          @deleted="preset_deleted"
        />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import ExercisePresetEdit from "./ExercisePresetEdit.vue";

export default {
  components: { ExercisePresetEdit },
  props: {
    exercise_id: {
      type: String,
      required: true,
    },
    preset_id: {
      type: String,
      default: null,
    },
    current_exercise_config: {
      type: Object,
      default: null,
    },
    current_exercise_title: {
      type: String,
      default: "",
    },
    instructions: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "",
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      exercise_preset_edit_dialog: false,
      exercise_presets: [],
      selected_preset_id: null,
      current_preset: null,
    };
  },
  computed: {
    ...mapState(["current_clinic_id", "exercises"]),
    preset_options() {
      let presets = this.exercise_presets.map((preset) => {
        return {
          text: preset.title,
          value: preset.id,
          description: preset.description,
        };
      });

      if (presets.length > 0) {
        presets.unshift({ text: this.$t("common.default"), value: null });
      }

      return presets;
    },
    exercise() {
      return this.exercises[this.exercise_id];
    },
  },
  watch: {
    exercise_id() {
      this.selected_preset_id = null;
      this.current_preset = null;
      this.exercise_preset_edit_dialog = false;
      
      // Load presets for this exercise_id and clinic_id
      this.load_presets(() => {
        this.maybe_preselect_preset();
      });
    },
  },
  mounted() {
    // Load presets for this exercise_id and clinic_id
    this.load_presets(() => {
      this.maybe_preselect_preset();
    });
  },
  methods: {
    maybe_preselect_preset() {
      // If the preset_id is set, select that
      if (this.preset_id) {
        let current_preset = this.exercise_presets.find(
          (preset) => preset.id == this.preset_id
        );

        if (current_preset) {
          this.selected_preset_id = this.preset_id;
          this.current_preset = current_preset;
          return;
        } else {
          this.selected_preset_id = null;
          this.current_preset = null;
        }
      }

      // Otherwise try to find a matching preset
      if (this.current_exercise_config) {
        let current_exercise_config = JSON.parse(
          JSON.stringify(this.current_exercise_config)
        );
        if (current_exercise_config.level) {
          delete current_exercise_config.level;
        }
        if (current_exercise_config.texturl) {
          delete current_exercise_config.texturl;
        }
        let preset = this.exercise_presets.find((preset) => {
          let config_matches = JSON.stringify(preset.config) === JSON.stringify(current_exercise_config);
          let instructions_match = preset.instructions === this.instructions;
          return config_matches && instructions_match;
        });
        if (preset) {
          this.selected_preset_id = preset.id;
          this.current_preset = preset;
        }
      }
    },
    edit_preset(preset_id) {
      this.current_preset = this.exercise_presets.find(
        (preset) => preset.id == preset_id
      );
      this.exercise_preset_edit_dialog = true;
    },
    preset_selected() {
      if (this.selected_preset_id === null) {
        // Emit that we've selected the null preset
        // Generally this means to reset the config entirely
        this.$emit("preset_selected", null);
      }
      this.current_preset = this.exercise_presets.find(
        (preset) => preset.id == this.selected_preset_id
      );
      this.$emit("preset_selected", this.current_preset);
    },
    preset_created_updated(preset) {
      this.load_presets();
      this.selected_preset_id = preset.id;
      this.current_preset = preset;
      this.$emit("preset_selected", preset);
    },
    preset_deleted() {
      this.load_presets();
    },
    new_preset() {
      let config;
      if (this.current_exercise_config) {
        config = JSON.parse(JSON.stringify(this.current_exercise_config));
      } else if (this.exercise) {
        config = this.exercise.global;
      } else {
        config = {};
      }
      if (config.level) {
        delete config.level;
      }

      this.current_preset = {
        title: this.current_exercise_title || "",
        description: "",
        instructions: this.instructions,
        exercise_id: this.exercise_id,
        clinic_id: this.current_clinic_id,
        config,
      };

      this.exercise_preset_edit_dialog = true;
    },
    load_presets(callback = false) {
      axios
        .get("/api/exercise_preset", {
          params: {
            exercise_id: this.exercise_id,
            clinic_id: this.current_clinic_id,
          },
        })
        .then((response) => {
          if (response.status != 200 || response.data.status != "success") {
            messageBus.$emit("api-error", response.data);
            return;
          }
          this.exercise_presets = response.data.data;
          if (callback) {
            callback();
          }
        });
    },
  },
};
</script>
