<template>
  <div>
    <v-list v-if="exercises && all_video_exercises" two-line subheader>
      <draggable v-model="localValue" @end="$emit('dragged')" :move="drag_move">
        <div
          v-for="(item, index) in localValue"
          :key="index"
          :data-exercise-type="item.exercise_type"
        >
          <v-sheet
            outlined
            rounded
            style="margin-bottom: 5px; cursor: move; cursor: grab"
            class="exercise-list-item"
          >
            <v-list-item>
              <v-list-item-avatar>
                <v-icon
                  v-if="
                    exercises[item.exercise_id] &&
                    exercises[item.exercise_id].group.length &&
                    (item.exercise_type == 'interactive' || !item.exercise_type)
                  "
                  class="grey lighten-1 white--text"
                  >{{ icons[exercises[item.exercise_id].group[0]] }}</v-icon
                >

                <v-icon
                  v-else-if="item.exercise_type == 'custom' && item.group"
                  class="grey lighten-1 white--text"
                  >{{ icons[item.group[0]] }}</v-icon
                >

                <v-icon
                  v-else-if="item.exercise_type == 'header'"
                  class="grey lighten-1 white--text"
                  >fas fa-heading</v-icon
                >

                <v-icon
                  v-else-if="item.exercise_type == 'questionnaire'"
                  class="grey lighten-1 white--text"
                  >mdi-clipboard-list</v-icon
                >

                <v-icon
                  v-else-if="item.exercise_type == 'question'"
                  class="grey lighten-1 white--text"
                  >fas fa-question</v-icon
                >

                <v-icon
                  v-else-if="item.exercise_type == 'assessment'"
                  class="grey lighten-1 white--text"
                  >fas fa-clipboard-list</v-icon
                >

                <template
                  v-else-if="
                    all_video_exercises &&
                    item.exercise_type.startsWith('video')
                  "
                >
                  <v-icon
                    v-if="
                      all_video_exercises[item.exercise_id] &&
                      all_video_exercises[item.exercise_id].group.length
                    "
                    class="grey lighten-1 white--text"
                    >{{
                      icons[all_video_exercises[item.exercise_id].group[0]]
                    }}</v-icon
                  >
                  <v-icon v-else class="grey lighten-1 white--text"
                    >ondemand_video</v-icon
                  >
                </template>
              </v-list-item-avatar>
              <v-list-item-content>
                <div
                  v-if="
                    item.exercise_type == 'interactive' || !item.exercise_type
                  "
                >
                  <v-list-item-title>{{
                    exercise_title(item)
                  }}</v-list-item-title>
                  <v-list-item-subtitle
                    style="white-space: pre-line; max-height: 40px"
                    >{{ item.instructions }}</v-list-item-subtitle
                  >
                </div>
                <div v-else-if="item.exercise_type.startsWith('video')">
                  <div v-if="all_video_exercises[item.exercise_id]">
                    <v-list-item-title>{{
                      exercise_title(item)
                    }}</v-list-item-title>
                  </div>
                  <v-list-item-title v-else style="font-style: italic">{{
                    $t("regimen_edit.video_exercise_deleted")
                  }}</v-list-item-title>
                  <v-list-item-subtitle
                    style="white-space: pre-line; max-height: 40px"
                    >{{ item.instructions }}</v-list-item-subtitle
                  >
                </div>
                <div v-else-if="item.exercise_type == 'custom'">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                  <v-list-item-subtitle
                    style="white-space: pre-line; max-height: 40px"
                    >{{ $t("common.custom") }}</v-list-item-subtitle
                  >
                </div>
                <div v-else-if="item.exercise_type == 'header'">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                  <v-list-item-subtitle
                    style="white-space: pre-line; max-height: 40px"
                    >{{ $t("common.header") }}</v-list-item-subtitle
                  >
                </div>
                <div v-else-if="item.exercise_type == 'questionnaire'">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                  <v-list-item-subtitle
                    style="white-space: pre-line; max-height: 40px"
                    >{{ $t("common.questionnaire") }}</v-list-item-subtitle
                  >
                </div>
                <div v-else-if="item.exercise_type == 'question'">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                  <v-list-item-subtitle
                    style="white-space: pre-line; max-height: 40px"
                    >{{ $t("common.single_question") }}</v-list-item-subtitle
                  >
                </div>
                <div v-else-if="item.exercise_type == 'assessment'">
                  <v-list-item-title>{{
                    assessment_title(item)
                  }}</v-list-item-title>
                  <v-list-item-subtitle
                    style="white-space: pre-line; max-height: 40px"
                    >{{ $t("common.assessment") }}</v-list-item-subtitle
                  >
                </div>
                <v-list-item-subtitle
                  v-if="admin_show_ids"
                  style="user-select: all"
                >
                  <pre>{{ item.uuid }}</pre>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <ExercisePreviewButton
                  :exercise="item"
                  :class="$vuetify.rtl ? 'ml-2' : 'mr-2'"
                  :fully_configured="false"
                  :additional_video_exercises="additional_video_exercises"
                />
              </v-list-item-action>

              <v-list-item-action v-if="!readonly">
                <v-tooltip top open-delay="500">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :class="$vuetify.rtl ? 'ml-2' : 'mr-2'"
                      icon
                      outlined
                      v-on="on"
                      @click.stop="$emit('edit', index)"
                    >
                      <v-icon color="green">edit</v-icon>
                    </v-btn>
                  </template>
                  {{ $t("common.edit") }}
                </v-tooltip>
              </v-list-item-action>

              <v-list-item-action v-if="!readonly">
                <v-tooltip top open-delay="500">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :class="$vuetify.rtl ? 'ml-n2' : 'mr-n2'"
                      icon
                      outlined
                      v-on="on"
                      @click.stop="duplicate_item(item, index)"
                    >
                      <v-icon color="purple">mdi-content-duplicate</v-icon>
                    </v-btn>
                  </template>
                  {{ $t("common.duplicate") }}
                </v-tooltip>
              </v-list-item-action>

              <v-list-item-action v-if="!readonly">
                <v-tooltip top open-delay="500">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :class="$vuetify.rtl ? 'ml-n1' : 'mr-n1'"
                      icon
                      outlined
                      v-on="on"
                      @click.stop="
                        localValue.splice(index, 1);
                        $emit('deleted', index);
                      "
                    >
                      <v-icon color="red lighten-1">delete</v-icon>
                    </v-btn>
                  </template>
                  {{ $t("common.delete") }}
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </v-sheet>
        </div>
      </draggable>
    </v-list>
    <div v-if="localValue.length == 0 && !readonly">
      <h3>{{ $t("regimen_edit.click_icon_to_add_exercise") }}</h3>
    </div>
    <div
      v-if="!readonly"
      style="height: 60px; position: relative; margin-top: 50px"
    >
      <v-fab-transition>
        <v-btn
          color="blue"
          dark
          absolute
          top
          right
          fab
          hint="Add new exercise"
          @click="$emit('add')"
        >
          <v-icon>add</v-icon>
        </v-btn>
      </v-fab-transition>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { mapState } from "vuex";
import { category_icons } from "../lib/category";
import ExercisePreviewButton from "./ExercisePreviewButton.vue";
import uuid from "uuid";
import Vue from "vue";

export default {
  components: { draggable, ExercisePreviewButton },
  props: {
    value: {
      type: Array,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: () => false,
    },
    additional_video_exercises: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    let data = {
      icons: category_icons,
    };

    return data;
  },
  computed: {
    ...mapState([
      "exercises",
      "video_exercises",
      "current_user",
      "grid_sets",
      "admin_show_ids",
    ]),
    localValue: {
      get() {
        return this.value;
      },
      set(localValue) {
        this.$emit("input", localValue);
      },
    },
    all_video_exercises() {
      if (!this.additional_video_exercises) {
        return this.video_exercises;
      }

      // Deep clone
      let add_video_exercises = JSON.parse(
        JSON.stringify(this.additional_video_exercises)
      );

      if (this.video_exercises) {
        return Object.assign(add_video_exercises, this.video_exercises);
      } else {
        return add_video_exercises;
      }
    },
  },
  methods: {
    duplicate_item(item, index) {
      let new_item = JSON.parse(JSON.stringify(item));

      new_item.uuid = uuid.v4();
      new_item.index = null;

      // Insert after the current item, by looking at index value 
      this.localValue.splice(index +1, 0, new_item);
    },
    drag_move() {
      if (this.readonly) {
        return false;
      }
      return true;
    },
    assessment_title(item) {
      let title = "";

      for (let grid_set of this.grid_sets) {
        if (item.assessment_id == grid_set.id) {
          title = grid_set.title;
          break;
        }
      }
      return title;
    },
    exercise_title(item) {
      if (item.title) {
        return item.title;
      }
      let exercise;
      if (item.exercise_type.startsWith("video")) {
        exercise = this.all_video_exercises[item.exercise_id];
      } else {
        exercise = this.exercises[item.exercise_id];
      }

      let locale = this.$i18n.lang();

      let title;
      if (exercise.title[locale]) {
        title = exercise.title[locale];
      } else {
        title = exercise.title.en;
      }

      if (item.video_exercise_variant && item.config && item.config.variants) {
        let variant = item.config.variants[item.video_exercise_variant];
        title += ": " + this.variant_title(variant);
      }

      return title;
    },
    variant_title(variant) {
      let locale = this.$i18n.lang();
      if (!variant.title) {
        return "";
      }

      if (variant.title[locale]) {
        return variant.title[locale];
      } else if (variant.title.en) {
        return variant.title.en;
      } else {
        return "";
      }
    },
  },
};
</script>

<style scoped>
div[data-exercise-type="header"] ~ div:not([data-exercise-type="header"]) {
  margin-left: 20px;
}
</style>
