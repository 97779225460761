<template>
  <div v-if="user">
    <!-- Toolbar -->
    <v-toolbar dark flat class="page_toolbar">
      <template>
        <v-toolbar-title style="cursor:pointer; " @click="subpage = ''">
          <v-icon left>person</v-icon>
          <span>{{ user.name_prefix }} {{ user.name_given }} {{ user.name_family }} {{ user.name_suffix }}</span>

          <span style="margin-left: 50px">{{ user.email }}</span>
        </v-toolbar-title>
        <v-spacer />
      </template>
      <v-toolbar-items>
        <v-btn
          v-if="show_activities_table"
          class="clinic_page_menu_button"
          small
          text
          @click="show_activities_table = false"
        >
          <v-icon small left>person</v-icon>View
        </v-btn>

        <v-btn class="clinic_page_menu_button" small text @click="user_edit_dialog = true">
          <v-icon small left>edit</v-icon>
          {{ $t("clinic_page.edit_info") }}
        </v-btn>

        <v-btn class="clinic_page_menu_button" small text @click="view_activities">
          <v-icon small left>fas fa-asterisk</v-icon>Activities
        </v-btn>

        <v-btn
          class="clinic_page_menu_button"
          small
          text
          target="_blank"
          :href="'https://console.cloud.google.com/logs/query;query='+ encodeURIComponent(logs_query) + ';timeRange=%2F' + now + '?project=steadfast-tesla-262204'"
        >
          <v-icon small left>fas fa-list</v-icon>Logs
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <!-- User edit dialog -->
    <v-dialog v-model="user_edit_dialog" max-width="1400" scrollable>
      <UserEdit
        :user="user"
        show-delete
        show_is_admin
        @close-user-account="user_edit_dialog = false"
        @user-updated="window.reload()"
      />
    </v-dialog>

    <div v-if="!show_activities_table">
      <v-layout>
        <v-flex xs5>
          <vue-json-pretty :data="user" style="margin-left: 20px;"></vue-json-pretty>
          <v-card style="margin-left: 20px; margin-top: 20px;">
            <v-card-title>
              Sessions
              <v-spacer></v-spacer>
            </v-card-title>
            <v-data-table
              :headers="sessions_headers"
              :items="sessions"
              show-expand
              :expanded.sync="expanded_sessions"
              class="elevation-1"
              :items-per-page="100"
              :sort-by.sync="sortBySessions"
              :sort-desc.sync="sortDescSessions"
            >
              <template v-slot:item.actions="{ item }">
                <v-btn
                  icon
                  x-small
                  target="_blank"
                  :href="'https://console.cloud.google.com/logs/query;query=' + encodeURIComponent('jsonPayload.session='+ item.id) + ';timeRange=%2F' + now + '?project=steadfast-tesla-262204'"
                >
                  <v-icon x-small>fas fa-list</v-icon>
                </v-btn>
              </template>

              <template v-slot:expanded-item="{ item }">
                <td :colspan="sessions_headers.length">
                  <vue-json-pretty :data="item"></vue-json-pretty>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>

        <v-flex xs7>
          <v-card style="margin-left: 20px">
            <v-card-title>
              Invites
              <v-spacer></v-spacer>
            </v-card-title>
            <v-data-table
              :headers="invites_headers"
              :items="invites_list"
              show-expand
              :expanded.sync="expanded_invites"
              class="elevation-1"
              :items-per-page="100"
            >
              <template v-slot:item.clinic="{ item }">
                <v-btn class="mr-2" icon x-small @click="pop_transfer_invite_dialog(item.id)">
                  <v-icon x-small>fas fa-exchange-alt</v-icon>
                </v-btn>

                <v-btn
                  class="mr-2"
                  icon
                  x-small
                  target="_blank"
                  :href="'/app/admin/clinic/' + item.clinic_id"
                >
                  <v-icon x-small>fas fa-tools</v-icon>
                </v-btn>

                <v-btn
                  class="mr-2"
                  icon
                  x-small
                  target="_blank"
                  :href="'/app/clinic/' + item.clinic_id"
                >
                  <v-icon x-small>fas fa-external-link-alt</v-icon>
                </v-btn>

                <span
                  v-if="clinics[item.clinic_id]"
                  style="cursor: pointer;"
                  @click="set_detailed_view(clinics[item.clinic_id])"
                >{{ clinics[item.clinic_id].title }}</span>
                <span v-else>{{ item.clinic_id }}</span>
              </template>
              <template v-slot:expanded-item="{ item }">
                <td :colspan="invites_headers.length">
                  <vue-json-pretty :data="item"></vue-json-pretty>
                </td>
              </template>
              <template v-slot:item.masquerade="{ item }">
                <v-btn small icon @click="masquerade_as_user(item.clinic_id)">
                  <v-icon small>pageview</v-icon>
                </v-btn>
              </template>
              <template v-slot:item.id="{ item }">
                <a
                  style="font-size: smaller"
                  :href="'/app/admin/revisions/' + item.id"
                  target="_blank"
                >{{ item.id }}</a>
              </template>
            </v-data-table>
          </v-card>

          <v-card style="margin-left: 20px; margin-top: 20px">
            <v-card-title>
              Regimens
              <v-spacer></v-spacer>
            </v-card-title>
            <v-data-table
              :headers="regimens_headers"
              :items="regimens"
              show-expand
              :expanded.sync="expanded_regimens"
              class="elevation-1"
              :items-per-page="100"
            >
              <template v-slot:item.clinic="{ item }">
                <v-btn
                  class="mr-2"
                  icon
                  x-small
                  target="_blank"
                  :href="'/app/admin/clinic/' + item.clinic_id"
                >
                  <v-icon x-small>fas fa-tools</v-icon>
                </v-btn>

                <v-btn
                  class="mr-2"
                  icon
                  x-small
                  target="_blank"
                  :href="'/app/clinic/' + item.clinic_id"
                >
                  <v-icon x-small>fas fa-external-link-alt</v-icon>
                </v-btn>

                <span
                  v-if="clinics[item.clinic_id]"
                  style="cursor: pointer;"
                  @click="set_detailed_view(clinics[item.clinic_id])"
                >{{ clinics[item.clinic_id].title }}</span>
                <span v-else>{{ item.clinic_id }}</span>
              </template>
              <template v-slot:expanded-item="{ item }">
                <td :colspan="regimens_headers.length">
                  <vue-json-pretty :data="item"></vue-json-pretty>
                </td>
              </template>
              <template v-slot:item.id="{ item }">
                <a
                  style="font-size: smaller"
                  :href="'/app/admin/revisions/' + item.id"
                  target="_blank"
                >{{ item.id }}</a>
              </template>
            </v-data-table>
          </v-card>

          <v-card style="margin-left: 20px; margin-top: 20px">
            <v-card-title>
              Metadata
              <v-spacer></v-spacer>
            </v-card-title>
            <v-data-table
              :headers="metadata_headers"
              :items="metadata"
              show-expand
              :expanded.sync="expanded_metadata"
              class="elevation-1"
              :items-per-page="100"
            >
              <template v-slot:item.clinic="{ item }">
                <span
                  v-if="clinics[item.clinic_id]"
                  style="cursor: pointer;"
                  @click="set_detailed_view(clinics[item.clinic_id])"
                >{{ clinics[item.clinic_id].title }}</span>
                <span v-else>{{ item.clinic_id }}</span>
              </template>
              <template v-slot:expanded-item="{ item }">
                <td :colspan="metadata_headers.length">
                  <vue-json-pretty :data="item"></vue-json-pretty>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-flex>
      </v-layout>
    </div>

    <v-card v-if="show_activities_table">
      <v-card-title>
        Activities
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="activities_headers"
        :items="activities"
        show-expand
        :expanded.sync="expanded"
        class="elevation-1"
        :items-per-page="100"
        :search="search"
        :sort-by.sync="sortByActivities"
        :sort-desc.sync="sortDescActivities"
      >
        <template v-slot:item.clinic="{ item }">
          <span
            v-if="clinics[item.clinic_id]"
            style="cursor: pointer;"
            @click="set_detailed_view(clinics[item.clinic_id])"
          >{{ clinics[item.clinic_id].title }}</span>
          <span v-else>{{ item.clinic_id }}</span>
        </template>

        <template v-slot:item.invite="{ item }">
          <span
            v-if="invites[item.invite_id]"
            style="cursor: pointer;"
            @click="set_detailed_view(invites[item.invite_id])"
          >
            {{ invites[item.invite_id].name }}
            {{ invites[item.invite_id].name_family }}
          </span>
          <span v-else>{{ item.invite_id }}</span>
        </template>

        <template v-slot:item.trace="{ item }">
          <v-btn
            v-if="item.trace"
            icon
            x-small
            target="_blank"
            :href="'https://console.cloud.google.com/logs/query;query='+ encodeURIComponent(item.trace) + ';timeRange=%2F' + now + '?project=steadfast-tesla-262204'"
          >
            <v-icon x-small>fas fa-list</v-icon>
          </v-btn>
        </template>

        <template v-slot:expanded-item="{ item }">
          <td :colspan="activities_headers.length">
            <vue-json-pretty :data="item"></vue-json-pretty>
          </td>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="detailed_view_dialog" width="1000" :transition="false">
      <v-card>
        <v-toolbar>
          <v-btn icon @click="detailed_view_dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <vue-json-pretty :data="detailed_view"></vue-json-pretty>
        </v-card-text>
      </v-card>
    </v-dialog>

    <div style="height:100px"></div>

    <v-dialog v-model="invite_transfer_dialog" width="600px">
      <v-card>
        <v-card-title>Transfer Invite {{ invite_to_transfer }}</v-card-title>
        <div style="padding: 50px">
          <v-autocomplete
            v-model="invite_transfer_to_clinic"
            :items="clinic_options"
            label="Transer to Clinic"
          />
        </div>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="transfer_invite">Transfer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import UserEdit from "../UserEdit";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import Vue from "vue";
import { logout_helpscout } from "../../lib/helpscout"

export default {
  components: { UserEdit, VueJsonPretty },
  props: {
    user_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      user_edit_dialog: false,
      show_activities_table: false,
      user: null,
      search: "",
      activities: [],
      sessions: [],
      clinics: {},
      invites: {},
      invites_list: [],
      expanded: [],
      expanded_sessions: [],
      expanded_invites: [],
      expanded_metadata: [],
      expanded_regimens: [],
      metadata: [],
      detailed_view_dialog: false,
      detailed_view: null,
      sortBySessions: "updated",
      sortDescSessions: true,
      sortByActivities: "created",
      sortDescActivities: true,
      invite_to_transfer: null,
      invite_transfer_dialog: false,
      invite_transfer_to_clinic: "",
      clinic_options: [],
      regimens: [],
      activities_headers: [
        {
          text: "Activity",
          value: "activity",
        },
        {
          text: "Date",
          value: "created",
        },
        { text: "Clinic", value: "clinic" },
        { text: "Invite", value: "invite" },
        { text: "", value: "trace" },
        { text: "", value: "data-table-expand" },
      ],
      sessions_headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Updated",
          value: "updated",
        },
        { text: "", value: "actions" },
        { text: "", value: "data-table-expand" },
      ],
      invites_headers: [
        {
          text: "",
          value: "masquerade",
        },
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Clinic",
          value: "clinic",
        },
        {
          text: "Role",
          value: "user_role",
        },
        { text: "", value: "data-table-expand" },
      ],
      regimens_headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Clinic",
          value: "clinic",
        },
        { text: "", value: "data-table-expand" },
      ],
      metadata_headers: [
        {
          text: "Category",
          value: "category",
        },
        {
          text: "Clinic",
          value: "clinic",
        },
        { text: "", value: "data-table-expand" },
      ],
    };
  },
  computed: {
    ...mapState(["current_clinic"]),
    now() {
      let now = new Date();
      return now.toISOString();
    },
    logs_query() {
      let query = "jsonPayload.userId=" + this.user_id;
      for (var i in this.sessions) {
        query += "\nOR jsonPayload.session=" + this.sessions[i].id;
      }
      return query;
    },
  },
  mounted() {
    this.load_user();
    this.load_invites();
    this.load_clinics();
    this.load_sessions();
    this.load_metadata();
  },
  methods: {
    transfer_invite() {
      let self = this;
      axios
        .post(
          "/api/invite/" + this.invite_to_transfer + "/transfer_clinic",
          JSON.stringify(this.invite_transfer_to_clinic),
          { headers: { "Content-Type": "applicaion/json" } }
        )
        .then(function (response) {
          let resp = response.data;
          if (resp.status == "error") {
            messageBus.$emit("api-error", resp);
          } else {
            self.load_invites();
          }
        });
      this.invite_transfer_to_clinic = "";
      this.invite_to_transfer = "";
      this.invite_transfer_dialog = false;
    },
    pop_transfer_invite_dialog(item_id) {
      this.invite_to_transfer = item_id;
      this.invite_transfer_dialog = true;
    },
    view_activities() {
      let self = this;
      self.show_activities_table = true;
      axios.get("/api/activity/user/" + this.user_id).then(function (response) {
        if (response.data.status == "success") {
          self.activities = response.data.data;
        }
      });
    },
    load_clinics() {
      let self = this;
      axios.get("/api/clinic").then(function (response) {
        if (response.data.status == "success") {
          let clinics = response.data.data;
          for (let clinic of clinics) {
            Vue.set(self.clinics, clinic.id, clinic);
            self.clinic_options.push({
              value: clinic.id,
              text: clinic.title + " (" + clinic.id + ")",
            });
          }
        }
      });
    },
    load_user() {
      let self = this;
      axios.get("/api/user/" + this.user_id).then(function (response) {
        self.loaded = true;
        if (response.data.status == "success") {
          self.user = response.data.data;
        }
      });
    },
    load_invites() {
      let self = this;
      axios
        .get("/api/user/" + this.user_id + "/invites")
        .then(function (response) {
          if (response.data.status == "success") {
            self.invites_list = response.data.data;
            let invites = response.data.data;
            for (let invite of invites) {
              self.invites[invite.id] = invite;
              self.load_regimen(invite.id);
            }
          }
        });
    },
    load_regimen(invite_id) {
      axios
        .get("/api/regimen?invite_id=" + invite_id)
        .then((response) => {
          if (response.data.status == "success") {
            this.regimens.push(response.data.data);
          }
        });
    },
    load_sessions() {
      let self = this;
      axios
        .get("/api/user/" + this.user_id + "/sessions")
        .then(function (response) {
          if (response.data.status == "success") {
            self.sessions = response.data.data;
          }
        });
    },
    load_metadata() {
      let self = this;
      axios
        .get("/api/user/" + this.user_id + "/metadata/*")
        .then(function (response) {
          if (response.data.status == "success") {
            self.metadata = response.data.data;
          }
        });
    },
    set_detailed_view(data) {
      this.detailed_view = data;
      this.detailed_view_dialog = true;
    },
    masquerade_as_user(clinic_id) {
      let masquerade = {
        clinic_id: clinic_id,
        user_id: this.user_id,
      };

      let self = this;
      axios.post("/api/user/masquerade", masquerade).then(function (response) {
        let resp = response.data;
        if (resp.status == "success") {
          logout_helpscout();
          window.location.href = "/app/clinic/" + clinic_id;
        } else {
          if (resp.error_code == "CannotMasquerade") {
            messageBus.$emit(
              "error",
              self.$t("clinic_page.cannot_masquerade_as_this_user")
            );
          } else {
            messageBus.$emit("api-error", resp);
          }
        }
      });
    },
  },
};
</script>