<template>
  <v-app>
    <v-toolbar style="max-height: 65px" dark flat class="page_toolbar">
      <v-toolbar-title>
        <v-icon left>fas fa-glasses</v-icon>
        {{ $t("common.equipment") }}
      </v-toolbar-title>
    </v-toolbar>

    VisionX Equipment Store goes here
  </v-app>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Equipment",
  components: {
    
  },
  data() {
    // Calculate locale
    var locale;
    let user_clinics = this.$root.$children[0].user_clinics;
    for (var i in user_clinics) {
      if (
        this.$root.$children[0].user_clinics[i].id == this.current_clinic_id
      ) {
        locale = user_clinics[i].billing_country.toUpperCase();
      }
    }

    // TODO: Change these root reads to vuex store reads
    return {
      locale: locale,
      current_user: this.$root.$children[0].current_user,
      user_invites: this.$root.$children[0].user_invites,
      clinic_id: this.current_clinic_id,
    };
  },
  computed: {
    ...mapState(["current_clinic_id"]),
    admin_or_staff() {
      if (this.current_user.is_admin) {
        return true;
      }
      for (var i in this.user_invites) {
        let invite = this.user_invites[i];
        if (invite.user_role == "admin" || invite.user_role == "staff") {
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style>
</style>