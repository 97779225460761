<template>
  <v-card>
    <v-card-text>
      <v-layout>
        <v-flex md4>
          <SelectExercise
            v-model="localValue.exercise_id"
            append_icon="ondemand_video"
            prepend_icon
            :label="$t('regimen_video_edit.select_video')"
            exercise_type="video"
            @change="exercise_change(); $emit('change', value); "
          />
        </v-flex>

        <v-flex xs4 offset-xs1>
          <v-select
            v-if="variant_options.length"
            v-model="localValue.video_exercise_variant"
            outlined
            :label="$t('regimen_video_edit.variant')"
            :items="variant_options"
            @change="variant_change(); $emit('change', value);"
          />
        </v-flex>
      </v-layout>

      <v-layout>
        <v-flex xs4>
          <v-text-field v-model="localValue.title" outlined :label="$t('common.title')" />

          <v-select
            v-model="localValue.timer"
            outlined
            :label="$t('regimen_video_edit.timer')"
            :items="timer_options"
            @change="$emit('change', value);"
          />
        </v-flex>

        <v-flex xs7 offset-xs1>
          <v-textarea
            v-if="localValue.exercise_id"
            v-model="localValue.instructions"
            auto-grow
            outlined
            rows="5"
            :label="$t('common.special_instructions')"
            :hint="$t('common.special_instructions_for_exercise')"
            @change="$emit('change', value)"
          />
        </v-flex>
      </v-layout>

      <v-layout v-if="app_config.exercise_education">
        <v-flex xs12>
          <quillEditor
            v-model="localValue.exercise_education"
            :options="quill_config"
            style="height: 100px; margin-bottom: 40px"
          />
        </v-flex>
      </v-layout>
    </v-card-text>

    <v-alert v-for="error in errors" :key="error" :value="true" type="error">{{ error }}</v-alert>

    <v-card-actions>
      <v-spacer />
      <v-btn v-if="localValue.exercise_id" color="primary" @click="save">{{ $t("common.save") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import SelectExercise from "./SelectExercise";
import { mapState } from "vuex";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

import { quillEditor } from "vue-quill-editor";

export default {
  components: { SelectExercise, quillEditor },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    let data = {
      errors: [],
      previous_exercise_id: this.value.exercise_id,
      previous_variant: this.value.video_exercise_variant,
      timer_options: [
        { value: "stopwatch", text: this.$t("regimen_video_edit.stopwatch") },
        { value: "none", text: this.$t("common.none") },
      ],
      quill_config: {
        placeholder: this.$t("common.education"),
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],

            ["clean"], // remove formatting button
          ],
        },
      },
    };
    return data;
  },
  computed: {
    ...mapState(["video_exercises", "app_config"]),
    variant_options() {
      let options = [];

      if (!this.localValue.exercise_id) {
        return options;
      }
      
      let exercise = this.video_exercises[this.localValue.exercise_id];
      if (exercise.variants) {
        Object.keys(exercise.variants).forEach((key) => {
          let variant = exercise.variants[key];
          options.push({
            text: this.variant_title(variant),
            value: key,
          });
        });
      }
      return options;
    },
    localValue: {
      get() {
        let value = this.value;

        // title should be set to the exercise title by default
        if (!value.title) {
          value.title = this.exercise_title(value.exercise_id, value.video_exercise_variant);
        }

        // Set to stopwatch if timer not set
        if (!value.timer) {
          value.timer = "stopwatch";
        }

        return value;
      },
      set(localValue) {
        this.$emit("input", localValue);
      },
    },
  },
  methods: {
    variant_title(variant) {
      let locale = this.$i18n.lang();
      if (!variant.title) {
        return "";
      }

      if (variant.title[locale]) {
        return variant.title[locale];
      } else if (variant.title.en) {
        return variant.title.en;
      } else {
        return "";
      }
    },
    exercise_title(exercise_id, variant_key = "") {
      let exercise = this.video_exercises[exercise_id];

      let locale = this.$i18n.lang() || "en";
      let title = exercise.title[locale];

      if (variant_key) {
        let variant = exercise.variants[variant_key];
        title += ": " + this.variant_title(variant);
      }
      return title;
    },
    exercise_change() {
      // On exercise change, we replace the config wholesale
      this.localValue.video_exercise_variant = null;

      // Update the title
      this.localValue.title = this.exercise_title(this.localValue.exercise_id);

      this.previous_exercise_id = this.localValue.exercise_id;
      this.previous_variant = null;
    },
    variant_change() {
      let previous_tile = this.exercise_title(this.localValue.exercise_id, this.previous_variant);
      if (this.localValue.title === previous_tile) {
        this.localValue.title = this.exercise_title(this.localValue.exercise_id, this.localValue.video_exercise_variant);
        this.$forceUpdate();
      }
      this.previous_variant = this.localValue.video_exercise_variant;
    },
    save() {
      this.$emit("save");
    },
  },
};
</script>

<style>
</style>