<template>
  <v-container @click="focus">
    <v-sheet elevation="2" class="calibration_sheet" style="padding: 20px; text-align: center">
      <p
        style="font-size: 1.1rem"
        v-html="$t('calibration_colour.calibration_colour_message', {
          side_from: this.$t(`common.${side}`),
          side_to: opposite(side),
          opposite_colour: this.$t(`common.${opposite_colour}`),
          colour: this.$t(`common.${colour}`)
        })"
      ></p>
      <div class="box">
        <span class="dot" :style="{ 'background-color': dotColour }"></span>
      </div>

      <v-slider
        :id="slider_id"
        ref="CalibrationColourSlider"
        v-model="localValue"
        class="calibration_colour_slider"
        :min="20"
        :max="100"
        @change="$emit('change', value)"
      >
        <template v-slot:prepend>
          <v-icon @click="decrement">mdi-minus</v-icon>
        </template>

        <template v-slot:append>
          <v-icon @click="increment">mdi-plus</v-icon>
        </template>
      </v-slider>
    </v-sheet>
  </v-container>
</template>


<script>
export default {
  props: {
    side: {
      type: String,
      required: true,
    },
    colour: {
      type: String,
      required: true,
    },
    opposite_colour: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
  },
  computed: {
    slider_id() {
      return "calibration_colour_slider-" + this.colour;
    },
    localValue: {
      get() {
        return this.value;
      },
      set(localValue) {
        this.$emit("input", localValue);
      },
    },
    dotColour() {
      let base_colour = parseInt(255 * (this.value / 100));

      if (this.colour == "red") {
        return "rgb(" + base_colour + ",0,0)";
      } else if (this.colour == "blue") {
        return "rgb(0,0," + base_colour + ")";
      } else if (this.colour == "green") {
        return "rgb(0," + base_colour + ",0)";
      } else {
        throw "Invalid colour";
      }
    },
  },
  methods: {
    focus() {
      if (this.$refs["CalibrationColourSlider"].$refs.thumb) {
        this.$refs["CalibrationColourSlider"].$refs.thumb.focus();
      }
    },
    opposite(side) {
      if (side == "left") {
        return this.$t("common.right");
      } else {
        return this.$t("common.left");
      }
    },
    increment() {
      if (this.localValue != 100) {
        this.localValue += 1;
      }
    },
    decrement() {
      if (this.localValue != 10) {
        this.localValue -= 1;
      }
    },
  },
};
</script>

<style scoped>
.box {
  background-color: black;
  text-align: center;
  vertical-align: center;
  height: 260px;
}

.dot {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: inline-block;
  margin-top: 110px;
}

.calibration_sheet {
  height: 400px;
}

.calibration_colour_slider {
  margin-bottom: 20px;
  margin-top: 20px;
}

/* iPad in landscape */
@media only screen and (min-device-width: 740px) and (max-device-width: 1080px) and (orientation: landscape) {
  .box {
    height: 180px;
  }

  .dot {
    margin-top: 70px;
  }

  .calibration_sheet {
    height: 350px;
  }
}
</style>