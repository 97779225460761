<template>
  <div class="monthly_reports">
    <!-- Toolbar -->
    <v-toolbar grey>
      <v-toolbar-title>Key Performance Indicators</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-select
        v-model="selected_cols"
        label="Stats"
        :items="cols_options"
        multiple
        dense
        outlined
        hide-details
        style="max-width: 500px"
      >
        <template v-slot:selection="{ item, index }">
          <span
            v-if="index === 0"
            class="text-grey text-caption align-self-center"
          >
            {{ selected_cols.length }} selected
          </span>
        </template>
      </v-select>
    </v-toolbar>

    <!-- Info -->
    <div style="float: right; max-width: 400px; margin-right: 30px">
      <v-sheet v-if="current_stat" elevation="4" style="padding: 20px">{{
        info[current_stat]
      }}</v-sheet>

      <v-sheet
        v-else-if="current_clinic_list_title && current_clinic_list.length"
        elevation="4"
        style="padding: 20px"
      >
        <h4>{{ current_clinic_list_title }}</h4>
        <br />
        <div v-for="clinic_id in current_clinic_list" :key="clinic_id">
          <a
            v-if="clinics[clinic_id]"
            :href="'/app/clinic/' + clinic_id"
            target="_blank"
            >{{ clinics[clinic_id].title }}</a
          >
          <a v-else :href="'/app/clinic/' + clinic_id">{{ clinic_id }}</a>
        </div>
      </v-sheet>
    </div>

    <!-- Mothly Report -->
    <table v-if="!loading">
      <tr>
        <td>
          <span>Date</span>
        </td>

        <td
          v-for="(col, col_index) in active_cols"
          :key="col_index"
          @mouseover="current_stat = col.value"
          @mouseleave="current_stat = ''"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span v-on="on">{{ col.title }}</span>
            </template>
            <span>{{ col.text }}</span>
          </v-tooltip>
        </td>

        <!--
        <td @mouseover="current_stat = '_ltv'" @mouseleave="current_stat = ''">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <span v-on="on">TLV</span>
            </template>
            <span>Total Lifetime Value (all customers)</span>
          </v-tooltip>
        </td>
        -->
      </tr>

      <tr v-for="(item, index) in stats" :key="index">
        <td>{{ item.month }}</td>
        <td
          v-for="(col, col_index) in active_cols"
          :key="col_index"
          @mouseover="col_ccl(item.month, col, item.data)"
        >
          <template v-if="col.is_percent">
            <template v-if="item.data[col.value]">
              {{ Math.round(item.data[col.value] * 1000) / 10 }}%
            </template>
          </template>
          <template v-else>
            {{ Math.round(item.data[col.value]).toLocaleString() }}
          </template>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import Clinic from "../../classes/clinic";

export default {
  props: {},
  data() {
    return {
      stats: [],
      loading: true,
      current_stat: "",
      clinics: {},
      current_clinic_list: [],
      current_clinic_list_title: "",
      info: {
        mrr: "Monthly Recurring Revenue. Running total of income from subscriptions in stripe for the month. Resets to zero at the start of each month.",
        nts: "New Trial Starts. Number of new trials signed up this month (either self-signup or manual-creation). Resets to zero at the start of each month.",
        ntc: "Number of Total Clinics.  Total number of clinics in the system.",
        nac: "Number of Active Clinics. An active clinic is defined as either on a yearly subscription, or on a monthly subscription that has active (paying) patients. Active patients means they have created their logins and are not-archived and not in-trial.",
        ncs: "New Clinic Starts. Number of clinics that signed up for a monthly subscription this month. Does not include yearlies. Resets to zero at the start of each month.",
        nap: "Number of All Patients. Total number of patients that have created their login and are not archived. Includes free, trialing, yearlies, monthlies (all!) clinics.",
        nps: "Number Patient Starts. Total number of patients that have created their login this month. Includes free, trialing, yearlies, monthlies (all!) clinics. Resets to zero at the start of each month",
        mpp: "Monthly Paying Patients. Number of patients we expect to bill for this month.",
        cnc: "Clinic Number Churned. It's the number of clinic who stopped being paying customers. May be overcounted because it does not count unchurned clinics.",
        ccp: "Clinic Churn Percentage.",
        yrm: "Yearly Revenue this Month",
        rcp: "Revenue Churn Percentage. The amount of revenue churned this month. Calculated by the number of clinics churned multiplied by the average monthly value of those churned clinics.",
        _ltv: "Lifetime Total Value. Taking into account the RCP, what is the total value of all customers over the next 5 years.",
        _dltv:
          "Discounted Lifetime Total Value. Taking into account the RCP, what is the total value of all customers over the next 5 years, discounted at 10% per year.",
        _bmrr:
          "Blended Monthly Recursing Revenue. MRR with yearlies spread out to and blended. Includes taxes and fees.",
        _agr: "Annual Growth Rate. The rate at which revenue is growing year over year.",
      },
      average_rcp: 0.0,
      total_yearly: 0.0,
      selected_cols: [
        "mrr",
        "yrm",
        "_bmrr",
        "nts",
        "ntc",
        "nac",
        "ncs",
        "nap",
        "nps",
        "mpp",
        "cnc",
      ],
      cols: [
        {
          title: "MRR",
          text: "Monthly Recurring Revenue",
          value: "mrr",
        },
        {
          title: "YRM",
          text: "Yearly Revenue this Month",
          value: "yrm",
        },
        {
          title: "BMRR",
          text: "Blend Monthly Recurring Revenue",
          value: "_bmrr",
        },
        {
          title: "NTS",
          text: "New Trial Starts",
          value: "nts",
          ccl: "nts_ids",
        },
        {
          title: "NTC",
          text: "Number of Total Clinics",
          value: "ntc",
        },
        {
          title: "NAC",
          text: "Number of Active Clinics",
          value: "nac",
        },
        {
          title: "NCS",
          text: "New Clinic Starts",
          value: "ncs",
          ccl: "ncs_ids",
        },
        {
          title: "NAP",
          text: "Number of All Patients",
          value: "nap",
        },
        {
          title: "NPS",
          text: "New Patient Starts",
          value: "nps",
        },
        {
          title: "MPP",
          text: "Monthly Payng Patients",
          value: "mpp",
        },
        {
          title: "CNC",
          text: "Clinic Number Churned",
          value: "cnc",
          ccl: "cnc_ids",
        },
        {
          title: "RCP",
          text: "Revenue Churn Percentage",
          value: "rcp",
          is_percent: true,
        },
        {
          title: "LTV",
          text: "Lifetime Total Value",
          value: "_ltv",
        },
        {
          title: "DLTV",
          text: "Discounted Lifetime Total Value",
          value: "_dltv",
        },
        {
          title: "AGR",
          text: "Annual Growth Rate",
          value: "_agr",
          is_percent: true,
        },
        {
          title: "MRRT",
          text: " Monthly Recurring Revenue Taxes",
          value: "mrr_taxes",
        },
        {
          title: "MRRF",
          text: "Monthly Recurring Revenue Fees",
          value: "mrr_fees",
        },
        {
          title: "YRMT",
          text: "Yearly Revenue this Month Taxes",
          value: "yrm_taxes",
        },
        {
          title: "YRMF",
          text: "Yearly Revenue this Month Fees",
          value: "yrm_fees",
        },
        {
          title: "BMRRT",
          text: "Blended Monthly Recurring Revenue Taxes",
          value: "_bmrr_taxes",
        },
        {
          title: "BMRRF",
          text: "Blended Monthly Recurring Revenue Fees",
          value: "_bmrr_fees",
        },
      ],
    };
  },
  computed: {
    cols_options: function () {
      let opts = [];
      for (let col of this.cols) {
        opts.push({
          text: col.title + " - " + col.text,
          value: col.value,
        });
      }
      return opts;
    },
    active_cols: function () {
      let cols = [];
      for (let col of this.cols) {
        if (this.selected_cols.includes(col.value)) {
          cols.push(col);
        }
      }
      return cols;
    },
  },
  mounted() {
    this.load_stats();
    this.load_clinics();
  },
  methods: {
    col_ccl: function (month, col, data) {
      if (col.ccl) {
        this.current_clinic_list_title = month + ": " + col.text;
        this.current_clinic_list = data[col.ccl];
      }
    },
    load_stats: function () {
      let self = this;
      axios.get("/api/stats/monthly_stats").then(function (response) {
        self.loading = false;
        if (response.data.status == "success") {
          let stats = response.data.data;

          stats.sort((a, b) => {
            if (a.month < b.month) {
              return 1;
            }
            if (a.month > b.month) {
              return -1;
            }
            return 0;
          });

          // Add some missing revenue that were paid in odd ways
          for (let s in stats) {
            if (stats[s].month == "2022-02") {
              stats[s].data.yrm += 422.45; // Health Partners Optical paid us via bank transfer
            }
          }

          self.stats = stats;

          // Calculate average rcp and total yearly for the past year
          let total_rcp = 0;
          let total_yearly = 0;
          let c = 0;
          for (var stat of stats) {
            c += 1;
            if (c == 13) {
              break;
            }

            total_rcp += stat.data.rcp;
            total_yearly += stat.data.yrm;
          }
          self.average_rcp = total_rcp / c;
          self.total_yearly = total_yearly;

          // Calculate total customer value
          for (var i in stats) {
            let total = 0;
            if (stats[i].data.mrr) {
              let mrr = stats[i].data.mrr + self.total_yearly / 12;
              let next_mrr = mrr - mrr * self.average_rcp;
              let num_months = 1;
              while (next_mrr > 100 && num_months < 5 * 12) {
                total += mrr;
                mrr = next_mrr;
                next_mrr = mrr - mrr * self.average_rcp;
                num_months += 1;
              }
            }

            self.stats[i].data._ltv = total;
          }

          // Calculate total customer value at a 10% discount rate
          // 0.8% monthly (compounded monthly) works out to 10% per year.
          // See: https://www.wolframalpha.com/input/?i=100+*+%281+%2B+0.008%29%5E12
          let monthly_discount = 0.008;
          for (var i2 in stats) {
            let total = 0;
            if (stats[i2].data.mrr) {
              let mrr = stats[i2].data.mrr + self.total_yearly / 12;
              let next_mrr = mrr - mrr * self.average_rcp;
              let num_months = 1;
              while (next_mrr > 100 && num_months < 5 * 12) {
                total += mrr;
                mrr = next_mrr;
                next_mrr =
                  mrr - mrr * self.average_rcp - mrr * monthly_discount;
                num_months += 1;
              }
            }

            self.stats[i2].data._dltv = total;
          }

          // Calculate levelilized MRR
          for (var i3 in stats) {
            let total = stats[i3].data.mrr || 0;
            let total_taxes = stats[i3].data.mrr_taxes || 0;
            let total_fees = stats[i3].data.mrr_fees || 0;
            let prev_year = stats.slice(parseInt(i3), parseInt(i3) + 12);
            for (var k in prev_year) {
              total += prev_year[k].data.yrm / 12;
              total_taxes += prev_year[k].data.yrm_taxes
                ? prev_year[k].data.yrm_taxes / 12
                : 0;
              total_fees += prev_year[k].data.yrm_fees
                ? prev_year[k].data.yrm_fees / 12
                : 0;
            }
            self.stats[i3].data._bmrr = total;
            self.stats[i3].data._bmrr_taxes = total_taxes;
            self.stats[i3].data._bmrr_fees = total_fees;
          }

          // Calculate rolling annual growth rate
          for (let i4 in stats) {
            let prev_year = stats[parseInt(i4) + 12];
            if (prev_year) {
              let total = ((stats[parseInt(i4)].data._bmrr / prev_year.data._bmrr)) - 1;
              if (total < 10) {
                self.stats[parseInt(i4)].data._agr = total;
              }
            }
          }
        }
      });
    },
    load_clinics: function () {
      axios.get("/api/clinic").then((response) => {
        if (response.data.status == "success") {
          var clinics = {};
          for (var i in response.data.data) {
            let clinic = Clinic.fromJson(response.data.data[i]);
            clinics[clinic.id] = clinic;
          }
          this.clinics = clinics;
        }
      });
    },
  },
};
</script>

<style scoped>
.monthly_reports td {
  border: 1px solid grey;
  padding: 10px;
  font-size: larger;
}

.monthly_reports td::first {
  font-weight: bold;
}
</style>
