<template>
  <v-select :items="items" :label="label" :value="value" :outlined="outlined" @input="value_change">
    <template slot="item" slot-scope="item">
      <div style="margin-top: 5px">
        <div v-if="item.item.value">
          <img
            :src="'https://storage.googleapis.com/opticalgym-large-assets/vectographs/' + item.item.value + '_thumbnail.png'"
            style="background-color: black; height:80px; width: 80px;"
          />
          <div style="padding-top: 30px;  padding-left: 10px; float: right;">{{ item.item.text }}</div>
        </div>
        <div v-else>None</div>
      </div>
    </template>
  </v-select>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: "Vectograph",
    },
    outlined: {
      type: Boolean,
      required: false,
      default: () => false,
    }
  },
  data() {
    return {
      items: [
        { text: this.$t('select_vectograph.snowflake'), value: "snow_flake" },
        { text: this.$t('select_vectograph.funky_sphere'), value: "funky_circle" },
        { text: this.$t('select_vectograph.wooden_star'), value: "wooden_star" },
        { text: this.$t('select_vectograph.lego_brock_string'), value: "lego_brockstring" },
        { text: this.$t('select_vectograph.lego_house'), value: "lego_house" },
        { text: this.$t('select_vectograph.king_arthur'), value: "king_arthur" },
        { text: this.$t('select_vectograph.lego_castle'), value: "lego_castle" },
        { text: this.$t('select_vectograph.river_swim'), value: "river_swim" },
        { text: this.$t('select_vectograph.bucky_ball'), value: "bucky_ball" },
        { text: this.$t('select_vectograph.molecule'), value: "molecule" },
        { text: this.$t('select_vectograph.julius_caesar'), value: "julius_caesar" },
        { text: this.$t('select_vectograph.assyrian_statue'), value: "assyrian" },
        { text: this.$t('select_vectograph.egyptian_statue'), value: "egyptian_statue" },
        { text: this.$t('select_vectograph.skull'), value: "skull" },
        { text: this.$t('select_vectograph.asteroid'), value: "asteroid" },
        { text: this.$t('select_vectograph.sun_2'), value: "sun_2" },
        { text: this.$t('select_vectograph.space_antenna'), value: "space_antenna" },
        { text: this.$t('select_vectograph.noctis_labyrinthus'), value: "noctis_labyrinthus" },
        { text: this.$t('select_vectograph.playing_cards'), value: "playing_cards" },
        { text: this.$t('select_vectograph.bridge_cartoon'), value: "bridge_cartoon" },
        { text: "Let's Go!", value: "lets_go_cat" },
      ],
    };
  },
  methods: {
    value_change(event) {
      this.$emit("input", event);
    },
  },
};
</script>