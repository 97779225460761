<template>
  <div class="component-anaglyph-select">
    <v-select
      v-model="inputVal"
      :label="$t('common.anaglyph_glasses')"
      style="max-width:500px"
      :items="options"
      :disabled="disabled"
    />
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: () => false,
    },
    include_random: {
      type: Boolean,
      default: () => true,
    },
    include_mfbf_od: {
      type: Boolean,
      default: () => true,
    },
    include_mfbf_os: {
      type: Boolean,
      default: () => true,
    },
    include_mfbf_random: {
      type: Boolean,
      default: () => true,
    },
    mfbf_suffix: {
      type: String,
      default: "DEFAULT",
    },
    additional_options: {
      type: Array,
      default() {
        return [];
      },
    },

    // TODO: Eventually make this type: String
    // eslint-disable-next-line vue/require-prop-types
    value: {
      required: true,
    },
  },
  data() {
    let data = {
      options: [
        {
          value: "",
          text: this.$t("common.none"),
        },
      ],
    };

    if (this.include_random) {
      data.options.push({
        value: "random",
        text: this.$t("common.random"),
      });
    }

    let mfbf_suffix = this.mfbf_suffix;
    if (mfbf_suffix == "DEFAULT") {
      mfbf_suffix = this.$t("common.on_target");
    }

    if (mfbf_suffix) {
      mfbf_suffix = " " + mfbf_suffix;
    }

    if (this.include_mfbf_od) {
      data.options.push({
        value: "mfbf_od",
        text: "MFBF - OD" + mfbf_suffix,
      });
    }
    if (this.include_mfbf_os) {
      data.options.push({
        value: "mfbf_os",
        text: "MFBF - OS" + mfbf_suffix,
      });
    }
    if (this.include_mfbf_random) {
      data.options.push({
        value: "mfbf_random",
        text: "MFBF - " + this.$t("common.randomly_alternating") + " OD/OS",
      });
    }

    data.options.push(...this.additional_options);

    return data;
  },
  computed: {
    inputVal: {
      get() {
        if (this.value === false) {
          return "";
        } else if (this.value === true) {
          return "random";
        } else return this.value;
      },
      set(val) {
        this.$emit("input", val);
        this.$emit("change", val);
      },
    },
  },
};
</script>
