<template>
  <v-card>
    <v-toolbar>
      <v-btn icon @click="$emit('close')">
        <v-icon>close</v-icon>
      </v-btn>
      <v-toolbar-title>{{ $t("common.printable_sheets") }}</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items>
        <v-btn
          small
          color="primary"
          @click="create_printable_dialog = true"
        >{{ $t("clinic_page_manage_printables.upload_pdf") }}</v-btn>
        <v-dialog v-model="create_printable_dialog" max-width="1200">
          <PrintableEdit
            v-if="create_printable_dialog"
            :printable="new_printable"
            @close="close_new_printable_dialog"
            @printable-created="load_printables"
            @printable-updated="load_printables"
          ></PrintableEdit>
        </v-dialog>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text>
      <v-banner two-line style="margin-top:20px">
        <p>{{ $t("clinic_page_manage_printables.printable_sheets_first_text") }}</p>

        <p>{{ $t("common.copy_right_text") }}</p>
      </v-banner>
      <v-list v-if="clinic_printables && clinic_printables.length != 0">
        <div v-for="(item, index) in clinic_printables" :key="index">
          <v-list-item>
            <v-list-item-icon>
              <v-badge
                v-if="item.shared && !item.shared.hidden"
                overlap
                bottom
                bordered
                color="grey"
                icon="mdi-share-variant"
              >
                <v-icon>fa fa-file-pdf</v-icon>
              </v-badge>
              <v-icon v-else>fa fa-file-pdf</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.title }}
                <v-chip
                  v-if="item.share_source"
                  label
                  small
                  outlined
                  class="mt-0 ml-5"
                  :href="'/app/shared/clinic/' + item.share_source.clinic_id"
                  target="_blank"
                >{{ $t('community_content.clinic_attribution', {'clinic_title': item.share_source.clinic_title }) }}</v-chip>
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action></v-list-item-action>

            <v-list-item-content>
              <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn small outlined fab color="blue" style="margin-right:15px" @click="print(item)">
                <v-icon>print</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action>
              <v-btn small outlined fab color="green" @click="open_printable_dialog(index)">
                <v-icon>edit</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action>
              <v-tooltip top open-delay="500">
                <template v-slot:activator="{ on }">
                  <v-btn small outlined fab color="red" v-on="on" @click="delete_printable(item)">
                    <v-icon>delete</v-icon>
                  </v-btn>
                </template>
                {{ $t("clinic_page_manage_printables.delete_printable") }}
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
          <v-divider inset />

          <v-dialog v-model="edit_printable_dialog[index]" max-width="1200">
            <PrintableEdit
              v-if="edit_printable_dialog[index]"
              :printable="item"
              @close="close_printable_dialog(index)"
              @printable-created="load_printables"
              @printable-updated="load_printables"
            ></PrintableEdit>
          </v-dialog>
        </div>
      </v-list>
      <v-banner v-else-if="!loading" two-line style="margin-top:20px; font-style:italic">
        <p>{{ $t("clinic_page_manage_printables.no_uploaded_printable_text") }}</p>
      </v-banner>
      <v-layout v-else-if="loading" align-center justify-center>
        <v-progress-circular style="margin: 30px" indeterminate color="primary"></v-progress-circular>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import printJS from "print-js";
import axios from "axios";
import PrintableEdit from "./PrintableEdit";
import Vue from "vue";
import { mapState } from "vuex";

export default {
  components: { PrintableEdit },
  props: {
    clinic_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      create_printable_dialog: false,
      edit_printable_dialog: [],
      new_printable: {
        title: "",
        description: "",
        category: "printable",
        mime: "application/pdf",
        public: false,
        filename: "",
        tools: [],
        clinic_id: this.clinic_id,
      },
    };
  },
  computed: {
    ...mapState(["file_base_url", "clinic_printables"]),
  },
  mounted() {
    this.load_printables();
  },
  methods: {
    file_url(file) {
      return this.file_base_url + file.clinic_id + "/" + file.id + "/1." + file.filename;
    },
    close_new_printable_dialog() {
      this.create_printable_dialog = false;
      this.new_printable = {
        title: "",
        description: "",
        category: "printable",
        mime: "application/pdf",
        public: false,
        filename: "",
        tools: [],
        clinic_id: this.clinic_id,
      };
    },
    load_printables() {
      this.loading = true;
      this.$store.dispatch("loadClinicPrintables", () => {
        this.loading = false;
        this.edit_printable_dialog = [];
        for (var i = 0; i < this.clinic_printables.length; i++) {
          this.edit_printable_dialog.push(false);
        }
      });
    },
    open_printable_dialog(index) {
      Vue.set(this.edit_printable_dialog, index, true);
    },
    close_printable_dialog(index) {
      Vue.set(this.edit_printable_dialog, index, false);
    },
    delete_printable(printable) {
      if (!confirm("Delete " + printable.title + "?")) {
        return;
      }

      let self = this;
      axios.delete("/api/file/" + printable.id).then(function (response) {
        if (response.data.status == "success") {
          messageBus.$emit("success", self.$t("clinic_page_manage_printables.printable_deleted"));
          self.load_printables();
        } else {
          messageBus.$emit("error", response.data.error_message);
        }
      });
    },
    print(printable) {
      let uri = this.file_url(printable);

      let random_int = Math.floor(Math.random() * 10000000).toString();

      uri += "?cache_buster=" + random_int;

      printJS({
        printable: uri,
      });
    },
  },
};
</script>

<style>
</style>