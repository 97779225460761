<template>
  <v-container v-if="user_clinics.length">
    <v-layout align-center justify-center>
      <v-flex xs8 m6>
        <v-card>
          <v-toolbar dark color="primary">
            <v-toolbar-title>{{ $t("common.select_clinic") }}</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-list two-line subheader>
              <div v-for="(item, index) in user_clinics" :key="index">
                <v-list-item @click="navigate_to_clinic(item.id)">
                  <v-list-item-content>{{ item.title }}</v-list-item-content>
                  <v-list-item-content>{{ item.website }}</v-list-item-content>
                  <v-list-item-content>{{ item.phone }}</v-list-item-content>
                </v-list-item>
                <v-divider />
              </div>
            </v-list>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState(["user_clinics"]),
  },
  methods: {
    navigate_to_clinic: function (clinic_id) {
      this.$router.push("/app/clinic/" + clinic_id);
      window.location.reload();
    },
  },
};
</script>
