<template>
  <div class="video-demo-list" style="margin-bottom: 60px">
    <div v-if="sidebar && $vuetify.breakpoint.name != 'xs' && $vuetify.breakpoint.name != 'sm'" class="category-list" style="position:fixed;">
      <v-container>
        <v-navigation-drawer permanent>
          <v-list class="mt-1">
            <div v-for="group in sorted_exercises" :key="group.group">
              <v-divider v-if="group.group == 'Disabled'" class="mt-5 mb-5"></v-divider>

              <v-list-item :href="group.anchor" class="video_list_jump_link">
                <v-list-item-action>
                  <v-icon>{{ icons[group.group] }}</v-icon>
                </v-list-item-action>

                <v-list-item-content v-if="group.group">
                  <v-list-item-title>{{ $t("group." + group.group) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-list>
        </v-navigation-drawer>
      </v-container>
    </div>

    <div :style="sidebar && $vuetify.breakpoint.name != 'xs' && $vuetify.breakpoint.name != 'sm' ? 'position: relative; left: 266px; padding-right: 270px' : ''">
      <div v-for="group in sorted_exercises" :key="group.group">
        <v-card>
          <v-card-title primary-title>
            <h3 :id="group.id" class="headline mb-0">
              <v-icon left style="vertical-align: unset">{{ icons[group.group] }}</v-icon>
              {{ $t("group." + group.group) }}
            </h3>
          </v-card-title>

          <div>
            <v-list>
              <div v-for="(item, index) in group.exercises" :key="index">
                <v-list-item
                  three-line
                  style="cursor: pointer"
                  @click="launch_video(item.id, index)"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      :class="video_selections[item.id] === false ? 'font-weight-thin' : 'font-weight-regular'"
                    >
                      {{ exercise_title(item) }}
                      <v-chip
                        v-if="item.is_new"
                        small
                        label
                        color="light-green"
                        style="margin-left: 10px"
                      >New</v-chip>
                    </v-list-item-title>
                    <v-list-item-subtitle
                      :class="video_selections[item.id] === false ? 'font-weight-thin' : 'font-weight-regular'"
                    >{{ exercise_description(item) }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-chip v-if="item.share_source && item.share_source.clinic_title" label small outlined class="mt-4">{{ $t('common.clinic_attribution', {'clinic_title': item.share_source.clinic_title }) }}</v-chip>
                    <v-chip v-else label small outlined class="mt-4">{{ $t('common.clinic_attribution', {'clinic_title': item.provider_name }) }}</v-chip>
                  </v-list-item-action>
                  <v-list-item-action>
                    <v-tooltip v-if="item.origin == 'clinic' && item.shared" top>
                      <template v-slot:activator="{ on }">
                        <v-chip class="ma-2 mt-4" style="width: 90px" small close label outlined close-icon="mdi-share-variant" v-on="on">Shared</v-chip>
                      </template>
                      <span>{{ $t("video_demo_list.shared_exercise") }}</span>
                    </v-tooltip>
                    <v-tooltip v-else-if="item.origin == 'clinic' && !item.shared" top>
                      <template v-slot:activator="{ on }">
                        <v-chip class="ma-2 mt-4" style="width: 90px" small label outlined v-on="on">Private <v-icon right x-small style="margin-left: 15px">fa fa-user-lock</v-icon></v-chip>
                      </template>
                      <span>{{ $t("video_demo_list.private_exercise") }}</span>
                    </v-tooltip>
                    <v-chip
                      v-if="current_clinic && current_clinic_selections && item.origin == 'builtin'"
                      class="ma-2 mt-4"
                      small
                      close
                      :color="video_selections[item.id] === false ? 'gray' : 'green'"
                      label
                      outlined
                      style="width: 90px"
                      :close-icon="in_progress[item.id] ? 'fas fa-spinner' : (video_selections[item.id] === false ? 'mdi-circle-off-outline' : 'mdi-check-circle-outline')"
                      @click.stop="toggle_enabled(item)"
                      @click:close="toggle_enabled(item)"
                    >{{ video_selections[item.id] === false ? $t('common.disabled') : $t('common.enabled') }}</v-chip>
                    <v-tooltip v-if="item.origin == 'public'" top>
                      <template v-slot:activator="{ on }">
                        <v-btn icon class="ma-2 ml-16" v-on="on">
                          <v-icon small left>fa fa-globe</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("video_demo_list.public_exercise") }}</span>
                    </v-tooltip>
                  </v-list-item-action>
                </v-list-item>
                <v-divider />
                <v-dialog
                  v-model="dialogs[item.id]"
                  max-width="800"
                  class="video_exercise_dialog"
                  @click:outside="exercise_stop(item.id, index)"
                >
                  <VideoExercise
                    v-if="dialogs[item.id]"
                    ref="VideoExercise"
                    :exercise="item"
                    show_config
                    :trusted="item.origin == 'builtin'"
                    @close="close_dialog(item.id, index)"
                    @exercise-finished="close_dialog(item.id, index)"
                  />
                </v-dialog>
              </div>
            </v-list>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import $ from "jquery";
import VideoExercise from "./VideoExercise";
import { mapState } from "vuex";
import { Selection } from "../classes/selection";
import { category_icons } from "../lib/category";
import jQuery from "jquery";

export default {
  components: { VideoExercise },
  props: {
    filter: {
      type: String,
      default: "",
    },
    sidebar: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      dialogs: {},
      require_calibration_dialog: false,
      icons: category_icons,
      enabled_disabed: {},
      toggled: [], // List of currenly toggled videos, so we don't recategorize them imminentely
      in_progress: {}, // List of in-progress toggles
    };
  },
  computed: {
    ...mapState([
      "current_user",
      "current_clinic_id",
      "current_clinic",
      "current_clinic_selections",
      "video_exercises",
      "user_clinics",
      "current_clinic_role",
      "small_screen",
    ]),
    filtered_exercises() {
      let exercises_array = [];
      for (var id in this.video_exercises) {
        exercises_array.push(this.video_exercises[id]);
      }

      let filter = this.filter.trim().toLowerCase();
      if (!filter) {
        return exercises_array;
      }

      let filtered = [];
      for (let exercise of exercises_array) {
        let title = this.exercise_title(exercise);
        let desc = this.exercise_description(exercise);
        let title_stripped = title.replace(/\./g, "");
        if (
          title.toLowerCase().includes(filter) ||
          desc.toLowerCase().includes(filter) ||
          title_stripped.toLowerCase().includes(filter) ||
          exercise.provider_name.toLowerCase().includes(filter)
        ) {
          filtered.push(exercise);
        }
      }

      return filtered;
    },
    video_selections() {
      let selections = {};

      if (!this.current_clinic_selections) {
        return selections;
      }

      for (let builtin_select of this.current_clinic_selections.get_by_type("video/builtin")) {
        selections[builtin_select.entity_id] = builtin_select.selected;
      }
      for (let video_select of this.current_clinic_selections.get_by_type("video_exercise")) {
        selections[video_select.entity_id] = video_select.selected;
      }

      return selections;
    },

    sorted_exercises() {
      let grouped = [];

      for (let exercise of this.filtered_exercises) {
        if (exercise.deprecated) {
          continue;
        }

        if (this.video_selections[exercise.id] === false) {
          if (this.current_clinic_role !== "admin") {
            continue;
          } else if (!this.toggled.includes(exercise.id)) {
            exercise.group = ["Disabled"];
          }
        }

        if (exercise.group.length == 0) {
          exercise.group.push("Uncategorized");
        }
        if (!exercise.group[0]) {
          exercise.group[0] = "Uncategorized";
        }

        exercise.group.forEach((group_name) => {
          var group_exists = false;
          var group = {};

          for (var i in grouped) {
            if (grouped[i].group == group_name) {
              group_exists = true;
              group = grouped[i];
            }
          }
          if (!group_exists) {
            group = {
              group: group_name,
              anchor: "#" + group_name.replace(/\s+/g, ""),
              id: group_name.replace(/\s+/g, ""),
              exercises: [],
            };
          }
          group.exercises.push(exercise);
          if (!group_exists) {
            grouped.push(group);
          }
        });
      }

      // Sort the groups and exercises
      grouped.sort((a, b) => {
        if (a.group == "Uncategorized") {
          if (b.group == "Disabled") {
            return -1;
          }
          return 1;
        }
        if (b.group == "Uncategorized") {
          if (b.group == "Disabled") {
            return 1;
          }
          return -1;
        }
        if (a.group == "Disabled") {
          return 1;
        }
        if (b.group == "Disabled") {
          return -1;
        }

        return a.group > b.group ? 1 : -1;
      });

      // Sort exercises in groups
      let locale = this.$i18n.lang();
      for (var i in self.grouped) {
        grouped[i].exercises.sort((a, b) => (a.title[locale] > b.title[locale] ? 1 : -1));
      }

      return grouped;
    },
  },
  mounted() {
    jQuery(window).scroll(function () {
      var $el = jQuery(".video-demo-list .category-list");

      let top = jQuery(this).scrollTop();
      let distance = 128;

      if (top > distance) {
        $el.css({ position: "fixed", top: "0px" });
      }
      if (top < distance) {
        $el.css({ position: "fixed", top: distance - top + "px" });
      }
    });

    // If we don't have a current clinic set, load it
    if (this.current_clinic_id && !this.current_clinic) {
      this.$store.dispatch("loadCurrentClinic", {
        clinic_id: this.current_clinic_id,
      });
    }
  },
  methods: {
    exercise_title(exercise) {
      if (!exercise.title) {
        return "";
      }

      if (typeof exercise.title == "string") {
        return exercise.title;
      }

      let locale = this.$i18n.lang();
      if (exercise.title[locale]) {
        return exercise.title[locale];
      } else {
        return exercise.title.en;
      }
    },
    exercise_description(exercise) {
      let locale = this.$i18n.lang();
      if (exercise.description[locale]) {
        return exercise.description[locale];
      } else {
        return exercise.description.en;
      }
    },
    close_dialog(exercise_id, index) {
      Vue.set(this.dialogs, exercise_id, false);
      this.exercise_stop(exercise_id, index);
    },
    exercise_stop(_exercise_id, _index) {
      for (var i in this.$refs["VideoExercise"]) {
        this.$refs["VideoExercise"][i].stop();
      }
    },
    launch_video(exercise_id, _index) {
      let self = this;
      Vue.set(this.dialogs, exercise_id, true);
      Vue.nextTick(function () {
        for (var i in self.$refs["VideoExercise"]) {
          if (self.$refs["VideoExercise"][i].exercise.id == exercise_id) {
            self.$refs["VideoExercise"][i].load();
          }
        }
      });
    },
    image_url(img_path) {
      return img_path;
    },
    toggle_enabled(item) {
      if (this.in_progress[item.id]) {
        return;
      }

      if (this.current_clinic_role !== "admin") {
        messageBus.$emit("error", this.$t("video_demo_list.please_ask_admin_to_enable"));
        return;
      }

      if (item.origin == "builtin") {
        let selection = this.current_clinic_selections.get_by_entity_id(item.id, "video/builtin");

        if (selection) {
          if (selection.selected) {
            selection.selected = false;
          } else {
            selection.selected = true;
          }
        } else {
          selection = Selection.fromJson({
            clinic_id: this.current_clinic.id,
            entity_id: item.id,
            entity_type: "video/builtin",
            selected: false, // By default builtins are ON, so we set it to false
          });
        }

        this.toggled.push(item.id);

        Vue.set(this.in_progress, item.id, true);
        this.$store.dispatch("upsertSelection", {
          selection,
          callback: () => {
            Vue.set(this.in_progress, item.id, false);
          },
        });
      }
    },
  },
};

window.jQuery = $;
window.$ = $;
</script>

<style scoped>
.theme--light a.video_list_jump_link {
  background-color: transparent;
}
</style>