<template>
  <v-select :items="items" :label="label" :value="value" @input="value_change">
    <template slot="item" slot-scope="item">
      <div style="margin-top: 5px">
        <div v-if="item.item.value">
          <v-layout justify-space-between style="width: 600px; padding: 10px">
            <div style="padding-top: 15px;  padding-left: 10px; ">{{ item.item.text }}</div>
            <img
              v-if="item.item.value == 'long'"
              :src="'/assets/arrows/' + item.item.value + '_black_right.png'"
              style="background-color: white; height:50px; width: 150px; "
            />
            <img
              v-else
              :src="'/assets/arrows/' + item.item.value + '_black_right.png'"
              style="background-color: white; height:50px; width: 50px;"
            />
          </v-layout>
          <v-divider></v-divider>
        </div>
      </div>
    </template>
  </v-select>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: function() {
        this.$t("common.arrow_shape")
      }
    },
  },
  data() {
    return {
      items: [
        { text: this.$t("common.short"), value: "short" },
        { text: this.$t("common.long"), value: "long" },
        { text: this.$t("common.triangle"), value: "triangle" },
        { text: this.$t("common.thin"), value: "thin" },
        { text: this.$t("common.circle"), value: "circled" },
        { text: this.$t("common.chevron"), value: "chevron" },
        { text: this.$t("common.tumbling_e"), value: "snellen_e" },
        { text: this.$t("common.tumbling_c"), value: "snellen_hook" },
        { text: this.$t("common.hand"), value: "hand" },
      ],
    };
  },
  methods: {
    value_change(event) {
      this.$emit("input", event);
    },
  },
};
</script>