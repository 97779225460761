import { render, staticRenderFns } from "./flat_jump_vergence.config.vue?vue&type=template&id=efd72194"
import script from "./flat_jump_vergence.config.vue?vue&type=script&lang=js"
export * from "./flat_jump_vergence.config.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../app/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports