export function boot_helpscout(current_user, current_clinic_id, user_clinics, user_invites, helpscout_key, app_path = '/app', i18n = null) {
    !(function (e, t, n) {
        function a() {
            var e = t.getElementsByTagName("script")[0],
                n = t.createElement("script");
            (n.type = "text/javascript"),
                (n.async = !0),
                (n.src = "https://beacon-v2.helpscout.net"),
                e.parentNode.insertBefore(n, e);
        }
        if (
            ((e.Beacon = n =
                function (t, n, a) {
                    e.Beacon.readyQueue.push({ method: t, options: n, data: a });
                }),
                (n.readyQueue = []),
                "complete" === t.readyState)
        )
            return a();
        e.attachEvent
            ? e.attachEvent("onload", a)
            : e.addEventListener("load", a, !1);
    })(window, document, window.Beacon || function () { });

    window.Beacon("init", helpscout_key);

    window.Beacon("config", {
        display: {
            verticalOffset: 30,
            horizontalOffset: 10,
            position: (i18n && (i18n.lang() == 'he' || i18n.lang == 'ar') ? 'left' : 'right')
        }
    });

    if (i18n && i18n.messages && i18n.messages[i18n.lang()]) {
        let messages = i18n.messages[i18n.lang()];
        if (messages.helpscout_beacon) {
            // Flatten messages.helpscout_beacon, only keeping the leaf keys, messages.helpscout_beacon is only 2 levels deep
            let helpscout_beacon_messages = {};
            for (let key in messages.helpscout_beacon) {
                for (let subkey in messages.helpscout_beacon[key]) {
                    helpscout_beacon_messages[subkey] = messages.helpscout_beacon[key][subkey];
                }
            }
            window.Beacon("config", {
                'labels': helpscout_beacon_messages
            });
        }
    }

    // Set user info
    try {
        let clinic_id = current_clinic_id;
        let clinic_title = "";

        user_clinics.forEach((clinic) => {
            if (clinic.id == clinic_id) {
                clinic_title = clinic.title;
            }
        });

        var user_role = "user";
        user_invites.forEach((invite) => {
            if (invite.user_role == "admin") {
                user_clinics.forEach((clinic) => {
                    if (clinic.id == invite.clinic_id && !clinic.has_tag('archived')) {
                        clinic_title = clinic.title;
                        clinic_id = clinic.id;
                        user_role = "admin";
                    }
                });
            }
            if (invite.user_role == "staff" && user_role == "user") {
                user_clinics.forEach((clinic) => {
                    if (clinic.id == invite.clinic_id && !clinic.has_tag('archived')) {
                        clinic_title = clinic.title;
                        clinic_id = clinic.id;
                        user_role = "staff";
                    }
                });
            }
        });

        if (current_user) {
            let identify = {
                name: current_user.name_given + " " + current_user.name_family,
                email: current_user.email,
                company: clinic_title,
                clinic: clinic_title,
                clinic_id: clinic_id,
                clinic_url: "https://" + location.host + app_path + "/clinic/" + clinic_id,
                user_id: current_user.id,
                user_role: user_role,
            };

            window.Beacon("identify", identify);
        }
    } catch (err) {
        window.OcudigitalLogError(err);
        if (console) {
            // eslint-disable-next-line no-console
            console.error(err);
        }
    }
}

export function open_helpscout(current_user, current_clinic_id, user_clinics, user_invites, helpscout_key) {
    if (window.Beacon) {
        window.Beacon("init", helpscout_key);
        window.Beacon("open");
    } else {
        boot_helpscout(current_user, current_clinic_id, user_clinics, user_invites);
        window.Beacon("open");
    }
}

export function logout_helpscout() {
    if (window.Beacon) {
        window.Beacon("logout", { endActiveChat: true });
    }
}