<template>
  <div
    class="clinic-page-user-line"
    style="
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px;
    "
  >
    <!-- Avatar, Name and email -->
    <div :style=" dense? 'width: 300' : 'width: 325px'">
      <div
        v-if="!$vuetify.rtl"
        style="float: left"
        class="user_line_avatar_container"
      >
        <v-avatar
          :color="avatar_color"
          size="42"
          class="user_avatar"
        >
          <span class="white--text" style="margin-bottom: 2px">{{
            avatar_text
          }}</span>
        </v-avatar>
      </div>
      <div style="width: 265px" class="user_line_name_container">
        <div style="user-select: all">
          {{ user.name_given }} {{ user.name_family }}
        </div>
        <div
          style="
            user-select: all;
            font-size: 0.8rem;
          "
        >
          {{ user.email }}
        </div>
      </div>
    </div>

    <!-- Assigned to staff -->
    <div v-if="show_assignment" style="width: 180px; font-size: 0.9rem">
      <div v-if="owner && owner.assigned" style="font-size: 0.8rem">{{ $t("common.assigned_to", {name_given: owner.name_given, name_family: owner.name_family}) }}</div>
      <div v-else style="font-style: italic">{{ $t("common.unassigned") }}</div>
    </div>

    <!-- Activity Summary -->
    <div :style="dense ? 'width: 280px' : 'width: 350px'">
      <v-lazy v-model="activity_summary_lazy">
        <ActivitySummary
          :class="dense ? 'mt-2' : 'mt-1'"
          :summary="user_activity_summary"
          :user="user"
          :clinic_id="clinic.id"
          :exercises="exercises"
          :video_exercises="video_exercises"
          :dense="dense"
        ></ActivitySummary>
      </v-lazy>
    </div>

    <!-- Notifications -->
    <div style="width: 250px; margin-top: 5px" :class="dense ? 'mt-3' : ''">
      <!-- Mock Invite -->
      <v-chip v-if="user.tags.includes('mock_invite')" outlined label :small="dense">{{
        $t("common.mock_patient")
      }}</v-chip>

      <!-- No subscription -->
      <div v-else-if="!subscription && !clinic.has_tag('free') && (user.billing_strategy == 'user' || (!user.billing_strategy && clinic.billing_strategy == 'user')) && (app_config.subsciption_first || user.regimen_exists)">
        <v-chip outlined label :small="dense" color="orange">
          <v-icon left :small="dense">mdi-card-bulleted-off-outline</v-icon>
          {{ $t("clinic_page_user_line.no_subscription") }}
        </v-chip>
      </div>

      <!-- Nothing assigned -->
      <v-chip
        v-else-if="!user.regimen_exists"
        outlined
        label
        :small="dense"
        color="orange"
      >
        {{ $t("clinic_page_user_line.nothing_assigned") }}
      </v-chip>

      <!-- Days until expire -->
      <div v-else-if="days_until_expire">
        <v-chip outlined label :small="dense" color="orange">
          <v-icon left :small="dense">alarm</v-icon>
          {{ $t("clinic_page_user_line.expires_in") }} {{ days_until_expire }}
          {{ $t("common.days") }}
        </v-chip>
      </div>

      <!-- Days since activity -->
      <Popper
        v-else-if="days_since_acivity && show_days_since"
        trigger="hover"
        :options="{ placement: 'top' }"
      >
        <div class="popper pa-3">
          <span
            >{{ $t("clinic_page_user_line.its_been") }}
            {{ days_since_acivity }}
            {{ $t("clinic_page_user_line.days_since") }} {{ user.name_given }}
            {{ user.name_family }}
            {{ $t("clinic_page_user_line.completed_an_activity") }}</span
          >
          <v-btn
            v-if="days_since_acivity == '30+' && !clinic.has_tag('free')"
            tile
            x-small
            class="ml-6"
            @click="archive_user"
            ><v-icon x-small left>archive</v-icon
            >{{ $t("common.archive") }}</v-btn
          >
        </div>

        <v-chip slot="reference" outlined label :small="dense">
          <v-icon left :small="dense">hourglass_empty</v-icon>
          {{ days_since_acivity }} {{ $t("common.days") }}
        </v-chip>
      </Popper>

      <!-- Non-standard billing strategy -->
      <div v-else-if="user.billing_strategy && (user.billing_strategy != clinic.billing_strategy)">
        <v-chip outlined label :small="dense" color="blue">
          <v-icon left :small="dense">mdi-account-cash</v-icon>
          <span v-if="user.billing_strategy == 'user'"> {{ $t('common.user_pays') }}</span>
          <span v-else-if="user.billing_strategy == 'clinic'"> {{ $t('common.clinic_pays') }}</span>
          <span v-else-if="user.billing_strategy == 'free'"> {{ $t('common.test_user_free') }}</span>
        </v-chip>
      </div>
    </div>

    <!-- Actions -->
    <div :style="'width: 300px; ' + ($vuetify.rtl ? 'text-align: left; margin-left: 10px;' : 'text-align: right; margin-right: 10px;')">
      <v-tooltip v-if="show_masquerade" top open-delay="500">
        <template v-slot:activator="{ on }">
          <v-btn
            :small="!dense"
            :x-small="dense"
            outlined
            fab
            :tile="dense"
            color="orange"
            :class="{
              user_line_action_button: !dense,
              user_line_action_button_dense: dense,
            }"
            v-on="on"
            @click="masquerade_as_user()"
          >
            <v-icon>pageview</v-icon>
          </v-btn>
        </template>
        {{ $t("clinic_page_user_line.view_application") }}
        {{ user.name_given }} {{ user.name_family }}
      </v-tooltip>

      <v-tooltip v-if="show_user_edit" top open-delay="500">
        <template v-slot:activator="{ on }">
          <v-btn
            :small="!dense"
            :x-small="dense"
            outlined
            fab
            :tile="dense"
            color="green"
            :class="{
              user_line_action_button: !dense,
              user_line_action_button_dense: dense,
            }"
            v-on="on"
            @click="open_active_invite_dialog"
          >
            <v-icon>perm_identity</v-icon>
          </v-btn>
        </template>
        {{ $t("clinic_page_user_line.view_edit_user") }}
      </v-tooltip>

      <v-tooltip v-if="show_regimen_edit" top open-delay="500">
        <template v-slot:activator="{ on }">
          <v-btn
            :small="!dense"
            :x-small="dense"
            outlined
            fab
            :tile="dense"
            color="teal"
            :class="{
              user_line_action_button: !dense,
              user_line_action_button_dense: dense,
            }"
            v-on="on"
            @click="regimen_dialog = true"
          >
            <v-icon>list</v-icon>
          </v-btn>
        </template>
        {{ $t("clinic_page_user_line.assign_exercises") }}
      </v-tooltip>

      <v-tooltip top open-delay="500">
        <template v-slot:activator="{ on }">
          <v-btn
            :small="!dense"
            :x-small="dense"
            outlined
            fab
            :tile="dense"
            color="blue"
            :class="{
              user_line_action_button: !dense,
              user_line_action_button_dense: dense,
            }"
            v-on="on"
            @click="report_dialog = true"
          >
            <v-icon>fa-chart-bar</v-icon>
          </v-btn>
        </template>
        {{ $t("common.progress_report") }}
      </v-tooltip>

      <slot name="action"></slot>
    </div>

    <v-dialog
      v-model="regimen_dialog"
      scrollable
      max-width="1200"
      :transition="false"
    >
      <RegimenEdit
        v-if="regimen_dialog"
        ref="regimen_edit"
        :invite_id="user.invite_id"
        :invite_accepted="true"
        :user_name="user.name_given"
        :current_user="current_user"
        :clinic="clinic"
        :exercises="exercises"
        :video_exercises="video_exercises"
        :staff="clinic_staff"
        :invite_owner_id="owner && owner.id ? owner.id : ''"
        :grid_sets="grid_sets"
        :grid_schedules="grid_schedules"
        @close="regimen_dialog = false"
        @regimen-created="$emit('regimen_created')"
        @regimen-updated="$emit('regimen_updated')"
      />
    </v-dialog>

    <v-dialog v-model="report_dialog" max-width="1400" :transition="false">
      <RegimenReport
        v-if="report_dialog"
        ref="regimen_report"
        :user="user"
        :invite_id="user.invite_id"
        :clinic_id="clinic.id"
        :exercises="exercises"
        :video_exercises="video_exercises"
        style="overflow: hidden"
        @close="report_dialog = false"
      />
    </v-dialog>

    <v-dialog
      v-model="active_invite_dialog"
      scrollable
      max-width="1000"
      :transition="false"
    >
      <InviteActiveEdit
        v-if="active_invite_dialog"
        ref="active_invite_edit"
        :user="user"
        :invite_id="user.invite_id"
        :clinic="clinic"
        :clinic_user_info_survey="clinic_user_info_survey"
        @close="active_invite_dialog = false"
        @archive_user="archive_user"
        @user_updated="user_updated"
        @invite_updated="user_updated"
      />
    </v-dialog>
  </div>
</template>

<script>
import ActivitySummary from "./ActivitySummary";
import RegimenReport from "./RegimenReport";
import RegimenEdit from "./RegimenEdit";
import InviteActiveEdit from "./InviteActiveEdit";
import Color from "color";
import Vue from "vue";
import Popper from "vue-popperjs";
import { mapState } from "vuex";

export default {
  components: {
    ActivitySummary,
    RegimenReport,
    RegimenEdit,
    InviteActiveEdit,
    Popper,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    current_user: {
      type: Object,
      required: true,
    },
    clinic: {
      type: Object,
      required: true,
    },
    subscription: {
      type: Object,
      default: () => (null),
    },
    exercises: {
      type: Object,
      required: true,
    },
    video_exercises: {
      type: Object,
      required: true,
    },
    clinic_staff: {
      type: Array,
      required: true,
    },
    latest_activity: {
      type: String,
      required: false,
      default: () => undefined,
    },
    show_days_since: {
      type: Boolean,
      default: () => true,
    },
    owner: {
      type: Object,
      validator: function (value) {
        return typeof value == "object" || typeof value == "undefined";
      },
      default: () => undefined,
    },
    activity_summary: {
      type: Object,
      validator: function (value) {
        return typeof value == "object" || typeof value == "undefined";
      },
      default: () => undefined,
    },
    grid_sets: {
      type: Array,
      required: true,
    },
    grid_schedules: {
      type: Array,
      required: true,
    },
    clinic_user_info_survey: {
      type: Object,
      required: false,
      default: () => null,
    },
    dense: {
      type: Boolean,
      default: () => false,
    },
    x_dense: {
      type: Boolean,
      default: () => false,
    },
    show_assignment: {
      type: Boolean,
      default: () => true,
    },
    show_user_edit: {
      type: Boolean,
      default: () => true,
    },
    show_regimen_edit: {
      type: Boolean,
      default: () => true,
    },
    show_masquerade: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      report_dialog: false,
      regimen_dialog: false,
      active_invite_dialog: false,
      activity_summary_lazy: false,
      notifications_lazy: false,
      actions_lazy: false,
    };
  },
  computed: {
    ...mapState(["app_config"]),
    is_ipad_sized() {
      let matched = window.matchMedia(
        "(min-device-width: 740px) and (max-device-width: 1080px) and (orientation: landscape)"
      );
      return matched.matches;
    },
    days_until_expire() {
      if (this.user.expiry_date) {
        const oneday = 60 * 60 * 24 * 1000;
        const oneweek = 7 * oneday;
        let now = new Date();
        let expiry_date = new Date(this.user.expiry_date);

        let duration = expiry_date - now;

        if (duration < oneweek) {
          return Math.round(duration / oneday);
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    days_since_acivity() {
      const oneday = 60 * 60 * 24 * 1000;
      const oneweek = 7 * oneday;
      let now = new Date();

      if (this.latest_activity) {
        let latest_activity = new Date(this.latest_activity);

        let duration = now - latest_activity;

        if (duration > oneweek) {
          return Math.round(duration / oneday);
        } else {
          return "";
        }
      } else {
        // Check invite created date
        if (this.user.invite_created) {
          let invite_created = new Date(this.user.invite_created);

          let duration = now - invite_created;

          if (duration > oneweek * 4) {
            return "30+";
          } else {
            return "";
          }
        }
        return "";
      }
    },
    user_activity_summary() {
      if (this.activity_summary) {
        return this.activity_summary;
      } else {
        // Return an empty activity summary
        return {
          user_id: this.user.id,
          invite_id: this.user.invite_id,
          days: {},
        };
      }
    },
    avatar_color() {
      let c = Color("#" + this.user.id.substring(0, 6));
      c = c.desaturate(0.1);
      if (c.isDark()) {
        c = c.lighten(0.5);
      } else {
        c = c.darken(0.3);
      }
      if (c.isDark()) {
        c = c.lighten(0.3);
      }
      c = c.whiten(0.1);

      return c.rgb().string();
    },
    avatar_text() {
      let avatar_text = "";
      if (this.user.name_given) {
        avatar_text += this.user.name_given[0].toUpperCase();
      }
      if (this.user.name_family) {
        avatar_text += this.user.name_family[0].toUpperCase();
      }
      return avatar_text;
    },
  },
  methods: {
    masquerade_as_user() {
      this.$emit("masquerade_as_user");
    },
    archive_user() {
      this.$emit("archive_user");
    },
    open_active_invite_dialog() {
      let self = this;
      this.active_invite_dialog = true;
      Vue.nextTick(function () {
        self.$refs.active_invite_edit.load_invite();
      });
    },
    user_updated(updated_user) {
      this.$emit("user_updated", updated_user);
    },
    invite_updated() {
      this.$emit("invite_updated");
    },
  },
};
</script>

<style>
.user_line_action_button {
  margin-left: 10px;
}

.user_line_action_button_dense {
  margin-left: 5px;
  margin-top: 8px;
}

.user_line_name_container {
  float: right;
}

@media screen and (max-width: 1720px) {
  .user_line_avatar_container {
    display: none !important;
  }
  .user_line_name_container {
    float: none;
  }
}
</style>
