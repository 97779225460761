<template>
  <v-card dark class="tool-full-colour-anaglyph">
    <v-toolbar dark>
      <v-btn icon @click="close">
        <v-icon>close</v-icon>
      </v-btn>
      <v-toolbar-title>{{ $t("common.full_colour_anaglyph_images") }}</v-toolbar-title>
      <v-spacer />

      <v-select v-model="model" :items="items" style="margin-top: 15px; width: 150px">
        <template slot="item" slot-scope="item">
          <div style="margin-top: 5px">
            <div>
              <img
                :src="thumbnail(item.item.src)"
                style="background-color: black; height:50px; width: 50px;"
              />
              <div
                style="padding-top: 10px;  padding-left: 10px; float: right;"
              >{{ item.item.text }}</div>
            </div>
          </div>
        </template>
      </v-select>
      <v-spacer />

      <!--
      <v-btn style="margin-right: 20px" @click="print">
        <v-icon>print</v-icon>
      </v-btn>
      -->
      <v-btn large :disabled="model == 0" style="margin-right: 20px" @click="model--">
        <v-icon left>skip_previous</v-icon>{{ $t("common.previous") }}
      </v-btn>
      <v-btn
        color="green darken-3"
        large
        :disabled="model == items.length - 1"
        style="margin-right: 7px"
        @click="model++"
      >
        {{ $t("common.next") }}
        <v-icon right>skip_next</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text></v-card-text>
    <v-card-text>
      <v-container v-if="no_calibration" style="width:500px">
        <v-layout justify-center>
          <v-card>
            <v-card-title class="headline">{{ $t("tool_full_colour_anaglyph.use_red_on_the_left_anaglyph_glasses") }}</v-card-title>
            <v-card-text style="font-size: larger">
              <p>{{ $t("tool_full_colour_anaglyph.these_anaglyph_images_are_meant_to_be_used_message") }}</p>
              <p>{{ $t("tool_full_colour_anaglyph.make_ensure_that_your_glasses_have_red_lens_message") }}</p>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="no_calibration = false">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-layout>
      </v-container>
      <v-container v-else-if="bad_anaglyph" style="width:500px">
        <v-layout justify-center>
          <v-card>
            <v-card-title class="headline">{{ $t("tool_full_colour_anaglyph.incompatible_anaglyph_glasses") }}</v-card-title>
            <v-card-text style="font-size: larger">
              <p>{{ $t("tool_full_colour_anaglyph.these_anaglyph_images_are_meant_to_be_used_with_red_on_the_left_anaglyph_glasses_however_you_have_configured") }}</p>
              <p>{{ $t("tool_full_colour_anaglyph.make_sure_you_are_using_red_on_the_left_anaglyph_glasses_message") }}</p>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="close()">{{ $t("common.cancel") }}</v-btn>
              <v-btn color="green darken-1" text @click="bad_anaglyph = false">{{ $t("common.continue") }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-layout>
      </v-container>
      <v-carousel
        v-else
        v-model="model"
        :height="height"
        dark
        fullscreen
        :show-arrows="false"
        hide-delimiters
      >
        <v-carousel-item
          v-for="(item,i) in items"
          :key="i"
          :src="item.src"
          reverse-transition="fade-transition"
          transition="fade-transition"
        ></v-carousel-item>
      </v-carousel>
    </v-card-text>
  </v-card>
</template>

<script>

// TODO: Move things to a global js lib dir
import { OpticalGymScreen } from "../../../exercises/v1/src/OpticalGymScreen.js";
import printJS from "print-js";
import jQuery from "jquery";

export default {
  components: {},
  props: {},
  data() {
    let items = [
      {
        text: this.$t("tool_full_colour_anaglyph.rocky_caves"),
        value: 0,
        src:
          "https://storage.googleapis.com/opticalgym-large-assets/full_colour_anaglyph/ABD_3658.jpg",
      },
      {
        text: this.$t("tool_full_colour_anaglyph.fall_river_bridge"),
        value: 1,
        src:
          "https://storage.googleapis.com/opticalgym-large-assets/full_colour_anaglyph/Inde.png",
      },
      {
        text: this.$t("tool_full_colour_anaglyph.chile_lauca_mountain"),
        value: 2,
        src:
          "https://storage.googleapis.com/opticalgym-large-assets/full_colour_anaglyph/Chile_lauca.png",
      },
      {
        text: this.$t("tool_full_colour_anaglyph.cedar_mill_goat"),
        value: 3,
        src:
          "https://storage.googleapis.com/opticalgym-large-assets/full_colour_anaglyph/Cider_Mill_Goat.jpg",
      },
      {
        text: this.$t("tool_full_colour_anaglyph.hippo_skulls"),
        value: 4,
        src:
          "https://storage.googleapis.com/opticalgym-large-assets/full_colour_anaglyph/3D_hippo_skulls.jpg",
      },
      {
        text: this.$t("tool_full_colour_anaglyph.darwin_moor_1"),
        value: 5,
        src:
          "https://storage.googleapis.com/opticalgym-large-assets/full_colour_anaglyph/Darwen_Moor_3D_1.jpg",
      },
      {
        text: this.$t("tool_full_colour_anaglyph.darwin_moor_2"),
        value: 6,
        src:
          "https://storage.googleapis.com/opticalgym-large-assets/full_colour_anaglyph/Darwen_Moor_3D.jpg",
      },
      {
        text: this.$t("tool_full_colour_anaglyph.hagley_park"),
        value: 7,
        src:
          "https://storage.googleapis.com/opticalgym-large-assets/full_colour_anaglyph/HagleyPark03_3D_gobeirne.jpg",
      },
      {
        text: this.$t("tool_full_colour_anaglyph.indoor_scene"),
        value: 8,
        src:
          "https://storage.googleapis.com/opticalgym-large-assets/full_colour_anaglyph/Museum_Pirna_Krankenzimmer.jpg",
      },
      {
        text: this.$t("tool_full_colour_anaglyph.statue_in_ueno_park"),
        value: 9,
        src:
          "https://storage.googleapis.com/opticalgym-large-assets/full_colour_anaglyph/Anaglyph-ueno-statue-2013.jpg",
      },
      {
        text: this.$t("tool_full_colour_anaglyph.cars_in_museum_pirna"),
        value: 10,
        src:
          "https://storage.googleapis.com/opticalgym-large-assets/full_colour_anaglyph/Museum_Pirna_Trabant_P50_und_Lada_1200_der_Feuerwehr.jpg",
      },
      {
        text: this.$t("tool_full_colour_anaglyph.tehran"),
        value: 11,
        src:
          "https://storage.googleapis.com/opticalgym-large-assets/full_colour_anaglyph/Tehran_North_East_view_Anaglyph.jpg",
      },
      {
        text: this.$t("tool_full_colour_anaglyph.tehran_at_night"),
        value: 12,
        src:
          "https://storage.googleapis.com/opticalgym-large-assets/full_colour_anaglyph/Tehran_landscape_at_night_Anaglyph.jpg",
      },
      {
        text: this.$t("tool_full_colour_anaglyph.trunk_hole"),
        value: 13,
        src:
          "https://storage.googleapis.com/opticalgym-large-assets/full_colour_anaglyph/Trunk_hole.jpg",
      },
      {
        text: this.$t("tool_full_colour_anaglyph.vostok_rocket_engines"),
        value: 14,
        src:
          "https://storage.googleapis.com/opticalgym-large-assets/full_colour_anaglyph/VDNKh-Wostok-Launcher-3.jpg",
      },
      {
        text: this.$t("tool_full_colour_anaglyph.warsaw_carmelite_church"),
        value: 15,
        src:
          "https://storage.googleapis.com/opticalgym-large-assets/full_colour_anaglyph/Warsaw_Carmelite_Church.jpg",
      },
      {
        text: this.$t("tool_full_colour_anaglyph.watch_mechanism"),
        value: 16,
        src:
          "https://storage.googleapis.com/opticalgym-large-assets/full_colour_anaglyph/Watch_mechanism.jpg",
      },
      {
        text: this.$t("tool_full_colour_anaglyph.wupakti_national_monument_1"),
        value: 17,
        src:
          "https://storage.googleapis.com/opticalgym-large-assets/full_colour_anaglyph/Wupakti_National_Monumen_22.jpg",
      },
      {
        text: this.$t("tool_full_colour_anaglyph.wupakti_national_monument_2"),
        value: 18,
        src:
          "https://storage.googleapis.com/opticalgym-large-assets/full_colour_anaglyph/Wupakti_National_Monument_13.jpg",
      },
      {
        text: this.$t("tool_full_colour_anaglyph.dusk_in_the_desert"),
        value: 19,
        src:
          "https://storage.googleapis.com/opticalgym-large-assets/full_colour_anaglyph/3D_dusk_on_Desert.jpg",
      },
      {
        text: this.$t("tool_full_colour_anaglyph.passion_flower"),
        value: 20,
        src:
          "https://storage.googleapis.com/opticalgym-large-assets/full_colour_anaglyph/passion_flower_layered.jpg",
      },
      {
        text: this.$t("tool_full_colour_anaglyph.beach_steps"),
        value: 21,
        src:
          "https://storage.googleapis.com/opticalgym-large-assets/full_colour_anaglyph/Beach_Steps.jpg",
      },
    ];

    let bad_anaglyph = false;
    let no_calibration = false;
    var calibration = OpticalGymScreen.getCalibration();
    if (!calibration) {
      no_calibration = true;
    }
    if (calibration && !calibration.anaglyph_orientation.startsWith("red")) {
      bad_anaglyph = true;
    }

    return {
      bad_anaglyph,
      no_calibration,
      items,
      in_flight: false,
      model: 0,
    };
  },
  computed: {
    height() {
      return window.innerHeight - 105;
    },
  },
  mounted() {
    this.set_zindex();
  },
  methods: {
    set_zindex() {
      jQuery(".tool-full-colour-anaglyph")
        .parents(".v-dialog__content--active")
        .css("z-index", "2147483001");
    },
    thumbnail(url) {
      return url.replace(
        "/full_colour_anaglyph/",
        "/full_colour_anaglyph/thumbnails/"
      );
    },
    close: function () {
      this.$emit("close");
    },
    print: function () {
      let self = this;

      if (navigator.vendor.includes("Apple")) {
        window.open(this.url(), "_blank");
        return;
      }

      printJS({
        printable: self.items[self.model].src,
        onLoadingStart: function () {
          self.in_flight = true;
        },
        onLoadingEnd: function () {
          self.in_flight = false;
        },
      });
    },
  },
};
</script>