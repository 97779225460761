import { render, staticRenderFns } from "./peripheral.config.vue?vue&type=template&id=420d8641"
import script from "./peripheral.config.vue?vue&type=script&lang=js"
export * from "./peripheral.config.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../app/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports