<template>
  <div class="exercise-demo-list">
    <div v-if="sidebar && $vuetify.breakpoint.name != 'xs' && $vuetify.breakpoint.name != 'sm'" class="category-list" style="position:fixed;">
      <v-container>
        <v-navigation-drawer permanent>
          <v-list class="mt-1">
            <v-list-item
              v-for="group in all_exercises"
              :key="group.group"
              :href="group.anchor"
              class="exercise_demo_list_jump_link"
            >
              <v-list-item-action>
                <v-icon>{{ icons[group.group] }}</v-icon>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ $t('group.' + group.group) }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </v-container>
    </div>

    <div :style="sidebar && $vuetify.breakpoint.name != 'xs' && $vuetify.breakpoint.name != 'sm' ? 'position: relative; left: 266px; padding-right: 270px' : ''">
      <div v-for="group in all_exercises" :key="group.group">
        <v-card>
          <v-card-title primary-title>
            <h3 :id="group.id" class="headline mb-0">
              <v-icon left style="vertical-align: unset">{{ icons[group.group] }}</v-icon>
              {{ $t('group.' + group.group) }}
            </h3>
          </v-card-title>

          <div>
            <v-list>
              <div
                v-for="item in group.exercises"
                :key="item.id"
                class="exercise_demo"
                :class="item.id"
              >
                <v-list-item
                  three-line
                  style="cursor: pointer;"
                  @click="open_exercise_dialog(item)"
                >
                  <v-list-item-avatar>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ exercise_title(item) }}
                      <v-chip v-if="item.experimental" small label color="blue">Beta</v-chip>
                      <v-chip v-if="item.demo_only" small label color="green">Admin Only</v-chip>
                      <v-chip
                        v-if="item.is_new"
                        small
                        label
                        color="light-green"
                        style="margin-left: 10px"
                      >New</v-chip>
                      <v-chip
                        v-if="!calibrated && item.requires_calibration"
                        small
                        label
                      >{{ $t("common.requires_calibration") }}</v-chip>
                    </v-list-item-title>
                    <v-list-item-subtitle v-html="exercise_description(item)" />
                  </v-list-item-content>
                </v-list-item>
                <v-divider />

                <div v-if="item.type == 'interactive'">
                  <v-dialog v-model="dialogs[item.id]" max-width="1000">
                    <ExerciseConfig
                      v-if="dialogs[item.id]"
                      :key="item.exercise_id"
                      :exercise="item"
                      :show_advanced="show_advanced_config"
                      show_additional
                      @close="close_dialog(item.id)"
                    />
                  </v-dialog>
                </div>
                <div v-if="item.type == 'video'">
                  <v-dialog
                    v-model="dialogs[item.id]"
                    max-width="800"
                    class="video_exercise_dialog"
                    @click:outside="stop_videos"
                  >
                    <VideoExercise
                      v-if="dialogs[item.id]"
                      ref="VideoExercise"
                      :exercise="item"
                      @close="close_dialog(item.id)"
                      @exercise-finished="close_dialog(item.id)"
                    />
                  </v-dialog>
                </div>
              </div>
            </v-list>
          </div>
        </v-card>
      </div>

      <v-banner
        v-if="show_missing_something"
        two-line
        elevation="2"
        style="margin-top: 40px;"
        width="800px"
        icon="contact_support"
      >
        <p>{{ $t("common.missing_something") }}</p>
        <p style="font-size: smaller">{{ $t("common.contact_us_text") }}</p>

        <template v-slot:actions>
          <v-btn
            text
            color="primary accent-4"
            style="relative; top: -20px"
            @click="contact_us_dialog = true"
          >{{ $t("common.contact_us") }}</v-btn>
        </template>

        <v-dialog v-model="contact_us_dialog" max-width="600px">
          <ContactUs v-if="contact_us_dialog" @close="contact_us_dialog = false" />
        </v-dialog>
      </v-banner>
    </div>

    <v-dialog v-model="require_calibration_dialog" max-width="400">
      <v-card v-if="require_calibration_dialog">
        <v-card-title class="headline">{{ $t("common.calibration_is_required") }}</v-card-title>
        <v-card-text>{{ $t("common.before_continue_we_need_to_calibrate_your_device") }}</v-card-text>
        <v-card-actions>
          <v-btn
            color="green darken-1"
            text
            @click="open_calibration_dialog"
          >{{ $t("common.click_here_to_calibrate_your_device") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import Vue from "vue";
import $ from "jquery";
import ExerciseConfig from "./ExerciseConfig.vue";
import VideoExercise from "./VideoExercise.vue";
import ContactUs from "./ContactUs.vue";
import { category_icons } from "../lib/category";
import jQuery from "jquery";

export default {
  components: { ExerciseConfig, ContactUs, VideoExercise },
  props: {
    show_advanced_config: {
      type: Boolean,
      default: true,
    },
    show_missing_something: {
      type: Boolean,
      default: false,
    },
    current_user: {
      type: Object,
      required: true,
    },
    filter: {
      type: String,
      default: "",
    },
    sidebar: {
      type: Boolean,
      default: () => true,
    },
    top_distance: {
      type: Number,
      default: 128,
    },
  },
  data() {
    return {
      exercises_array: [],
      grouped: [],
      dialogs: {},
      require_calibration_dialog: false,
      contact_us_dialog: false,
      icons: category_icons,
    };
  },
  computed: {
    ...mapState(["user_metadata_loading", "current_calibration", "exercises", "video_exercises", "current_clinic"]),
    interactive_exercises() {
      return this.exercises;
    },
    calibrated() {
      if (this.user_metadata_loading) {
        return true;
      }
      if (this.current_calibration) {
        return true;
      }

      return false;
    },
    all_exercises() {
      // All exercises is a list of groups, with a list of exercisees
      let groups = [];

      // Group them
      // ["interactive_exercises", "video_exercises"].forEach((exercise_type) => {
      ["interactive_exercises"].forEach((exercise_type) => {
        for (var id in this[exercise_type]) {
          var exercise = this[exercise_type][id];

          // Filter
          if (this.filter) {
            let filter = this.filter.trim().toLowerCase();
            let exercise_title = this.exercise_title(exercise);
            let exercise_desc = this.exercise_description(exercise);
            if (!exercise_title.toLowerCase().includes(filter) && !exercise_desc.toLowerCase().includes(filter)) {
              continue;
            }
          }

          if (exercise_type == "interactive_exercises") {
            exercise.type = "interactive";
            exercise.icon = "category";

            // If its just a demo, hide it unless we are admin
            // If it's deprecated, don't show it at all
            if (
              exercise.deprecated ||
              (exercise.demo_only && !this.current_user.is_admin) ||
              (exercise.canary && (!this.current_clinic || !this.current_clinic.has_tag("canary")) && !this.current_user.is_admin)
            ) {
              continue;
            }
          } else if (exercise_type == "video_exercises") {
            exercise.type = "video";
            if (exercise.default.video) {
              exercise.icon = "ondemand_video";
            } else {
              exercise.icon = "subject"; // TODO: find an icon that sugggests text and images
            }
          }

          exercise.group.forEach((group_name) => {
            if (group_name == "") {
              group_name = "Other";
            }

            var group_exists = false;
            var group = {};

            for (var i in groups) {
              if (groups[i].group == group_name) {
                group_exists = true;
                group = groups[i];
              }
            }

            if (!group_exists) {
              group = {
                group: group_name,
                anchor: "#" + group_name.replace(/\s+/g, ""),
                id: group_name.replace(/\s+/g, ""),
                exercises: [],
              };
            }

            group.exercises.push(exercise);
            if (!group_exists) {
              groups.push(group);
            }
          });
        }
      });

      // Sort the groups and exercises
      groups.sort((a, b) => {
        if (a.group == "Other") {
          return 1;
        } else return a.group > b.group ? 1 : -1;
      });

      // Sort exercises in groups
      for (var i in groups) {
        groups[i].exercises.sort((a, b) => (this.exercise_title(a) > this.exercise_title(b) ? 1 : -1));
      }

      return groups;
    },
  },
  mounted() {
    let distance = this.top_distance;
    jQuery(window).scroll(function () {
      var $el = jQuery(".exercise-demo-list .category-list");

      let top = jQuery(this).scrollTop();

      if (top > distance) {
        $el.css({ position: "fixed", top: "0px" });
      }
      if (top < distance) {
        $el.css({ position: "fixed", top: distance - top + "px" });
      }
    });
  },
  methods: {
    exercise_title(exercise) {
      let locale = this.$i18n.lang();
      if (exercise.title[locale]) {
        return exercise.title[locale];
      } else {
        return exercise.title.en;
      }
    },
    exercise_description(exercise) {
      let locale = this.$i18n.lang();
      if (exercise.description[locale]) {
        return exercise.description[locale];
      } else {
        return exercise.description.en;
      }
    },
    stop_videos() {
      for (var i in this.$refs["VideoExercise"]) {
        this.$refs["VideoExercise"][i].stop();
      }
    },
    close_dialog(exercise_id) {
      this.stop_videos();
      Vue.set(this.dialogs, exercise_id, false);
    },
    open_calibration_dialog() {
      this.$store.commit("setCalibrationDialog", true);
      this.require_calibration_dialog = false;
    },
    open_exercise_dialog(exercise) {
      var calibration = this.current_calibration;
      if (!calibration && exercise.requires_calibration) {
        this.require_calibration_dialog = true;
      } else {
        Vue.set(this.dialogs, exercise.id, true);
        Vue.nextTick(function () {
          $("#exercise_config_focus").focus();
        });
      }
    },
    image_url(img_path) {
      return img_path;
    },
  },
};
</script>

<style scoped>
.theme--light a.exercise_demo_list_jump_link {
  background-color: transparent;
}
</style>