<template>
  <v-card>
    <v-card-text>
      <v-container>
        <v-layout row>
          <v-flex xs12>
            <v-select
              v-model="localValue.assessment_id"
              :items="assessment_select_options"
              :label="$t('common.assessment')"
              :rules="[(v) => !!v || 'Assessment is required']"
              outlined
            >
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title v-html="item.text"></v-list-item-title>
                  <v-list-item-subtitle
                    v-html="item.subtitle"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-select>
          </v-flex>

          <v-flex xs12>
            <v-checkbox
              v-model="localValue.email_pdf_to_user"
              :label="$t('regimen_assessment_edit.email_pdf_to_user')"
            />
          </v-flex>

          <v-flex xs12>
            <p>{{ $t("common.instructions") }}</p>
            <quillEditor
              v-model="localValue.instructions"
              :options="quill_config"
              style="height: 140px; margin-bottom: 20px"
            />
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" class="mt-6" @click="save">{{ $t("common.save") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

import { quillEditor } from "vue-quill-editor";

import { mapState } from "vuex";

export default {
  components: { quillEditor },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    let data = {
      assessment_id: null,
      instructions: "",
      email_pdf_to_user: false,
      quill_config: {
        placeholder: this.$t("common.instructions"),
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],

            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],

            ["clean"], // remove formatting button
          ],
        },
      },
    };
    return data;
  },
  computed: {
    ...mapState(["current_user", "grid_sets", "app_config"]),
    assessment_select_options() {
      let assessment_select_options = [];

      if (!this.app_config.assessments) {
        return assessment_select_options;
      }

      for (let grid_set of this.grid_sets) {
        if (!this.app_config.assessments[grid_set.id]) {
          continue;
        }

        let option = {
          text: grid_set.title,
          value: grid_set.id,
          subtitle: grid_set.description,
        };
        assessment_select_options.push(option);
      }

      return assessment_select_options;
    },
    localValue: {
      get() {
        let value = this.value;

        if (!value.assessment_id && this.app_config.assessments) {
          value.assessment_id = this.assessment_select_options[0].value;
        }

        return value;
      },
      set(localValue) {
        if (!localValue.assessment_id && this.app_config.assessments) {
          localValue.assessment_id = this.assessment_select_options[0].value;
        }
        this.$emit("input", localValue);
      },
    },
  },
  methods: {
    save() {
      this.$emit("save");
    },
  },
};
</script>

<style></style>
