<template>
  <v-flex xs12 sm8 md4>
    <v-card class="elevation-12">
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ $t("password_reset.password_reset") }}</v-toolbar-title>
        <v-spacer />
      </v-toolbar>

      <v-card-text>
        <v-alert
          v-if="!one_time_token || !email || invalid_token"
          :value="true"
          type="error"
        >{{ $t("password_reset.invalid_password_reset_link") }}</v-alert>

        <!-- TODO: Provide form to reset again -->
        <v-alert
          v-else-if="token_expired"
          :value="true"
          type="error"
        >{{ $t("password_reset.this_password_reset_link_has_expired") }}</v-alert>

        <v-form v-else @submit="reset">
          <DoublePassword
            v-model="password"
            load-on-mount
            always-show-confirm
            @passwords-match="update_passwords_match"
          />
        </v-form>
      </v-card-text>

      <v-card-actions v-if="one_time_token && email && !invalid_token">
        <v-spacer />
        <v-btn
          color="primary"
          :disabled="!passwords_match"
          @click="reset()"
        >{{ $t("common.reset_password") }}</v-btn>
      </v-card-actions>
      <v-progress-linear v-if="in_flight" :indeterminate="true" />
    </v-card>
  </v-flex>
</template>


<script>
import axios from "axios";
import DoublePassword from "./DoublePassword";

export default {
  components: { DoublePassword },
  data() {
    return {
      password: "",
      one_time_token: null,
      email: null,
      in_flight: false,
      passwords_match: false,
      invalid_token: false,
      token_expired: false,
    };
  },
  mounted() {
    let self = this;
    window.addEventListener("keyup", function (event) {
      // Enter Key
      if (event.keyCode === 13) {
        self.reset();
      }
    });

    // Get the one_time_token and the email
    let query_params = new URLSearchParams(location.search);
    self.one_time_token = query_params.get("one_time_token") || null;
    self.email = query_params.get("email") || null;

    if (!self.one_time_token) {
      return;
    }

    // Check that the one-time-token is valid
    axios
      .post(
        "/api/user/check_password_reset",
        JSON.stringify(self.one_time_token),
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(function (response) {
        let resp = response.data;

        if (resp.status != "success") {
          if (resp.error_code == "InvalidOneTimeToken") {
            self.invalid_token = true;
          }
          if (resp.error_code == "ExpiredOneTimeToken") {
            self.token_expired = true;
          }
        }
      });
  },
  methods: {
    update_passwords_match: function (event_value) {
      this.passwords_match = event_value;
    },
    reset: function () {
      let self = this;
      self.in_flight = true;
      axios
        .post("/api/user/password_reset", {
          email: self.email,
          pass: self.password,
          one_time_token: self.one_time_token,
        })
        .then(function (response) {
          self.in_flight = false;
          let resp = response.data;
          if (resp.status == "success") {
            self.$router.push("/app");
            window.location.reload();
          } else {
            if (resp.error_code == "InvalidOneTimeToken") {
              self.invalid_token = true;
            }
            if (resp.error_code == "ExpiredOneTimeToken") {
              self.token_expired = true;
            }
          }
        });
    },
  },
};
</script>