<template>
  <v-app>
    <GridHistory v-if="current_clinic" />
  </v-app>
</template>

<script>
import GridHistory from "../components/GridHistory.vue";
import { mapState } from "vuex";

export default {
  components: { GridHistory },
  data() {
    return {};
  },
  computed: {
    ...mapState(["current_clinic", "current_clinic_id"]),
  },
  mounted() {
    if (!this.current_clinic && this.current_clinic_id) {
      this.$store.dispatch("loadCurrentClinic", { clinic_id: this.current_clinic_id });
    }
  }
};
</script>

<style>
</style>