<template>
  <div>
    <v-card id="assessment_exercise_modal">
      <v-card-title style="padding: 0px">
        <v-toolbar grey flat>
          <v-btn icon @click="close">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>{{
            assessment_grid ? assessment_grid.title : ""
          }}</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <slot name="toolbar_items"></slot>
          </v-toolbar-items>
        </v-toolbar>
      </v-card-title>
      <v-divider />

      <v-card-text>
        <div
          v-if="exercise_instructions"
          style="font-size: larger; margin-top: 20px"
          class="exercise-instructions"
          v-html="exercise_instructions"
        ></div>

        <Assessment
          v-if="assessment_grid"
          ref="VuetifySurvey"
          :assessment_id="exercise.assessment_id"
          :invite_id="invite_id"
          :show_title="false"
          @exercise-finished="exercise_finished"
        />
      </v-card-text>

      <!-- Completed modal -->
      <v-dialog v-model="completed_modal" max-width="500" persistent>
        <v-card>
          <v-card-title class="headline">
            {{
              $t("assessment_exercise.assessment_complete", {
                title: assessment_grid ? assessment_grid.title : "",
              })
            }}</v-card-title
          >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <p>
                    {{ $t("assessment_exercise.assessment_complete_message") }}
                  </p>

                  <p v-if="exercise.email_pdf_to_user">
                    {{
                      $t("assessment_exercise.assessment_complete_email_pdf")
                    }}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="mb-2"
              @click="exercise_finished_emit()"
            >
              {{ $t("common.continue") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import xss from "xss";
import Assessment from "./Assessment.vue";
import { mapState } from "vuex";
import axios from "axios";

export default {
  name: "AssessmentExercise",
  components: { Assessment },
  props: {
    exercise: {
      type: Object,
      required: true,
    },
    trusted: {
      type: Boolean,
      default: false,
    },
    invite_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      survey_data: {},
      start_time: Date.now(),
      end_time: 0,
      assessment_grid: null,
      completed_modal: false,
      results: null,
    };
  },
  computed: {
    ...mapState(["grid_sets", "current_clinic_id"]),
    current_language() {
      return this.$i18n.lang();
    },
    exercise_instructions() {
      if (this.trusted) {
        return this.exercise.instructions;
      } else {
        return xss(this.exercise.instructions);
      }
    },
    questionnaire() {
      return {
        items: [this.exercise.question],
      };
    },
  },
  watch: {},
  mounted() {
    this.loadAssessmentGrid();
  },
  methods: {
    exercise_finished(results) {
      this.results = results;
      let assessment_complete = {
        assessment_id: results.assessment_id,
        invite_id: results.invite_id,
        assessment_instance_id: results.assessment_instance_id,
        clinic_id: this.current_clinic_id,
        grid_set: this.assessment_grid,
      };

      let pdf_path =
        "/app/assessment_report/" +
        results.assessment_id +
        "/" +
        results.invite_id +
        "/" +
        results.assessment_instance_id +
        "?invite_id=" +
        results.invite_id;
      
      let uri = "/api/assessment/" + results.assessment_id;

      if (this.exercise.email_pdf_to_user) {
        uri += "?pdf_path=" + encodeURIComponent(pdf_path);
      }

      // Send assessment report
      axios.post(uri, assessment_complete).then((resp) => {
        if (resp.status == 200) {
          messageBus.$emit("success", "Assessment report sent to user.");
        } else {
          messageBus.$emit("error", resp.data.error_message);
        }
      });
      this.completed_modal = true;
    },
    exercise_finished_emit() {
      this.$emit("exercise-finished", this.results);
    },
    close: function () {
      this.stop();
      this.$emit("close", {});
    },
    stop: function () {},
    loadAssessmentGrid() {
      for (let gs of this.grid_sets) {
        if (gs.id == this.exercise.assessment_id) {
          this.assessment_grid = gs;
          break;
        }
      }

      if (!this.assessment_grid) {
        axios
          .get("/api/grid_set/" + this.exercise.assessment_id)
          .then((resp) => {
            if (resp.data.status == "success") {
              this.assessment_grid = resp.data.data;
            } else {
              messageBus.$emit("api-error", resp.data);
            }
          });
      }
    },
  },
};
</script>

<style></style>
