<template>
  <div class="all_charges_table">
    <!-- Toolbar -->
    <v-toolbar grey>
      <v-toolbar-title>Charges</v-toolbar-title>

      <v-btn class="ml-12" small @click="monthly_report_dialog = true">View Report</v-btn>

      <v-select
        v-model="monthly"
        :items="optionsMonthly"
        solo
        append-outer-icon="mdi-calendar"
        style="margin-left: 50px; margin-top: 25px"
        dense
        @change="select_monthly"
      ></v-select>

      <v-spacer />
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
    </v-toolbar>

    <!-- Mothly Report -->

    <!-- All Charges List -->
    <v-data-table :items="charges" :headers="headers" :items-per-page="1000" :search="search" :loading="loading" show-expand>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <table>
            <tr>
              <td>ID</td>
              <td>{{ item.id }}</td>
            </tr>
            <tr>
              <td>Payment</td>
              <td>
                <a target="_blank" :href="'https://dashboard.stripe.com/payments/' + item.payment_intent">{{ item.payment_intent }}</a>
              </td>
            </tr>
            <tr>
              <td>Customer</td>
              <td>
                <a target="_blank" :href="'https://dashboard.stripe.com/payments/' + item.customer">{{ item.customer }}</a>
              </td>
            </tr>
            <tr>
              <td>Invoice</td>
              <td>
                <a v-if="item.invoice" target="_blank" :href="'https://dashboard.stripe.com/invoices/' + item.invoice.id">{{ item.invoice.id }}</a>
                <span v-else>No Invoice</span>
              </td>
            </tr>
          </table>
        </td>
      </template>
    </v-data-table>

    <!-- Monthly Report -->
    <v-dialog v-model="monthly_report_dialog" max-width="1000">
      <v-card>
        <v-card-title style="padding: 0px">
          <v-toolbar grey flat>
            <v-btn
              icon
              @click="
                monthly_report_dialog = false;
                month_report_selected = '';
              "
            >
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>Monthly Report - {{ monthly }}</v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-divider />

        <v-card-text>
          <v-progress-circular v-if="loading" class="mt-12" indeterminate color="primary"></v-progress-circular>

          <table v-else class="monthly_report">
            <thead>
              <tr>
                <th></th>
                <th>Gross</th>
                <th>Fee</th>
                <th>Tax</th>
                <th>Net</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="title">New Subscription (Monthly)</td>
                <td>{{ monthly_report.new_subscription_gross }}</td>
                <td>{{ monthly_report.new_subscription_fee }}</td>
                <td>{{ monthly_report.new_subscription_tax }}</td>
                <td>{{ monthly_report.new_subscription_net }}</td>
              </tr>
              <tr>
                <td class="title">Subscription Renewal (Monthly)</td>
                <td>{{ monthly_report.subscription_gross }}</td>
                <td>{{ monthly_report.subscription_fee }}</td>
                <td>{{ monthly_report.subscription_tax }}</td>
                <td>{{ monthly_report.subscription_net }}</td>
              </tr>
              <tr>
                <td class="title">Other</td>
                <td>{{ monthly_report.other_gross }}</td>
                <td>{{ monthly_report.other_fee }}</td>
                <td>{{ monthly_report.other_tax }}</td>
                <td>{{ monthly_report.other_net }}</td>
              </tr>
              <tr>
                <td class="title">Total</td>
                <td class="total">{{ monthly_report.total_gross }}</td>
                <td class="total">{{ monthly_report.total_fee }}</td>
                <td class="total">{{ monthly_report.total_tax }}</td>
                <td class="total">{{ monthly_report.total_net }}</td>
              </tr>
            </tbody>
          </table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

function pad(num, size) {
  num = num.toString();
  while (num.length < size) num = "0" + num;
  return num;
}

export default {
  props: {},
  data() {
    var date = new Date();
    let currentMonth = date.getMonth() + 1;
    let currentYear = date.getFullYear();

    // Get list of available years and month
    let year = 2020;
    let month = 3;

    let optionsMonthly = [];
    while (year !== currentYear || month !== currentMonth) {
      optionsMonthly.push(year + "-" + pad(month, 2));
      month++;
      if (month == 13) {
        year++;
        month = 1;
      }
    }
    optionsMonthly.push(currentYear + "-" + pad(currentMonth, 2));
    optionsMonthly.reverse();

    return {
      headers: [
        { text: "Date", value: "_created_date", sortable: true },
        { text: "Name", value: "invoice.customer_name", sortable: true },
        { text: "Type", value: "_type", sortable: true },
        { text: "Gross", value: "_amount", sortable: true },
        { text: "Tax", value: "_tax", sortable: true },
        { text: "Fee", value: "_fee", sortable: true },
        { text: "Net", value: "_net", sortable: true },
        { text: "Original", value: "_original", sortable: true },
      ],
      monthly: currentYear + "-" + pad(currentMonth, 2),
      optionsMonthly,
      charges: [],
      month_report_selected: "",
      monthly_report_dialog: false,
      search: "",
      rows_per_page: [-1],
      loading: true,
    };
  },
  computed: {
    monthly_report() {
      let report = {
        new_subscription_gross: 0,
        subscription_gross: 0,
        other_gross: 0,
        total_gross: 0,

        new_subscription_fee: 0,
        subscription_fee: 0,
        other_fee: 0,
        total_fee: 0,

        new_subscription_tax: 0,
        subscription_tax: 0,
        other_tax: 0,
        total_tax: 0,

        new_subscription_net: 0,
        subscription_net: 0,
        other_net: 0,
        total_net: 0,
      };

      for (var i in this.charges) {
        let charge = this.charges[i];

        report.total_gross += charge._amount;
        report.total_fee += charge._fee;
        report.total_tax += charge._tax;
        report.total_net += charge._net;
        if (charge._type == "new_subscription_month") {
          report.new_subscription_gross += charge._amount;
          report.new_subscription_fee += charge._fee;
          report.new_subscription_tax += charge._tax;
          report.new_subscription_net += charge._net;
        }
        if (charge._type == "subscription_renewal_month") {
          report.subscription_gross += charge._amount;
          report.subscription_fee += charge._fee;
          report.subscription_tax += charge._tax;
          report.subscription_net += charge._net;
        }
        if (charge._type == "other") {
          report.other_gross += charge._amount;
          report.other_fee += charge._fee;
          report.other_tax += charge._tax;
          report.other_net += charge._net;
        }
      }

      let format = new Intl.NumberFormat("en-CA", {
        style: "currency",
        currency: "CAD",
      });
      for (var f in report) {
        report[f] = format.format(report[f]);
      }

      return report;
    },
  },
  mounted() {
    this.load_charges();
  },
  methods: {
    select_monthly() {
      this.load_charges();
    },
    load_charges: function () {
      let self = this;

      let year = this.monthly.substr(0, 4);
      let month = this.monthly.substr(5, 7);

      self.loading = true;
      self.charges = [];
      axios.get("/api/stripe/charges/" + year + "/" + month).then(function (response) {
        self.loading = false;
        if (response.data.status == "success") {
          for (var i in response.data.data) {
            let charge = response.data.data[i];
            if (charge.status != "succeeded") {
              continue;
            }

            // Computed
            var date = new Date(charge.created * 1000);
            charge._created_date = date.toISOString().substring(0, 10);
            charge._amount = charge.balance_transaction.amount / 100;

            if (charge.invoice && charge.invoice.tax) {
              charge._tax = charge.invoice.tax / 100;
            } else {
              charge._tax = 0;
            }

            charge._fee = charge.balance_transaction.fee / 100;

            if (charge.invoice && charge.invoice.tax) {
              charge._net = (charge.balance_transaction.amount - charge.balance_transaction.fee - charge.invoice.tax) / 100;
            } else {
              charge._net = (charge.balance_transaction.amount - charge.balance_transaction.fee) / 100;
            }

            charge._original = charge.amount / 100 + " " + charge.currency.toUpperCase();

            // Check if it's a charge for a new subscription, a renewal, or non-subscription
            if (charge.invoice && charge.invoice.subscription) {
              if (charge.invoice.period_start == charge.invoice.subscription.billing_cycle_anchor) {
                charge._type = "new_subscription";
              } else {
                charge._type = "subscription_renewal";
              }
              charge._type += "_" + charge.invoice.subscription.plan.interval;
            } else {
              charge._type = "other";
            }

            self.charges.push(charge);
          }
        }
      });
    },
  },
};
</script> 


<style>
.all_clnics_table tbody tr .text-start:nth-child(2) {
  font-size: xx-small;
  font-weight: bold;
  font-family: "Courier New", Courier, monospace;
}

.all_charges_table div.v-data-footer__select,
.all_charges_table .v-data-footer__icons-before,
.all_charges_table .v-data-footer__icons-after {
  display: none;
}

.monthly_report {
  margin: 0 auto;
}

.monthly_report,
.monthly_report th,
.monthly_report td {
  border: 1px solid grey;
  padding: 10px;
  font-size: larger;
}

.monthly_report td:not(.title) {
  font-family: "Courier New", Courier, monospace;
}

.monthly_report td.title {
  font-weight: bold;
}

.monthly_report td.total {
  font-weight: bold;
}
</style>