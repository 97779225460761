import axios from "axios";
import axiosRetry from './axios_retry';

// Retry requests up to three times, only for network errors
// TODO: Upgrade retryCondition to include retries for 5xx errors when idempotent (need to rewrite custom interceptors)
axiosRetry(axios, { retries: 3, retryCondition: axiosRetry.isNetworkError });

export default function (error_callback) {
  axios.defaults.validateStatus = function (status) {
    return (status >= 200 && status < 500) || status == 503;
  };

  axios.interceptors.response.use(
    function (response) {
      if (!response) {
        return response;
      }

      // If it's a network error (status of zero), do nothing
      if (!response.status) {
        return response;
      }

      // If it's an error response, log it
      if (response.status >= 400) {
        let error = new Error("Got error response from server");
        let additional = {
          url: response.config ? response.config.url : null,
          method: response.config ? response.config.method : null,
          response_data: response.data,
          response_status: response.status,
          response_headers: response.headers,
        };

        if (response.headers["x-cloud-trace-context"]) {
          additional.trace_context = response.headers["x-cloud-trace-context"];
        }

        try {
          additional.data = JSON.parse(response.config.data);
          if (additional.data.hasOwnProperty("password")) {
            additional.data.password = "********";
          }
        } catch (_) {
          additional.data = response.config.data;
        }

        window.OcudigitalLogError(error, additional);
      }

      return response;
    },
    function (err) {
      let additional = {};

      // Do nothing if it's a network error
      if (err.response && !err.response.status) {
        return;
      }

      if (err.response) {
        additional.response_data = err.response.data;
        additional.response_status = err.response.status;
        additional.response_headers = err.response.headers;

        if (err.response.headers["x-cloud-trace-context"]) {
          additional.trace_context =
            err.response.headers["x-cloud-trace-context"];
        }

        if (err.response.config) {
          additional.method = err.response.config.method;
          try {
            additional.data = JSON.parse(err.response.config.data);
            if (additional.data.hasOwnProperty("password")) {
              additional.data.password = "********";
            }
          } catch (_) {
            additional.data = err.response.config.data;
          }
        }
        if (err.response.request) {
          additional.url = err.response.request.responseURL;
        }
      }

      window.OcudigitalLogError(err, additional);
      if (console) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
      // If it's a request-aborted error, then just reload
      if (err.hasOwnProperty("code") && err.code == "ECONNABORTED") {
        window.location.reload();
      } else if (err.response && err.response.status == 418) {
        window.location.href = "/app?session_timeout=true";
      } else if (err.response && err.response.status == 403) {
        window.location.href = "/app?session_timeout=true";
      } else {
        if (error_callback) {
          error_callback(err);
        }
      }
    }
  );
}
