<template>
  <v-container style="width: 261px">
    <v-expand-transition>
      <v-layout v-show="!hide_time" justify-center>
        <span
          :class="{ formatted_elapsed_paused: paused, 'display-3': include_hours, 'display-4': !include_hours }"
          class="font-weight-light stopwatch-time"
          v-text="formattedElapsedTime"
        />
      </v-layout>
    </v-expand-transition>

    <v-layout justify-center style="margin-top: -6px">
      <v-flex>
        <span v-if="paused" class="font-weight-light" style="margin-left: 90px">{{ paused_text }}</span>
        <span v-else class="font-weight-light" style="margin-left: 90px"></span>
      </v-flex>
    </v-layout>

    <div v-if="include_reset && include_pause">
      <v-layout justify-space-between>
        <v-btn v-if="!started" dark large :color="start_color" @click="start">{{ start_label }}</v-btn>
        <v-btn v-else dark large :color="stop_color" @click="stop">{{ stop_label }}</v-btn>

        <v-btn
          v-if="include_reset"
          :disabled="elapsedTime == 0"
          style="margin-top: 3px"
          @click="reset"
        >{{ reset_label }}</v-btn>
      </v-layout>

      <v-btn
        v-if="include_pause && started"
        style="margin-top: 3px; margin-left: 155px"
        @click="toggle_pause"
      >
        <span v-if="!paused">{{ pause_label }}</span>
        <span v-else>{{ resume_label }}</span>
      </v-btn>
    </div>

    <v-layout
      v-else-if="include_reset || include_pause"
      :justify-center="hide_time"
      :justify-space-between="!hide_time"
      mb-3
    >
      <v-btn
        v-if="!started"
        dark
        :large="!hide_time"
        :x-large="hide_time"
        :color="start_color"
        @click="start"
      >{{ start_label }}</v-btn>
      <v-btn v-else dark large :color="stop_color" @click="stop">{{ stop_label }}</v-btn>
      <v-btn
        v-if="include_reset"
        :disabled="elapsedTime == 0"
        style="margin-top: 3px"
        @click="reset"
      >{{ reset_label }}</v-btn>
      <v-btn v-if="include_pause && started" style="margin-top: 3px" @click="toggle_pause">
        <span v-if="!paused">{{ pause_label }}</span>
        <span v-else>{{ resume_label }}</span>
      </v-btn>
    </v-layout>

    <v-layout v-else justify-center>
      <v-btn v-if="!started" dark large :color="start_color" @click="start">{{ start_label }}</v-btn>
      <v-btn v-else dark large :color="stop_color" @click="stop">{{ stop_label }}</v-btn>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  props: {
    start_color: {
      type: String,
      default: "primary",
    },
    stop_color: {
      type: String,
      default: "primary",
    },
    start_label: {
      type: String,
      default: "Start",
    },
    stop_label: {
      type: String,
      default: "Stop",
    },
    reset_label: {
      type: String,
      default: "Reset",
    },
    pause_label: {
      type: String,
      default: "Pause",
    },
    resume_label: {
      type: String,
      default: "Resume",
    },
    paused_text: {
      type: String,
      default: "Paused",
    },
    include_reset: {
      type: Boolean,
      default: () => false,
    },
    include_pause: {
      type: Boolean,
      default: () => true,
    },
    no_reset_on_stop: {
      type: Boolean,
      default: () => false,
    },
    initial_hide_time: {
      type: Boolean,
      default: () => false,
    },
    include_hours: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      elapsedTime: 0, // In milliseconds
      timer: undefined,
      started: false,
      paused: false,
      hide_time: this.initial_hide_time,
    };
  },
  computed: {
    formattedElapsedTime() {
      const date = new Date(null);
      date.setSeconds(this.elapsedTime / 1000);
      const utc = date.toUTCString();

      if (this.include_hours) {
        return utc.substr(utc.indexOf(":") - 2, 8);
      } else {
        return utc.substr(utc.indexOf(":") + 1, 6);
      }
    },
  },
  methods: {
    get_elapsed() {
      // In milliseconds
      return this.elapsedTime;
    },
    start() {
      this.started = true;
      this.hide_time = false;
      this.timer = setInterval(() => {
        this.elapsedTime += 1000;
      }, 1000);
      this.$emit("start", this.elapsedTime);
    },
    stop() {
      this.started = false;
      this.paused = false;
      clearInterval(this.timer);
      this.$emit("stop", this.elapsedTime);

      if (!this.no_reset_on_stop) {
        this.elapsedTime = 0;
      }
    },
    reset() {
      this.elapsedTime = 0;
      this.$emit("reset", this.elapsedTime);
    },
    toggle_pause() {
      if (!this.paused) {
        clearInterval(this.timer);
        this.paused = true;
        this.$emit("paused", this.elapsedTime);
      } else {
        this.start();
        this.paused = false;
        this.$emit("unpaused", this.elapsedTime);
      }
    },
    total_reset() {
      this.started = false;
      this.paused = false;
      clearInterval(this.timer);
      this.elapsedTime = 0;
      this.hide_time = this.initial_hide_time;
    },
  },
};
</script>

<style>
.formatted_elapsed_paused {
  opacity: 0.5;
}

@media screen and (max-width: 600px) {
  .v-application .stopwatch-time.display-4 {
    font-size: 4rem !important;
  }
  .v-application .stopwatch-time.display-3 {
    font-size: 3rem !important;
  }
}
</style>