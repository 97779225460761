<template>
  <v-card>
    <v-card-text>
      <v-container>
        <VuetifySurveyItemEditor
          ref="VuetifySurveyItemEditor"
          v-model="localValue.question"
          class="mt-2"
          :allow_edit_instructions="false"
          :allow_edit_visible="false"
          :show_id="false"
          :allow_edit_style="false"
          :allow_edit_class="false"
          :allow_edit_required="false"
          :allow_edit_value="false"
          :disallow_same_option_value="true"
          @change="inner_changed"
        >
          <template v-slot:topright>
            <ExercisePresetSelect
              :preset_id="preset ? preset.id : null"
              :label="$t('common.presets')"
              exercise_id="question"
              :current_exercise_config="current_exercise_config"
              :current_exercise_title="current_exercise_config.title"
              @preset_selected="preset_selected"
            />
          </template>
        </VuetifySurveyItemEditor>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" @click="save">{{ $t("common.save") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import VuetifySurveyItemEditor from "./vuetify-survey/components/VuetifySurveyItemEditor.vue";
import ExercisePresetSelect from "./ExercisePresetSelect";
import Vue from "vue";

export default {
  components: { VuetifySurveyItemEditor, ExercisePresetSelect },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    let data = {
      preset: null,
      current_exercise_config: this.value.question || {
        type: "text-field",
        props: {},
        id: "question",
        title: "",
      },
    };
    return data;
  },
  computed: {
    localValue: {
      get() {
        let value = this.value;
        value.question = value.question || {
          type: "text-field",
          props: {},
          id: "question",
          title: "",
        };
        return value;
      },
      set(localValue) {
        if (!localValue.question) {
          Vue.set(localValue, "question", {
            type: "text-field",
            props: {},
            id: "question",
            title: "",
          });
        }
        this.$emit("input", localValue);
      },
    },
  },
  watch: {},
  mounted() {},
  methods: {
    inner_changed(value) {
      this.current_exercise_config = value;
    },
    preset_selected(preset) {
      this.preset = preset;
      if (preset) {
        Vue.set(this.localValue, "question", preset.config);
        this.localValue.id = "question";
      } else {
        Vue.set(this.localValue, "question", {
          type: "text-field",
          props: {},
          id: "question",
          title: "",
        });
      }
      this.$forceUpdate();
    },
    save() {
      let question = this.$refs.VuetifySurveyItemEditor.save();

      // Check if failed validation
      if (!question) {
        return;
      }

      Vue.set(this.localValue, "question", question);
      Vue.set(this.localValue, "title", question.title);
      Vue.set(this.localValue, "instructions", question.instructions);
      this.$emit("save");
    },
  },
};
</script>

<style>
#invite_edit_modal .v-messages__message {
  line-height: normal;
}

.ql-editor {
  font-size: large;
}

.theme--dark .ql-snow .ql-stroke {
  stroke: gray;
}

.theme--light .ql-editor p {
  color: black;
}
</style>
