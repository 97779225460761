import { Uuid } from "./uuid";
import { NaiveDateTime } from "./datetime";

export default interface VideoExercise {
    id: string;
    title: { [key: string]: string };
    is_new: boolean;
    group: string[];
    origin: string;
    deprecated: boolean;
    description: { [key: string]: string };
    instructions: { [key: string]: string };
    provider_name: string;
    default: any;
    variants: { [key: string]: any };
};

export interface ClinicVideoExercise {
    id: Uuid;
    created: NaiveDateTime;
    updated: NaiveDateTime;
    clinic_id: Uuid;

    title: string;
    description: string;
    video: string;
    instructions: string;
    exercise_group: string;
    variant: string
    variant_group: Uuid;
    tools: any;
    shared: any;
}

export function clinic_to_builtin(exercise: ClinicVideoExercise): VideoExercise {
    let default_variant = {
        title: {},
        builtin_video_type: "variant",
        condition: null,
        tools: [],
        video: { "en": exercise.video },
        subtitles: {},
    };

    let builtin = {
        id: exercise.id,
        origin: "clinic",
        deprecated: false,
        title: { "en": exercise.title },
        group: [exercise.exercise_group],
        description: { "en" : exercise.description },
        instructions: { "en" : exercise.instructions },
        default: default_variant,
        variants: [],
        is_new: false,
        provider_name: "",
        provider_clinic_id: "",
        shared: exercise.shared
    };

    return builtin;
}