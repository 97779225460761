<template>
  <v-card>
    <v-card-title style="padding: 0px">
      <v-toolbar>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span>{{ $t("common.exercises_for") }} {{ user_name }}</span>
        </v-toolbar-title>
        <v-spacer />
        <pre v-if="admin_show_ids && regimen"> {{ regimen.id }}</pre>
      </v-toolbar>
    </v-card-title>
    <v-card-text class="pt-6">
      <v-layout row wrap justify-space-around>
        <v-flex xs12 md5>
          <v-select
            v-model="regimen.assignment"
            outlined
            style="margin-top: 20px"
            :label="$t('regimen_edit.assignment')"
            :items="assignment_options"
            @change="assignment_changed"
          />

          <v-select
            v-model="regimen.owner_id"
            outlined
            :label="$t('common.assigned_to_label')"
            :hint="$t('regimen_edit.the_primary_contact_person_for_user_regimen')"
            :items="owner_options"
            @change="unsaved_data = true"
          />

          <v-textarea
            v-model="regimen.instructions"
            outlined
            auto-grow
            rows="4"
            :label="$t('common.special_instructions')"
            :hint="$t('regimen_edit.any_special_instructions')"
            @change="unsaved_data = true"
          />

          <v-expansion-panels v-model="tools_permissions_panel">
            <v-expansion-panel v-if="regimen">
              <v-expansion-panel-header>{{ $t("regimen_edit.tools_and_permissions") }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-checkbox
                  v-model="regimen.permissions.view_printables"
                  dense
                  :indeterminate="regimen.permissions.view_printables === null || regimen.permissions.view_printables === undefined"
                  :indeterminate-icon="default_allow_view_printables ? 'mdi-check-box-outline' : 'mdi-checkbox-blank-outline'"
                  :label="$t('regimen_edit.allow_view_printables')"
                />

                <v-checkbox
                  v-model="regimen.permissions.view_tools"
                  dense
                  :indeterminate="regimen.permissions.view_tools === null || regimen.permissions.view_tools === undefined"
                  :indeterminate-icon="default_allow_view_tools ? 'mdi-check-box-outline' : 'mdi-checkbox-blank-outline'"
                  :label="$t('regimen_edit.allow_view_tools')"
                />

                <v-checkbox
                  v-model="regimen.permissions.view_progress_report"
                  dense
                  :indeterminate="regimen.permissions.view_progress_report === null || regimen.permissions.view_progress_report === undefined"
                  :indeterminate-icon="default_allow_progress_report ? 'mdi-check-box-outline' : 'mdi-checkbox-blank-outline'"
                  :label="$t('regimen_edit.allow_progress_report')"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-flex>
        <v-flex v-if="regimen.assignment == 'manual' || !regimen.assignment" xs12 md6 class="pt-3">
          <ExerciseListEdit v-model="regimen.exercises" @edit="edit" @add="add" @delete="unsaved_data = true" @dragged="unsaved_data = true" />
        </v-flex>
        <v-flex v-if="regimen.assignment == 'grid_set'" xs12 md6>
          <h3 style="margin-top: 40px">{{ $t("regimen_edit.assign_to_grid_set") }}</h3>
          <p>{{ $t("regimen_edit.assign_to_grid_set_desc") }}</p>
          <v-select v-model="regimen.grid_set_id" outlined label="Choose Grid Set" :items="grid_set_options" @change="grid_set_changed">
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title v-html="data.item.text"></v-list-item-title>
                <v-list-item-subtitle v-html="data.item.description"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-select>
        </v-flex>
        <v-flex v-if="regimen.assignment == 'grid_schedule'" xs12 md6>
          <h3 style="margin-top: 40px">{{ $t("regimen_edit.assign_to_grid_schedule") }}</h3>
          <p>{{ $t("regimen_edit.assign_to_grid_schedule_desc") }}</p>

          <v-layout justify-space-between>
            <v-flex xs12 md8>
              <v-select
                ref="grid_schedule_select"
                v-model="regimen.grid_schedule_id"
                outlined
                :label="$t('regimen_edit.choose_grid_schedule')"
                :items="grid_schedule_options"
                @change="grid_schedule_changed"
              >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.text"></v-list-item-title>
                    <v-list-item-subtitle v-html="data.item.description"></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-select>
            </v-flex>
            <v-flex xs12 md3>
              <v-btn style="margin-top: 5px" @click="open_grid_schedule_edit_dialog"> {{ $t("common.customize") }}</v-btn>
            </v-flex>
          </v-layout>

          <v-select
            v-if="regimen.grid_schedule_id"
            v-model="regimen.grid_current_stage"
            outlined
            :label="$t('regimen_edit.choose_schedule_stage')"
            :items="grid_current_stage_options"
            @change="grid_current_stage_changed"
          >
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title v-html="data.item.text"></v-list-item-title>
                <v-list-item-subtitle v-html="data.item.description"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-select>

          <v-sheet v-if="regimen.grid_schedule_id && invite_accepted" outlined rounded>
            <v-date-picker
              v-model="schedule_anchor_date"
              class="opticalgym_grid_anchor_picker"
              :events="grid_schedule_calendar_events"
              full-width
              @mouseenter:date="grid_date_mouseover"
              @mouseleave:date="grid_date_mouseover_text = ''"
            ></v-date-picker>
            <div v-if="regimen.grid_schedule_id" style="height: 20px; padding-left: 40px; overflow: hidden; position: relative; bottom: 20px">
              {{ grid_date_mouseover_text }}
            </div>
          </v-sheet>
          <div v-else-if="regimen.grid_schedule_id && !invite_accepted">
            <p>{{ $t("regimen_edit.grid_schedule_invite_required") }}</p>
          </div>
        </v-flex>
      </v-layout>
    </v-card-text>

    <v-dialog v-model="dialog" max-width="1200">
      <RegimenExerciseMetaEdit v-if="dialog" v-model="current_regimen_exercise_edit" context="regimen" @save="save_exercise" />
    </v-dialog>

    <v-card-actions>
      <v-alert v-if="error_message" type="error" outlined dense style="margin-left: 20px">{{ error_message }}</v-alert>
      <p v-else-if="unsaved_data && regimen.exercises.length > 0" style="margin-left: 20px">{{ $t("regimen_edit.edited_unsaved_changes") }}</p>
      <v-spacer />
      <v-btn text @click="discard_changes">{{ $t("regimen_edit.discard_changes") }}</v-btn>
      <v-btn color="primary" :disabled="regimen.exercises.length == 0 || in_flight" @click="submit">{{ $t("regimen_edit.save_exercises") }}</v-btn>
    </v-card-actions>

    <v-progress-linear v-if="in_flight" :indeterminate="true" />

    <v-dialog v-model="grid_schedule_edit_dialog" max-width="1200">
      <GridScheduleEdit
        v-if="grid_schedule_edit_dialog"
        ref="grid_schedule_edit"
        :existing_schedule="regimen.grid_schedule"
        :current_user="current_user"
        :clinic_id="clinic.id"
        :exercises="exercises"
        :video_exercises="video_exercises"
        @close="close_grid_schedule_edit_dialog"
        @grid-schedule-updated="grid_schedule_updated"
      ></GridScheduleEdit>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import Vue from "vue";
import axios from "axios";
import uuid from "uuid";
import RegimenExerciseMetaEdit from "./RegimenExerciseMetaEdit.vue";
import GridScheduleEdit from "./GridScheduleEdit.vue";
import ExerciseListEdit from "./ExerciseListEdit";
import { category_icons } from "../lib/category";

export default {
  components: { ExerciseListEdit, RegimenExerciseMetaEdit, GridScheduleEdit },
  props: {
    invite_id: {
      type: String,
      required: true,
    },
    user_name: {
      type: String,
      required: true,
    },
    current_user: {
      type: Object,
      required: true,
    },
    clinic: {
      type: Object,
      required: true,
    },
    // List of staff members that can be used to construct possible owners
    staff: {
      type: Array,
      required: true,
    },
    exercises: {
      type: Object,
      required: true,
    },
    video_exercises: {
      type: Object,
      required: true,
    },
    invite_owner_id: {
      // Might be '' if unknown
      type: String,
      required: true,
    },
    invite_accepted: {
      type: Boolean,
      default_value() {
        return true;
      },
    },
    grid_sets: {
      type: Array,
      required: true,
    },
    grid_schedules: {
      type: Array,
      required: true,
    },
  },
  data() {
    let data = {
      dialog: false,
      unsaved_data: false,
      in_flight: false,
      schedule_anchor_date: null,
      grid_date_mouseover_text: "",
      grid_schedule_edit_dialog: false,
      error_message: "",
      regimen: {
        instructions: "",
        exercises: [],
        owner_id: "",
        permissions: {},
      },
      current_regimen_exercise_edit: {
        exercise_id: "",
        level: 0,
        instructions: "",
        config: {},
      },
      icons: category_icons,
      assignment_options: [
        {
          text: this.$t("common.manual"),
          value: "manual",
        },
      ],
      tools_permissions_panel: false,
    };

    let grid_set_title = this.$t("common.grid_set");
    if (!this.grid_sets.length) {
      grid_set_title += " (" + this.$t("regimen_edit.none_available") + ")";
    }
    data.assignment_options.push({
      text: grid_set_title,
      value: "grid_set",
      disabled: !this.grid_sets.length,
    });

    let grid_schedule_title = this.$t("common.grid_schedule");
    if (!this.grid_schedules.length) {
      grid_schedule_title += " (" + this.$t("regimen_edit.none_available") + ")";
    }
    data.assignment_options.push({
      text: grid_schedule_title,
      value: "grid_schedule",
      disabled: !this.grid_schedules.length,
    });

    return data;
  },
  computed: {
    ...mapState(["admin_show_ids"]),
    localValue: {
      get() {
        return this.value;
      },
      set(localValue) {
        this.$emit("input", localValue);
      },
    },
    default_allow_view_tools() {
      if (this.clinic.has_tag("default_allow_view_tools")) {
        return this.clinic.tag_value("default_allow_view_tools");
      } else {
        return true;
      }
    },
    default_allow_view_printables() {
      if (this.clinic.has_tag("default_allow_view_printables")) {
        return this.clinic.tag_value("default_allow_view_printables");
      } else {
        return true;
      }
    },
    default_allow_progress_report() {
      if (this.clinic.has_tag("default_allow_progress_report")) {
        return this.clinic.tag_value("default_allow_progress_report");
      } else {
        return false;
      }
    },
    grid_set_options() {
      let options = [];
      for (let grid_set of this.grid_sets) {
        options.push({
          text: grid_set.title,
          description: grid_set.description,
          value: grid_set.id,
        });
      }
      return options;
    },
    grid_schedule_options() {
      let options = [];

      let customized_grid_id = "";
      if (this.regimen && this.regimen.grid_schedule && this.regimen.grid_schedule.customized && this.current_grid_schedule) {
        options.push({
          text: this.current_grid_schedule.title + " (" + this.$t("common.customized") + ")",
          description: this.current_grid_schedule.description,
          value: this.current_grid_schedule.id,
        });
        customized_grid_id = this.current_grid_schedule.id;
      }

      for (let grid_schedule of this.grid_schedules) {
        options.push({
          text: grid_schedule.title + (customized_grid_id ? " (" + this.$t("regimen_edit.reset_customization") + ")" : ""),
          description: grid_schedule.description,
          value: customized_grid_id == grid_schedule.id ? grid_schedule.id + "_original" : grid_schedule.id,
        });
      }

      return options;
    },
    current_grid_schedule() {
      if (this.regimen.grid_schedule) {
        return this.regimen.grid_schedule;
      }

      let current_schedule = null;
      if (this.regimen.grid_schedule_id) {
        let grid_schedule_id = this.regimen.grid_schedule_id.replace("_original", "");

        for (let grid_schedule of this.grid_schedules) {
          if (grid_schedule.id == grid_schedule_id) {
            current_schedule = grid_schedule;
            break;
          }
        }
      }
      return current_schedule;
    },
    current_grid_stage() {
      let current_schedule = this.current_grid_schedule;
      if (current_schedule && this.regimen.grid_current_stage) {
        for (let grid_stage of current_schedule.grid) {
          if (grid_stage.id == this.regimen.grid_current_stage) {
            return grid_stage;
          }
        }
      }
      return null;
    },
    grid_current_stage_options() {
      let options = [];
      if (this.current_grid_schedule) {
        for (var i in this.current_grid_schedule.grid) {
          let title = "Week " + (parseInt(i) + 1);
          options.push({
            text: title,
            value: this.current_grid_schedule.grid[i].id,
          });
        }
      }
      return options;
    },
    can_display_by_week() {
      let current_schedule = this.current_grid_schedule;
      if (!current_schedule) {
        return false;
      }

      for (let grid_stage of current_schedule.grid) {
        if (grid_stage.interval != 7) {
          return false;
        }
      }
      return true;
    },
    owner_options() {
      let options = [];
      for (var i in this.staff) {
        let staff = this.staff[i];
        options.push({
          value: staff.id,
          text: staff.name_given + " " + staff.name_family,
        });
      }

      // Sort by name -- todo sort by last-name?
      options.sort(function (a, b) {
        if (a.text < b.text) {
          return -1;
        } else if (a.text > b.text) {
          return 1;
        } else {
          return 0;
        }
      });

      return options;
    },
    grid_schedule_events() {
      let events = [];
      var anchor_date;
      let sched_stage = this.current_grid_stage;
      if (this.schedule_anchor_date) {
        anchor_date = new Date(Date.parse(this.schedule_anchor_date));
      } else if (this.regimen.grid_next_stage_date && sched_stage) {
        // Anchor date is grid_next_schedule_set minus the current sched_set's interval
        let anchor_date_ms = Date.parse(this.regimen.grid_next_stage_date);

        let interval_ms = sched_stage.interval * 24 * 60 * 60 * 1000;
        anchor_date = new Date(anchor_date_ms - interval_ms);
      } else {
        anchor_date = new Date();

        // If we're past noon (or early hours of the AM), add a day
        //if (now.getHours() <= 1 || now.getHours() >= 12) {
        //  anchor_date.setDate(anchor_date.getDate() + 1);
        //}
      }

      let skipping = true;
      for (var i in this.current_grid_schedule.grid) {
        let grid_stage = this.current_grid_schedule.grid[i];
        if (grid_stage.id == this.regimen.grid_current_stage) {
          skipping = false;
        }
        if (skipping) {
          continue;
        }
        let num_days = grid_stage.interval;
        anchor_date.setDate(anchor_date.getDate() + num_days);

        let title = "Week ";
        title += parseInt(i) + 2;

        events.push({
          date: anchor_date.toISOString().substr(0, 10),
          title: title,
        });
      }

      return events;
    },
    grid_schedule_calendar_events() {
      let calendar_events = {};
      for (let i in this.grid_schedule_events) {
        let event = this.grid_schedule_events[i];
        calendar_events[event.date] = i == this.grid_schedule_events.length - 1 ? "red" : "blue";
      }
      return calendar_events;
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    grid_date_mouseover(date) {
      let now = new Date();
      let today = now.toISOString().substr(0, 10);
      if (date < today) {
        return "";
      }

      // Get the first week ID
      let first_week_id = "";
      for (let option of this.grid_current_stage_options) {
        if (option.value == this.regimen.grid_current_stage) {
          first_week_id = option.text;
          break;
        }
      }

      this.grid_date_mouseover_text = first_week_id;
      for (var i = 0; i < this.grid_schedule_events.length; i++) {
        let event = this.grid_schedule_events[i];
        if (date >= event.date) {
          this.grid_date_mouseover_text = event.title;
          if (i == this.grid_schedule_events.length - 1) {
            this.grid_date_mouseover_text = "";
          }
        }
      }
    },
    assignment_changed(assignment_value) {
      this.unsaved_data = true;
      if (assignment_value == "manual") {
        this.regimen.grid_set_id = null;
        this.regimen.grid_schedule_id = null;
        this.regimen.grid_schedule = null;
        this.regimen.grid_current_stage = null;
        this.regimen.grid_next_stage_date = null;
      }
      if (assignment_value == "grid_set") {
        this.regimen.grid_set_id = this.grid_sets[0].id;
        this.grid_set_changed(this.regimen.grid_set_id);
      }
      if (assignment_value == "grid_schedule") {
        this.regimen.grid_schedule_id = this.grid_schedules[0].id;
        this.grid_schedule_changed(this.regimen.grid_schedule_id);
      }
    },
    grid_set_changed(new_grid_set_id) {
      for (let grid_set of this.grid_sets) {
        if (grid_set.id == new_grid_set_id) {
          Vue.set(this.regimen, "exercises", grid_set.exercises);
          Vue.set(this.regimen, "grid_schedule_id", null);
          Vue.set(this.regimen, "grid_schedule", null);
          Vue.set(this.regimen, "grid_current_stage", null);
          Vue.set(this.regimen, "grid_next_stage_date", null);
          break;
        }
      }
    },
    grid_schedule_changed(new_grid_schedule_id) {
      if (new_grid_schedule_id.endsWith("_original")) {
        new_grid_schedule_id = new_grid_schedule_id.replace("_original", "");
      }

      this.error_message = "";
      for (let grid_schedule of this.grid_schedules) {
        if (grid_schedule.id == new_grid_schedule_id) {
          Vue.set(this.regimen, "grid_set_id", null);
          Vue.set(this.regimen, "grid_schedule", grid_schedule);
          Vue.set(this.regimen, "grid_current_stage", grid_schedule.grid[0].id);
          this.grid_current_stage_changed(this.regimen.grid_current_stage);
          break;
        }
      }

      Vue.nextTick(() => {
        Vue.set(this.regimen, "grid_schedule_id", new_grid_schedule_id);
      });
    },
    grid_current_stage_changed(new_grid_current_stage_id) {
      for (let grid_stage of this.current_grid_schedule.grid) {
        if (grid_stage.id == new_grid_current_stage_id) {
          Vue.set(this.regimen, "exercises", grid_stage.exercises);
          break;
        }
      }
    },
    exercise_title(item) {
      if (item.title) {
        return item.title;
      }
      let exercise;
      if (item.exercise_type.startsWith("video")) {
        exercise = this.video_exercises[item.exercise_id];
      } else {
        exercise = this.exercises[item.exercise_id];
      }

      let locale = this.$i18n.lang();

      let title;
      if (exercise.title[locale]) {
        title = exercise.title[locale];
      } else {
        title = exercise.title.en;
      }

      if (item.video_exercise_variant && item.config && item.config.variants) {
        let variant = item.config.variants[item.video_exercise_variant];
        title += ": " + this.variant_title(variant);
      }

      return title;
    },
    variant_title(variant) {
      let locale = this.$i18n.lang();
      if (!variant.title) {
        return "";
      }

      if (variant.title[locale]) {
        return variant.title[locale];
      } else if (variant.title.en) {
        return variant.title.en;
      } else {
        return "";
      }
    },
    open_grid_schedule_edit_dialog() {
      this.grid_schedule_edit_dialog = true;
      let self = this;
      Vue.nextTick(() => {
        self.$refs.grid_schedule_edit.reset();
        self.$refs.grid_schedule_edit.load();
      });
    },
    close() {
      this.$emit("close", {});
    },
    save_exercise() {
      this.dialog = false;

      // Deep clone via JSON
      let current_regimen_exercise_edit = JSON.parse(JSON.stringify(this.current_regimen_exercise_edit));
      if (current_regimen_exercise_edit.new) {
        this.regimen.exercises.push(current_regimen_exercise_edit);
      } else {
        let index = current_regimen_exercise_edit.index;
        Vue.set(this.regimen.exercises, index, current_regimen_exercise_edit);
      }
      this.unsaved_data = true;
      this.current_regimen_exercise_edit = this.blank_regimen_exercise_edit();
    },
    edit(index) {
      this.current_regimen_exercise_edit = this.regimen.exercises[index];
      this.current_regimen_exercise_edit.new = false;
      this.current_regimen_exercise_edit.index = index;
      this.dialog = true;
    },
    add() {
      this.current_regimen_exercise_edit = this.blank_regimen_exercise_edit();
      this.dialog = true;
    },
    blank_regimen_exercise_edit() {
      return {
        uuid: uuid.v4(),
        exercise_id: "",
        customization: "",
        instructions: "",
        config: {},
        level: 0,
        new: true,
        index: null,
        config_version: 0,
      };
    },
    close_grid_schedule_edit_dialog() {
      this.grid_schedule_edit_dialog = false;
    },
    grid_schedule_updated(updated_schedule) {
      this.regimen.grid_schedule = updated_schedule;
      this.regimen.grid_schedule.customized = true;
      this.close_grid_schedule_edit_dialog();

      let stage_exists = false;
      for (let stage of this.regimen.grid_schedule.grid) {
        if (stage.id == this.regimen.grid_current_stage) {
          stage_exists = true;
          break;
        }
      }
      if (!stage_exists) {
        this.regimen.grid_current_stage = this.regimen.grid_schedule.grid[0].id;
      }

      this.grid_current_stage_changed(this.regimen.grid_current_stage);
    },
    load() {
      let self = this;
      this.in_flight = true;
      // Get
      axios.get("/api/invite/" + self.invite_id + "/regimen").then(function (response) {
        self.in_flight = false;
        let resp = response.data;
        if (resp.status == "error") {
          messageBus.$emit("api-error", resp);
        } else {
          if (resp.data) {
            let regimen = resp.data;

            // Delete any exercises that don't have an exercise_type, or don't have an ID and are not custom or header
            // TODO: Determine HOW these badly formed exercises got into the db into the first place
            regimen.exercises = regimen.exercises.filter((exercise) => {
              if (!exercise.exercise_type) {
                return false;
              }
              if (
                exercise.exercise_type != "custom" &&
                exercise.exercise_type != "header" &&
                exercise.exercise_type != "questionnaire" &&
                exercise.exercise_type != "question" &&
                exercise.exercise_type != "assessment" &&
                !exercise.exercise_id
              ) {
                return false;
              }
              return true;
            });

            self.regimen = Object.assign({}, self.regimen, regimen);

            // Set the anchor date
            // TODO: actually check the current stage interval instead of assuming a week
            let interval = 7;
            if (self.regimen.grid_next_stage_date) {
              let anchor_date = new Date(Date.parse(self.regimen.grid_next_stage_date));
              anchor_date = new Date(anchor_date.getTime() - interval * 24 * 60 * 60 * 1000);

              self.schedule_anchor_date = anchor_date.toISOString().substr(0, 10);
            }
          } else {
            self.regimen = {};
            Vue.set(self.regimen, "invite_id", self.invite_id);
            Vue.set(self.regimen, "clinic_id", self.clinic.id);
            Vue.set(self.regimen, "assignment", "manual");
            Vue.set(self.regimen, "owner_id", self.invite_owner_id ? self.invite_owner_id : self.current_user.id);
            Vue.set(self.regimen, "instructions", "");
            Vue.set(self.regimen, "exercises", []);
            Vue.set(self.regimen, "permissions", {});
          }

          self.unsaved_data = false;
          // If ownership doesn't match, mark unsaved_data as true so we update the invite on save
          if (self.invite_owner_id && self.invite_owner_id != self.regimen.owner_id) {
            self.unsaved_data = true;
          }
        }
      });
    },
    discard_changes() {
      if (confirm(this.$t("regimen_edit.discard_changes") + "?")) {
        this.load();
      }
    },
    submit: function () {
      let self = this;
      this.error_message = "";

      // If the exercises don't yet have a uuid, give them a uuid
      for (let exercise of self.regimen.exercises) {
        if (!exercise.uuid) {
          exercise.uuid = uuid.v4();
        }
      }

      // Remove all .config from video exercises
      // TODO: Remove this at some point in the future when it's irrelevant
      for (let exercise of this.regimen.exercises) {
        if (exercise.exercise_type == "video/builtin" || exercise.exercise_type == "video/clinic" || exercise.exercise_type == "video/public") {
          exercise.config = {};
        }
      }

      if (this.regimen.assignment == "grid_schedule") {
        if (!this.regimen.grid_schedule_id) {
          this.error_message = this.$t("regimen_edit.no_grid_schedule");
          this.$refs.grid_schedule_select.focus();
          return;
        }

        // If we are in a grid schedule, set the date if there is one
        if (this.schedule_anchor_date) {
          let anchor_date = new Date(Date.parse(this.schedule_anchor_date));
          let grid_stage = this.current_grid_stage;
          if (grid_stage) {
            let interval = parseInt(grid_stage.interval);
            let next_date = new Date(anchor_date.getTime() + interval * 24 * 60 * 60 * 1000);
            this.regimen.grid_next_stage_date = next_date.toISOString().substr(0, 10);
          }
        }
      }

      self.in_flight = true;
      if (this.regimen.id) {
        // Update
        axios.put("/api/regimen/" + self.regimen.id, self.regimen).then(function (response) {
          self.in_flight = false;
          let resp = response.data;
          if (resp.status == "error") {
            messageBus.$emit("api-error", resp);
          } else {
            self.unsaved_data = false;
            messageBus.$emit("success", self.$t("regimen_edit.exercises_updated"));
            self.$emit("regimen-updated", self.regimen);
            self.$emit("close");
          }
        });
      } else {
        // Create
        axios.post("/api/regimen", self.regimen).then(function (response) {
          self.in_flight = false;
          let resp = response.data;
          if (resp.status == "error") {
            messageBus.$emit("api-error", resp);
          } else {
            self.unsaved_data = false;
            messageBus.$emit("success", self.$t("regimen_edit.exercises_updated"));
            self.$emit("regimen-created", self.regimen);
            self.regimen.id = resp.data;
            self.$emit("close");
          }
        });
      }
    },
  },
};
</script>

<style>
.opticalgym_grid_anchor_picker .v-picker__title {
  display: none;
}
</style>
