<template>
  <div class="component-anaglyph-cancellation">
    <v-select
      v-model="inputVal"
      :label="$t('anaglyph_cancellation.cancellation')"
      persistent-hint
      style="max-width:500px"
      :items="options"
      :hint="$t('anaglyph_cancellation.level_of_cancellation_provided_by_the_anaglyph_glasses')"
    />
  </div>
</template>

<script>
export default {
  props: {
    // TODO: Eventually make this type: Number
    // eslint-disable-next-line vue/require-prop-types
    value: {
      required: true,
    },
  },
  data() {
    let data = {
      options: [
        { value: 1, text: this.$t("anaglyph_cancellation.full") },
        { value: 0.75, text: this.$t("anaglyph_cancellation.medium") },
        {
          value: 0.25,
          text: this.$t("anaglyph_cancellation.low_easiest"),
        },
      ],
    };
    return data;
  },
  computed: {
    inputVal: {
      get() {
        if (!this.value && this.value !== 0) {
          return 1;
        } else return this.value;
      },
      set(val) {
        this.$emit("input", val);
        this.$emit("change", val);
      },
    },
  },
};
</script>

<style>
.component-anaglyph-cancellation {
  margin-bottom: 20px;
}
</style>