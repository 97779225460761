<template>
  <div :id="instance_id">
    <a :id="focus_id" href="#" />
    <v-card id="video_exercise_modal">
      <v-card-title style="padding: 0px;">
        <v-toolbar grey flat>
          <v-btn icon @click="close">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ exercise_title }}
            <span
              v-if="config && config_title(config.title) && !show_config"
            >: {{ config_title(config.title) }}</span>
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn tile elevation="0" @click="open_metronome">
              <v-avatar left size="25" style="margin-right:10px">
                <v-img style="opacity: 0.5;" src="/assets/metronome_icon_2.png"></v-img>
              </v-avatar>
              {{ $t("common.metronome") }}
            </v-btn>
            <slot name="toolbar_items"></slot>
          </v-toolbar-items>
        </v-toolbar>
      </v-card-title>
      <v-divider />

      <v-card-text>
        <v-container v-if="config && config.video" fluid>
          <v-layout justify-center>
            <div class="ocudigital_video_wrapper">&nbsp;</div>
          </v-layout>
        </v-container>

        <v-layout justify-center style="padding-left:60px; padding-right:60px">
          <v-select
            v-if="show_config && exercise_variants.length"
            v-model="selected_variant"
            full-width
            :label="$t('video_exercise.select_variant')"
            :items="exercise_variant_options"
          />
        </v-layout>

        <div v-if="include_stopwatch">
          <StopWatch
            ref="stopwatch"
            :stop_label="$t('video_exercise.finished')"
            :start_label="$t('video_exercise.start_exercise')"
            :pause_lavel="$t('common.pause')"
            start_color="green"
            stop_color="green"
            initial_hide_time
            :include_hours="false"
            @stop="finished"
          />
        </div>
        <div v-else-if="done_button">
          <v-layout justify-center>
            <v-btn
              x-large
              dark
              color="green"
              @click="finished()"
            >{{ $t("video_exercise.finished") }}</v-btn>
          </v-layout>
        </div>

        <v-sheet v-if="custom_instruction" class="pa-5 ma-5" elevation="5">
          <h3
            v-if="custom_instruction_credit"
          >{{ $t("video_exercise.special_instructions_from", { "credit": custom_instruction_credit}) }}</h3>

          <span style="white-space: pre-line; font-size: large">{{ custom_instruction }}</span>
        </v-sheet>

        <v-sheet v-if="exercise_education && custom_instruction && exercise_instructions" elevation="5" class="pa-5 ma-5">
          <h3>Exercise Guidelines</h3>
          <div
            style="font-size:larger; margin-top: 20px;"
            class="exercise-instructions"
            v-html="exercise_instructions"
          />
        </v-sheet>
        <div
          v-else-if="exercise_instructions"
          style="font-size:larger; margin-top: 20px;"
          class="exercise-instructions"
          v-html="exercise_instructions"
        ></div>

        <v-sheet v-if="exercise_education" elevation="5" class="pa-5 ma-5">
          <h3 v-if="exercise_education">{{ $t("video_exercise.exercise_education_title") }}</h3>
          <div v-html="exercise_education" />
        </v-sheet>
      </v-card-text>
    </v-card>

    <!-- tools -->
    <div>
      <v-dialog
        v-for="(item, index) in tool_list"
        :key="index"
        v-model="tool_dialogs[item.id]"
        max-width="600"
        @close="tool_dialogs[item.id] = false"
      >
        <div v-if="tool_dialogs[item.id]">
          <component :is="item.component" />
        </div>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import urlParser from "js-video-url-parser";
import $ from "jquery";
import xss from "xss";
import tools from "../Tools.js";
import StopWatch from "./StopWatch";

export default {
  components: { StopWatch },
  props: {
    exercise: {
      type: Object,
      required: true,
    },
    include_stopwatch: {
      type: Boolean,
      default: () => false,
    },
    show_config: {
      type: Boolean,
      default: false,
    },
    trusted: {
      type: Boolean,
      default: false,
    },
    done_button: {
      type: Boolean,
      default: true,
    },
    custom_instruction: {
      type: String,
      default() {
        return "";
      },
    },
    custom_instruction_credit: {
      type: String,
      default() {
        return "";
      },
    },
    exercise_education: {
      type: String,
      default() {
        return "";
      },
    },
    variant: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    // Tools
    let tool_list = [];
    let tool_dialogs = {};
    for (var tool_id in tools) {
      tool_list.push({
        id: tool_id,
        component: tools[tool_id],
      });
      tool_dialogs[tool_id] = false;
    }

    let selected_variant = this.variant;
    var config;
    if (selected_variant) {
      config = this.exercise.variants[selected_variant];
    } else {
      config = this.exercise.default;
    }

    let instance_id = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(0, 5);

    return {
      config,
      tool_list,
      tool_dialogs,
      instance_id,
      start_time: Date.now(),
      end_time: 0,
      selected_variant: selected_variant,
    };
  },
  computed: {
    exercise_variants() {
      let variants = [];
      for (var variant_id in this.exercise.variants) {
        let variant = this.exercise.variants[variant_id];
        variant.variant_id = variant_id;
        variants.push(variant);
      }
      return variants;
    },
    exercise_variant_options() {
      let options = [];
      for (var variant_id in this.exercise.variants) {
        options.push({
          text: this.variant_title(variant_id),
          value: variant_id,
        });
      }
      return options;
    },
    video_info() {
      if (!this.config.video) {
        return null;
      }

      // Check to see if there's a video in our language
      let original_video_url;
      if (this.config.video[this.current_language]) {
        original_video_url = this.config.video[this.$i18n.lang()].trim();
      }
      else {
        original_video_url = this.config.video['en'].trim();
      }

      if (!original_video_url) {
        return null;
      }
      let url_parts = this.parse_url(original_video_url);

      if (url_parts.host == "drive.google.com") {
        let match = original_video_url.match(/https:\/\/drive\.google\.com\/file\/d\/([\w-]+)\/?.*/);
        let gid = match[1];
        return {
          provider: "google_drive",
          url: "https://drive.google.com/file/d/" + gid + "/preview",
        };
      }
      if (url_parts.host == "videos.sproutvideo.com") {
        return {
          provider: "sproutvideo",
          url: "https://videos.sproutvideo.com" + url_parts.pathname,
        };
      }
      if (url_parts.host == "vimeo.com") {
        let url = "https://player.vimeo.com/video/";
        let path_parts = url_parts.pathname.split("/").filter((c) => c);

        url += path_parts[0];

        if (path_parts.length > 1) {
          url += "?h=" + path_parts[1];
        }

        return {
          provider: "vimeo",
          url: url,
        };
      }
      if (url_parts.host == "player.vimeo.com") {
        return {
          provider: "vimeo",
          url: original_video_url,
        };
      }
      if (
        url_parts.host == "www.dropbox.com" ||
        url_parts.host == "dropbox.com" ||
        url_parts.host == "dropboxusercontent.com" ||
        url_parts.host == "dl.dropboxusercontent.com"
      ) {
        return {
          provider: "dropbox",
          url: "https://www.dropbox.com" + url_parts.pathname + "?raw=1",
        };
      }
      if (url_parts.pathname.endsWith(".mp4")) {
        return {
          provider: "mp4",
          url: original_video_url,
        };
      }
      if (url_parts.pathname.endsWith(".webm")) {
        return {
          provider: "webm",
          url: original_video_url,
        };
      }
      return urlParser.parse(original_video_url);
    },
    video_url() {
      if (!this.video_info) {
        return "";
      }
      if (this.video_info.provider == "mp4") {
        return this.video_info.url;
      }
      if (this.video_info.provider == "webm") {
        return this.video_info.url;
      } else if (this.video_info.provider == "google_drive") {
        return this.video_info.url;
      } else if (this.video_info.provider == "dropbox") {
        return this.video_info.url;
      } else if (this.video_info.provider == "sproutvideo") {
        return this.video_info.url;
      } else if (this.video_info.provider == "vimeo") {
        return this.video_info.url;
      } else {
        var current_url = window.location.href;
        var arr = current_url.split("/");
        var origin = arr[0] + "//" + arr[2];
        let url = urlParser.create({
          videoInfo: this.video_info,
          format: "embed",
          params: {
            rel: 0,
            showinfo: 0,
            modestbranding: 1,
            enablejsapi: 1,
            origin: origin,
          },
        });
        return url;
      }
    },
    video_subtitle_langs() {
      var subtitles;
      if (!this.variant) {
        subtitles = this.exercise.default.subtitles;
      } else {
        subtitles = this.exercise.variants[this.variant].subtitles;
      }
      return Object.keys(subtitles);
    },
    current_language() {
      return this.$i18n.lang();
    },
    video_subtitle_langs_html() {
      let html = "";
      let variant = "default";
      if (this.variant) {
        variant = this.variant;
      }

      let base_url = "/api/video_exercise/" + this.exercise.id + "/subtitle/" + variant + "/";

      for (var i in this.video_subtitle_langs) {
        let lang = this.video_subtitle_langs[i];

        let def = "";
        if (!this.config.video[lang] && this.current_language == lang) {
          def = "default";
        }

        html += '<track label="' + lang + '" kind="subtitles" srclang="' + lang + '" src="' + base_url + lang + '" + ' + def + ">";
      }

      return html;
    },
    video_embed_html() {
      if (!this.video_url) {
        return "";
      }
      if (this.video_info.provider == "mp4" || this.video_info.provider == "webm") {
        return (
          "<video width='640' height='360' class='ocudigital_html_video' controls controlsList='nodownload'> \
          <source src='" +
          this.video_url +
          "' type='video/" +
          this.video_info.provider +
          "' />" +
          this.video_subtitle_langs_html +
          "</video>"
        );
      } else
        return (
          "<iframe \
        class='ocudigital_video_iframe' \
        width='640' \
        height='360' \
        src='" +
          this.video_url +
          "' \
        frameborder='0' \
        allow='fullscreen; accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' \
        allowfullscreen \
        ></iframe>"
        );
    },
    exercise_instructions() {
      var instructions;
      let locale = this.$i18n.lang();

      if (typeof this.exercise.instructions == "string") {
        instructions = this.exercise.instructions;
      } else if (this.exercise.instructions[locale]) {
        instructions = this.exercise.instructions[locale];
      } else {
        instructions = this.exercise.instructions.en;
      }

      if (this.trusted) {
        return instructions;
      } else {
        return xss(instructions);
      }
    },
    exercise_title() {
      if (!this.exercise.title) {
        return "";
      }

      if (typeof this.exercise.title == "string") {
        return this.exercise.title;
      }

      let locale = this.$i18n.lang();
      if (this.exercise.title[locale]) {
        return this.exercise.title[locale];
      } else {
        return this.exercise.title.en;
      }
    },
    focus_id() {
      return this.exercise.id + "_video_exercise_modal_focus";
    },
  },
  watch: {
    selected_variant(new_variant) {
      this.stop();
      this.config = this.exercise.variants[new_variant];
      this.load();
    },
    variant(new_variant) {
      this.stop();
      this.config = this.exercise.variants[new_variant];
      this.load();
    },
  },
  methods: {
    load() {
      this.focus();
      this.apply_tool_links();
      this.apply_description_visibility();
      this.inject_video();
    },
    destroy_video() {
      $("#" + this.instance_id + " .ocudigital_video_wrapper > *").remove();
    },
    inject_video() {
      this.destroy_video();
      let self = this;
      Vue.nextTick(function () {
        if (self.video_embed_html) {
          $("#" + self.instance_id + " .ocudigital_video_wrapper").append(self.video_embed_html);
        }
      });
    },
    config_title(title) {
      if (typeof title == "string") {
        return title;
      } else if (typeof title == "object") {
        let locale = this.$i18n.lang();
        if (title[locale]) {
          return title[locale];
        } else if (title.en) {
          return title.en;
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    variant_title(variant_id) {
      let locale = this.$i18n.lang();
      let variant = this.exercise.variants[variant_id];
      if (!variant.title) {
        return "";
      }
      if (variant.title[locale]) {
        return variant.title[locale];
      } else if (variant.title.en) {
        return variant.title.en;
      } else {
        return "";
      }
    },
    focus: function () {
      let self = this;
      Vue.nextTick(function () {
        $("#" + self.focus_id).focus();
      });
      this.start_time = Date.now();
    },
    finished: function (time_taken_ms = 0) {
      this.stop();

      this.end_time = Date.now();
      if (!time_taken_ms) {
        time_taken_ms = this.end_time - this.start_time;
      }

      var seconds = Math.floor((time_taken_ms / 1000) % 60);
      var minutes = Math.floor((time_taken_ms / (1000 * 60)) % 60);
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      var type;
      if (!this.exercise.id) {
        type = "custom";
      } else {
        type = "video/" + this.exercise.origin;
      }

      this.$emit("exercise-finished", {
        level_completed: false,
        type: type,
        score: {
          time_taken_ms: time_taken_ms,
        },
        report: {
          timeStart: this.start_time,
          exercise_id: this.exercise.id || "custom",
          level: 0,
          timeToComplete: time_taken_ms, //ms
          pausedTime: 0,
          events: [],
          params: {},
          calibration: {},
        },
      });
    },
    close: function () {
      this.stop();
      this.$emit("close", {});
    },
    stop: function () {
      if (this.$refs["stopwatch"]) {
        this.$refs["stopwatch"].total_reset();
      }

      this.destroy_video();
    },
    apply_description_visibility() {
      // TODO: This needs to be redone
      return;

      // Modify descriptions as per config
      //document
      //  .querySelectorAll(".exercise-instructions .exercise-config-hide")
      //  .forEach(function(element) {
      //    element.style.display = "none";
      //  });
      //for (var key in this.config) {
      //  let value = this.config[key].toString().replace(/[^\w]/gi, "");
      //  document
      //    .querySelectorAll(
      //      ".exercise-instructions .exercise-config-show-" + key + "-" + value
      //    )
      //    .forEach(function(element) {
      //      element.style.display = "inherit";
      //    });
      // }
    },

    apply_tool_links() {
      let self = this;
      $(".exercise-instructions a").each(function () {
        let href = $(this).attr("href");
        let arr = href.split("://");
        if (arr[0] == "tool") {
          let tool_id = arr[1];
          $(this).click(function () {
            self.tool_dialogs[tool_id] = true;
          });
        }
      });
    },

    parse_url(url) {
      var parser = document.createElement("a"),
        searchObject = {},
        queries,
        split,
        i;

      // Let the browser do the work
      parser.href = url;

      // Convert query string to object
      queries = parser.search.replace(/^\?/, "").split("&");
      for (i = 0; i < queries.length; i++) {
        split = queries[i].split("=");
        searchObject[split[0]] = split[1];
      }

      return {
        protocol: parser.protocol,
        host: parser.host,
        hostname: parser.hostname,
        port: parser.port,
        pathname: parser.pathname,
        search: parser.search,
        searchObject: searchObject,
        hash: parser.hash,
      };
    },
    open_metronome() {
      if (window.OpticalGymGlobalMetronome) {
        window.OpticalGymGlobalMetronome.open();
      }
    },
  },
};
</script>

<style>
.video_iframe .ytp-share-button {
  display: none;
}

.exercise-instructions li {
  margin-top: 10px;
}

video::cue {
  opacity: 1;
  position: 0%;
}

.ocudigital_html_video {
  background-color: black;
}
</style>