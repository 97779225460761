<template>
  <v-card>
    <v-card-title style="padding: 0px;">
      <v-toolbar grey flat>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("common.peripheral_chart") }}</v-toolbar-title>
      </v-toolbar>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-container>
        <v-select v-model="variant" :items="variant_option" :label="$t('common.variant')" />
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-btn @click="view()">{{ $t("common.view") }}</v-btn>
      <v-spacer />
      <v-btn color="primary" @click="print()">{{ $t("common.print") }}</v-btn>
    </v-card-actions>
    <v-progress-linear v-if="in_flight" :indeterminate="true" />
  </v-card>
</template>

<script>
import printJS from "print-js";
import { Chance } from "chance";
let chance = Chance();

export default {
  components: {},
  props: {},
  data() {
    let data = {
      variant: "circular",
      variant_option: [
        { text: this.$t("common.circular"), value: "circular" },
        { text: this.$t("common.cross"), value: "cross" },
        { text: this.$t("common.classic"), value: "classic" },
      ],
      in_flight: false,
    };
    return data;
  },
  methods: {
    url() {
      let uri =
        "https://storage.googleapis.com/opticalgym-large-assets/pdfs/peripheral_charts/";
      uri += this.variant + "/";
      uri += chance.integer({ min: 0, max: 40 }) + ".pdf";

      return uri;
    },
    view: function () {
      window.open(this.url(), "_blank");
    },
    print: function () {
      let self = this;

      if (navigator.vendor.includes("Apple")) {
        window.open(this.url(), "_blank");
        return;
      }
      
      printJS({
        printable: self.url(),
        onLoadingStart: function () {
          self.in_flight = true;
        },
        onLoadingEnd: function () {
          self.in_flight = false;
        },
        style: "@page { size: Letter landscape; }",
      });
    },
    close: function () {
      this.$emit("close");
    },
  },
};
</script>
