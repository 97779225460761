<template>
  <v-container>
    <v-layout align-center justify-space-around>
      <v-sheet :elevation="elevation" style="padding: 50px; width: 800px">
        <h2>{{ $t("clinic_page.welcome_neurovisual_trainer") }}</h2>
        <p></p>

        <slot></slot>

        <p>{{ $t("clinic_page.welcome_second_text") }}</p>

        <h4 class="mt-8 mb-1">{{ $t("clinic_page.welcome_video_part_1") }}</h4>
        <iframe
          class="sproutvideo-player"
          src="https://videos.sproutvideo.com/embed/0690dabd1819e3c18f/2a6d4fbe19042b53"
          width="630"
          height="354"
          frameborder="0"
          allowfullscreen
          referrerpolicy="no-referrer-when-downgrade"
          title="Video Player"
        ></iframe>

        <h4 class="mt-8 mb-1">{{ $t("clinic_page.welcome_video_part_2") }}</h4>
        <iframe
          class="sproutvideo-player"
          src="https://videos.sproutvideo.com/embed/ac90dabd1c19e8cc25/759847d07e1d0c57"
          width="630"
          height="354"
          frameborder="0"
          allowfullscreen
          referrerpolicy="no-referrer-when-downgrade"
          title="Video Player"
        ></iframe>

        <strong>{{ $t('common.questions_question') }}</strong>
        <p>
          {{ $t("clinic_page.can_also_check") }}
          <a
            href="http://help.ocudigital.com"
            target="_blank"
          >{{ $t("clinic_page.knowledge_base") }}</a>
          {{ $t("clinic_page.helpful_articles_videos") }} {{ $t("welcome_video.or_you_can") }}
          <a
            href="#"
            @click.stop="contact_us_dialog = true"
          >{{ $t("welcome_video.contact_us") }}</a>
          {{ $t("welcome_video.arrange_demo") }}
        </p>
      </v-sheet>
    </v-layout>

    <!-- Contact Us -->
    <v-dialog v-model="contact_us_dialog" max-width="600px">
      <v-card v-if="contact_us_dialog">
        <v-card-title style="padding: 0px">
          <v-toolbar grey flat>
            <v-btn icon @click="contact_us_dialog = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ $t("common.contact_us") }}</v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <ContactInfo />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ContactInfo from "./ContactInfo";

export default {
  components: { ContactInfo },
  props: {
    elevation: {
      type: Number,
      required: false,
      default: () => 2,
    },
  },
  data() {
    return {
      contact_us_dialog: false,
    };
  },
};
</script>