<template>
  <ClinicPage
    :clinic_id="clinic_id"
    :current_user="current_user"
    :user_invites="user_invites"
    :route_subpage="subpage"
  />
</template>

<script>
import ClinicPage from "../components/ClinicPage";

export default {
  components: {
    ClinicPage,
  },
  data() {
    let subpage = "";
    if (this.$route.path.endsWith("/billing")) {
      subpage = "billing";
    }
    if (this.$route.path.endsWith("/grids")) {
      subpage = "grids";
    }
    return {
      clinic_id: this.$route.params.id,
      current_user: this.$root.$children[0].current_user,
      user_invites: this.$root.$children[0].user_invites,
      subpage,
    };
  },
  mounted() {
    this.$store.commit("setCurrentClinicId", this.$route.params.id);
  },
};
</script>

<style>
</style>