<template>
  <v-card>
    <v-card-title style="padding: 0px;">
      <v-toolbar grey flat>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("common.anaglyph_charts") }}</v-toolbar-title>
      </v-toolbar>
    </v-card-title>
    <v-card-text>
      <v-layout>
        <v-flex>
          <v-select
            v-model="chars"
            :items="chars_options"
            :label="$t('tool_hart_chart.characters')"
          />
        </v-flex>
      </v-layout>

      <v-layout align-center justify-space-between>
        <v-flex xs7>
          <v-select
            v-model="hue_strategy"
            :label="$t('common.colour')"
            :items="hue_strategy_options"
          />
        </v-flex>

        <v-flex xs4>
          <v-select v-model="size" :label="$t('common.size')" :items="size_options" />
        </v-flex>
      </v-layout>

      <v-checkbox
        v-if="hue_strategy == 'RandomRed' || hue_strategy == 'RandomGreen' || hue_strategy == 'RandomBlue'"
        v-model="fixation_lock"
        :label="$t('tool_anaglyph_chart.fixation_lock')"
        :hint="$t('tool_anaglyph_chart.fixation_lock_hint')"
        persistent-hint
      />

      <v-sheet style="margin-top:20px">
        <p>For best results, CMYK printers should use the following colour settings:</p>
        <table>
          <tr>
            <td>Red</td>
            <td>C=0 / M=100 / Y=100 / K=0</td>
          </tr>
          <tr>
            <td>Green</td>
            <td>C=100 / M=0 / Y=100 / K=0</td>
          </tr>
          <tr>
            <td>Blue</td>
            <td>C=100 / M=0 / Y=0 / K=0</td>
          </tr>
        </table>
      </v-sheet>
    </v-card-text>

    <v-card-actions>
      <v-btn @click="view()">{{ $t("common.view") }}</v-btn>
      <v-spacer />
      <v-btn color="primary" @click="print()">{{ $t("common.print") }}</v-btn>
    </v-card-actions>
    <v-progress-linear v-if="in_flight" :indeterminate="true" />
  </v-card>
</template>

<script>
import printJS from "print-js";
import { Chance } from "chance";
let chance = Chance();

export default {
  components: {},
  props: {},
  data() {
    let data = {
      chars: "both",
      hue_strategy: "RandomRed",
      size: "large",
      fixation_lock: false,
      chars_options: [
        { text: this.$t("common.numbers_letters"), value: "both" },
        { text: this.$t("common.letters"), value: "letters" },
        { text: this.$t("common.numbers"), value: "numbers" },
        { text: this.$t("tool_anaglyph_cart.dice"), value: "dice" },
        {
          text: this.$t("tool_anaglyph_chart.directional_arrow"),
          value: "arrow",
        },
        {
          text: this.$t("tool_anaglyph_chart.directional_e"),
          value: "directionale",
        },
        { text: this.$t("tool_hart_chart.s_5"), value: "sfive" },
      ],
      hue_strategy_options: [
        { text: this.$t("tool_anaglyph_cart.random_red"), value: "RandomRed" },
        {
          text: this.$t("tool_anaglyph_cart.random_green"),
          value: "RandomGreen",
        },
        {
          text: this.$t("tool_anaglyph_cart.random_blue"),
          value: "RandomBlue",
        },
        {
          text: this.$t("tool_anaglyph_cart.barred_red_green"),
          value: "StripedRedGreen",
        },
        {
          text: this.$t("tool_anaglyph_cart.barred_red_blue"),
          value: "StripedRedBlue",
        },
      ],
      size_options: [
        { text: this.$t("common.large"), value: "large" },
        { text: this.$t("common.medium_large"), value: "med_large" },
        { text: this.$t("common.medium_small"), value: "med_small" },
        { text: this.$t("common.small"), value: "small" },
      ],
      in_flight: false,
    };
    return data;
  },
  computed: {
    font_size() {
      if (
        this.hue_strategy == "RandomRed" ||
        this.hue_strategy == "RandomBlue" ||
        this.hue_strategy == "RandomGreen"
      ) {
        if (this.size == "large") {
          return 76;
        } else if (this.size == "med_large") {
          return 60;
        } else if (this.size == "med_small") {
          return 46;
        } else if (this.size == "small") {
          return 28;
        } else return "";
      } else {
        if (this.size == "large") {
          return 60;
        } else if (this.size == "med_large") {
          return 48;
        } else if (this.size == "med_small") {
          return 32;
        } else if (this.size == "small") {
          return 20;
        } else return "";
      }
    },
    rows() {
      if (
        this.hue_strategy == "RandomRed" ||
        this.hue_strategy == "RandomBlue" ||
        this.hue_strategy == "RandomGreen"
      ) {
        return 8;
      } else {
        return 10;
      }
    },
    cols() {
      if (
        this.hue_strategy == "RandomRed" ||
        this.hue_strategy == "RandomBlue" ||
        this.hue_strategy == "RandomGreen"
      ) {
        return 6;
      } else {
        return 9;
      }
    },
  },
  methods: {
    view: function () {
      window.open(this.url(), "_blank");
    },
    url: function () {
      let fixation_lock = false;
      let hue_strategy = this.hue_strategy;
      if (
        hue_strategy == "RandomRed" ||
        hue_strategy == "RandomGreen" ||
        hue_strategy == "RandomBlue"
      ) {
        fixation_lock = this.fixation_lock;
      }

      let uri = "/api/tools/pdf/anaglyph_chart.pdf";
      uri += "?chars=" + this.chars;
      uri += "&rows=" + this.rows;
      uri += "&cols=" + this.cols;
      uri += "&font_size=" + this.font_size;
      uri += "&hue_strategy=" + hue_strategy;
      uri += "&fixation_lock=" + fixation_lock;
      uri += "&cache_buster=" + chance.hex();

      return uri;
    },
    print: function () {
      let self = this;

      if (navigator.vendor.includes("Apple")) {
        window.open(this.url(), "_blank");
        return;
      }

      printJS({
        printable: self.url(),
        onLoadingStart: function () {
          self.in_flight = true;
        },
        onLoadingEnd: function () {
          self.in_flight = false;
        },
      });
    },
    close: function () {
      this.$emit("close");
    },
  },
};
</script>


<style>
</style>