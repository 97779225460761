<template>
  <div>
    <v-alert v-if="bad_token_error" type="error" text style="font-size: small;">
      <div style="text-align: center" v-html="bad_token_error" />
    </v-alert>
    <v-container v-else-if="show_scroll_tos">
      <v-card style="max-width: 800px">
        <v-toolbar dark color="primary">
          <v-toolbar-title>{{ $t('user_signup.terms_of_service') }}</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-card-text>
          <v-textarea id="user-signup-tos-textarea" ref="tos_textarea" :rows="20" :value="tos_text" no-resize readonly />
        </v-card-text>

        <v-card-actions>
          <v-container>
            <v-row justify="space-between" align="center">
              <p v-if="!scroll_tos_at_bottom" class="ml-2"> {{ $t('user_sign_up.scroll_to_bottom') }} </p>

              <v-spacer />

              <v-btn
                class="ml-1"
                :disabled="scroll_tos_at_bottom == false"
                @click="decline_tos()"
              >{{ $t('user_sign_up.decline') }}</v-btn>

              <v-btn
                class="ml-2"
                color="primary"
                :disabled="scroll_tos_at_bottom == false || in_flight"
                @click="accept_tos()"
              >{{ $t('user_sign_up.accept') }}</v-btn>
            </v-row>
          </v-container>
        </v-card-actions>
        <v-progress-linear v-if="in_flight" :indeterminate="true" />
      </v-card>
    </v-container>
    <v-container v-else-if="clinic">
      <v-card style="max-width: 800px">
        <v-toolbar dark color="primary">
          <v-toolbar-title>{{ $t('user_signup.create_account') + " " + clinic.title }}</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row justify="space-around">
              <v-text-field
                ref="name_given"
                v-model="name_given"
                :label="$t('common.first_name')"
                type="text"
                :error="name_given_error"
                style="max-width: 360px"
              />
              <v-text-field
                ref="name_family"
                v-model="name_family"
                :label="$t('common.last_name')"
                type="text"
                :error="name_family_error"
                style="max-width: 360px"
              />
            </v-row>

            <v-text-field
              ref="username"
              v-model="email"
              :label="underage ? $t('user_sign_up.parent_email') : $t('common.email')"
              :hint="underage ? $t('user_sign_up.parent_email_hint') : ''"
              name="username"
              type="text"
            />

            <v-text-field
              v-if="underage"
              v-model="player_email"
              :label="$t('user_sign_up.player_email')"
              type="text"
            />

            <DoublePassword
              ref="password"
              v-model="password"
              :label="$t('common.choose_a_password')"
              load-on-mount
              always-show-confirm
              @passwords-match="update_passwords_match"
            />
          </v-container>

          <p
            v-if="!require_tos_scroll"
            style="font-size: small; color: grey; margin:top: 5px; margin-bottom:5px;"
          >
            {{ $t('user_sign_up.accept_terms_of_use') }}
            <a
              href="/terms"
              target="_blank"
            >{{ $t('common.terms_of_use') }}</a>.
          </p>
        </v-card-text>

        <v-card-actions>
          <v-container>
            <v-row justify="space-between" align="center">
              <v-alert
                v-if="error_message != ''"
                outlined
                :value="error_message != ''"
                type="error"
              >{{ error_message }}</v-alert>

              <v-spacer />

              <v-btn
                class="ml-1"
                color="primary"
                :disabled="in_flight"
                @click="submit()"
              >{{ $t('user_sign_up.create_account') }}</v-btn>
            </v-row>
          </v-container>
        </v-card-actions>
        <v-progress-linear v-if="in_flight" :indeterminate="true" />
      </v-card>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import DoublePassword from "./DoublePassword";
import { mapState } from "vuex";
import Vue from "vue";
import jQuery from "jquery";
import Clinic from "../classes/clinic";

export default {
  components: { DoublePassword },
  props: {
    user_signup_token: {
      type: String,
      required: true,
    },
    require_tos_scroll: {
      type: Boolean,
      default: false,
    },
    tos_text: {
      type: String,
      default: "",
    },
  },
  data() {
    let url_search = new URLSearchParams(window.location.search);

    return {
      in_flight: false,
      passwords_match: false,
      password: "",
      name_given: "",
      name_family: "",
      name_given_error: false,
      name_family_error: false,
      email: "",
      bad_token_error: "",
      error_message: "",
      clinic: null,
      selected_subscription_type_id: url_search.get("selected_subscription_type_id") || "",
      free_trial_grid_schedule: url_search.get("free_trial_grid_schedule") || "",
      show_scroll_tos: false,
      scroll_tos_at_bottom: false,
      scroll_tos_accepted: false,
      player_email: "",
    };
  },
  computed: {
    ...mapState(["current_user", "app_config"]),
    token() {
      if (this.user_signup_token) {
        return this.user_signup_token;
      } else {
        return this.app_config.default_user_signup_token;
      }
    },
    underage() {
      if (this.clinic && this.clinic.has_tag("underage") && this.clinic.tag_value("underage")) {
        return true;
      }
      return false;
    }
  },
  mounted() {
    if (this.current_user) {
      window.location.pathname = "/app/";
    }

    axios.get("/api/clinic/from_signup_token/" + this.token).then((response) => {
      let resp = response.data;

      if (resp.status == "success") {
        this.clinic = Clinic.fromJson(resp.data);
      } else {
        this.bad_token_error = "Invalid signup token. Please contact your administrator to get a valid URL for signup.";
      }
    });
    // Bind Enter Key to submit
    window.addEventListener("keyup", function (event) {
      if (event.keyCode === 13 && this.token) {
        self.submit();
      }
    });
  },
  methods: {
    decline_tos() {
      this.show_scroll_tos = false;
      this.scroll_tos_at_bottom = false;
      this.scroll_tos_accepted = false;
    },
    accept_tos() {
        // Send  "tos_accepted" activity to the backend when we are all done
        this.scroll_tos_accepted = true;
        this.submit();
    },
    submit() {
      // Reset error state
      this.name_given_error = false;
      this.name_family_error = false;
      this.error_message = "";

      if (!this.name_given) {
        this.error_message = this.$t("accept_invite.please_provide_first_name");
        this.name_given_error = true;
        this.$refs.name_given.focus();
        return;
      }
      if (!this.name_family) {
        this.error_message = this.$t("accept_invite.please_provide_last_name");
        this.name_family_error = true;
        this.$refs.name_family.focus();
        return;
      }
      if (!this.password) {
        this.error_message = this.$t("accept_invite.please_provide_password");
        if (this.$refs.password) {
          this.$refs.password.focus();
        }
        return;
      }
      if (!this.passwords_match) {
        this.error_message = this.$t("accept_invite.confirm_password_does_not_match_password");
        if (this.$refs.password) {
          this.$refs.password.focus_confirm();
        }
        return;
      }

      // If we need to accept TOS, do that next
      if (this.require_tos_scroll && !this.scroll_tos_accepted) {
        this.show_scroll_tos = true;

        let self = this;
        Vue.nextTick(() => {
          jQuery("#user-signup-tos-textarea").scroll(function () {
            if (jQuery(this).scrollTop() + jQuery(this).height() >= jQuery(this)[0].scrollHeight - 4) {
              self.scroll_tos_at_bottom = true;
            }
          });
        });
        return;
      }

      this.in_flight = true;

      let url = "/api/invite/user_signup";
      if (this.scroll_tos_accepted) {
        url += "?tos_accepted=true";
      }
      if (this.free_trial_grid_schedule) {
        url += "&free_trial_grid_schedule=" + encodeURIComponent(this.free_trial_grid_schedule);
      }

      axios
        .post(url, {
          email: this.email,
          name_given: this.name_given,
          name_family: this.name_family,
          pass: this.password,
          token: this.token ? this.token : this.app_config.default_user_signup_token,
          clinic_user_info: this.underage ? { underage: true, player_email: this.player_email } : {},
        })
        .then((response) => {
          this.in_flight = false;
          let resp = response.data;
          if (resp.status == "success") {
            let clinic_page = "/app/clinic/" + resp.data;

            if (this.selected_subscription_type_id) {
              clinic_page += "?selected_subscription_type_id=" + encodeURIComponent(this.selected_subscription_type_id);
            }

            window.location.pathname = clinic_page;
          } else {
            this.decline_tos();
            messageBus.$emit("api-error", resp);
          }
        });
    },
    update_passwords_match: function (event_value) {
      this.passwords_match = event_value;
    },
  },
};
</script>


<style>
</style>