import { Uuid } from "./uuid";
import { NaiveDateTime } from "./datetime"

import { Taggable } from "./taggable";

export default class Invite extends Taggable {
    id: Uuid;
    created: NaiveDateTime;
    updated: NaiveDateTime;
    user_id: Uuid | null;
    clinic_id: Uuid;
    owner_id: Uuid;
    user_role: string; // 'user', 'staff', 'admin'
    email: string;
    email_message: string;
    email_status: string;
    email_status_message: string | null;
    name: string;
    token: string;
    token_secret: string;
    archived: boolean;
    name_family: string;
    expiry_date: NaiveDateTime | null;
    accept_date: NaiveDateTime | null;

    static fromJson(data: Object): Invite {
        let obj = new Invite();
        Object.assign(obj, data);
        return obj;
    }
};