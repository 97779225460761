<template>
  <div>
    <!-- Fullscreen enabled - use the iframe start button -->
    <v-container v-if="fullscreen_enabled" fluid>
      <v-layout align-center justify-center row fill-height>
        <div class="fullscreen_enabled_iframe_container"></div>
        <v-progress-circular v-if="loading" :size="70" color="primary" indeterminate />
      </v-layout>
    </v-container>

    <!-- Fullscreen disabled - use our own start button -->
    <v-container v-if="!fullscreen_enabled" fluid>
      <v-layout v-if="!needs_to_unzoom" align-center justify-center row fill-height>
        <v-btn v-if="show_start" x-large dark color="green" height="70px" width="150px" style="font-size: larger" @click="start_exercise_iframe_disabled">{{
          start_button_text
        }}</v-btn>
        <v-progress-circular v-if="loading" :size="70" color="primary" indeterminate />
      </v-layout>
      <v-layout v-else align-center justify-center row fill-height>
        <p style="font-size: larger">
          {{ $t("exercise_launch.please_zoom_out_before_starting_the_exercise") }}
        </p>
        <img style="width: 150px; height: 150px" src="/assets/pinch_zoom_out.png" />
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import $ from "jquery";
import Vue from "vue";

export default {
  components: {},
  props: {
    fullscreen_enabled: {
      type: Boolean,
      required: true,
    },
    config: {
      type: Object,
      required: true,
    },
    exercise: {
      type: Object,
      required: true,
    },
    compat: {
      type: Boolean,
      default: () => false,
    },
    unique_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      finished: false,
      loading: true,
      iframe_loaded: false,
      show_start: false,
      start_button_text: this.$t("common.start"),
      error_loading_exercise: false,
      needs_to_unzoom: this.compute_needs_to_unzoom(),
    };
  },
  mounted() {
    // When zoom is changed, recompute needs_to_unzoom
    if (!this.fullscreen_enabled && window.visualViewport && window.visualViewport.scale) {
      window.visualViewport.addEventListener("resize", () => {
        this.needs_to_unzoom = this.compute_needs_to_unzoom();
        if (!this.needs_to_unzoom) {
          this.load_exercise();
        }
      });
    }

    this.load_exercise();
  },
  methods: {
    compute_needs_to_unzoom() {
      if (this.compat) {
        return false;
      }
      return !this.fullscreen_enabled && window.visualViewport && window.visualViewport.scale && window.visualViewport.scale > 1;
    },
    iframe_element() {
      return document.getElementById("opticalgym-exercise-iframe-" + this.unique_id);
    },
    remove_iframe() {
      $(".opticalgym-exercise-iframe").remove();
      this.iframe_loaded = false;
    },
    reset: function () {
      this.finished = false;
      this.loading = false;
      this.show_start = false;
      this.remove_iframe();
      this.start_button_text = this.$t("common.start");
      if ("_check_for_iframe" in this) clearInterval(this._check_for_iframe);
    },
    load_exercise() {
      this.needs_to_unzoom = this.compute_needs_to_unzoom();
      if (this.needs_to_unzoom) {
        return;
      }
      let self = this;
      this.reset();

      // Denote that we are loading the exercise
      this.loading = true;

      // Set the iframe
      Vue.nextTick(function () {
        if ($("." + self.unique_id).is(":visible")) {
          let $element = $("." + self.unique_id + " .fullscreen_enabled_iframe_container");
          if (self.fullscreen_enabled) {
            $element.append(self.iframe_html());
          } else {
            $(window.document.body).append(self.iframe_html());
          }

          let element = window.document.getElementById("opticalgym-exercise-iframe-" + self.unique_id);
          if (element) {
            $(element).focus();
            element.onload = function () {
              self.iframe_loaded = true;
            };
          } else {
            // DOM hasn't been written yet, so wait two ticks
            Vue.nextTick(function () {
              Vue.nextTick(function () {
                let element = window.document.getElementById("opticalgym-exercise-iframe-" + self.unique_id);

                if (element) {
                  $(element).focus();

                  element.onload = function () {
                    self.iframe_loaded = true;
                  };
                }
              });
            });
          }
        }
      });

      // Wait until the iframe loads
      self._check_for_iframe = setInterval(function () {
        let element = self.iframe_element();
        let exercise = self.exercise_instance();

        if (self.error_loading_exercise) {
          clearInterval(self._check_for_iframe);
          return;
        }

        if (exercise) {
          exercise.onReady(function () {
            self.loading = false;
            self.show_start = true;

            if (exercise.game && exercise.game.canvas) {
              $(exercise.game.canvas).focus();
            }
          });
          exercise.onFinish(function (report) {
            if (self.finished) {
              self.remove_iframe();
              return;
            }
            self.score = report.getScore();
            if (report.levelPassed()) {
              self.level_completed = true;
            } else {
              self.level_completed = false;
            }

            self.finished = true;
            self.$emit("exercise-finished", {
              report: report,
              score: self.score,
              level_completed: self.level_completed,
              type: "interactive",
            });
            self.remove_iframe();
          });
          exercise.onStop(function () {
            if (!self.fullscreen_enabled) {
              $(element).css("z-index", -1000);
            }
            self.start_button_text = this.$t("common.resume");
          });
          clearInterval(self._check_for_iframe);
        }
      }, 50);
    },
    exercise_instance() {
      let self = this;
      var element = this.iframe_element();
      try {
        if (self.iframe_loaded && element && element.contentWindow && element.contentWindow.OpticalGymExerciseInstance) {
          return element.contentWindow.OpticalGymExerciseInstance;
        } else {
          return null;
        }
      } catch (e) {
        let additional = {};
        if (element) {
          additional.iframe_src = element.src;
        }
        if (window.OcudigitalLogError) {
          window.OcudigitalLogError(e, additional);
        }
        self.error_loading_exercise = true;
        return null;
      }
    },
    start_exercise_iframe_disabled() {
      let element = this.iframe_element();
      let exercise = this.exercise_instance();
      if (exercise) {
        $(element).css("z-index", 2147483001);
        exercise.start();

        if (element && element.contentWindow) {
          element.contentWindow.focus();
        } else if (element) {
          element.focus();
        }
      }
    },
    clear_exercise: function () {
      this.finished = false;
      this.loading = false;
      this.score = {};
      this.level_completed = false;
      if ("_check_for_iframe" in self) clearInterval(self._check_for_iframe);
      this.remove_iframe();
    },
    iframe_html() {
      let config = this.config;
      let path = "/api/exercise/" + this.exercise.id + "/launch";

      if (config.level && config.level != 0) {
        path += "/" + config.level;
      }

      if (config.override_level_params && Array.isArray(config.override_level_params)) {
        config.override_level_params = config.override_level_params.join(",");
      }

      const config_string = new URLSearchParams(config).toString();

      path += "?" + config_string;

      if (!this.fullscreen_enabled) {
        path += "&fullscreen_off=true";
      }

      if (this.app_config && this.app_config.id) {
        path += "&opticalgym_app=" + this.app_config.id;
      }

      if (this.compat) {
        path += "&compat=true";
      }

      // Set locale
      path += "&locale=" + this.$i18n.lang();

      // Set config version
      path += "&config_version=" + this.config_version;

      // If we're a canary clinic, set canary flag
      if (this.current_clinic && this.current_clinic.has_tag("canary")) {
        path += "&canary=true";
      }

      let iframe_class = "opticalgym-exercise-iframe ";
      if (!this.fullscreen_enabled) {
        iframe_class += "opticalgym-exercise-iframe-fullscreen-disabled";
      } else {
        iframe_class += "opticalgym-exercise-iframe-fullscreen-enabled";
      }

      return (
        "<iframe class='" +
        iframe_class +
        "' id='opticalgym-exercise-iframe-" +
        this.unique_id +
        "' src='" +
        path +
        "' frameBorder='0' scrolling='no' allowfullscreen allow='autoplay fullscreen' sandbox='allow-scripts allow-same-origin allow-pointer-lock allow-orientation-lock allow-popups allow-forms allow-top-navigation' ></iframe>"
      );
    },
  },
};
</script>

<style>
.opticalgym-exercise-iframe-fullscreen-disabled {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: -1000;
}

.opticalgym-exercise-iframe-fullscreen-enabled {
  height: 80px;
}
</style>
