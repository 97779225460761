<template>
  <v-card class="pb-2">
    <v-card-title style="padding: 0px;">
      <v-toolbar grey flat>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span>{{ local_printable.id ? local_printable.title : $t('printable_edit.new_printable_sheet') }}</span>
          <v-chip
            v-if="local_printable.share_source"
            label
            small
            outlined
            class="mt-0 ml-5"
            :href="'/app/shared/clinic/' + local_printable.share_source.clinic_id"
            rget="_blank"
          >{{ $t('community_content.clinic_attribution', {'clinic_title': local_printable.share_source.clinic_title }) }}</v-chip>
        </v-toolbar-title>
      </v-toolbar>
    </v-card-title>
    <v-divider />
    <v-card-text class="pt-6">
      <v-text-field
        ref="title"
        v-model="local_printable.title"
        :label="$t('common.title')"
        type="text"
        outlined
      />
      <v-file-input
        v-model="file_upload"
        accept="application/pdf"
        :label="$t('printable_edit.select_pdf')"
        append-icon="fa fa-file-pdf"
        outlined
      ></v-file-input>
      <v-textarea
        ref="description"
        v-model="local_printable.description"
        :label="$t('common.description')"
        rows="3"
        outlined
      />
      <CommunitySharing
        v-if="local_printable && app_config.community_sharing && !local_printable.share_source"
        v-model="local_printable.shared"
        :hint_disabled="$t('community_sharing.printable_hint_disabled')"
        :hint_enabled="$t('community_sharing.printable_hint_enabled')"
        :item_title="local_printable.title"
      />
    </v-card-text>

    <v-card-actions>
      <v-btn v-if="printable.id" small color="red" outlined class="ml-3" @click="delete_printable">
        <v-icon left>delete</v-icon>{{ $t("common.delete") }}
      </v-btn>
      <v-alert
        v-if="error_message"
        outlined
        dense
        class="ml-3 mt-2 mr-12"
        type="error"
      >{{ error_message }}</v-alert>
      <v-progress-linear
        v-if="loaded_percent"
        v-model="loaded_percent"
        color="light-blue"
        striped
        height="25"
        reactive
        style="width:1000px"
      >
        <strong>{{ Math.ceil(loaded_percent) }}%</strong>
      </v-progress-linear>

      <v-spacer />
      <v-btn
        v-if="printable.id"
        :disabled="in_flight"
        color="primary"
        @click="submit()"
      >{{ $t("printable_edit.update") }}</v-btn>
      <v-btn
        v-else
        color="primary"
        :disabled="in_flight"
        @click="submit()"
      >{{ $t("printable_edit.create") }}</v-btn>
    </v-card-actions>
    <v-progress-linear v-if="in_flight" :indeterminate="true" />
  </v-card>
</template>

<script>
import axios from "axios";
import CommunitySharing from "./CommunitySharing";
import { mapState } from "vuex";

export default {
  components: { CommunitySharing },
  props: {
    printable: {
      type: Object,
      required: true,
    },
  },
  data() {
    let data = {
      file_upload: [],
      error_message: "",
      local_printable: Object.assign({}, this.printable),
      in_flight: false,
      loaded_percent: 0,
    };
    return data;
  },
  computed: {
    ...mapState(["app_config"]),
  },
  watch: {
    printable() {
      this.local_printable = Object.assign({}, this.printable);
    },
  },
  methods: {
    close: function () {
      this.error_message = "";
      this.local_printable = Object.assign({}, this.printable);
      this.file_upload = [];
      this.loaded_percent = 0;
      this.$emit("close");
    },
    verify: function () {
      let printable_id = this.local_printable.id || this.printable.id;
      if (!printable_id) {
        if (this.file_upload.length == 0) {
          this.error_message = this.$t("printable_edit.select_file_to_upload");
          return false;
        }
      }

      if (this.file_upload.length) {
        if (this.file_upload.bytes > 10485760) {
          this.error_message = this.$t("printable_edit.file_is_too_large");
          return false;
        }
      }

      if (!this.local_printable.title) {
        this.error_message = this.$t("common.title_required");
        this.$refs.title.focus();
        return false;
      }

      if (this.local_printable.shared) {
        if (!this.local_printable.description) {
          this.error_message = this.$t("common.description_required_for sharing");
          this.$refs.description.focus();
          return false;
        }
      }

      // All checks pass
      return true;
    },
    upload_progress(progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      this.loaded_percent = percentCompleted;
    },
    submit: function () {
      this.error_message = "";
      if (!this.verify()) {
        return;
      }

      let self = this;
      self.in_flight = true;
      let printable_id = self.local_printable.id || self.printable.id;
      if (printable_id) {
        // Update
        if (this.file_upload.length) {
          this.local_printable.filename = this.file_upload.name;
        }

        let url = "/api/file/" + printable_id;
        axios.put(url, self.local_printable).then(function (response) {
          let resp = response.data;
          if (resp.status == "success") {
            if (self.file_upload.length == 0) {
              self.in_flight = false;
              messageBus.$emit("success", self.$t("printable_edit.reading_printable_updated"));
              self.$emit("printable-updated", self.local_printable);
              self.close();
            } else {
              // Upload
              let formData = new FormData();
              formData.append("file", self.file_upload);
              axios
                .post("/api/file/" + printable_id + "/upload", formData, {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                  onUploadProgress: self.upload_progress,
                })
                .then(function (response) {
                  self.in_flight = false;
                  let resp = response.data;
                  if (resp.status == "success") {
                    self.$emit("printable-updated", self.local_printable);
                    self.close();
                  } else {
                    messageBus.$emit("api-error", resp);
                  }
                });
            }
          } else {
            messageBus.$emit("api-error", resp);
          }
        });
      } else {
        this.local_printable.mime = this.file_upload.type;
        this.local_printable.filename = this.file_upload.name;

        // Create
        let self = this;

        // Upload
        let formData = new FormData();
        formData.append("file", self.file_upload);
        formData.append("title", self.local_printable.title);
        formData.append("description", self.local_printable.description);
        formData.append("category", self.local_printable.category);
        formData.append("clinic_id", self.local_printable.clinic_id);
        formData.append("category", self.local_printable.category);
        formData.append("filename", self.local_printable.filename);
        formData.append("mime", self.local_printable.mime);
        formData.append("public", self.local_printable.public ? "true" : "false");
        formData.append("shared", self.local_printable.shared ? JSON.stringify(self.local_printable.shared) : "null");

        axios
          .post("/api/file/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: self.upload_progress,
          })
          .then(function (response) {
            self.in_flight = false;
            let resp = response.data;
            if (resp.status == "success") {
              self.$emit("printable-created", self.local_printable);
              self.close();
            } else {
              messageBus.$emit("api-error", resp);
            }
          });
      }
    },
    delete_printable() {
      let confirm_message = this.$t("printable_edit.are_you_sure_you_wish_to_delete_this_reading_printable");
      if (confirm(confirm_message)) {
        this.in_flight = true;
        axios.delete("/api/file/" + this.printable.id).then((response) => {
          this.in_flight = false;
          let resp = response.data;
          if (resp.status == "success") {
            messageBus.$emit("success", this.$t("printable_edit.reading_printable_deleted"));
            this.$emit("printable-deleted", this.local_printable);
            this.close();
          } else {
            messageBus.$emit("api-error", resp);
          }
        })
        .catch((error) => {
          this.in_flight = false;
          messageBus.$emit("api-error", error);
        })
      }
    },
  },
};
</script>


<style>
.reading_printable_question .v-text-field__details {
  display: none;
}

.printable-edit-printable textarea {
  font-size: 14pt;
}
</style>
