<template>
  <v-sheet
    v-if="contact_person || contact_clinic"
    :elevation="elevation"
    :style="(elevation ? 'padding: 30px;' :'') + 'font-size: 16px;'"
  >
    <h2 style="margin-bottom: 10px">{{ title }}</h2>
    <p v-if="show_desc">{{ $t("your_vision_specialist.for_vision_therapy_related_questions") }}</p>
    <p style="font-weight: 500;">
      <span
        v-if="contact_person"
      >{{ contact_person.name_prefix }} {{ contact_person.name_given }} {{ contact_person.name_family }}</span>
      <span v-if="contact_person && contact_clinic">,&nbsp;</span>
      <span v-if="contact_clinic">{{ contact_clinic.title }}</span>
    </p>
    <p style="font-weight: 500;">
      <span v-if="contact_email">
        <a :href="'mailto:' + contact_email">{{ contact_email }}</a>
        <br />
      </span>
      <span v-if="contact_clinic && contact_clinic.phone">{{ contact_clinic.phone }}</span>
    </p>
  </v-sheet>
</template>

<script>
export default {
  components: {},
  props: {
    contact_person: {
      type: Object,
      default: null,
    },
    contact_clinic: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: "Your Vision Specialist",
    },
    elevation: {
      type: Number,
      default: 2,
    },
    show_desc: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    contact_email() {
      if (this.contact_clinic && this.contact_clinic.has_tag('clinic_email')) {
        return this.contact_clinic.tag_value('clinic_email')
      }
      if (this.contact_person && !this.contact_clinic.has_tag('private_email')) {
        return this.contact_person.email
      }
      return ""
    }
  }
};
</script>