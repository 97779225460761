<template>
  <div>
    <div v-if="has_custom_score_component">
      <component
        :is="exercise.id + '-score'"
        :context="context"
        :score="score"
        :exercise="exercise"
        :config="exercise_config"
      />
    </div>
    <table v-else>
      <tr v-for="field in summary_fields" :key="field.name">
        <td class="pr-6"><strong>{{ field_title(field.config) }}</strong></td>
        <td v-if="!field.config.boolean_label_only">
          {{ field_value(field.id) }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>

// Some exercise have a custom score display that uses a vue component, load them here
let exercise_components = {};
import OpticalGymScores from "../../../exercises/OpticalGymScores.js";
for (let exercise_id of Object.keys(OpticalGymScores)) {
  exercise_components[exercise_id + "-score"] = OpticalGymScores[exercise_id];
}

import { exercise_score_display } from "../classes/exercise.ts";

export default {
  components: Object.assign(exercise_components, { /* Add other components here */ }),
  props: {
    exercise: {
      type: Object,
      required: true,
    },
    score: {
      type: Object,
      required: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    context: {
      type: String,
      required: false,
    },
    exercise_config: {
      type: Object,
      required: false,
    }
  },
  computed: {
    has_custom_score_component() {
      return OpticalGymScores[this.exercise.id] !== undefined;
    },
    summary_fields() {
      let summary_fields = []

      for (const [field_id, field_config] of Object.entries(this.exercise.reporting)) {
        if (!field_config.summary) continue;

        if (field_config.display_if) {
          let display_if_value = this.score[field_config.display_if];
          if (!display_if_value) {
            continue;
          }
        }

        let field_value = this.score[field_id];

        if (field_value === undefined) {
          continue;
        }

        if (field_config.display === 'boolean_label_only') {
          if (!field_value) {
            continue;
          }
        }

        summary_fields.push({
          id: field_id,
          config: field_config,
        });
      }

      return summary_fields;
    },
  },
  methods: {
    field_title(field_config) {
      let current_lang = this.$i18n.locale.slice(0, 2);
      return field_config.title[current_lang] || field_config.title.en;
    },
    field_value(field_id) {
      return exercise_score_display(this.exercise, field_id, this.score, this.$i18n);
    }
  }
};
</script>
