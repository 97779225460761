<template>
  <div>
    <v-list dense>
      <v-list-item v-if="show_new_team" @click="create_clinic_dialog = true">
        <v-list-item-icon>
          <v-icon>fa fa-plus</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title style="font-size: 1.2rem">
            New Team
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="current_user.is_admin" link to="/app/admin/clinics">
        <v-list-item-icon>
          <v-icon>fa fa-cogs</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title style="font-size: 1.2rem">{{
            $t("main_menu.admin")
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="clinics.length == 1"
        link
        :to="'/app/clinic/' + clinics[0].id"
      >
        <v-list-item-icon>
          <v-icon>fa-solid fa-list</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title style="font-size: 1.2rem">
            <template v-if="highest_perm == 'admin' || highest_perm == 'staff'">
              {{ $t("main_menu.my_team") }}
            </template>
            <template v-else>
              {{ $t("main_menu.my_clinic") }}
            </template>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <template v-if="clinics.length > 1">
        <v-list-item
          v-if="clinics.length > 1"
          :link="!current_user.is_admin"
          :to="current_user.is_admin ? '' : '/app/'"
        >
          <v-list-item-icon>
            <v-icon>business</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title style="font-size: 1.2rem">{{
              $t("main_menu.my_clinics")
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <template v-if="!collapsed && clinics.length < 5">
          <v-list-item
            v-for="clinic in clinics"
            :key="clinic.id"
            :href="'/app/clinic/' + clinic.id"
          >
            <v-list-item-icon> </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ clinic.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </template>

      <v-list-item v-if="view_exercises" link to="/app/exercises">
        <v-list-item-icon>
          <v-icon>laptop_chromebook</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title style="font-size: 1.2rem">{{
            $t("common.exercises")
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="view_exercises" link to="/app/videos">
        <v-list-item-icon>
          <v-icon>mdi-run-fast</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title style="font-size: 1.2rem">{{
            $t("main_menu.videos")
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!--
      <v-list-item v-if="show_history" link to="/app/history">
        <v-list-item-icon>
          <v-icon>fas fa-history</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title style="font-size: 1.2rem">{{
            $t("common.past_training")
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      -->

      <v-list-item v-if="user_regimen" link to="/app/progress_report">
        <v-list-item-icon>
          <v-icon>fa-chart-bar</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title style="font-size: 1.2rem">{{
            $t("common.progress_report")
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link to="/app/tools">
        <v-list-item-icon>
          <v-icon>build</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title style="font-size: 1.2rem">{{
            $t("common.tools")
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link to="/app/printables">
        <v-list-item-icon>
          <v-icon>print</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title style="font-size: 1.2rem">{{
            $t("common.printable_sheets")
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-for="(shared_grid, sg_i) in shared_grids"
        :key="sg_i"
        link
        :to="'/app/grid_set/' + shared_grid.id"
      >
        <v-list-item-icon>
          <v-icon color="#ec2027">mdi-run-fast</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title style="font-size: 1.2rem; color: #ec2027">{{ shared_grid.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!--
    <v-list-item link to="/app/equipment">
      <v-list-item-icon>
        <v-icon>fas fa-baseball-ball</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title style="font-size: 1.2rem">{{ $t("common.equipment") }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    -->

      <v-list-item
        v-if="current_user.stripe_customer_id"
        link
        :href="'/api/subscription/portal/user/' + current_user.id"
        target="_blank"
      >
        <v-list-item-icon>
          <v-icon style="margin-left: 3px">fas fa-file-invoice-dollar</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title style="font-size: 1.2rem">{{
            $t("common.billing")
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-dialog v-model="create_clinic_dialog" max-width="1000">
      <ClinicEdit
        :clinic="default_clinic"
        @close-clinic-edit="create_clinic_dialog = false"
        @clinic-created="load_clinics"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";

import ClinicEdit from "../../shared/components/ClinicEdit.vue";

export default {
  components: { ClinicEdit },
  props: {
    user: {
      type: Object,
      required: true,
    },
    clinics: {
      type: Array,
      default: () => [],
    },
    collapsed: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    let data = {
      dark_mode_toggle: this.$root.$children[0].$vuetify.theme.dark,
      create_clinic_dialog: false,
      shared_grids: [],
      default_clinic: {
        title: "",
        phone: "",
        website: "",
        physical_address_1: "",
        physical_address_2: "",
        physical_sub_locality: "",
        physical_locality: "",
        physical_sub_district: "",
        physical_district: "",
        physical_postal: "",
        physical_country: "XX",
        billing_address_1: "",
        billing_address_2: "",
        billing_sub_locality: "",
        billing_locality: "",
        billing_sub_district: "",
        billing_district: "",
        billing_postal: "",
        billing_country: "US",
        trial_end: null,
        tags: [],
        app: window.app_config.id,
        billing_strategy: window.app_config.default_billing_strategy,
      },
    };

    return data;
  },
  computed: {
    ...mapState([
      "current_user",
      "user_regimens",
      "current_clinic_id",
      "user_metadata",
      "current_invite",
      "highest_perm",
      "user_clinics",
      "grid_sets",
    ]),
    view_store() {
      for (var i in this.clinics) {
        if (
          this.clinics[i].user_role == "admin" ||
          this.clinics[i].user_role == "staff"
        )
          return true;
      }
      if (this.user.is_admin) {
        return true;
      }
      return false;
    },
    view_exercises() {
      if (this.user.is_admin) {
        return true;
      }
      return false;
    },
    user_regimen() {
      for (let user_regimen of this.user_regimens) {
        if (user_regimen.clinic_id == this.current_clinic_id) {
          return user_regimen;
        }
      }
      return null;
    },
    show_history() {
      if (this.user_metadata) {
        for (let metadata of this.user_metadata) {
          if (metadata.category == "completed_grid_schedule") {
            return true;
          }
        }
      }
      if (
        !this.user_regimen ||
        !this.user_regimen.grid_schedule ||
        !this.user_regimen.grid_schedule.grid.length
      ) {
        return false;
      }
      return (
        this.user_regimen.grid_current_stage !=
        this.user_regimen.grid_schedule.grid[0].id
      );
    },
    show_new_team() {
      for (let metadata in this.user_metadata) {
        if (metadata.category == "partner" && metadata.data === true) {
          return false;
        }
      }
      return false;
    },
  },
  mounted() {
    let shared_grid_ids = [];

    for (let clinic of this.user_clinics) {
      if (clinic.has_tag("shared_grid_set")) {
        let allowed_roles = ["staff", "admin"];
        if (clinic.has_tag("shared_grid_set_roles")) {
          allowed_roles = clinic.tag_value("shared_grid_set_roles");
        }
        if (allowed_roles.includes(clinic.user_role)) {
          let val = clinic.tag_value("shared_grid_set");
          if (typeof val == "string") {
            shared_grid_ids.push(val);
          } else {
            shared_grid_ids.push(...val);
          }
        }
      }
    }

    if (shared_grid_ids.length) {
      this.$store.dispatch("loadGrids", () => {
        for (let grid of this.grid_sets) {
          if (shared_grid_ids.includes(grid.id)) {
            this.shared_grids.push(grid);
          }
        }
      });
    }
  },
  methods: {
    go_to_clinic(clinic_id) {
      // TODO: Not working
      this.$store.commit("setCurrentClinicId", clinic_id);
      this.$router.push("/app/clinic/" + clinic_id);
    },
    toggle_dark_mode() {
      if (this.dark_mode_toggle) {
        this.dark_mode_toggle = false;
      } else {
        this.dark_mode_toggle = true;
      }
      this.dark_mode();
    },
    dark_mode() {
      messageBus.$emit("dark-mode", this.dark_mode_toggle);
    },
    load_clinics() {
      this.$store.dispatch("loadCurrentUser");
    },
  },
};
</script>
