<template>
  <v-card id="invite_role_edit_modal">
    <v-toolbar>
      <v-btn icon @click="close_role_edit">
        <v-icon>close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span>{{ $t("invite_role_edit.edit_role") }}</span>
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <div style="margin-top:20px"></div>

      <div>
        <v-radio-group v-model="user_role" :label="$t('common.role')" mandatory>
          <v-radio v-for="(role, index) in role_options" :key="index" :value="role.value">
            <template v-slot:label>
              {{ role.text }}
              <span v-if="!is_educational">
                -&nbsp;
                <span style="font-size: smaller">{{ role.desc }}</span>
              </span>
            </template>
          </v-radio>
        </v-radio-group>
      </div>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" @click="submit()">{{ $t("common.save_changes") }}</v-btn>
    </v-card-actions>
    <v-progress-linear v-if="in_flight" indeterminate />
  </v-card>
</template> 

<script>
import axios from "axios";

export default {
  components: {},
  props: {
    current_user: {
      type: Object,
      required: true,
    },
    invite_id: {
      type: String,
      required: true,
    },
    current_role: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: false,
      default() {
        return "";
      },
    },
    is_educational: {
      type: Boolean,
      default: false,
    },
    nopatients: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let data = {
      in_flight: false,
      user_role: this.current_role,
    };
    return data;
  },
  computed: {
    role_options() {
      let roles = [];
      if (this.current_user.is_admin || this.current_role == "user") {
        roles.push({
          text: this.$t("common.user"),
          value: "user",
          desc: this.$t("invite_role_edit.regular_clinic_user"),
        });
      }

      if (
        this.current_user.is_admin ||
        this.current_role == "staff" ||
        this.current_role == "admin"
      ) {
        roles.push({
          text: this.is_educational ? "Student" : this.$t("common.staff"),
          value: "staff",
          desc: this.$t("common.manage_regular_users"),
        });
        roles.push({
          text: this.is_educational
            ? "Teacher"
            : this.$t("common.administrator"),
          value: "admin",
          desc: this.$t("common.manage_regular_users_and_staff"),
        });
      }
      return roles;
    },
  },
  methods: {
    close_role_edit: function () {
      this.$emit("close");
    },
    submit: function () {
      let self = this;

      let role = { user_role: self.user_role };
      let url = "/api/invite/" + self.invite_id + "/user_role";
      axios.put(url, role).then(function (response) {
        self.in_flight = false;
        let resp = response.data;
        if (resp.status == "error") {
          messageBus.$emit("api-error", resp);
        } else {
          messageBus.$emit(
            "success",
            self.$t("invite_role_edit.user_role_updated")
          );
          self.$emit("invite-role-updated", self.local_invite);
          self.$emit("close-invite-role-edit");
        }
        self.close_role_edit();
      });
    },
  },
};
</script>
