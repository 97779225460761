<template>
  <v-card class="grid_schedule_grid" style="overflow: scroll" @wheel.prevent="wheel_event">
    <v-card-title style="padding: 0px">
      <v-toolbar grey>
        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span v-if="grid_schedule.title">{{ grid_schedule.title }}</span>
          <span v-else>{{ $t("grid_set_edit.new_schedule") }}</span>
          <v-chip
            v-if="grid_schedule.share_source"
            label
            small
            outlined
            class="mt-0 ml-5"
            :href="'/app/shared/clinic/' + grid_schedule.share_source.clinic_id"
            rget="_blank"
            >{{ $t("community_content.clinic_attribution", { clinic_title: grid_schedule.share_source.clinic_title }) }}</v-chip
          >
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items v-if="!readonly">
          <v-btn v-if="!is_fullscreen" small style="margin-right: 10px" @click="$emit('fullscreen', true)">
            <v-icon left>fas fa-expand-arrows-alt</v-icon>{{ $t("common.fullscreen") }}
          </v-btn>
          <v-btn v-else small style="margin-right: 10px" @click="$emit('fullscreen', false)">
            <v-icon left>fas fa-compress-arrows-alt</v-icon>{{ $t("common.exit_fullscreen") }}
          </v-btn>
          <v-btn small color="primary" style="margin-right: 10px" @click="add_item"> <v-icon left>add</v-icon>{{ $t("common.add_exercise") }} </v-btn>
          <v-btn small color="primary" style="margin-right: 10px" @click="add_week">
            {{ $t("grid_set_edit.add_week") }}
            <v-icon right>mdi-arrow-right</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
    </v-card-title>
    <v-card-text>
      <div id="grid_layout_wrapper" class="grid-layout-wrapper" :style="grid_layout_wrapper_height_style">
        <v-layout row wrap justify-space-between style="width: 1100px; margin-top: 5px; margin-left: 10px">
          <v-flex md4>
            <v-text-field ref="title" v-model="grid_schedule.title" :readonly="readonly" required :label="$t('common.title')"></v-text-field>
          </v-flex>
          <v-flex md7>
            <v-text-field ref="description" v-model="grid_schedule.description" :readonly="readonly" :label="$t('common.description')"></v-text-field>
          </v-flex>
        </v-layout>
        <table class="grid-layout-header-table" :style="grid_layout_header_table_style">
          <tr height="20px" align="center">
            <td v-for="index in num_weeks" :key="index" class="grid-backing-cell" style="cursor: pointer" @click="week_focus = index">
              <v-icon v-if="week_focus == index && !readonly" x-small left color="blue" style="margin-left: -22px">fas fa-circle</v-icon>
              {{ $t("grid_set_edit.week") }} {{ index }}
            </td>
          </tr>
        </table>
        <table class="grid-layout-backing-table" :style="grid_layout_backing_table_style">
          <tr height="470px">
            <td v-for="index in num_weeks" :key="index" lass="grid-backing-cell bordered"></td>
          </tr>
        </table>
        <grid-layout
          class="grid-schedule-grid-layout"
          :style="grid_schedule_grid_layout_style"
          :layout.sync="layout"
          :col-num="num_weeks"
          :row-height="100"
          :is-draggable="!readonly"
          :is-resizable="!readonly"
          :use-css-transforms="true"
          :auto-size="false"
        >
          <grid-item
            v-for="item in layout"
            :key="item.i"
            :x="item.x"
            :y="item.y"
            :w="item.w"
            :h="item.h"
            :i="item.i"
            :max-h="1"
            :class="'grid-width-' + item.w"
          >
            <v-card outlined>
              <v-toolbar :style="'overflow: hidden; ' + (readonly ? '' : 'cursor:grab;')" class="vue-draggable-handle" height="45px">
                <v-icon v-if="item.value.exercise_type == 'interactive' || !item.value.exercise_type" left small style="margin-left: -8px" color="grey">{{
                  icons[exercises[item.value.exercise_id].group[0]]
                }}</v-icon>

                <v-icon v-if="item.value.exercise_type == 'question'" left small style="margin-left: -8px" color="grey">fas fa-question</v-icon>

                <v-icon v-if="item.value.exercise_type == 'questionnaire'" left small style="margin-left: -8px" color="grey">mdi-clipboard-list</v-icon>

                <v-icon v-if="item.value.exercise_type == 'email'" left small style="margin-left: -8px" color="grey">email</v-icon>

                <v-icon v-if="item.value.exercise_type == 'assessment'" left small style="margin-left: -8px" color="grey">fas fa-clipboard-list</v-icon>

                <v-icon v-else-if="item.value.exercise_type == 'custom' && item.value.group" left small style="margin-left: -8px" color="grey">{{
                  icons[item.value.group[0]]
                }}</v-icon>

                <v-icon v-else-if="item.value.exercise_type == 'header'" left small style="margin-left: -8px" color="grey">fas fa-heading</v-icon>

                <template v-else-if="all_video_exercises && item.value.exercise_type.startsWith('video')">
                  <v-icon
                    v-if="all_video_exercises[item.value.exercise_id] && all_video_exercises[item.value.exercise_id].group.length"
                    left
                    small
                    style="margin-left: -8px"
                    color="grey"
                    >{{ icons[all_video_exercises[item.value.exercise_id].group[0]] }}</v-icon
                  >
                  <v-icon v-else small left style="margin-left: -8px" color="grey">ondemand_video</v-icon>
                </template>

                <div :class="'grid-item-title ' + (exercise_title(item.value).length > 30 ? 'long-title' : '')">{{ exercise_title(item.value) }}</div>
              </v-toolbar>
              <v-card-text class="no-drag" style="padding: 10px">
                <v-layout wrap justify-space-around>
                  <ExercisePreviewButton
                    v-if="item.value.exercise_type != 'header'"
                    :exercise="item.value"
                    :fully_configured="false"
                    :additional_video_exercises="additional_video_exercises"
                  />
                  <v-btn v-if="!readonly" x-small outlined fab color="green" @click="edit_item(item)">
                    <v-icon>edit</v-icon>
                  </v-btn>
                  <v-btn v-if="!readonly" x-small outlined fab color="purple" @click="duplicate_item(item)">
                    <v-icon>mdi-content-duplicate</v-icon>
                  </v-btn>
                  <v-btn v-if="!readonly" x-small outlined fab color="red" @click="delete_item(item)">
                    <v-icon>delete</v-icon>
                  </v-btn>
                </v-layout>
              </v-card-text>
            </v-card>
          </grid-item>
        </grid-layout>
      </div>

      <CommunitySharing
        v-if="grid_schedule && app_config.community_sharing && !readonly && !grid_schedule.share_source"
        v-model="grid_schedule.shared"
        :hint_disabled="$t('community_sharing.grid_hint_disabled')"
        :hint_enabled="$t('community_sharing.grid_hint_enabled')"
        :item_title="grid_schedule.title"
      />

      <!-- Next Grid Schedule -->
      <!-- Disabled if there's community sharing since the interaction between the two is not yet implemented -->
      <div v-if="app_config.show_grid_schedule_next && !app_config.community_sharing" class="mt-6" style="max-width: 500px">
        <v-select v-model="grid_schedule.next_grid_schedule" :items="next_grid_schedule_options" :label="$t('grid_set_edit.next_grid_schedule')" outlined />
      </div>
    </v-card-text>

    <v-card-actions v-if="!readonly">
      <v-alert v-if="error_message != ''" outlined :value="error_message != ''" type="error">{{ error_message }}</v-alert>
      <p v-else-if="unsaved_data && layout.length > 0" style="margin-left: 20px">{{ $t("regimen_edit.edited_unsaved_changes") }}</p>
      <v-spacer />
      <v-btn text @click="discard_changes">{{ $t("regimen_edit.discard_changes") }}</v-btn>

      <v-tooltip v-if="grid_schedule_id && app_config.allow_grid_schedule_update_regimens" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="grid_schedule_id && app_config.allow_grid_schedule_update_regimens"
            color="primary lighten-1"
            large
            :disabled="layout.length == 0 || in_flight"
            v-bind="attrs"
            v-on="on"
            @click="submit(true)"
          >
            <div>
              {{ $t("grid_schedule_edit.save_schedule") }}
              <div style="font-size: x-small; text-transform: none">{{ $t("grid_schedule_edit.update_associated_patients") }}</div>
            </div>
          </v-btn>
        </template>
        <span>{{ $t("grid_schedule_edit.update_associated_patients_tooltip") }}</span>
      </v-tooltip>

      <v-btn color="primary" large :disabled="layout.length == 0 || in_flight" @click="submit(false)">
        <div>
          {{ $t("grid_schedule_edit.save_schedule") }}
          <div v-if="grid_schedule_id && app_config.allow_grid_schedule_update_regimens" style="font-size: x-small; text-transform: none">
            {{ $t("grid_schedule_edit.dont_update_associated_patients") }}
          </div>
        </div>
      </v-btn>
    </v-card-actions>

    <v-dialog v-model="dialog" max-width="1200">
      <RegimenExerciseMetaEdit
        v-if="dialog"
        v-model="current_grid_exercise_edit"
        :exercises="exercises"
        :video_exercises="all_video_exercises"
        context="grid_schedule"
        @save="save_exercise"
      />
    </v-dialog>

    <v-dialog v-model="share_dependencies_dialog" max-width="800">
      <v-card v-if="share_dependencies_dialog">
        <v-card-title style="padding: 0px">
          <v-toolbar grey flat>
            <v-btn icon @click="share_dependencies_dialog = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ $t("common.community_sharing") }}</v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="mt-4">
          The following video exercises are part of this grid schedule, but are private to your clinic. To enable community sharing for this grid schedule, you
          must confirm that you wish to additionally share these video exercises.
          <ul style="font-size: 1.05rem" class="mt-4">
            <li v-for="(dep, index) in share_dependencies_needs_share" :key="index" style="margin-top: 5px">{{ dep.title }}</li>
          </ul>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            :disabled="in_flight"
            @click="
              grid_schedule.shared = null;
              share_dependencies_dialog = false;
              submit_grid_schedule();
            "
          >
            {{ $t("common.save_without_sharing") }}
          </v-btn>
          <v-btn
            color="primary"
            :disabled="in_flight"
            @click="
              share_dependencies_dialog = false;
              submit_grid_schedule();
            "
          >
            {{ $t("common.confirm_additional_sharing") }}
          </v-btn>
        </v-card-actions>
        <v-progress-linear v-if="in_flight" :indeterminate="true" />
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import VueGridLayout from "vue-grid-layout";
import uuid from "uuid";
import RegimenExerciseMetaEdit from "./RegimenExerciseMetaEdit.vue";
import ExercisePreviewButton from "./ExercisePreviewButton.vue";
import CommunitySharing from "./CommunitySharing.vue";
import Vue from "vue";
import axios from "axios";
import { category_icons } from "../lib/category";
import { mapState } from "vuex";

export default {
  name: "GridScheduleEdit",
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    RegimenExerciseMetaEdit,
    ExercisePreviewButton,
    CommunitySharing,
  },
  props: {
    grid_schedule_id: {
      type: String,
      required: false,
      default: "",
    },
    existing_schedule: {
      type: Object,
      required: false,
      default: null,
    },
    clinic_id: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: () => false,
    },
    additional_video_exercises: {
      type: Object,
      default: () => {},
    },
    is_fullscreen: {
      type: Boolean,
      default: () => false,
    },
    all_grid_schedules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialog: false,
      error_message: "",
      in_flight: false,
      unsaved_data: false,
      week_focus: 1,
      current_i: 0,
      grid_schedule: {
        clinic_id: this.clinic_id,
        title: "",
        description: "",
        instructions: "",
        grid: [],
        next_grid_schedule: null,
      },
      current_grid_exercise_edit: {
        exercise_id: "",
        level: 0,
        instructions: "",
        config: {},
      },
      layout: [],
      icons: category_icons,
      share_dependencies: [],
      share_dependencies_dialog: false,
      update_regimens_on_submit: false,
    };
  },
  computed: {
    ...mapState(["small_screen", "app_config", "exercises", "video_exercises", "grid_sets"]),
    all_video_exercises() {
      if (!this.additional_video_exercises) {
        return this.video_exercises;
      }

      // Deep clone
      let add_video_exercises = JSON.parse(JSON.stringify(this.additional_video_exercises));

      if (this.video_exercises) {
        return Object.assign(add_video_exercises, this.video_exercises);
      } else {
        return add_video_exercises;
      }
    },
    share_dependencies_needs_share() {
      return this.share_dependencies.filter((item) => item.needs_share);
    },
    grid_layout_wrapper_height_style() {
      if (this.is_fullscreen) {
        return "height: " + (window.innerHeight - 230) + "px;";
      } else if (window.innerHeight <= 800) {
        return "height: 400px";
      } else if (window.innerHeight <= 700) {
        return "height: 380px";
      } else return "height: 600px;";
    },
    num_weeks() {
      return this.grid_schedule.grid.length;
    },
    grid_schedule_grid_layout_style() {
      let width = this.num_weeks * 200 + 85;
      return {
        width: width + "px",
      };
    },
    grid_layout_backing_table_style() {
      let width = this.num_weeks * 200 + 75;
      return {
        width: width + "px",
        position: "relative",
        "margin-bottom": "-480px",
        left: "5px",
        "border-collapse": "collapse",
        "table-layout": "fixed",
      };
    },
    grid_layout_header_table_style() {
      let width = this.num_weeks * 200 + 75;
      return {
        width: width + "px",
        "margin-top": "10px",
        "table-layout": "fixed",
      };
    },
    next_grid_schedule_options() {
      let options = [];

      options.push({
        text: this.$t("common.none"),
        value: null,
      });

      for (let grid_schedule of this.all_grid_schedules) {
        if (grid_schedule.id !== this.grid_schedule.id) {
          options.push({
            text: grid_schedule.title,
            value: grid_schedule.id,
          });
        }
      }

      return options;
    },
  },
  watch: {
    is_fullscreen() {},
  },
  mounted() {
    this.reset();
    this.load();
  },
  methods: {
    add_week() {
      this.grid_schedule.grid.push({
        id: uuid.v4(),
        interval: 7,
        exercises: [],
      });
    },
    fill_weeks() {
      // First reset all exercises
      for (let week of this.grid_schedule.grid) {
        week.exercises = [];
      }

      let max_y = 0;
      for (let item of this.layout) {
        if (item.y > max_y) {
          max_y = item.y;
        }
      }

      let num_weeks = this.grid_schedule.grid.length;

      // For each week, look backwards and work your way forwards until you come to the current week
      for (let x = 0; x < num_weeks; x++) {
        for (let y = 0; y <= max_y; y++) {
          for (let delta = num_weeks; delta >= 0; delta--) {
            let check_x = x - delta;
            let min_width = 1 + delta;
            if (check_x < 0) {
              continue;
            }
            for (let item of this.layout) {
              if (item.y == y && item.x == check_x && item.w >= min_width) {
                let exercise = JSON.parse(JSON.stringify(item.value));
                exercise.grid_y = y;
                if (min_width == 1) {
                  exercise.grid_width = item.w; // it's the original exercise
                } else {
                  exercise.grid_width = 0; // it's a placeholder exercise
                }
                this.grid_schedule.grid[x].exercises.push(exercise);
              }
            }
          }
        }
      }
    },
    fill_layout() {
      this.layout = [];

      let num_weeks = this.grid_schedule.grid.length;

      // Deep clone the grid
      let grid = JSON.parse(JSON.stringify(this.grid_schedule.grid));

      // Frist grab the maximum number of exercises in a week
      let max_exercises = 0;
      for (let week of grid) {
        if (week.exercises.length > max_exercises) {
          max_exercises = week.exercises.length;
        }
      }

      for (let x = 0; x < num_weeks; x++) {
        for (let y = 0; y < max_exercises; y++) {
          let week = grid[x];
          if (week.exercises.length > y) {
            let exercise = week.exercises[y];
            if (!exercise) continue;

            if (!exercise.grid_width) {
              continue;
            }

            exercise.index = this.current_i;

            this.layout.push({
              x: x,
              y: exercise.grid_y,
              w: exercise.grid_width,
              h: 1,
              i: this.current_i,
              value: exercise,
            });
            this.current_i++;
          }
        }
      }
    },
    wheel_event(event) {
      document.getElementById("grid_layout_wrapper").scrollBy(event.deltaX / 2, event.deltaY / 2);
    },
    discard_changes() {
      if (confirm(this.$t("regimen_edit.discard_changes") + "?")) {
        this.reset();
        this.load();
      }
    },
    reset() {
      let grid = [];
      for (let i = 0; i < 5; i++) {
        grid.push({
          id: uuid.v4(),
          interval: 7,
          exercises: [],
        });
      }

      this.grid_schedule = {
        clinic_id: this.clinic_id,
        grid_type: "grid",
        title: "",
        description: "",
        instructions: "",
        grid: grid,
      };

      this.current_grid_exercise_edit = {
        exercise_id: "",
        level: 0,
        instructions: "",
        config: {},
      };
      this.share_dependencies = [];
      this.share_dependencies_dialog = false;
    },
    load() {
      let self = this;
      // Get
      if (self.grid_schedule_id) {
        self.in_flight = true;
        axios.get("/api/grid_schedule/" + self.grid_schedule_id).then(function (response) {
          self.in_flight = false;
          let resp = response.data;
          if (resp.status == "error") {
            messageBus.$emit("api-error", resp);
            self.$emit("close");
          } else {
            self.grid_schedule = resp.data;
            self.fill_layout();

            for (let i = self.grid_schedule.grid.length; i < 5; i++) {
              self.grid_schedule.grid.push({
                id: uuid.v4(),
                interval: 7,
                exercises: [],
              });
            }
          }
        });
      } else if (self.existing_schedule) {
        // Deep clone
        self.grid_schedule = JSON.parse(JSON.stringify(self.existing_schedule));
        self.fill_layout();
        for (let i = self.grid_schedule.grid.length; i < 5; i++) {
          self.grid_schedule.grid.push({
            id: uuid.v4(),
            interval: 7,
            exercises: [],
          });
        }
      }
    },
    assessment_title(item) {
      let title = "";

      for (let grid_set of this.grid_sets) {
        if (item.assessment_id == grid_set.id) {
          title = grid_set.title;
          break;
        }
      }
      return title;
    },
    exercise_title(item) {
      if (item.title) {
        return item.title;
      }

      if (item.exercise_type == "assessment") {
        return this.assessment_title(item);
      }

      let exercise = null;
      if (item.exercise_type.startsWith("video")) {
        if (this.all_video_exercises[item.exercise_id]) {
          exercise = this.all_video_exercises[item.exercise_id];
        }
      } else {
        if (this.exercises[item.exercise_id]) {
          exercise = this.exercises[item.exercise_id];
        }
      }
      if (!exercise) {
        return "";
      }

      let locale = this.$i18n.lang();

      let title;
      if (exercise.title[locale]) {
        title = exercise.title[locale];
      } else {
        title = exercise.title.en;
      }

      if (item.video_exercise_variant && item.config && item.config.variants) {
        let variant = item.config.variants[item.video_exercise_variant];
        title += ": " + this.variant_title(variant);
      }

      return title;
    },
    variant_title(variant) {
      let locale = this.$i18n.lang();
      if (!variant.title) {
        return "";
      }

      if (variant.title[locale]) {
        return variant.title[locale];
      } else if (variant.title.en) {
        return variant.title.en;
      } else {
        return "";
      }
    },
    close() {
      this.$emit("close", {});
    },
    save_exercise() {
      this.dialog = false;

      // Deep clone via JSON
      let current_grid_exercise_edit = JSON.parse(JSON.stringify(this.current_grid_exercise_edit));
      if (current_grid_exercise_edit.new) {
        let new_i = this.current_i;
        this.current_i++;
        current_grid_exercise_edit.index = new_i;
        this.layout.push({
          x: this.week_focus - 1,
          y: 0,
          h: 1,
          w: 1,
          i: new_i,
          value: current_grid_exercise_edit,
        });
      } else {
        const index = this.layout.map((item) => item.i).indexOf(current_grid_exercise_edit.index);
        Vue.set(this.layout[index].value, current_grid_exercise_edit);
      }
      this.unsaved_data = true;
      this.current_grid_exercise_edit = this.blank_grid_exercise_edit();
    },
    edit_item(item) {
      const index = this.layout.map((item) => item.i).indexOf(item.i);
      this.current_grid_exercise_edit = this.layout[index].value;
      this.current_grid_exercise_edit.new = false;
      this.dialog = true;
    },
    add_item() {
      this.current_grid_exercise_edit = this.blank_grid_exercise_edit();
      this.dialog = true;
    },
    duplicate_item(item) {
      let new_item = JSON.parse(JSON.stringify(item));
      let new_i = this.current_i;
      this.current_i++;
      new_item.i = new_i;
      new_item.value.index = new_i;
      new_item.value.uuid = uuid.v4();
      this.layout.push(new_item);
    },
    delete_item(item) {
      const index = this.layout.map((item) => item.i).indexOf(item.i);
      this.layout.splice(index, 1);
    },
    blank_grid_exercise_edit() {
      return {
        uuid: uuid.v4(),
        exercise_id: "",
        customization: "",
        instructions: "",
        config: {},
        level: 0,
        new: true,
        index: null,
        config_version: 0,
      };
    },
    submit_grid_schedule() {
      let self = this;
      self.in_flight = true;

      // Remove all .config from video exercises
      // TODO: Remove this at some point in the future when it's irrelevant
      for (let week of this.grid_schedule.grid) {
        for (let exercise of week.exercises) {
          if (exercise.exercise_type == "video/builtin" || exercise.exercise_type == "video/clinic" || exercise.exercise_type == "video/public") {
            exercise.config = {};
          }
        }
      }

      if (this.grid_schedule.id) {
        let url = "/api/grid_schedule/" + self.grid_schedule.id;
        if (this.update_regimens_on_submit) {
          url += "?update_regimens=true";
        }

        // Update
        axios.put(url, self.grid_schedule).then(function (response) {
          self.in_flight = false;
          let resp = response.data;
          if (resp.status == "error") {
            messageBus.$emit("api-error", resp);
          } else {
            self.unsaved_data = false;
            messageBus.$emit("success", self.$t("grid_schedule_edit.schedule_updated"));
            self.$emit("grid-schedule-updated", self.grid_schedule);
            self.$emit("close");
          }
        });
      } else {
        // Create
        axios.post("/api/grid_schedule", self.grid_schedule).then(function (response) {
          self.in_flight = false;
          let resp = response.data;
          if (resp.status == "error") {
            messageBus.$emit("api-error", resp);
          } else {
            self.unsaved_data = false;
            messageBus.$emit("success", self.$t("grid_schedule_edit.schedule_created"));
            self.$emit("grid-schedule-updated", self.grid_schedule);
            self.grid_schedule.id = resp.data;
            self.$emit("close");
          }
        });
      }
    },
    submit: function (update_regimens = false) {
      this.update_regimens_on_submit = update_regimens;

      let self = this;

      this.error_message = "";

      if (!this.grid_schedule.title) {
        this.error_message = this.$t("grid_schedule_edit.title_required");
        this.$refs.title.focus();
        return;
      }

      if (this.grid_schedule.shared) {
        if (!this.grid_schedule.description) {
          this.error_message = this.$t("common.description_required_for sharing");
          this.$refs.description.focus();
          return false;
        }
      }

      // Fill weeks from grid layout
      this.fill_weeks();

      // Remove empty weeks
      this.grid_schedule.grid = this.grid_schedule.grid.filter((week) => week.exercises.length > 0);

      // Remove convienience values from grid set schedules before submitting
      // Also make sure intervals are ints
      for (var i in self.grid_schedule.grid) {
        for (var j in self.grid_schedule.grid[i].exercise) {
          delete self.grid_schedule.grid[i].exercises[j].new;
          delete self.grid_schedule.grid[i].exercises[i].index;
        }

        self.grid_schedule.grid[i].interval = parseInt(self.grid_schedule.grid[i].interval);
      }

      // If we're editing an existing grid_schedule, just pass it up
      if (self.existing_schedule) {
        this.$emit("grid-schedule-updated", self.grid_schedule);
        return;
      }

      // If we're shared, check for dependencies
      if (this.grid_schedule.shared) {
        // Check for dependencies
        this.in_flight = true;
        let video_exercise_promises = [];
        for (let stage of this.grid_schedule.grid) {
          for (let exercise of stage.exercises) {
            if (exercise.exercise_type == "video/clinic") {
              video_exercise_promises.push(axios.get("/api/video_exercise/" + exercise.exercise_id));
            }
          }
        }

        Promise.all(video_exercise_promises).then((results) => {
          let needs_additional_sharing = false;
          for (let result of results) {
            if (result.data.status == "success") {
              let video_exercise = result.data.data;

              if (this.share_dependencies.filter((d) => d.id === video_exercise.id).length == 0) {
                this.share_dependencies.push({
                  id: video_exercise.id,
                  title: video_exercise.title,
                  needs_share: !video_exercise.shared,
                });
              }
              if (!video_exercise.shared) {
                needs_additional_sharing = true;
              }
            }
          }
          if (needs_additional_sharing) {
            this.share_dependencies_dialog = true;
            this.in_flight = false;
          } else {
            this.submit_grid_schedule();
          }
        });
      } else {
        this.submit_grid_schedule();
      }
    },
  },
};
</script>

<style>
.vue-grid-layout {
  background: transparent;
}
.vue-grid-item {
  overflow: hidden;
}
.vue-grid-item:not(.vue-grid-placeholder) {
  background: #ccc;
  border: 1px solid black;
}
.vue-grid-item .resizing {
  opacity: 0.9;
}

.vue-grid-item .minMax {
  font-size: 12px;
}

.vue-grid-item .add {
  cursor: pointer;
}

.vue-grid-item > .vue-resizable-handle {
  height: 100px !important;
  cursor: col-resize !important;
}

.grid-layout-backing-table td {
  border-left: 1px solid gray;
  border-right: 1px so lid gray;
}

.grid-layout-wrapper {
  margin-top: 10px;
  overflow: scroll;
}

.grid-layout-wrapper::-webkit-scrollbar {
  width: 20px;
}

.grid-layout-wrapper::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

.grid-layout-wrapper::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border: 3px solid rgba(0, 0, 0, 0.18);
  background-color: #aaa;
}

.grid-width-1 .grid-item-title.long-title {
  font-size: 0.8rem;
  line-height: 95%;
}
</style>
