<template>
  <v-card id="invite_edit_modal">
    <v-card-title style="padding: 0px">
      <v-toolbar>
        <v-btn icon @click="close_invite_edit">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span v-if="local_invite.id">{{ local_invite.name }}</span>
          <span v-else>{{ $t("invite_edit.invite_new_user") }}</span>
          <span v-if="local_invite.email"
            >&nbsp;-&nbsp;{{ local_invite.email }}</span
          >
        </v-toolbar-title>
      </v-toolbar>
    </v-card-title>
    <v-card-text>
      <v-banner
        v-if="!local_invite.id && local_invite.user_role == 'user'"
        two-line
        style="margin-top: 20px; margin-bottom: 20px"
      >
        <p>{{ $t("invite_edit.new_user_first_text") }}</p>

        <p>{{ $t("invite_edit.new_user_second_text") }}</p>

        <p>{{ $t("invite_edit.new_user_third_text") }}</p>
      </v-banner>

      <div v-else style="margin-top: 20px"></div>

      <div v-if="!trial_expired || clinic.billing_strategy == 'user'">
        <v-layout>
          <v-flex xs4>
            <v-text-field
              ref="name_given"
              v-model="local_invite.name"
              :label="$t('common.first_name')"
              type="text"
              outlined
            />
          </v-flex>

          <v-flex xs4 offset-xs1>
            <v-text-field
              ref="name_family"
              v-model="local_invite.name_family"
              :label="$t('common.last_name')"
              type="text"
              outlined
            />
          </v-flex>

          <v-flex xs2 offset-xs1>
            <v-select
              v-model="lang"
              :items="languageOptions"
              :label="$t('common.language')"
              type="text"
              outlined
              dense
              height="55px"
            />
          </v-flex>
        </v-layout>

        <v-layout>
          <v-flex xs9>
            <v-text-field
              ref="email"
              v-model="local_invite.email"
              :label="$t('common.email')"
              type="text"
              :rules="email_rules"
              validate-on-blur
              outlined
              @unblur="email_rules = []"
              @blur="check_email"
            />
          </v-flex>

          <v-flex xs2 offset-xs1>
            <v-select
              v-if="
                local_invite.user_role == 'user' &&
                billingStrategyOptions.length
              "
              v-model="local_invite.billing_strategy"
              :items="billingStrategyOptions"
              :label="$t('common.billing')"
              :error-messages="billing_strategy_error_messages"
              outlined
              height="55px"
            >
              <template v-slot:item="{ item, index }">
                <v-list-item-content>
                  <v-list-item-title v-text="item.text" />
                  <v-list-item-subtitle v-if="item.subtext">
                    {{ item.subtext }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-select>
          </v-flex>
        </v-layout>

        <div v-if="allow_user_role_edit">
          <v-radio-group
            v-model="local_invite.user_role"
            :label="$t('common.role')"
            mandatory
          >
            <v-radio
              v-for="(role, index) in staff_roles"
              :key="index"
              :value="role.value"
            >
              <template v-slot:label>
                {{ role.text }}
                <span v-if="!is_educational">
                  -&nbsp;
                  <span style="font-size: smaller">{{ role.desc }}</span>
                </span>
              </template>
            </v-radio>
          </v-radio-group>
        </div>

        <v-checkbox
          v-if="current_user.is_admin && invite.id"
          v-model="local_invite.archived"
          label="Archived"
        />

        <v-textarea
          v-model="local_invite.email_message"
          :label="$t('invite_edit.message')"
          outlined
        />
      </div>

      <v-alert v-else type="info">
        <v-banner style="font-size: larger">
          <p>
            Cannot invite any additional users.
            {{ $t("clinic_page_notifications.your_free_trial_has_ended") }}.
          </p>
          <p>
            <span v-if="current_user_role == 'admin'">{{
              $t("clinic_page_notifications.enter_your_billing_information")
            }}</span>
            <span v-else>{{
              $t("clinic_page_notifications.staff_contact_admin")
            }}</span>
          </p>

          <v-col v-if="current_user_role == 'admin'" class="shrink">
            <v-btn light @click="billing_click">{{
              $t("clinic_page.enter_billing_info")
            }}</v-btn>
          </v-col>
        </v-banner>
      </v-alert>

      <v-alert v-if="show_hipaa_notice" outlined type="info">
        <p>HIPAA NOTICE</p>

        <p style="font-size: smaller">
          All invited staff must be authorized to access patient ePHI
          (Electronic Personal Health Information). This information includes
          patient names, email, assigned exercises and progress reports.
        </p>
      </v-alert>
    </v-card-text>

    <v-card-actions v-if="!trial_expired">
      <v-btn v-if="show_delete" color="error" @click="remove">
        <v-icon small left>delete_forever</v-icon>
        {{ $t("common.delete") }}
      </v-btn>

      <v-alert
        v-if="error_message != ''"
        outlined
        :value="error_message != ''"
        type="error"
        >{{ error_message }}</v-alert
      >

      <v-spacer />

      <v-checkbox
        v-if="current_user.is_admin || allow_edit_send_email"
        v-model="send_email"
        style="margin-right: 20px"
        :label="$t('invite_edit.send_email')"
      />

      <v-btn
        v-if="invite.id && send_email && !allow_edit_send_email"
        color="primary"
        @click="submit()"
        >{{ $t("invite_edit.update_invite_resend") }}</v-btn
      >
      <v-btn
        v-if="invite.id && (!send_email || allow_edit_send_email)"
        color="primary"
        @click="submit()"
        >{{ $t("invite_edit.update_invite") }}</v-btn
      >
      <v-btn v-if="!invite.id" color="primary" @click="submit()">{{
        $t("invite_edit.invite_user")
      }}</v-btn>
    </v-card-actions>

    <v-progress-linear v-if="in_flight" :indeterminate="true" />

    <v-dialog v-model="unarchive_dialog" max-width="500">
      <v-card v-if="unarchive_dialog">
        <v-card-title class="text-h5">{{
          $t("invite_edit.user_is_archived")
        }}</v-card-title>
        <v-card-text>{{ $t("invite_edit.user_is_archived_text") }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="unarchive_dialog = false">{{
            $t("common.cancel")
          }}</v-btn>
          <v-btn color="primary" @click="unarchive_invite">{{
            $t("invite_edit.unarchive")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "axios";
import OpticalGymUI from "../lib/OpticalGymUI";
import Vue from "vue";
import { mapState } from "vuex";

export default {
  components: {},
  props: {
    invite: {
      type: Object,
      required: true,
    },
    clinic: {
      type: Object,
      required: true,
    },
    current_user: {
      type: Object,
      required: true,
    },
    show_delete: {
      type: Boolean,
      default: false,
    },
    allow_user_role_edit: {
      type: Boolean,
      default: false,
    },
    resend_on_update: {
      type: Boolean,
      default: false,
    },
    show_hipaa_notice: {
      type: Boolean,
      default: false,
    },
    check_trial_expired: {
      type: Boolean,
      default: false,
    },
    current_user_role: {
      type: String,
      default: "",
    },
    show_user_role_option: {
      type: Boolean,
      default: false,
    },
    is_educational: {
      type: Boolean,
      default: false,
    },
    default_send_email: {
      type: Boolean,
      default: true,
    },
    allow_edit_send_email: {
      type: Boolean,
      default: false,
    },
    allow_test_users: {
      type: Boolean,
      default: true,
    },
    allow_edit_billing_strategy: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    let staff_roles = [
      {
        text: this.is_educational ? "Student" : this.$t("common.staff"),
        value: "staff",
        desc: this.$t("common.manage_regular_users"),
      },
      {
        text: this.is_educational ? "Teacher" : this.$t("common.administrator"),
        value: "admin",
        desc: this.$t("common.manage_regular_users_and_staff"),
      },
    ];

    if (this.show_user_role_option) {
      staff_roles.push({
        text: this.$t("common.user"),
        value: "user",
        desc: this.$t("common.patients"),
      });
    }
    let data = {
      languageOptions: [
        { value: "en", text: "English" },
        { value: "fr", text: "Français" },
        { value: "es", text: "Español" },
      ],
      lang: this.$i18n.lang(),
      local_invite: Object.assign({}, this.invite),
      invite_override: null,
      in_flight: false,
      name: "",
      error_message: "",
      email_rules: [],
      email_valid: true,
      email_hard_no: false,
      clinic_notifications: null,
      send_email: this.invite.id
        ? this.resend_on_update
        : this.default_send_email,
      staff_roles: staff_roles,
      email_checks: {},
      unarchive_dialog: false,
      billing_strategy: this.invite.billing_strategy,
      billing_strategy_error_messages: "",
    };
    return data;
  },
  computed: {
    ...mapState(["app_config"]),
    billingStrategyOptions() {
      let options;
      if (!this.allow_edit_billing_strategy) {
        return [];
      }
      if (this.clinic.billing_strategy == "clinic") {
        options = [
          {
            value: null,
            text: `${this.$t("common.default")} (${this.$t(
              "common.clinic_pays"
            )})`,
          },
          { 
            value: "user", 
            text: this.$t("common.user_pays") 
          }
        ];
      } else if (this.clinic.billing_strategy == "user") {
        options = [
          {
            value: null,
            text: `${this.$t("common.default")} (${this.$t(
              "common.user_pays"
            )})`,
          },
        ];
        let opt = {
          value: "clinic",
          text: this.$t("common.clinic_pays"),
        };
        if (!this.clinic.stripe_subscription_id) {
          opt.disabled = true;
          opt.subtext = this.$t("invite_edit.clinic_pays_disabled");
        }
        options.push(opt);
      } else {
        return [];
      }

      if (this.allow_test_users) {
        options.push({ value: "free", text: this.$t("common.test_user_free") });
      }

      return options;
    },
    trial_expired() {
      if (
        this.check_trial_expired &&
        this.clinic_notifications &&
        this.clinic_notifications.trial_end
      ) {
        let now = new Date();
        let trial_end = new Date(this.clinic_notifications.trial_end);
        if (trial_end < new Date("Aug 1, 2020")) {
          trial_end = new Date("Aug 1, 2020");
        }

        // 1 week grace period
        trial_end.setDate(trial_end.getDate() + 7);
        if (now > trial_end) {
          return true;
        }
      }
      return false;
    },
  },
  watch: {
    invite() {
      this.local_invite = Object.assign({}, this.invite);
    },
  },

  mounted() {
    if (this.check_trial_expired) {
      this.load_clinic_notifications();
    }
  },
  methods: {
    close_invite_edit: function () {
      this.local_invite = Object.assign({}, this.invite);
      this.$emit("close-invite-edit");
    },
    check: function () {
      this.error_message = "";
      this.email_rules = [];

      if (!this.local_invite.name) {
        this.error_message = this.$t("invite_edit.provide_first_name");
        this.name_given_error = true;
        if (this.$refs.name_given) {
          this.$refs.name_given.focus();
        }
        return false;
      }
      if (!this.local_invite.name_family) {
        this.error_message = this.$t("invite_edit.provide_last_name");
        this.name_family_error = true;
        if (this.$refs.name_family) {
          this.$refs.name_family.focus();
        }
        return false;
      }

      this.local_invite.email = this.local_invite.email.trim();
      if (!this.local_invite.email) {
        this.email_rules = ["Missing email"];
        this.error_message = this.$t("common.missing_email");
        if (this.$refs.email) {
          this.$refs.email.focus();
        }
        return false;
      }
      if (!OpticalGymUI.validate_email(this.local_invite.email)) {
        this.email_rules = [this.$t("invite_edit.invalid_email_message")];
        this.error_message = this.$t("invite_edit.invalid_email");
        return false;
      }

      // All checks pass
      return true;
    },
    submit() {
      let self = this;

      if (!this.check()) {
        return false;
      }

      // Set the owner ID if we are not a super-admin
      // TODO: Make this explicit?
      if (!self.current_user.is_admin) {
        this.local_invite.owner_id = self.current_user.id;
      }

      this.billing_strategy_error_messages = "";
      this.in_flight = true;

      OpticalGymUI.check_email(
        self.local_invite.email,
        function (check_email_result) {
          self.email_rules = check_email_result.email_rules;
          self.email_valid = check_email_result.valid;
          self.email_hard_no = check_email_result.hard_no;

          if (self.email_hard_no && !self.current_user.is_admin) {
            self.error_message = self.$t("invite_edit.invalid_email");
            self.error_message += ": " + self.email_rules.join(", ");
            self.in_flight = false;
            return false;
          }

          let invite_id = self.local_invite.id || self.invite.id;
          if (invite_id) {
            let url = "/api/invite/" + invite_id + "?lang=" + self.lang;
            if (self.send_email) {
              url += "&resend=true";
            }
            axios.put(url, self.local_invite).then(function (response) {
              self.in_flight = false;
              let resp = response.data;
              if (resp.status == "error") {
                if (resp.error_code == "EmailInvalidHost") {
                  let parts = self.local_invite.email.split("@");
                  self.error_message =
                    parts[1] + " " + self.$t("invite_edit.is_not_valid");
                  messageBus.$emit(
                    "error",
                    self.$t("invite_edit.cannot_create_invite") +
                      ". '" +
                      parts[1] +
                      "' " +
                      self.$t("invite_edit.is_not_valid") +
                      "."
                  );
                } else if (resp.error_code.hasOwnProperty("EmailHighRisk")) {
                  self.error_message = self.$t("common.invalid_email_address");
                  messageBus.$emit(
                    "error",
                    self.$t("invite_edit.cannot_create_invite") + "."
                  );
                } else {
                  if (resp.error_code == "TooManyMockInvites") {
                    self.billing_strategy_error_messages = self.$t(
                      "invite_edit.too_many_test_users"
                    );
                  }
                  messageBus.$emit("api-error", resp);
                }
              } else {
                if (self.send_email) {
                  messageBus.$emit(
                    "success",
                    self.$t("invite_edit.invite_updated_email_sent")
                  );
                } else {
                  messageBus.$emit(
                    "success",
                    self.$t("invite_edit.invite_updated")
                  );
                }

                self.$emit("invite-updated", self.local_invite);
                self.$emit("close-invite-edit");
              }
            });
          } else {
            // Create
            axios
              .post(
                "/api/invite?lang=" +
                  self.lang +
                  "&send_email=" +
                  self.send_email,
                self.local_invite
              )
              .then(function (response) {
                self.in_flight = false;
                let resp = response.data;
                if (resp.status == "error") {
                  if (resp.error_code == "InviteUserExists") {
                    self.error_message = self.$t(
                      "invite_edit.user_with_email_accepted_invitation"
                    );
                  } else if (resp.error_code == "InviteUserExistsArchived") {
                    self.unarchive_dialog = true;
                  } else if (resp.error_code == "InviteEmailExists") {
                    let resend = window.confirm(
                      self.$t("invite_edit.invitation_already_exists")
                    );
                    if (resend) {
                      axios
                        .get(
                          "/api/invite/by_email/" +
                            self.local_invite.clinic_id +
                            "/" +
                            self.local_invite.email
                        )
                        .then(function (response) {
                          let resp = response.data;
                          if (resp.status == "error") {
                            messageBus.$emit("api-error", resp);
                          } else {
                            self.local_invite.id = resp.data.id;
                            self.local_invite.token = resp.data.token;
                            self.local_invite.token_secret =
                              resp.data.token_secret;
                            Vue.nextTick(self.submit);
                          }
                        });
                    }
                  } else if (resp.error_code == "EmailInvalidHost") {
                    let parts = self.local_invite.email.split("@");
                    self.error_message = parts[1] + " is not valid";
                    messageBus.$emit(
                      "error",
                      self.$t("invite_edit.invite_updated_email_sent") +
                        ". '" +
                        parts[1] +
                        "' " +
                        self.$t("invite_edit.is_not_valid") +
                        "."
                    );
                  } else if (resp.error_code.hasOwnProperty("EmailHighRisk")) {
                    self.error_message = self.$t(
                      "common.invalid_email_address"
                    );
                    messageBus.$emit(
                      "error",
                      self.$t("invite_edit.cannot_create_invite")
                    );
                  } else {
                    if (resp.error_code == "TooManyMockInvites") {
                      self.billing_strategy_error_messages = self.$t(
                        "invite_edit.too_many_test_users"
                      );
                    }
                    messageBus.$emit("api-error", resp);
                  }
                } else {
                  messageBus.$emit(
                    "success",
                    self.$t("invite_edit.invite_sent")
                  );
                  self.local_invite.id = resp.data;
                  self.$emit("invite-created", self.local_invite);
                  self.$emit("close-invite-edit");
                }
              });
          }
        }
      );
    },
    remove: function () {
      let self = this;
      let confirm_message = self.$t(
        "invite_edit.are_you_sure_you_wish_to_delete_this_invite"
      );

      if (confirm(confirm_message)) {
        self.in_flight = true;
        axios.delete("/api/invite/" + self.invite.id).then(function (response) {
          self.in_flight = false;
          let resp = response.data;
          if (resp.status == "error") {
            messageBus.$emit("api-error", resp);
          } else {
            messageBus.$emit("success", self.$t("invite_edit.invite_deleted"));
            self.$emit("invite-deleted", self.local_invite);
            self.$emit("close-invite-edit");
          }
        });
      }
    },
    check_email: function () {
      let self = this;
      let email = this.local_invite.email;
      this.email_rules = [];

      if (!OpticalGymUI.validate_email(email)) {
        this.email_rules = [this.$t("common.invalid_email_address")];
        return;
      }

      OpticalGymUI.check_email(email, function (result) {
        self.email_rules = result.email_rules;
        self.email_valid = result.valid;
        self.email_hard_no = result.hard_no;
      });
    },
    load_clinic_notifications() {
      let self = this;
      axios
        .get("/api/notifications/clinic/" + self.invite.clinic_id)
        .then(function (response) {
          if (response.data.status == "success") {
            self.clinic_notifications = response.data.data;
          } else {
            messageBus.$emit("error", response.data.error_message);
          }
        });
    },
    billing_click() {
      this.local_invite = Object.assign({}, this.invite);
      this.$emit("close-invite-edit");
      this.$emit("billing_click");
    },
    unarchive_invite() {
      let self = this;
      axios
        .get(
          "/api/invite/by_email/" +
            self.local_invite.clinic_id +
            "/" +
            self.local_invite.email
        )
        .then(function (resp) {
          if (resp.data.status == "success") {
            let invite = resp.data.data;
            invite.archived = false;
            invite.expiry_date = null;
            invite.user_role = "user";
            invite.billing_strategy = null;
            axios
              .put("/api/invite/" + invite.id, invite)
              .then(function (response) {
                if (response.data.status == "success") {
                  messageBus.$emit(
                    "success",
                    self.$t("clinic_page.user_restored")
                  );
                  self.local_invite = invite;
                  self.unarchive_dialog = false;
                  self.$emit("invite-updated", self.local_invite);
                  self.$emit("close-invite-edit");
                } else {
                  messageBus.$emit("error", response.data.error_message);
                }
              });
          } else {
            messageBus.$emit("error", resp.data.error_message);
          }
        });
    },
  },
};
</script>

<style>
#invite_edit_modal .v-messages__message {
  line-height: normal;
}
</style>
